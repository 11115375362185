import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class StockActivityService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'stockActivity';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'stockActivity/lazySearch';
    return this.http.post(url, payload);
  }
  getStatusCountPD(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'stockActivity/getStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'stockActivity/' + id;
    return this.http.get(url);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'stockActivity/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'stockActivity/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  getByIdAsync(id): any {
    const url = this.baseURL + 'stockActivity/' + id;
    return this.http.get(url).toPromise();
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getbrewNo(category): Observable<any> {
    const url = this.baseURL + 'brewIntimation/brewPlanNo?licenseNo=' + category;
    return this.http.get(url);
  }
  getbrewNoData(category): Observable<any> {
    const url = this.baseURL + 'brewingBook/approved/brewNoDetails?licenseNo=' + category;
    return this.http.get(url);
  }
  getrawMaterial(): Observable<any> {
    const url = this.baseURL + 'findBrandDetails/stockActivity/rawMaterial?entityType=BREWERY';
    return this.http.get(url);
  }
  getrawMaterial1(unitcode): Observable<any> {
    const url = this.baseURL + 'findBrandDetails/stockActivity/unitCode/rawMaterial?entityType=BREWERY&unitCode='+unitcode;
    return this.http.get(url);
  }
  getrawMaterialAysnc(unitcode): any {
    const url = this.baseURL + 'findBrandDetails/stockActivity/unitCode/rawMaterial?entityType=BREWERY&unitCode='+unitcode;
    return this.http.get(url).toPromise();
  }
 
  getoldQuantity(licanceNum, rawCode, entityType = 'BREWERY'): Observable<any> {
    const url = this.baseURL + 'stockActivity/oldQuantity/' + rawCode + '?licenseNo=' + licanceNum + '&entityType=' + entityType;
    return this.http.get(url);
  }
  approveStock(postdata): Observable<any> {
    const url = this.baseURL + 'stockActivity/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  updateStock(postdata): Observable<any> {
    const url = this.baseURL + 'stockActivity/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  download(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=STOCK_IN_PENALTY';
    return this.http.get(url, { responseType: 'blob' });
  }
}
