<h5 class="m-b-10 ng-star-inserted">
    Accidental Request
    <button type="submit" class="btn btn-success custom_add_button pull-right"
        routerLink="/sugarmill/{{outsideupvalidation}}/add" *ngIf="designation == 'DEO'">
        <i class="feather icon-plus mr-2"></i>
        Add Accidental Request
    </button>
</h5>

<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'SUGAR_MILL'"><a href="javascript:">
            {{'language.sugarmill' | translate}} </a></li>
    <li class="breadcrumb-item"><a href="javascript:">Accidental Request</a></li>
    <li class="breadcrumb-item">List</li>
</ul>
<div class="row">
    <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg4 animate__animated animate__backInUp">
          <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="changeStatus('normal');getList('APPROVED')">
            <div class="row align-items-center">
              <div class="col">
                <span>{{ 'brewery.brandlabelreglist.brandapproved' | translate }}</span>
                <h3 class="mt-2 strong">{{dataCount?.totalApprovedApplications}}</h3>
                <i class="fas fa-thumbs-up"></i>
              </div>
            </div>
          </app-card>
        </div>
        
        <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg1 animate__animated animate__backInUp" *ngIf="isCustomer">
          <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="changeStatus('current');getList('IN_PROGRESS')">
            <div class="row align-items-center">
              <div class="col">
                <span>{{ 'brewery.brandlabelreglist.brandreqpending' | translate }}</span>
                <h3 class="mt-2 strong">{{dataCount?.totalPendingApplications}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
        
        <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg1 animate__animated animate__backInUp" *ngIf="!isCustomer">
          <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="changeStatus('current');getList('IN_PROGRESS')">
            <div class="row align-items-center">
              <div class="col">
                <span>{{ 'brewery.brandlabelreglist.brandreqpending' | translate }} (Current
                  User)</span>
                <h3 class="mt-2 strong">{{dataCount?.totalPendingApplicationsCurrentOfficer}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
        
        <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg5 animate__animated animate__backInUp" *ngIf="!isCustomer">
          <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="changeStatus('Officer');getList('IN_PROGRESS')">
            <div class="row align-items-center">
              <div class="col">
                <span style="position: absolute;width: 100%;">{{ 'brewery.brandlabelreglist.brandreqpending' | translate }}(All Other Officer)</span><br>
                <h3 class="mt-2 strong">{{dataCount?.totalPendingApplicationsOtherOfficer}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
        
        <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg3 animate__animated animate__backInUp">
          <app-card [hidHeader]="true" cardClass="comp-card bg-danger linkClass" (click)="changeStatus('normal');getList('REJECTED')">
            <div class="row align-items-center">
              <div class="col">
                <span>{{ 'brewery.brandlabelreglist.brandreqrejected' | translate }}</span>
                <h3 class="mt-2 strong">{{dataCount?.totalRejectedApplications}}</h3>
                <i class="fas fa-thumbs-down text-danger"></i>
              </div>
            </div>
          </app-card>
        </div>
    </div>
<div class="row">
    <div class="col-xl-12">
        <app-card cardTitle="Search" class="search-panel">
            <div class="row">
                <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Transport Pass Number</label>
                        <input type="text" class="form-control" 
                            placeholder="Enter Transport Pass Number"/>
                    </div>
                </div>  -->
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Request ID</label>
                        <select class="form-control" [(ngModel)]="Obj['requestId']">
                            <option value="">select</option>
                            <option *ngFor="let item of accidentRequestData" value="{{item.applicationNumber}}">
                                {{item.applicationNumber}}</option>
                        </select>


                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Request Date</label>
                        <input type="text" [(ngModel)]="Obj['requestDate']" placeholder="DD/MM/YYYY"
                            (keydown)="$event.preventDefault();" class="form-control"
                            [owlDateTimeTrigger]="dtPickerThisEDate" [owlDateTime]="dtPickerThisEDate"
                            id="fortnightEndDate">
                        <owl-date-time #dtPickerThisEDate [pickerType]="'calendar'"></owl-date-time>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Request Date</label>
                        <select class="form-control" [(ngModel)]="Obj['requestDate']">
                            <option value="">DD--MM--YYYY</option>
                            <option *ngFor="let item of accidentRequestData" value="{{item.requestDate}}">{{item.requestDate | date:"dd-MMM-yyyy"}}</option>
                        </select>

                    </div>
                </div>  -->
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>MF-4 Gate Pass No</label>
                        <!-- <select class="form-control" [(ngModel)]="Obj['gatePassNo']" 
                        (change)="getMf4PassNo()">
                            <option value="">select</option>
                            <option *ngFor="let item of mf4PassNoList" value="{{item.mf4GatePassNo}}">{{item.mf4GatePassNo}}</option>
                        </select> -->
                        <ng-multiselect-dropdown [data]="mf4PassNoList" [placeholder]="'Select'"
                            [(ngModel)]="Obj['gatePassNo']" [settings]="brandDropDownSettings"
                            (onFilterChange)="onFilterChangeBrand($event)"
                            (onDropDownClose)="onDropDownCloseBrandFilter()"
                            [ngClass]="brandDropDownSettings.singleSelection ? 'singleselect' : '' ">
                        </ng-multiselect-dropdown>

                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Accident Type</label>
                        <select class="form-control" [(ngModel)]="Obj['accidentType']">
                            <option value="">select</option>
                            <option *ngFor="let item of accidentTypeList" value="{{item.name}}">{{item.name}}</option>
                        </select>

                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>{{'mf1.list.status' | translate}}</label>
                        <select id="status" required class="form-control" [(ngModel)]="Obj['status']">
                            <option value="" selected>{{'mf1.list.select' | translate}}</option>
                            <option *ngFor="let item of statuslist" value="{{item.value}}">{{item.name}}</option>

                        </select>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <button type="submit" class="btn btn-primary mr-2" (click)="getData()">{{
                        'language.search' | translate }}</button>
                    <button type="submit" class="btn btn-secondary"(click)="changeStatus('current');clearData()">{{
                        'language.clear' | translate }}</button>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div class="row">
    <div class="col-xl-12">
        <app-card cardTitle="Accidental Request List" class="list-panel">
            <div class="table-responsive">
                <app-mat-normal-table [columns]="columns" [actionKeys]="actionKeys" [dataSource]="dataSource"
                    [actionBtnBasedOnCond]="actionBtnBasedOnCond" [pageSize]="pageSize" [totalCount]="totalCount"
                    (paginationEvent)="handlePagination($event)" (editAction)="onEdit($event)"
                    (viewAction)="onView($event)">
                </app-mat-normal-table>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div ngbDropdown>
                        <button class="btn btn-success" ngbDropdownToggle type="button">{{'parentunit.common.download' |
                            translate}}</button>
                        <div ngbDropdownMenu>
                            <a class="dropdown-item" (click)="ExportTOExcel()" href="javascript:">{{
                                'sugarmill.common.excel' | translate }}</a>
                            <a class="dropdown-item" (click)="generatePdf()" href="javascript:">{{
                                'sugarmill.common.pdf' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>
    </div>
</div>