import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class ExportPermitService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  baseUrlMaster = environment.baseURLMaster;
  smcProd = environment.scmproduction;
  wholesaleNewURL = environment.wholesaleNewURL;
  domainUrl = environment.domainUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'exportPermit/';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'exportPermit/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'exportPermit/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getState(): Observable<any> {
    const url = this.baseUrlMaster + 'location/findStateByCountryCode?countryCode=IN';
    return this.http.get(url);
  }
  getBrand(licenseType): Observable<any> {
    const url = this.smcProd + 'brandLabel/existing?licenseType=' + licenseType;
    return this.http.get(url);
  }
  getExportPermitList(payload): Observable<any> {
    console.log("environment", environment)
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, payload);
  }
  getRecordCount(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }

  getFeesDetails(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search'
    return this.http.post(url, postParam);
  }
  getAdvFees(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get'
    return this.http.post(url, postParam);
  }

  feesDetecting(payload){
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd'
    return this.http.post(url, payload);
  }

  updateTransPaymentStatus(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'exportpermit/updateexportpermitpaymentstatus';
    return this.http.post(url, payload);
  }


  updateTransactionNumber(payload){
    const url = this.wholesaleNewURL + 'customdata/savedata'
    return this.http.post(url, payload);
  }

  amountRefund(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/walletrefund'
    return this.http.post(url, payload);
  }

  update(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/updateindentrequestfundstatus";
    return this.http.post(url, postData)
  }
  dutyAmountGetPromise(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/advanceDuty/get";
    return this.http.post(url, postData).toPromise();
  }
  payandreconPromise(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/config/sub/search";
    return this.http.post(url, postData).toPromise();
  }
  getFeesDetailsForExportPermit(postParam): any {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search'
    return this.http.post(url, postParam).toPromise();
  }

}
