import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
    providedIn: 'root'
})
export class PinCodeMasterService {

    baseURL = environment.baseURLMaster;
    baseURLIpAddr = environment.baseURLMasterIpAddr;

    constructor(
        private http: HttpClient
    ) { }


    findStateDistrictyByPinCode(code): Observable<any> {
        return this.http
            .get( this.baseURLIpAddr + 'location/findStateDistrictyByPinCode?pinCode=' + code)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
   
}
