import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
@Injectable({
  providedIn: 'root'
})
export class WastageOfBeerBottlingService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  domainUrl = environment.domainUrl;

  addetails(data): Observable<any> {
      const url = this.baseURL + 'breweryMstIst';
      return this.http.post(url, data);
  }
  search(payload): Observable<any> {
      const url = this.baseURL + 'breweryMstIst/lazySearch';
      return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
      const url = this.baseURL + 'breweryMstIst/' + id;
      return this.http.get(url);
  }

  getTanks(payload): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, payload);
}
  // payment related api's
  saveupdatepaymentdetails(payload): Observable<any> {
      const url = this.baseURL + 'breweryMstIst/saveUpdatePaymentDetails';
      return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
      let url = this.baseURL + 'breweryMstIst/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
      return this.http.get(url);
  }
  licenceAdd(payload): Observable<any> {
      const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
      return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
      const url = this.baseURL + 'reportReceipt/downloadSampleFeeReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
      return this.http.get(url, { responseType: 'blob' });
  }
  getPaymentDetail(payload): Observable<any> {
      const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
      return this.http.post(url, payload);
  }
  
}
