import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class TransferOfBeerService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'transferOfBeer';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'transferOfBeer/lazySearch';
    return this.http.post(url, payload);
  }
  asysearch(payload):any {
    const url = this.baseURL + 'transferOfBeer/lazySearch';
    return this.http.post(url, payload).toPromise();;
  }
  searchbottlingPlanTank(payload): Observable<any> {
    const url = this.baseURL + 'transferOfBeer/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'transferOfBeer/' + id;
    return this.http.get(url);
  }
  getBottlingDetail(entityType){
    const url = this.baseURL + 'transferOfBeer/getBottlingPlanDetails?entityType='+entityType;
    return this.http.get(url);
  }
  getBottlingDetailNew(entityType,licenseType):any{
    const url = this.baseURL + 'transferOfBeer/getBottlingPlanDetails?entityType='+entityType +'&licenseType='+licenseType;
    return this.http.get(url).toPromise();
  }
  getBottlingDetailNew1(entityType,licenseType,status):any{
    const url = this.baseURL + 'transferOfBeer/getBottlingPlanDetails?entityType='+entityType +'&licenseType='+licenseType+'&status='+status;
    return this.http.get(url).toPromise();
  }
  getStatusCount(entityType?, createdBy?): Observable<any> {
    const url = this.baseURL + 'transferOfBeer/getStatusCount?entityType=' + entityType +'&createdBy=' + createdBy;
    return this.http.get(url);
  }
  downloadUnitank(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getBreweryCardCount(payload): Observable<any> {
    const url = this.baseURL + 'brewery/getStatusCount'
    return this.http.post(url,payload);
  }
  // http://65.0.150.210:3501/scmProduction/swagger-ui.html#!/transfer-of-beer-controller/getBottlingDetailsUsingGET/
}
