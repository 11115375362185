import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class BrewerBoxService {

  constructor(private http: HttpClient) { }
  baseURL = environment.wholesaleNewURL;
  paymentURL = environment.payandreconBaseUrl;
  trackandtrace = environment.trackandtraceUrl;
  licenseURL = environment.licneseBaseUrl;

  get(data): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, data);
  }

  // saveboxingplan(data): Observable<any> {
  //   const url = this.baseURL + 'boxing/save';
  //   return this.http.post(url, data);
  // }

  saveboxingplan(data): Observable<any> {
    const url = this.baseURL + 'boxing/records';
    return this.http.post(url, data);
  }

  startPrinting(data): Observable<any> {
    const url = this.baseURL + 'qrprint/schedulebwprinting';
    return this.http.post(url, data);
  }

  sopPrinting(data): Observable<any> {
    const url = this.baseURL + 'qrprint/stopbwprinting';
    return this.http.post(url, data);
  }

  //   search(payload): Observable<any> {
  //     const url = this.baseURL + 'stockActivity/lazySearch';
  //     return this.http.post(url, payload);
  //   }
  //   getById(id): Observable<any> {
  //     const url = this.baseURL + 'stockActivity/' + id;
  //     return this.http.get(url);
  //   }
  getRecordCount(postParam): any {
    const url = this.baseURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }

  submitWastage(data): Observable<any> {
    const url = this.baseURL + 'productionwastage/submitwastage';
    return this.http.post(url, data);
  }
  save(data): Observable<any> {
    const url = this.baseURL + 'customdata/savedata';
    return this.http.post(url, data);
  }

  savePenalty(data): Observable<any> {
    const url = this.paymentURL + 'trackandtrace/v1.0.0/penalty/savePenaltyDetails';
    return this.http.post(url, data);
  }

  payMethod(payValues) {
    const url = this.paymentURL + 'payandrecon/1.0/config/getdutybalance';
    return this.http.post(url, payValues);

  }
  payPostMethod(paylod) {
    const url = this.trackandtrace + "penalty/savePaymentDetails";
    return this.http.post(url, paylod)
  }

  payPostMethod1(paylod) {
    const url = this.trackandtrace + "penalty/savePenaltyDetails";
    return this.http.post(url, paylod)
  }

  getAppConfigValue(postParam): any {
    const url = this.baseURL + 'appconfig/getappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }
  boxingProcess(data): Observable<any> {
    // const url = this.baseURL + 'boxing/records2';
    const url = this.baseURL + 'boxing/records';
    return this.http.post(url, data);
  }

  getAppConfigValueEntityWise(postParam): any {
    const url = this.baseURL + 'appconfig/getentityappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }

  palletBeforeBoxingProcess(data): Observable<any> {
    const url = this.baseURL + 'palletstock/stocktransferbeforeboxing';
    return this.http.post(url, data);
  }
  entityAppKey(data): Observable<any> {
    const url = this.baseURL + 'appconfig/getentityappconfigvalue'
    return this.http.post(url, data);
  }
  getLicanceData(category, subCategory?): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category; /* + '?subCategory=' + subCategory; */
    return this.http.get(url);
  }

}
