import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from "@appEnv/apiurls";

@Injectable({
  providedIn: 'root'
})
export class AddcomplaintagainstshoporlicenseeService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  baseURLUser = environment.baseURLUser;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  payandreconBaseUrl = environment.payandreconBaseUrl;

  getdetails(): Observable<any> {
    ///const url = this.licenseURL + 'license/category/SUGAR_MILL';
    const url = this.licenseURL + 'license/category/BREWERY';
    return this.http.get(url);
  }

  addComplaintAgainstShopOrLicnesee(data): Observable<any> {
    const url = this.baseURL + 'vigilance';
    return this.http.post(url, data);
  }

  search(payload): Observable<any> {
    const url = this.baseURL + 'vigilance/lazySearch';
    return this.http.post(url, payload);
  }

  getapplicationnumbers(data) {
    //const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    const url = this.baseURL + 'generateApplicationNumber?entityType=' + 'VIGILANCE' + '&registrationType=' + data.registrationType; + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {});
  }

  getComplaintById(id): Observable<any> {
    const url = this.baseURL + 'vigilance/' + id;
    return this.http.get(url);
  }

  EntityDropDown(type: String): Observable<any> {
    const url = this.payandreconBaseUrl + 'payandrecon/1.0/EntityTypeMaster/getEntityByModuleCode?moduleCode=' + type;
    return this.http.get(url);
  }

  getLicenseType(type: String): Observable<any> {
    const url = this.licenseURL + 'license/category/' + type;
    return this.http.get(url);
  }

  getActiveEntityType(postParam): Observable<any> {
    const url = this.baseURLUser + apiUrls.getActiveEntityType;
    return this.http.post(url, postParam);
  }

  getLicenseType1(obj): Observable<any> {
    const url = this.baseURLUser + apiUrls.getLicenseType + obj;
    //const url = this.licenseURL + 'license/category/' + obj;
    return this.http.get(url);
  }

  getLicenseTypeDropDown(payload): Observable<any> {
    let url = this.baseURL + 'vigilance/getVigilanceDropDown?type='
      + payload.type + '&dropDownValue=' + payload.dropDownValue
      + '&entityType=' + payload.entityType;
    return this.http.get(url);
  }
}
