import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class BottlingProducatioinPlanService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.scmproduction;
  licenseURL = environment.licneseBaseUrl;
  domainUrl = environment.domainUrl;
  qaurl = environment.wholesaleNewURL;
  get(data): Observable<any> {
    const url = this.qaurl + 'customdata/getdata';
    return this.http.post(url, data);
  }
  getRecordCount(postParam): any {
    const url = this.qaurl + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }
  getById(id): any {

    const url = this.baseURL + apiUrls.bottlinggetbyid + id;
    return this.http.get(url).toPromise();

  }

  addBottling(data): Observable<any> {
    const url = this.baseURL + apiUrls.addbottlingdata;
    return this.http.post(url, data);
  }

  addsubBottling(data): Observable<any> {
    const url = this.baseURL + "bottlingProductionPlan/sub/bottlingPlan";
    return this.http.post(url, data);
  }

  getBrandDetailsByEtin(etin): Observable<any> {
    const url = this.baseURL + apiUrls.getBrandDetailsByEtin + "/" + etin;
    return this.http.get(url);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + apiUrls.bottlingsearch;
    return this.http.post(url, payload);
  }
  search1(payload): Observable<any> {
    const url = this.baseURL + 'bottlingProductionPlan/sub/bottlingPlan/lazySearch';
    return this.http.post(url, payload);
  }

  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, postdata);
  }

  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'bottlingProductionPlan/findPaymentDetails?applicationNumber=' + applicationno + '&codeType=' + feetype;
    return this.http.get(url);
  }


  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'bottlingProductionPlan/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }

  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  getPaymentDetailAsync(payload): any {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload).toPromise();
  }
  getAdvanceDuty(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get';
    return this.http.post(url, payload);
  }
  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadBottlingPlanReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=MAPPED';
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadRecipt(appnumber, liceanseNumber, feeType): any {
    // console.log("this.baseURL",this.domainUrl);
    const url = environment.parentUnit + 'reportReceipt/downloadBottlingPlanReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=MAPPED';
    return this.http.get(url, { responseType: 'blob' });
  }

  productionlineconfig(): any {
    // console.log("this.baseURL",this.domainUrl);
    const url = this.baseURL + 'bottlingProductionPlan/getProductLineConfig?entityType=BREWERY';
    return this.http.get(url);
  }
  planIddropdown(payload){
    let url = this.baseURL+'bottlingProductionPlan/bottlingPlanDropDown'
    return this.http.post(url, payload);
  }

  getStatusCount(entityType?,subbottlingPlan?): Observable<any> {
    const url = this.baseURL + 'bottlingProductionPlan/getStatusCount?entityType=' + entityType + '&subbottlingPlan=' +subbottlingPlan ;
     return this.http.post(url,entityType,subbottlingPlan);
      }
   getPdCardCount(payload): Observable<any> {
        const url = this.baseURL + 'pd/getStatusCount'
        return this.http.post(url,payload);
      }
    getbreweryCardCount(payload): Observable<any> {
        const url = this.baseURL + 'brewery/getStatusCount'
        return this.http.post(url,payload);
      }

      getealfeesvalue(payload): Observable<any> {
        const url = this.baseURL + "bottlingProductionPlan/getEalFeeDetails?unitCode="+payload.unitCode+"&entityType="+payload.entityType+"&subModuleCode="+payload.subModuleCode+"&domainValue="+payload.domainValue+"&supplyTypeValue="+payload.supplyTypeValue;
        return this.http.get(url);
      }

}
