import { Injectable }                                                              from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { LicenceManagementService } from '@app/services/license-managment.service';
import { LicenseConstants, LAT_LONG_RANGE } from '@app/containers/licencemgnt/license.constants';

const {
    STATUS_DRAFT, STATUS_SAVE, STATUS_SUBMITTED, STATUS_SENDBACK, STATUS_APPROVED, STATUS_PENDING_SITE_INFO,
    STATUS_SENDBACK_SITE_INFO, CUSTOMER, LOCALITY_URBAN, LOCALITY_RURAL, PREVIOUSYEAR_ITR, CURRENTYEAR_ITR,BEFOREPREVIOUSYEAR_ITR
} = LicenseConstants;

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    valiForLicenseNumber = [Validators.pattern("^[a-zA-Z0-9\/]{1,100}$")];
    valiForAlphaNumeric = [Validators.required, Validators.pattern("^[a-zA-Z0-9]{1,100}$")];
    valiForStreetVarcharSpl = [ Validators.required, Validators.pattern("^[ A-Za-z0-9.,'%_@\/#&-]*$"), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$')];
    valiForStreetVarcharSplOnly = [ Validators.pattern("^[ A-Za-z0-9.,'%_@\/#&-]*$"), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$')];
    valiForSingleSpaceChar = [ Validators.required, Validators.pattern("^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$")];
    valiForSingleSpaceCharOnly = [ Validators.pattern("^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$")];
    valiForSingleSpaceAlphaNumeric = [ Validators.required, Validators.pattern(/^[A-z0-9.,'%_@\/#&-]+([-\s]{1}[A-z0-9.,'%_@\/#&-]+)*$/)];
    valiForSingleSpaceNumeric = [ Validators.required, Validators.pattern(/^[A-z0-9()]+([-\s]{1}[A-z0-9()]+)*$/)];

    valiForSingleSpaceFirstNameChar = [ Validators.required, Validators.maxLength(50), Validators.pattern("^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$")];
    valiForSingleSpaceNameOnly = [ Validators.maxLength(50), Validators.pattern("^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$")];

    valiForNum = [Validators.required, Validators.pattern('^[0-9]*$')]
    valiForNumOnly = [Validators.pattern('^[0-9]*$')];
    valiReqOnly = [ Validators.required, Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$') ];
    valiReqTrueOnly = [ Validators.requiredTrue ];
    valiForMob = [ Validators.required, Validators.pattern('^[1-9][0-9]+$'), Validators.minLength(10), Validators.maxLength(10) ];
    valiForMobOnly = [ Validators.pattern('^[1-9][0-9]+$'), Validators.minLength(10), Validators.maxLength(10) ];
    valiForBankAcc = [ Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(18) ];
    valiForPan = [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}') ];
    valiForPanOnly = [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}') ];
    valiForEmail = [ Validators.required, Validators.email ];
    valiForEmailOnly = [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-_]+\\.([a-zA-Z0-9]|[a-zA-Z0-9]+\\.[a-zA-Z0-9]){2,4}$")]
    valiForEmailLowerCaseOnly = [
            Validators.required,
            Validators.pattern("^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-_]+\\.([a-z0-9]|[a-z0-9]+\\.[a-z0-9]){2,4}$")]
    valiForEmailOptional = [Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-_]+\\.[a-zA-Z0-9]{2,3}$")]
    valiForPdfImg = [ Validators.required, Validators.pattern('^.*\.(pdf|PDF|jpg|JPG|png|PNG|jpeg|JPEG)$')]
    valiForPdfImgOnly = [ Validators.pattern('^.*\.(pdf|PDF|jpg|JPG|png|PNG|jpeg|JPEG)$')]
    valiForPdf = [ Validators.required, Validators.pattern('^.*\.(pdf|PDF)$')]
    valiForImg = [ Validators.required, Validators.pattern('^.*\.(jpg|JPG|png|PNG|jpeg|JPEG)$')]
    valiForPdfOnly = [ Validators.pattern('^.*\.(pdf|PDF)$')]
    valiForImgOnly = [ Validators.pattern('^.*\.(jpg|JPG|png|PNG|jpeg|JPEG)$')]
    valiForName = [ Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z _]*$'), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$') ];
    valiForFullname = [ Validators.required, Validators.maxLength(50), Validators.pattern('^[a-z|A-Z]+(?: [a-z|A-Z]+)*$') ];
    valiForNameOnly = [ Validators.maxLength(50), Validators.pattern('^[a-zA-Z _]*$') ];
    valiForVarchar = [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*') ];
    valiForVarcharUnitname = [
            Validators.required,
            Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*') ];
    valiForVarcharOnly = [ Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*'), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$') ];
    // valiForVarcharSpl = [ Validators.required, Validators.pattern('^[ A-Za-z0-9_.,-\/]*$'), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$') ];
    // valiForVarcharSplOnly = [ Validators.pattern('^[ A-Za-z0-9_.,-\/]*$'), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$') ];
    valiForVarcharSpl = [ Validators.required, Validators.pattern("^[ A-Za-z0-9.,'%_@\/#&-]*$"), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$')];
    valiForVarcharSplOnly = [ Validators.pattern("^[ A-Za-z0-9.,'%_@\/#&-]*$"), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$')];
    valiForPinCode = [ Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(6) ];
    valiForPinCodeOnly = [ Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(6) ];
    // valiForVarcharMandate = [ Validators.required, Validators.pattern('^[0-9]*[ A-Za-z][ A-Za-z0-9]+$')]
    valiForVarcharMandate = this.valiForVarcharOnly;
    valiForVarcharSite = [ Validators.required, Validators.pattern('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*'), Validators.pattern('^[^\\s]+(\\s+[^\\s]+)*$') ];
    valiForAadhar = [ Validators.required, Validators.minLength(12), Validators.maxLength(12) ];
    valiForIfsc = [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(11),
        Validators.pattern('^[A-Z]{4}[A-Z0-9]{7}') ];
    valiForLatitude = Validators.compose([ //27.8924N, 27.8924S Ex: -90.0N : +90.0N, -90.0S : +90.0S
        Validators.required,Validators.minLength(3), Validators.maxLength(10),
	    this.latitudeRangeValidatorBuilder(),
        // Validators.pattern('^(\\+|-)?(?:(90)(?:(N|S))(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?)(?:(N|S)))$')];
	    Validators.pattern('^[0-9]{2}[.]{1}[0-9]{1}|[0-9]{2}[.]{1}[0-9]{2}|[0-9]{2}[.]{1}[0-9]{3}|[0-9]{2}[.]{1}[0-9]{4}|[0-9]{2}[.]{1}[0-9]{5}|[0-9]{2}[.]{1}[0-9]{6}|[0-9]{2}[.]{1}[0-9]{7}$')]);
    valiForLongitude = Validators.compose([ //79.902E, 79.902W Ex: -180.0E : +180.0E, -180.0W : +180.0W
        Validators.required,Validators.minLength(3), Validators.maxLength(10),
	    this.longitudeRangeValidatorBuilder(),
        // Validators.pattern('^(\\+|-)?(?:(180)(?:(E|W))(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?)(?:(E|W)))$')
        Validators.pattern('^[0-9]{2}[.]{1}[0-9]{1}|[0-9]{2}[.]{1}[0-9]{2}|[0-9]{2}[.]{1}[0-9]{3}|[0-9]{2}[.]{1}[0-9]{4}|[0-9]{2}[.]{1}[0-9]{5}|[0-9]{2}[.]{1}[0-9]{6}|[0-9]{2}[.]{1}[0-9]{7}$')]);
    valiForLatitudeOnly = Validators.compose([ //27.8924N, 27.8924S Ex: -90.0N : +90.0N, -90.0S : +90.0S
      Validators.required,Validators.minLength(3), Validators.maxLength(10),
	    this.latitudeRangeValidatorBuilder(),
        // Validators.pattern('^(\\+|-)?(?:(90)(?:(N|S))(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?)(?:(N|S)))$')];
	    Validators.pattern('^[0-9]{2}[.]{1}[0-9]{1}|[0-9]{2}[.]{1}[0-9]{2}|[0-9]{2}[.]{1}[0-9]{3}|[0-9]{2}[.]{1}[0-9]{4}|[0-9]{2}[.]{1}[0-9]{5}|[0-9]{2}[.]{1}[0-9]{6}|[0-9]{2}[.]{1}[0-9]{7}$')]);
    valiForLongitudeOnly = Validators.compose([ //79.902E, 79.902W Ex: -180.0E : +180.0E, -180.0W : +180.0W
      Validators.required,Validators.minLength(3), Validators.maxLength(10),
	    this.longitudeRangeValidatorBuilder(),
        // Validators.pattern('^(\\+|-)?(?:(180)(?:(E|W))(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?)(?:(E|W)))$')]
	    Validators.pattern('^[0-9]{2}[.]{1}[0-9]{1}|[0-9]{2}[.]{1}[0-9]{2}|[0-9]{2}[.]{1}[0-9]{3}|[0-9]{2}[.]{1}[0-9]{4}|[0-9]{2}[.]{1}[0-9]{5}|[0-9]{2}[.]{1}[0-9]{6}|[0-9]{2}[.]{1}[0-9]{7}$')]);
    valiForGstin = [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(18),
        Validators.pattern('^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5}[0-9]{4}[A-Z]{1}[1-9a-zA-Z]{1}[Z]{1}[0-9A-Z]{1})+$')]
    valiForFeeGreaterthenOne = [Validators.required, Validators.min(1)];
    valiForPlinthArea = [Validators.required, Validators.min(100)];
    valiForMicroBreweryArea = [Validators.required, Validators.min(250)];
    valiForSittingCapacity = [Validators.required, Validators.min(30)];
    valiForTan = [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[A-Z]{4}[0-9]{5}[A-Z]{1}') ];
    valiForMaxQuantity = [
        Validators.required,
        Validators.maxLength(10)];

	validationForPassword = Validators.compose([
		Validators.required,
		Validators.minLength(8),
		Validators.maxLength(20),
		// check whether the entered password has a number
		this.patternValidatorBuilder(/\d/, { hasNumber: true }),
		// Check for uppercase letter
		this.patternValidatorBuilder(/[A-Z]/, { hasCapitalCase: true }),
		//check for lowercase letter
		this.patternValidatorBuilder(/[a-z]/, { hasSmallCase: true }),
		// check for special character
		this.patternValidatorBuilder(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/, { hasSpecialCharacters: true }),
	]);
    validationForDecimal = Validators.compose([ 
		// check whether the entered password has a number
		this.patternValidatorBuilder(/^[.\d]+$/, {  }),
        //this.patternValidatorBuilder(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/, {  }),
	 ]);
     valiForBottlingLatitude = Validators.compose([ //27.8924N, 27.8924S Ex: -90.0N : +90.0N, -90.0S : +90.0S
        Validators.required, 
        // this.latitudeRangeValidatorBuilder(),
	    Validators.pattern('^[0-9]{2}\'[0-9]{2,7}$')]);
    valiForBottlingLongitude = Validators.compose([ //79.902E, 79.902W Ex: -180.0E : +180.0E, -180.0W : +180.0W
        Validators.required, 
        // this.longitudeRangeValidatorBuilder(),
        Validators.pattern('^[0-9]{2}\'[0-9]{2,7}$')]);

    valiForRegiCheck(control: FormControl): { [key: string]: any } | null {
        if (control.value !== true) {
            return { 'required': true }; // Return an error if the value is not true
        }
        return null; // Return null if validation passes
        }
          

    constructor( private fb: FormBuilder, private licenseSer: LicenceManagementService, ) { }

    buildBankDetFormGroup(data?: any) {
        const basicEle = {
            accountNumber: ['', this.valiForBankAcc],
            ifscCode: ['', this.valiForIfsc],
            //bankCode: ['', this.valiReqOnly],
            bankName: ['', this.valiReqOnly],
            address:['', this.valiReqOnly],
            cancel_cheque_file: ['', this.valiForImg],
            cancel_cheque_file_source: ['']
        };
        return this.fb.group(basicEle);
    }

    buildAddressFormGroup(identifier, data?: any) {
        const basicEle = {
            addressIdentifier: [identifier],
            copyIdentifier: [''],
            country: [{ value: 'INDIA', disabled: true }, this.valiReqOnly],
            stateCode: ['', this.valiReqOnly],
            stateDesc: [''],
            districtCode: ['', this.valiReqOnly],
            districtDesc: [''],
            pinCode: ['', this.valiForPinCode],
            tahsilCode: ['', this.valiReqOnly],
            tahsilDesc: [''],
            localityType: ['', this.valiReqOnly],
            localityTypeDesc: [''],
            block: ['', this.valiForVarcharSplOnly],
            village: ['', this.valiForVarcharSplOnly],
            municipalityName: ['', this.valiForVarcharSplOnly],
            ward: ['',  this.valiForVarcharSplOnly],
            locality: ['',  this.valiForVarcharSpl],
            policeStationCode: [''],
            policeStationDesc: [''],
            policeStationName: ['', this.valiForVarcharSpl],
            street: ['',  this.valiForStreetVarcharSpl]
        };
        return this.fb.group(basicEle);
    }

    buildAppliDetFormGroup(data?: any) {
        const basicEle = {
            salutationCode: ['', this.valiReqOnly],
            salutationDesc: ['', this.valiReqOnly],
            firstName: ['', this.valiReqOnly],
            middleName: [''],
            lastName: ['', this.valiReqOnly],
            mobileNumber: ['', this.valiForMob],
            emailId: ['', this.valiForEmailOnly],
            genderCode: ['', this.valiReqOnly],
            genderDesc: ['', this.valiReqOnly],
            fatherHusbandName: [''],
            dob: ['', this.valiReqOnly],
            aadharNumber: ['', this.valiReqOnly],
            panNumber: ['', this.valiForPan],
            itrAssesmentYearCode: ['', this.valiReqOnly],
            previousItrNotFiled: ['', this.valiReqOnly],

            appli_photo_file: ['', this.valiForImg],
            appli_photo_file_source: ['', this.valiReqOnly],

            appli_aadhar_file: ['', this.valiReqOnly],
            appli_aadhar_file_source: ['', this.valiReqOnly],

            appli_pan_file: ['', this.valiForPdf],
            appli_pan_file_source: ['', this.valiReqOnly],

            appli_itr_file: ['', this.valiForPdf],
            appli_itr_file_source: ['', this.valiReqOnly],
        };
        return this.fb.group(basicEle);
    }

    buildBusinessUnitDetFormGroup(data?: any) {
        const basicEle = {
            firmLicenseNumber: ['', this.valiReqOnly],
            firmCompanyPanNumber: ['', this.valiForPan],
            itrAssessmentYearCode: ['', this.valiReqOnly],
            itrAssessmentYearDesc: ['', this.valiReqOnly],
            previusItrUploadReason: ['', this.valiReqOnly],
            anuualTurnOver: ['', this.valiReqOnly],
            annualProductionCapacity: ['', this.valiReqOnly],
            stateSupplyCode: ['', this.valiReqOnly],
            stateSupplyDesc: ['', this.valiReqOnly],
            stateQuantitySupply: ['', this.valiReqOnly],

            unitLicense_file: ['', this.valiReqOnly],
            unitLicense_file_source: ['', this.valiReqOnly],

            bwflBondDoc_file: ['', this.valiReqOnly],
            bwflBondDoc_file_source: ['', this.valiReqOnly],

            powerOfAttorney_file: ['', this.valiReqOnly],
            powerOfAttorney_file_source: ['', this.valiReqOnly],

            unitAffadavait_file: ['', this.valiReqOnly],
            unitAffadavait_file_source: ['', this.valiReqOnly],

            compFirmPan_file: ['', this.valiReqOnly],
            compFirmPan_file_source: ['', this.valiReqOnly],

            lastYrITR_file: ['', this.valiReqOnly],
            lastYrITR_file_source: ['', this.valiReqOnly],

            uploadGstin_file: ['', this.valiReqOnly],
            uploadGstin_file_source: ['', this.valiReqOnly],

            annualTurnOverCert_file: ['', this.valiReqOnly],
            annualTurnOverCert_file_source: ['', this.valiReqOnly],

            stateExciseOfficialCert_file: ['', this.valiReqOnly],
            stateExciseOfficialCert_file_source: ['', this.valiReqOnly],

            declarationCert_file: ['', this.valiReqOnly],
            declarationCert_file_source: ['', this.valiReqOnly],
        };
        return this.fb.group(basicEle);
    }

    buildAdditionalDetFormGroup(data?: any) {
        const basciEle = {
            solvencyCerticateValidity: ['', this.valiReqOnly],

            solvencyCertByAuth_file: ['', this.valiForPdf],
            solvencyCertByAuth_file_source: ['', this.valiReqOnly],

            affadavait_file: ['', this.valiForPdf],
            affadavait_file_source: ['', this.valiReqOnly],

            boundries_file: ['', this.valiForPdfOnly],
            boundries_file_source: [''],
        };
        return this.fb.group(basciEle);
    }

    buildRemarkFormGroup(data?: any) { return this.fb.group({ comments: ['', this.valiForSingleSpaceChar] }); }
    buildCommentFormGroup(data?: any) { return this.fb.group({ comments: ['', this.valiReqOnly] }); }
    buildSiteInspectionGroup(data?: any) { return this.fb.group({ inspectionReport : ['', this.valiReqOnly] }); }
    buildDeoReportGroup(data?: any) { return this.fb.group({ deoReport : ['', this.valiReqOnly] }); }
    buildSiteInspectionOptionalGroup(data?: any) { return this.fb.group({ inspectionOptionlReport : [''] }); }
    buildSendbackFormGroup(data?: any) { return this.fb.group({ sendback: ['', this.valiReqOnly] }); }
    buildProcessingFeeConsentFormGroup(data?: any) { return this.fb.group({ isProcessingFeeConsent: ["", this.valiReqOnly] }); }

    buildCheckListDetFormGroup(data?: any) {
        const basicEle = {
            documentCode: [data.documentCode ? data.documentCode : '', this.valiReqOnly],
            documentDesc: [data.documentDesc ? data.documentDesc : '', this.valiReqOnly],
            documentName: [data.documentName ? data.documentName : ''],
            isVerified: [data.isVerified ? data.isVerified : false, this.valiReqOnly]
        };
        return this.fb.group(basicEle);
    }

    buildCheckListFormGroup(applicationNumber, data?: any) {
        const basicEle = {
            applicationNumber: [applicationNumber, this.valiReqOnly],
            checkListDocument: this.fb.array([]),
        };
        return this.fb.group(basicEle);
    }

    buildSiteDetFormGroup(data?: any) {
        const basicEle = {
            latitude: ['', this.valiForLatitude],
            longitude: ['', this.valiForLongitude],
            east: ['', this.valiForVarchar],
            north: ['', this.valiForVarchar],
            south: ['', this.valiForVarchar],
            west: ['', this.valiForVarchar],

            documentList: this.fb.array([
                this.buildSiteDocumentFormGroup()
            ]),
        };
        return this.fb.group(basicEle);
    }

    buildSiteDocumentFormGroup(data?: any) {
        const basicEle = {
            description: [data ? data.description : '', this.valiForVarchar],

            site_photo_file: data ? [''] : ['', this.valiForImg],
            site_photo_file_source: data ? [''] : ['', this.valiReqOnly],
        };
        return this.fb.group(basicEle);
    }

    buildAssignSiteFormGroup(data?: any) {
        const basicEle = {
            districtCode: ['', this.valiReqOnly],
            districtDesc: ['', this.valiReqOnly],
            roleCode: ['', this.valiReqOnly],
            roleDesc: ['', this.valiReqOnly],
            userEmail: ['', this.valiReqOnly],
            userName: ['', this.valiReqOnly],
            name: ['', this.valiReqOnly],
            designationCode: ['', this.valiReqOnly],
            designationDesc: ['', this.valiReqOnly],
        };
        return this.fb.group(basicEle);
    }

    async createLicense(payload, saveType, commentForm, sendbackForm, officerLevel, isFormEdit, licenseApplicationData) {

        console.log('before-payload::', payload);
        payload = await this.getFinalPayload(
            payload, saveType, commentForm, sendbackForm,
            officerLevel, isFormEdit, licenseApplicationData
        );
        if (!payload) {
            console.log('payload problem');
            return false;
        }
        console.log('final-payload::', payload);

        let status = false;
        let data = {};
        let msg;
        const licenseCreationRes: any = await this.licenseSer.createLicense(payload);
        const { responseCode: respCode, responseMessage: respMsg, content: respContent } = licenseCreationRes;
        console.log('respContent::', respContent);
        if (respCode === 200) {
            status = true;
            data = respContent;
            msg = respMsg;
        } else {
            msg = respMsg;
        }
        return { status, data, msg };
    }

    async getFinalPayload(payload, saveType, commentForm, sendbackForm, officerLevel, isFormEdit, licenseApplicationData) {

        const { comments } = commentForm.value;

        switch (saveType) {
            case STATUS_DRAFT:
            case STATUS_SAVE:
            case STATUS_SENDBACK:
                payload.event = '';
                payload.level = '';
                break;
            case STATUS_SUBMITTED:
                payload.event = 'INITIATE';
                payload.level = 'Level 1';
                break;
            default:
                payload.event = saveType;
                payload.level = officerLevel;
                break;
        }

        if (comments) {
            payload.comments = comments;
        }

        if (isFormEdit) {
            payload.id = licenseApplicationData.id;
        }

        if (saveType === STATUS_SENDBACK) {
            const { sendback } = sendbackForm.value;
            payload.sentBackLevels = sendback;
        }

        return payload;
    }

    localityChangeValidatation(formContrl, localityType, addressType?, licenseType?) {
        console.log(formContrl);
        console.log(localityType);
        console.log(addressType);
        console.log(licenseType);
        if(licenseType === 'FL2A') {
            if (localityType === LOCALITY_URBAN) {
                formContrl.get('municipalityName').setValidators(this.valiForVarcharSpl);
                formContrl.get('municipalityName').updateValueAndValidity();
                formContrl.get('ward').setValidators(this.valiForVarcharSpl);
                formContrl.get('ward').updateValueAndValidity();

                formContrl.get('block').setValidators([]);
                formContrl.get('block').updateValueAndValidity();
                formContrl.get('village').setValidators([]);
                formContrl.get('village').updateValueAndValidity();
                formContrl.get('street').setValidators(this.valiForStreetVarcharSpl);
                formContrl.get('street').updateValueAndValidity();
            }
            if (localityType === LOCALITY_RURAL) {
                formContrl.get('block').setValidators(this.valiForVarcharSpl);
                formContrl.get('block').updateValueAndValidity();
                formContrl.get('village').setValidators(this.valiForVarcharSpl);
                formContrl.get('village').updateValueAndValidity();

                formContrl.get('municipalityName').setValidators([]);
                formContrl.get('municipalityName').updateValueAndValidity();
                formContrl.get('ward').setValidators([]);
                formContrl.get('ward').updateValueAndValidity();

                formContrl.get('street').setValidators(this.valiForStreetVarcharSpl);
                formContrl.get('street').updateValueAndValidity();
            }

            return true;

        }
        // else if(licenseType === 'FL11') {
        //     if (localityType === LOCALITY_URBAN) {
        //         formContrl.get('municipalityName').setValidators([]);
        //         formContrl.get('municipalityName').updateValueAndValidity();
        //         formContrl.get('ward').setValidators([]);
        //         formContrl.get('ward').updateValueAndValidity();
        //         formContrl.get('block').setValidators([]);
        //         formContrl.get('block').updateValueAndValidity();
        //         formContrl.get('village').setValidators([]);
        //         formContrl.get('village').updateValueAndValidity();
        //         formContrl.get('street').setValidators(this.valiForStreetVarcharSpl);
        //         formContrl.get('street').updateValueAndValidity();
        //     }
        //     if (localityType === LOCALITY_RURAL) {
        //         formContrl.get('block').setValidators([]);
        //         formContrl.get('block').updateValueAndValidity();
        //         formContrl.get('village').setValidators([]);
        //         formContrl.get('village').updateValueAndValidity();
        //         formContrl.get('municipalityName').setValidators([]);
        //         formContrl.get('municipalityName').updateValueAndValidity();
        //         formContrl.get('ward').setValidators([]);
        //         formContrl.get('ward').updateValueAndValidity();
        //         formContrl.get('street').setValidators(this.valiForStreetVarcharSpl);
        //         formContrl.get('street').updateValueAndValidity();
        //     }

        //     return true;

        // } 
        else {
            if (localityType === LOCALITY_URBAN) {
                formContrl.get('municipalityName').setValidators(this.valiForVarcharSpl);
                formContrl.get('municipalityName').updateValueAndValidity();
                formContrl.get('ward').setValidators(this.valiForVarcharSpl);
                formContrl.get('ward').updateValueAndValidity();
                formContrl.get('locality').setValidators(this.valiForVarcharSpl);
                formContrl.get('locality').updateValueAndValidity();
                formContrl.get('policeStationName').setValidators(this.valiForVarcharSpl);
                formContrl.get('policeStationName').updateValueAndValidity();
                formContrl.get('block').setValidators();
                formContrl.get('block').updateValueAndValidity();
                formContrl.get('village').setValidators();
                formContrl.get('village').updateValueAndValidity();
                formContrl.get('street').setValidators(this.valiForStreetVarcharSpl);
                formContrl.get('street').updateValueAndValidity();
            }
            if (localityType === LOCALITY_RURAL) {
                formContrl.get('block').setValidators(this.valiForVarcharSpl);
                formContrl.get('block').updateValueAndValidity();
                formContrl.get('village').setValidators(this.valiForVarcharSpl);
                formContrl.get('village').updateValueAndValidity();
                formContrl.get('locality').setValidators(this.valiForVarcharSpl);
                formContrl.get('locality').updateValueAndValidity();
                formContrl.get('policeStationName').setValidators(this.valiForVarcharSpl);
                formContrl.get('policeStationName').updateValueAndValidity();
                formContrl.get('municipalityName').setValidators();
                formContrl.get('municipalityName').updateValueAndValidity();
                formContrl.get('ward').setValidators();
                formContrl.get('ward').updateValueAndValidity();

                formContrl.get('street').setValidators(this.valiForStreetVarcharSpl);
                formContrl.get('street').updateValueAndValidity();
            }

            return true;
        }
    }

    isPreviousITRShow(event): boolean {
        return (event === PREVIOUSYEAR_ITR || event === BEFOREPREVIOUSYEAR_ITR || event === 'OTHERS') ? true : false;
    }

    selectITRchangevalidation(formContrl, code, parentUnit?) {
        const formCntrl = parentUnit ? 'previusItrUploadReason' : 'previousItrNotFiled';

        formContrl.get(formCntrl).setValidators( (code === PREVIOUSYEAR_ITR || code === BEFOREPREVIOUSYEAR_ITR || code === 'OTHERS') ? this.valiForSingleSpaceChar : []);
        formContrl.get(formCntrl).updateValueAndValidity();

        return true;

    }

    checkDateType(date) {
        const pattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
        return pattern.test(date)
    }

	patternValidatorBuilder(regex: RegExp, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (!control.value) {
				// if control is empty return no error
				return null;
			}

			// test the value of the control against the regexp supplied
			const valid = regex.test(control.value);

			// if true, return no error (no error), else return error passed in the second parameter
			return valid ? null : error;
		};
	}

	latitudeRangeValidatorBuilder(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			const { value } = control;
			if (!value) {
				return null;
			} else if (!(/^([0-9]{2}.[0-9]{1}|[0-9]{2}.[0-9]{2}|[0-9]{2}.[0-9]{3}|[0-9]{2}.[0-9]{4}|[0-9]{2}.[0-9]{5}|[0-9]{2}.[0-9]{6}|[0-9]{2}.[0-9]{7})$/g.test(value))) {
				return { pattern: true };
			}
			const [minute, secondsWithNS] = value.split(".");
			const seconds = (secondsWithNS) ? secondsWithNS : '';
			const minimumMinute = LAT_LONG_RANGE.LATITUDE.MINUTES.MIN;
			const maximumMinute = LAT_LONG_RANGE.LATITUDE.MINUTES.MAX;
			const minimumFor20 = LAT_LONG_RANGE.LATITUDE.SECONDS.MIN;
			const maximumFor32 = LAT_LONG_RANGE.LATITUDE.SECONDS.MAX;
			const errorToReturn = {
				latitudeRangeError: {
					min: `${minimumMinute}.${minimumFor20}`,
					max: `${maximumMinute}.${maximumFor32}`
				}
			};

			if (minute < minimumMinute || minute > maximumMinute) {
				return errorToReturn;
			} else if (
				(minute == minimumMinute && (seconds < minimumFor20))
			) {
				return  errorToReturn;
			} else if (
				(minute == maximumMinute && (seconds > maximumFor32))
			) {
				return  errorToReturn;
			}
			return null;
		}
	}

	longitudeRangeValidatorBuilder(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			const { value } = control;
			if (!value) {
				return null;
			} else if (!(/^([0-9]{2}.[0-9]{1}|[0-9]{2}.[0-9]{2}|[0-9]{2}.[0-9]{3}|[0-9]{2}.[0-9]{4}|[0-9]{2}.[0-9]{5}|[0-9]{2}.[0-9]{6}|[0-9]{2}.[0-9]{7})$/g.test(value))) {
				return { pattern: true };
			}
			const [minute, secondsWithEW] = value.split(".");
			const seconds = (secondsWithEW) ? secondsWithEW : '';
			const minimumMinute = LAT_LONG_RANGE.LONGITUDE.MINUTES.MIN;
			const maximumMinute = LAT_LONG_RANGE.LONGITUDE.MINUTES.MAX;
			const minimumFor75 = LAT_LONG_RANGE.LONGITUDE.SECONDS.MIN;
			const maximumFor85 = LAT_LONG_RANGE.LONGITUDE.SECONDS.MAX;
			const errorToReturn = {
				latitudeRangeError: {
					min: `${minimumMinute}.${minimumFor75}`,
					max: `${maximumMinute}.${maximumFor85}`
				}
			};

			if (minute < minimumMinute || minute > maximumMinute) {
				return errorToReturn;
			} else if (
				(minute == minimumMinute && (seconds < minimumFor75))
			) {
				return  errorToReturn;
			} else if (
				(minute == maximumMinute && (seconds > maximumFor85))
			) {
				return  errorToReturn;
			}
			return null;
		}
	}

}
