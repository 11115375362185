
import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Observable, of } from 'rxjs';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class DropdownConfigurationService {
  constructor(private http: HttpClient) { }

  baseURL = environment.scmproduction;
  licenseTypeBaseUrl = environment.licenseTypeBaseUrl;
  licenseURL = environment.licneseBaseUrl;

  getdetails(): Observable<any> {//work
    const url = this.licenseURL + 'license/category/PD';
    return this.http.get(url);
  }
  getBrandName(data: any): Observable<any> {
    const url = this.baseURL + 'findBrandDetails/dropDown';
    return this.http.post(url, data);
  }
  getSelectedBrandLabelInfo(data: any): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, data);
  }
  getSubLiqourTypes(data: any): Observable<any> {
    const url = this.baseURL + 'findBrandDetails/dropDown';
    return this.http.post(url, data);
  }
  getSourceTank(data: any): Observable<any> {
    const url = this.baseURL + 'materialTank/dropDown';
    return this.http.post(url, data);
  }
  
}
