import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { merge, Observable, of as observableOf } from 'rxjs';
// import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return `0 of ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `Showing ${startIndex + 1} to ${endIndex} of ${length} entries`;
};

@Component({
  selector: 'app-mat-normal-table',
  templateUrl: './mat-normal-table.component.html',
  styleUrls: ['./mat-normal-table.component.css'],
})
export class MatNormalTableComponent
  implements AfterViewInit, OnInit, OnChanges {
  @Input() pageSize = 10;
  @Input() totalCount = 10;
  @Input() columns: [];
  @Input() dataSource = new MatTableDataSource();
  @Input() actionKeys: string[] = [];
  @Input() actionBtnBasedOnCond: any = {};
  @Input() actionKeysShowBasedOnCond: string[] = [];
  @Input() isSearchRes = false;
  @Input() isLoadingResults: boolean;
  @Input() categoryType: string;
  @Input() isProfile: string;
  @Input() isApplicantActivate= 'false';
  @Input() isProfileSoftwareAssets: string;
  @Input() actionName: string = 'Action';
  @Input() customButtons: any = [];
  @Output() editAction = new EventEmitter<any>();
  @Output() viewAction = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() downloadAction = new EventEmitter<any>();
  @Output() applyAction = new EventEmitter<any>();
  @Output() paginationEvent = new EventEmitter<any>();
  @Output() checkboxAction = new EventEmitter<any>();
  @Output() renewAction = new EventEmitter<any>();
  @Output() customButtonAction = new EventEmitter<any>();
  @Output() surrenderAction = new EventEmitter<any>();

  @Output() popUpAction = new EventEmitter<any>(); 

  public displayedColumns: string[] = [];
  public resultsLength = 0;
  public showEditButton = true;
  public showDownloadButton = true;
  public currentPage = 0;
  public pageSizeOptions = [5, 10, 25, 100];
  public array: any;
  public optionVal = ['Active', 'In-Active'];
  public defDateFormat = 'dd/MM/yyyy';
  public tempPageSize=10

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  sort: MatSort;
  // @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
        this.dataSource.sort = sort;
    }
}

  constructor() { }

  ngOnInit(): void {
    // console.log('adv-mat-dynamic', this.pageSize);
    // console.log('adv-mat-dynamic::columns', this.columns);
    // console.log('adv-mat-dynamic::totalCount', this.totalCount);

    // console.log("this.columns0",this.columns)

    this.columns.forEach((ele: any, val) => {
      this.displayedColumns.push(ele.name);
    });

    if (this.actionKeys.length > 0) {
      this.displayedColumns.push('actions');
    }

    // console.log('adv-mat-dynamic::dataSource', this.dataSource);
    // console.log('adv-mat-dynamic::currentPage', this.currentPage);

    // this.isLoadingResults = false;
    this.resultsLength = this.totalCount;
  }

  ngAfterViewInit() {
    this.tempPageSize=this.paginator.pageSize;
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.itemsPerPageLabel = 'Show';
    this.paginator._intl.getRangeLabel = dutchRangeLabel;
    this.dataSource.sortingDataAccessor = _.get; 
    this.dataSource.sort = this.sort;
    this.resultsLength = this.totalCount;
    // console.log('this.resultsLength::', this.resultsLength);

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe((res) => {
    //   this.paginator.pageIndex = 0;
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    // const currentItem: SimpleChange = changes.isSearchRes;
    // console.log('prev value: ', currentItem.previousValue);
    // console.log('got item: ', currentItem.currentValue);

    // console.log('changes::', changes.isSearchRes);

    // console.log('this.isSearchRes::', this.isSearchRes);
    if (changes.isSearchRes) {
      this.currentPage = 0;
    }
    if (changes.totalCount) {
      this.currentPage = 0;
      this.pageSize = this.tempPageSize;
    }
  }

  handlePagination(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;

    // console.log('this.currentPage::', this.currentPage);
    // console.log('this.pageSize::', this.pageSize);

    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    // console.log('end::', end);
    // console.log('start::', start);
    // const part = this.array.slice(start, end);

    // console.log('part::', part);
    const paginationObj = {
      isSearchRes: this.isSearchRes,
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      start,
      end,
    };

    // console.log( 'child-handlePagination', paginationObj );
    this.paginationEvent.emit(paginationObj);
  }

  onEditClicked(id, event, row) {

    this.editAction.emit({ event, id, row });
  }

  //b4-form changes starts

  onClickData(id, event ,  value) {

    this.popUpAction.emit({ event, id, value });
  }
  //b4-form changes ends

  onViewClicked(id, event, row) {

    this.viewAction.emit({ event, id, row });
  }

  onDeleteClicked(id, event, row) {

    this.deleteAction.emit({ event, id, row });
  }

  onDownloadClicked(id, event, row) {

    this.downloadAction.emit({ event, id, row });
  }

  onApplyClicked(id, event) {
    this.applyAction.emit({ event, id });
  }

  onSurrenderClicked(id, event) {
    this.surrenderAction.emit({ event, id })
  }

  onRowClicked(row) {

  }

  onCheckboxChecked(id, event) {

    this.checkboxAction.emit({ event, id });
  }

  hasDownloadKey() {
    return this.actionKeys.includes('download') ? true : false;
  }

  checkEditButton(eleVal) {
    if (this.actionBtnBasedOnCond.add) {
      if (this.actionBtnBasedOnCond.add.condition) {
        return (this.actionBtnBasedOnCond.add.condition === eleVal);
      }
    }
    if (this.actionBtnBasedOnCond.edit) {
      if (this.actionBtnBasedOnCond.edit.condition) {
        return (this.actionBtnBasedOnCond.edit.condition === eleVal);
      }
      if (this.actionBtnBasedOnCond.edit.conditionIn) {
        return this.actionBtnBasedOnCond.edit.conditionIn.includes(eleVal);
      }
    }
    return false;
  }

  checkDownloadButton(eleVal) {
    
    if (this.actionBtnBasedOnCond.download) {
      if (this.actionBtnBasedOnCond.download.condition) {
        return (this.actionBtnBasedOnCond.download.condition === eleVal);
      }
      if (this.actionBtnBasedOnCond.download.conditionIn) {
        return this.actionBtnBasedOnCond.download.conditionIn.includes(eleVal);
      }
    }
    return false;
  }
  checkviewCondButton(eleVal) {
    if (this.actionBtnBasedOnCond.viewcond) {
      if (this.actionBtnBasedOnCond.viewcond.condition) {
        return (this.actionBtnBasedOnCond.viewcond.condition === eleVal);
      }
      if (this.actionBtnBasedOnCond.viewcond.conditionIn) {
        return this.actionBtnBasedOnCond.viewcond.conditionIn.includes(eleVal);
      }
    }
    return false;
  }

  onRenewClicked(id, event) {
    this.renewAction.emit({ event, id })
  }

  checkCustomButton(row, item) {
    if (item.condition && item.column && row[item.column]) {
      return (row[item.column] === item.condition);
    }
    return false;
  }

  onCustomActionsClicked(id, event, row, item) {
    this.customButtonAction.emit({ event, id, row, item });
  }
  
}
