import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class BrandlabelReg {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.scmproduction;
  licenseTypeBaseUrl = environment.licenseTypeBaseUrl;
  licenseURL = environment.licneseBaseUrl;
  domainUrl=environment.domainUrl;
  //getdetails(): Observable<any> {//work
  async getdetails() {//work
    const url = this.licenseURL + 'license/category/SUGAR_MILL';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  async getdSugarmilletails() {//work
    const url = this.licenseURL + 'license/categoryQuery/SUGAR_MILL';
    const response = await this.http.get(url).toPromise();
    return response;
  }

  brandLabelExisting(param?: any): Observable<any> {
    let url = this.baseURL + 'brandLabel/existing';
    url += param ? '?licenseType=' + param : '';
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  // getapplicationnumbers(data, licensenumber): Observable<any> {//work
  //   const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data + '&licenseNumber=' + licensenumber;
  //   return this.http.post(url, data);
  // }

  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber+ '&liquorType=' + data.liquorType;
    return this.http.post(url, data).toPromise();
  }

  getApprovedLicenseTypes(licensetype) {//work
    let url = this.licenseTypeBaseUrl + "brandLabel/approved/licenseType/" + licensetype;
    return this.http.post(url, {});

  }

  addetails(data: any): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    return this.http.post(url, data);
  }


  getbrandlabelbyid(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  createbrandlabeldetails(data): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    console.log(url);
    return this.http.post(url, data);
  }
  getbrandandlabeldatabyetin(etin): Observable<any> {
    const url = this.baseURL + 'brandLabel/approved/etin/' + etin;
    return this.http.get(url);
  }
  getbrandandlabeldatabylicensetype(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/approved/licenseType/' + licensetype;
    return this.http.get(url);
  }
  searchstockindetails(data): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, data);
  }
  getdistinctbrandandproduct(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/existing?licenseType=' + licensetype;
    return this.http.get(url);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'brandLabel/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  getbrandlabelstatusdatacount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount';
    return this.http.get(url);
  }
  getbrandlabeldropdowndtls(brandname) {
    const url = this.baseURL + 'brandLabel/getDropDown?type=' + brandname;
    return this.http.get(url)
  }
  getlabeldetails(postParam) {
    const url = this.baseURL + 'brandLabel/overview/labelDetails';
    return this.http.post(url, postParam);
  }
  labelfeepayment(postParam) {
    const url = this.baseURL + "brandLabel/saveUpdatePaymentDetails"
    return this.http.post(url, postParam);
  }

  searchoverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }
  getoverviewstatuscount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/statusCount';
    return this.http.get(url);
  }

  updatelabelstatusoverview(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/workflowCallBack';
    return this.http.post(url, payload);
  }

  getoverviewbrandlabeldata(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }

  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }

  updatebrandlabelworkflowdtls(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/updateBrandLabelWorkFlowDetails';
    return this.http.post(url, payload);
  }

  validategtin(payload: any): Observable<any> {
    const url = this.licenseTypeBaseUrl + 'gtinValidation';
    return this.http.post(url, payload);
  }

  // overview service
  searchOverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }


  getDropDowns(type) {
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type;
    url += '&status=' + 'APPROVED';
    return this.http.get(url);
  }

  getOverviewById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }


  addLabelDetails(data): Observable<any> {
    const url = this.baseURL + "brandLabel/overview/labelDetails"
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, payload);
  }
  getbrandlabelcount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount';
    return this.http.get(url);
  }
  getSearchDropdowns(paramData) {
    const url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    return this.http.get(url)
  }

  getPaymentDetail(payload): Observable<any> {
    const url =  this.domainUrl+'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
}
