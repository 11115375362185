import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { FormControl, FormGroup, Validators } from "@angular/forms";


@Injectable({
    providedIn: 'root'
})
export class PalletService {

    constructor(private http: HttpClient,) { }
    baseURL = environment.wholesaleNewURL;
    trackandtrace = environment.trackandtraceUrl;
    domailUrl = environment.domainUrl
    trackandtraceUrl = environment.trackandtraceUrl
    scmUrl = environment.scmproduction
    validateTransport(postdata) {
        const url = this.baseURL + 'customdata/getdata';
        return this.http.post(url, postdata);
    }
    validateCase(postdata) {
        const url = this.baseURL + 'customdata/getdata';
        return this.http.post(url, postdata);
    }
    validatemapping(postdata) {
        const url = this.baseURL + 'palletstock/loadcaseinpallet';
        return this.http.post(url, postdata);
    }
    validateunmapping(postdata) {
        const url = this.baseURL + 'palletstock/unloadcasefrompallet';
        return this.http.post(url, postdata);
    }
    validateTransportunmappingpallet(postdata) {
        const url = this.baseURL + 'customdata/getdata';
        return this.http.post(url, postdata);
    }
    validatecaseunmapping(postdata) {
        const url = this.baseURL + 'customdata/getdata';
        return this.http.post(url, postdata);
    }

    addpallet(pallet): Observable<any> {
        const url = this.baseURL + "pallet/savepallet";
        return this.http.post(url, pallet);
    }


    getUserData(param): Observable<any> {
        console.log('Base URL' + this.baseURL)
        const url = this.baseURL + "customdata/getdata";

        return this.http.post(url, param);
    }


    viewUser(customerdata): Observable<any> {
        const url = this.baseURL + "customdata/getdata";
        console.log(url)
        return this.http.post(url, customerdata);
    }

    updatePallet(id): Observable<any> {
        const url = this.baseURL + "pallet/editpallet";
        return this.http.post(url, id);
    }

    saveData(payload): Observable<any> {
        const url = this.baseURL + 'customdata/getdata';
        return this.http.post(url, payload);
    }

    saveFineMethod(payload): Observable<any> {
        const url = this.trackandtrace + "penalty/savePenaltyDetails";
        return this.http.post(url, payload);
    }

    approve1(paylod): Observable<any> {
        const url = this.baseURL + "itemmaplog/mappingandunmapping";
        return this.http.post(url, paylod);
    }

    Payment(payload): Observable<any> {
        const url = this.baseURL + "penalty/savePaymentDetails";
        return this.http.post(url, payload)
    }

    mstAdd(payload): Observable<any> {
        const url = this.baseURL + "stockverification";
        return this.http.post(url, payload)
    }

    listSearchPagination(postParam): Observable<any> {
        const url = this.baseURL + 'customdata/getdata';
        return this.http.post(url, postParam);
    }

    mstDepApplog(payload): Observable<any> {
        const url = this.baseURL + 'approvalflow/processrequest';
        return this.http.post(url, payload);
    }

    accidentalReqApprove(payload): Observable<any> {
        const url = this.baseURL + 'approvalflow/process';
        return this.http.post(url, payload);
    }

    breakageEntrySubmit(payload): Observable<any> {
        const url = this.baseURL + 'customdata/savedata';
        return this.http.post(url, payload);
    }

    palletGetSticker(payload) {
        const url = this.baseURL + 'palletsticker/getsticker';
        return this.http.post(url, payload);
    }

    downloadQRCode(qrCode): Observable<Blob> {
        const url = this.baseURL + 'palletsticker/getsticker';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'image/png' });
        return this.http.post(url, qrCode, { headers: headers, responseType: 'blob' });
    }

    accidentRequest(payload): Observable<any> {
        const url = this.baseURL + 'accident/savewithdoc';
        return this.http.post(url, payload);
    }

    savePenaltyDetails(payload): Observable<any> {
        const url = this.baseURL + 'penalty/savePenaltyDetails';
        return this.http.post(url, payload);
    }
    getdutybalance(payload): Observable<any> {
        const url = this.domailUrl + 'payandrecon/1.0/config/getdutybalance';
        return this.http.post(url, payload);
    }

    savePaymentDetailsRecord(payload): Observable<any> {
        const url = this.trackandtraceUrl + 'penalty/savePaymentDetails';
        return this.http.post(url, payload);
    }

    inventoryUpdate(payload): Observable<any> {
        const url = this.baseURL + 'stockverificationdetails/addstockininventoryschedule';
        return this.http.post(url, payload)
    }

    getBottlingLine(payload): Observable<any> {
        const url = this.scmUrl + 'bottlingLineRegistration/lazySearch';
        return this.http.post(url, payload)
    }
    loadPalletBeforeBoxing(payload): Observable<any> {
        const url = this.baseURL + 'palletstock/loadcaseinpalletbeforeboxing';
        return this.http.post(url, payload);
    }
    completePalletBeforeBoxing(payload): Observable<any> {
        const url = this.baseURL + 'palletstock/updatepalletregisterstatus';
        return this.http.post(url, payload);
    }
    getBrandDetails(customerdata): Observable<any> {
        const url = this.baseURL + "customdata/getdata";
        return this.http.post(url, customerdata);
    }

}


