import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class IndentRequestService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  // addetails(postdata): Observable<any> {
  //   const url = this.baseURL + 'pdIndentRequest/';
  //   return this.http.post(url, postdata);
  // }
  // search(postdata): Observable<any> {
  //   const url = this.baseURL + 'pdIndentRequest/acceptance/lazySearch';
  //   return this.http.post(url, postdata);
  // }
  // getById(id): Observable<any> {
  //   const url = this.baseURL + 'pdIndentRequest/' + id;
  //   return this.http.get(url);
  // }
  // getapplicationnumbers(data): any {
  //   const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
  //   return this.http.post(url, {}).toPromise();
  // }
  // getLicanceData(category): Observable<any> {
  //   const url = this.licenseURL + 'license/category/' + category;
  //   return this.http.get(url);
  // }
  validityExtension(payload): Observable<any> {
    const url = this.baseURL + apiUrls.searchIndentAcceptance1;
    return this.http.post(url, payload);
  }

  approveIndent(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.indentApprove;
    return this.http.post(url, postdata);
  }
  searchIndent(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.searchIndentAcceptance;
    return this.http.post(url, postdata);
  }
  indentGetById(id): Observable<any> {
    const url = this.baseURL + apiUrls.orderRequestGetById + id;
    return this.http.get(url);
  }
  getUserById(userId): Observable<any> {
    const url = this.baseURL + apiUrls.getUserById + '?id=' + userId;
    return this.http.get(url);
  }
  getTotalIndentByStatus(data): Observable<any> {
    var url = this.baseURL + apiUrls.getTotalIndentByStatus + '?Domain=' + data.Domain + '&licenseNo=' + data.licenseNo + '&status=' + data.status;
    if (data.industrySubCategory) {
      url += '&industrySubCategory=' + data.industrySubCategory;
    }
    if (data.subDomain) {
      url += '&subDomain=' + data.subDomain;
    }
    return this.http.get(url);
  }


  // Indent request cattle field
  addetails(data): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/lazySearch';
    return this.http.post(url, payload);
  }
 

 
  getById(id): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }

  downloadReportVal(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt';
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'pdIndentRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findpaymentdetailsIndentRequest(applicationno, feetype, processType): Observable<any> {
    let url = this.baseURL + 'pdIndentRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype +'&processType=' +processType;
    return this.http.get(url);
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=MOLASSES_PERMIT_PENALTY';
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadPaymentForIndentRequest(appnumber, liceanseNumber, feeType,processType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=MOLASSES_PERMIT_PENALTY'
    +'&processType='+processType;
    return this.http.get(url, { responseType: 'blob' });
  }
}
