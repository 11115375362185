import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
  providedIn: 'root'
})
export class Mf5Part2Service {
  baseURL = environment.parentUnit;
  baseURL1 = environment.scmproduction;
  constructor(private http: HttpClient) { }
  getAllMf5Part2(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllMf5Part2;
    return this.http.get(url);
  }
  getMf5Part2ById(id): Observable<any> {
    const url = this.baseURL + apiUrls.mf5Part2GetById + id;
    return this.http.get(url);
  }
  addMf5Part2(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addMf5Part2;
    return this.http.post(url, postdata);
  }
  updateMf5Part2(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.updateMf5Part2;
    return this.http.put(url, postdata);
  }
  searchMf5Part2(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mf5Part2Search;
    return this.http.post(url, postdata);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }
  gettanksDetails(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.gettanksbyid;
    return this.http.post(url, postdata);
  }
  getYear(): Observable<any> {
    const url = this.baseURL + 'utility/molasses/forecastDetails';
    return this.http.get(url);
  }
  getStockInDetail(postdata): Observable<any> {
    const url = this.baseURL + 'mf5Part2/getStockInDetail';
    return this.http.post(url, postdata);
  }
  getStockInTankList(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getStockInTankList;
    return this.http.post(url, postdata);
  }
  // gettanksbyid(id) {
  //   const ids = `?gradeId=${id}`
  //   const url = this.baseURL + `${apiUrls.gettanksbyid}${ids}`;
  //   return this.http.get(url);
  // }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, data);
  }
  getStatusCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL1 + 'mf5Part2/getMf5Part2StatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
}
