import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tokens } from '@app/models/tokens';
import { AuthUser } from '@app/models/user-management';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable, of } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { catchError, map, mapTo, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly USER_NAME = 'USER_NAME';
  private readonly FULL_NAME = 'FULL_NAME';
  private readonly USER_ID = 'USER_ID';
  private readonly LOGIN_TIME = 'LOGIN_TIME';
  private readonly DESIGNATION = 'DESIGNATION';
  private readonly LOGIN_REMOTE_IP = 'LOGIN_REMOTE_IP';
  private readonly ENTITY_TYPE = 'EntityType';
  private readonly PROFILE_URL = 'ProfileUrl';
  private readonly IS_CUSTOMER = 'IS_CUSTOMER';
  private readonly USER_DET = 'USER_DET';
  private readonly LIC_APPLI_NO = 'LIC_APPLI_NO';
  private readonly LICENSE_DETAILS = 'licenseDetails';
  private readonly ENTITY_CODE = 'entityCode';
  private readonly LICENSE_APPLICATION_NUMBER = 'licenseApplicationNumber';
  private readonly LICENSE_NUMBER = 'licenseNumber';
  private readonly LICENSE_TYPE = 'licenseType';
  private readonly UNIT_NAME = 'unitName';
  private readonly UNIT_ADDRESS = 'unitAddress';
  private readonly UNIT_TYPE = 'unitType';
  private readonly DISTRICT_CODE = 'districtCode';
  private readonly DISTRICT_NAME = 'districtName';
  private readonly LICENSE_TYPE_CODE = 'licenseTypeCode';
  private readonly ALLOCATED_DISTRICT = 'ALLOCATED_DISTRICT';
  private readonly ALLOCATED_UNIT = 'ALLOCATED_UNIT';
  private readonly USER_ACTIVITY_CONFIG = 'USER_ACTIVITY_CONFIG';
  private readonly PROCESS_TYPE = 'processType';

  private readonly RENEWAL_PARENT_LIC_NUMBER = 'RENEWAL_PARENT_LIC_NUMBER';

  private loggedUser: string;
  isProduction = environment.production;
  authToken: any;
  private baseURL = environment.baseURLUser;
  private paymentSalt: any;
  private paymentSurl = environment.paymentSurl;
  private paymentFurl = environment.paymentFurl;
  public fileUploadFlag = false;
  private licencemanagementIPURL = environment.licencemanagementIPURL;

  private readonly USER_MENUS = 'USER_MENUS';

  constructor(private http: HttpClient) {
    if (!this.authToken) {
      setTimeout(() => {
        this.setToken();
      }, 100);
    }
  }

  async setToken() {
    const jsonStr = atob(environment.info.substring(5));
    const strParse = JSON.parse(jsonStr);
    this.paymentSalt = strParse.paymentSalt;
    this.authToken = strParse.authToken;
    const tokenFromAPI = await this.getNewToken();
    this.authToken = tokenFromAPI ? tokenFromAPI : strParse.authToken;
  }

  private handleError(error: any) {
    if (error instanceof Response) {
      return _throw(error.json()['error'] || 'backend server error');
    }
    // in a case server returns 400 error, which means no data found
    return of([]);
  }

  // public isAuthenticated(): boolean {
  //   const token = this.getJwtToken();    // Check whether the token is expired and return
  //   // true or false
  //   return !this.jwtHelper.isTokenExpired(token);
  // }

  login(user: { userName: string; password?: string; loginType?: string; otp?: string; }): Observable<boolean> {

    return this.http
      .post<any>(this.baseURL + apiUrls.loginUser, user)
      .pipe(
        tap((resp) => {
          if (resp.responseCode === 500) {
            return resp;
          }
          const logRemoteIp = '65.0.150.210';
          const { auth: respAuth, userDetails: userDet, lastLoginTime: logInTime, fullName, configKeys, resetPassword = false, link = '', message = '' } = resp.content;
          if(resetPassword){
            respAuth.resetPassword = resetPassword
            respAuth.link = link
            respAuth.message = message
          }
          const {
            token: jwt, token: refreshToken,
            userName, entityType, userId,
            isCustomer, entityList, designationCode
          } = respAuth;

          localStorage.setItem('USER_ACTIVITY_CONFIG', JSON.stringify(configKeys));
          const newTabLogoutConfig = configKeys.find(res => res.code === 'TAB_LOGOUT');
          if (newTabLogoutConfig.value.toLowerCase() === 'yes') {
            sessionStorage.setItem('NEW_TAB_WILL_LOGOUT', 'true');
          }

          !resetPassword && this.doLoginUser(
            user.userName,
            { jwt, refreshToken },
            { userName, userId, logInTime, logRemoteIp, isCustomer, fullName, designationCode },
            entityType
          );

          if (isCustomer && !resetPassword) {
            this.setUserDet(userDet)
          }
          this.setEntityList(entityList)
          this.storeProfileUrl(isCustomer);
        }),
        map((ev) => ev.responseCode != 500 ? ev.content.auth : ev),
        catchError((error) => this.handleError(error))
      );
  }

  checkPasswordResetRequiredorNot(userName): Observable<any> {
    const url = this.baseURL + apiUrls.checkPasswordResetRequired + '?userName=' + userName;
    return this.http.get(url);
  }

  getUserById(userId): Observable<any> {
    const url = this.baseURL + apiUrls.getCustomerById + '?id=' + userId;
    return this.http.get(url);
  }

  createCustomer(payload) {
    const url = this.baseURL + apiUrls.createCustomer;
    return this.http.post(url, payload).toPromise();
  }

  checkApplicantRegistration() {
    const url = this.baseURL + apiUrls.checkApplicantRegistration;
    return this.http.get(url).toPromise();
  }

  authenticationGenerateCaptcha(width, height) {
    const url = this.baseURL + apiUrls.authenticationGenerateCaptcha + '?width=' + width + '&height=' + height;
    return this.http.get(url).toPromise();
  }

  authenticateNMRequest(token) {
    const url = this.baseURL + apiUrls.authenticateNMRequest + '?token=' + token;
    return this.http.get(url)
  }

  authenticateEDistrictRequest(token) {
    const url = this.baseURL + apiUrls.authenticateEDistrictRequest + '?token=' + token;
    return this.http.get(url)
  }

  authenticateIspRequest(token) {
    const url = this.baseURL + apiUrls.authenticateIspRequest + '?token=' + token;
    return this.http.get(url)
  }

  updateCustomer(payload) {
    const url = this.licencemanagementIPURL + apiUrls.updateCustomer;
    return this.http.post(url, payload).toPromise();
  }

  checkEmail(email, userId?) {
    let url = '';

    if (userId) {
      url = this.baseURL + apiUrls.mailCheck + '?emailId=' + email + '&userId=' + userId;
    } else {
      url = this.baseURL + apiUrls.mailCheck + '?emailId=' + email;
    }

    return this.http.get(url).toPromise();
  }

  checkMobile(mobile, userId?) {
    let url = '';

    if (userId) {
      url = this.baseURL + apiUrls.mobileCheck + '?mobileNumber=' + mobile + '&userId=' + userId;
    } else {
      url = this.baseURL + apiUrls.mobileCheck + '?mobileNumber=' + mobile;
    }

    return this.http.get(url).toPromise();
  }

  checkAadhar(aadhar) {
    const url = this.baseURL + apiUrls.aadharCheck + '?aadharNumber=' + aadhar;
    return this.http.get(url).toPromise();
  }

  checkPanNumber(panNo) {
    const url = this.baseURL + apiUrls.panNoCheck + '?panNumber=' + panNo;
    return this.http.get(url).toPromise();
  }

  checkUsername(username) {
    const url = this.baseURL + apiUrls.userNameCheck + '?userName=' + username;
    return this.http.get(url).toPromise();
  }

  generateOTP(emailId) {
    const url = this.baseURL + apiUrls.generateOTP + '?emailId=' + emailId;
    return this.http.post(url, {}).toPromise();
  }

  checkOTPLogin() {
    const url = this.baseURL + apiUrls.checkOTPLogin;
    return this.http.get(url).toPromise();
  }

  passwordReset(payload: { token: string; password: string, confirmPassword: string }) {
    const url = this.baseURL + apiUrls.passwordReset;
    return this.http.post(url, payload).toPromise();
  }

  logout_org() {
    const payload = {
      refreshToken: this.getRefreshToken(),
    };
    return this.http.post<any>(`authentication/logout`, payload).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError((error) => this.handleError(error))
    );
  }

  logoutAPICall() {
    if (this.isLoggedIn()) {
      const url = this.baseURL + apiUrls.logoutUser;
      this.http.get(url).subscribe();
    }
  }

  logout() {
    this.logoutAPICall();
    this.doLogoutUser();
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  isCustomer() {
    return localStorage.getItem(this.IS_CUSTOMER) === 'true' ? true : false;
  }

  getLogInTime() {
    return localStorage.getItem(this.LOGIN_TIME);
  }

  refreshToken() {
    const payload = {
      refreshToken: this.getRefreshToken(),
    };
    return this.http.post<any>(`authentication/refresh`, payload).pipe(
      tap((tokens: Tokens) => {
        this.storeJwtToken(tokens.jwt);
      })
    );
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public doLoginUser(
    username: string,
    tokens: Tokens,
    user: AuthUser,
    entityType: any = 'Customer'
  ) {
    this.loggedUser = username;
    this.storeTokens(tokens);
    this.storeUser(user);
    this.storeentity(entityType);
  }

  private storeentity(entity) {
    localStorage.setItem(this.ENTITY_TYPE, entity);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
    this.removeUser();
    this.clearStorages();
  }

  public clearStorages() {
    localStorage.clear();
    sessionStorage.clear();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getUserName() {
    return localStorage.getItem(this.USER_NAME);
  }

  getUserFullName() {
    return localStorage.getItem(this.FULL_NAME);
  }

  getUserId() {
    return localStorage.getItem(this.USER_ID);
  }

  getDesignationCode() {
    return localStorage.getItem(this.DESIGNATION);
  }

  getUserMenus() {
    return localStorage.getItem(this.USER_MENUS);
  }

  getProfileUrl() {
    return localStorage.getItem(this.PROFILE_URL);
  }

  setUserDet(userObj) {
    return localStorage.setItem(this.USER_DET, JSON.stringify(userObj));
  }

  getUserDet() {
    return JSON.parse(localStorage.getItem(this.USER_DET));
  }

  setLicenseDetails(code) {
    return localStorage.setItem(this.LICENSE_DETAILS, code);
  }
  getLicenseDetails() {
    return localStorage.getItem(this.LICENSE_DETAILS);
  }

  setEntityCode(code) {
    return localStorage.setItem(this.ENTITY_CODE, code);
  }
  setEntityList(code) {
    return localStorage.setItem('entityList', code ? code.join(',') : '');
  }
  getEntityCode() {
    return localStorage.getItem(this.ENTITY_CODE);
  }

  setLicenseApplicationNumber(number) {
    return localStorage.setItem(this.LICENSE_APPLICATION_NUMBER, number);
  }

  getLicenseApplicationNumber() {
    return localStorage.getItem(this.LICENSE_APPLICATION_NUMBER);
  }

  setLicenseNumber(number) {
    return localStorage.setItem(this.LICENSE_NUMBER, number);
  }

  getLicenseNumber() {
    return localStorage.getItem(this.LICENSE_NUMBER);
  }

  setLicenseType(str) {
    return localStorage.setItem(this.LICENSE_TYPE, str);
  }

  getLicenseType() {
    return localStorage.getItem(this.LICENSE_TYPE);
  }

  setUnitName(str) {
    return localStorage.setItem(this.UNIT_NAME, str);
  }

  getUnitName() {
    return localStorage.getItem(this.UNIT_NAME);
  }

  setUnitAddress(str) {
    return localStorage.setItem(this.UNIT_ADDRESS, str);
  }

  getUnitAddress() {
    return localStorage.getItem(this.UNIT_ADDRESS);
  }

  setUnitType(str) {
    return localStorage.setItem(this.UNIT_TYPE, str);
  }

  setDistrictCode(str) {
    return localStorage.setItem(this.DISTRICT_CODE, str);
  }

  setDistrictName(str) {
    return localStorage.setItem(this.DISTRICT_NAME, str);
  }

  setLicenseTypeCode(str) {
    return localStorage.setItem(this.LICENSE_TYPE_CODE, str);
  }

  setAllocatedDistrict(obj) {
    return localStorage.setItem(this.ALLOCATED_DISTRICT, JSON.stringify(obj));
  }

  setAllocatedUnit(obj) {
    return localStorage.setItem(this.ALLOCATED_UNIT, JSON.stringify(obj));
  }

  getPaymentDet() {
    return { salt: this.paymentSalt, surl: this.paymentSurl, furl: this.paymentFurl };
  }

  // getUserDetByEle(ele) {
  //   const userObj = this.getUserDet();
  //   return userObj;
  // }

  removeUserDet() {
    return localStorage.removeItem(this.USER_DET);
  }

  setUserMenus(menuJson) {
    return localStorage.setItem(this.USER_MENUS, JSON.stringify(menuJson));
  }

  removeUserMenus() {
    return localStorage.removeItem(this.USER_MENUS);
  }

  setLicAppliNo(licAppliNo) {
    return localStorage.setItem(this.LIC_APPLI_NO, licAppliNo);
  }

  getLicAppliNo() {
    return localStorage.getItem(this.LIC_APPLI_NO);
  }

  setProcessType(processType) {
    return localStorage.setItem(this.PROCESS_TYPE, processType);
  }

  getProcessType() {
    return localStorage.getItem(this.PROCESS_TYPE);
  }

  remLicAppliNo() {
    return localStorage.removeItem(this.LIC_APPLI_NO);
  }

  setRenewedParentLicenseNumber(licenseNumber: string) {
    return localStorage.setItem(this.RENEWAL_PARENT_LIC_NUMBER, licenseNumber);
  }

  getRenewedParentLicenseNumber() {
    return localStorage.getItem(this.RENEWAL_PARENT_LIC_NUMBER);
  }

  removeRenewedParentLicenseNumber() {
    return localStorage.removeItem(this.RENEWAL_PARENT_LIC_NUMBER);
  }

  public storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  storeUser(user: AuthUser) {
    localStorage.setItem(this.USER_NAME, user.userName);
    localStorage.setItem(this.FULL_NAME, user.fullName);
    localStorage.setItem(this.USER_ID, user.userId);
    localStorage.setItem(this.LOGIN_TIME, user.logInTime);
    localStorage.setItem(this.LOGIN_REMOTE_IP, user.logRemoteIp);
    localStorage.setItem(this.IS_CUSTOMER, user.isCustomer);
    localStorage.setItem(this.DESIGNATION, user.designationCode);
  }

  public storeProfileUrl(isCustomer) {
    const profileUrl = isCustomer ?
      '/customer/profile' : '/supplychainmanagement/sugarmills/profile';
    localStorage.setItem(this.PROFILE_URL, profileUrl);
  }

  private removeUser() {
    localStorage.removeItem(this.USER_NAME);
    localStorage.removeItem(this.USER_ID);
    localStorage.removeItem(this.LOGIN_TIME);
    localStorage.removeItem(this.LOGIN_REMOTE_IP);
    localStorage.removeItem(this.ENTITY_TYPE);
    localStorage.removeItem(this.PROFILE_URL);
    localStorage.removeItem(this.IS_CUSTOMER);
    localStorage.removeItem(this.USER_DET);
    localStorage.removeItem(this.ENTITY_CODE);
    localStorage.removeItem(this.LICENSE_APPLICATION_NUMBER);
    localStorage.removeItem(this.LICENSE_NUMBER);
    localStorage.removeItem(this.LICENSE_TYPE);
    localStorage.removeItem(this.UNIT_NAME);
    localStorage.removeItem(this.UNIT_ADDRESS);
    localStorage.removeItem(this.USER_ACTIVITY_CONFIG);
    localStorage.removeItem(this.PROCESS_TYPE);
    this.remLicAppliNo();
  }
  async getNewToken() { 
    const url = this.baseURL + apiUrls.authenticationGenerateGatewayToken;
    const { status: status, accessToken: accessToken, statusDesc: statusDesc
    }: any = await this.http.post(url, {}).toPromise();
    if (status === "SUCCESS") {
      if (accessToken) {
        return accessToken;
      } else { return ''; }
    } else {
      statusDesc && console.log('statusDesc ', statusDesc);
      return '';
    }
  }
  getEntityType() {
    const unitType = localStorage.getItem('unitType') ? localStorage.getItem('unitType') : "";
    const entityList = localStorage.getItem('entityList') ? localStorage.getItem('entityList') : "";
    let entityType = "";
    switch (unitType) {
      case "HBR":
        entityType = "HBR";
        break;
      case "RETAIL":
        entityType = "RETAIL";
        break;
      case "WHOLESALE":
        entityType = "WHOLESALE";
        break;
      case "BOTTELING":
        entityType = "BREWERY"; // entityList.includes('DISTILLERY') ? "DISTILLERY" : 
        break;
      case "DISTILLERY":
        entityType = "DISTILLERY";
        break;
      case "BREWERY":
        entityType = "BREWERY";
        break;
      case "DISTRICT_WAREHOUSE":
        entityType = "DISTRICT_WAREHOUSE";
        break;
      case "WAREHOUSE":
        entityType = "WAREHOUSE";
        break;
      case "MASTER_WARE_HOUSE":
        entityType = "MASTER_WARE_HOUSE";
        break;
      case "PARENT_UNIT":
        entityType = "PARENT_UNIT";
        break;
      case "CSD_SUPPLY":
        entityType = "CSD_SUPPLY";
        break;
      case "IMPORT_UNIT":
        entityType = "IMPORT_UNIT";
        break;
      case "BIO_ZERO":
        entityType = "BIO_ZERO";
        break;
      default:
        entityType = unitType;
        break;
    }
    return entityType;
  }

  menuShowHide(licenseType, flag) {
    if (localStorage.getItem('USER_MENUS')) {
      const userMenu = JSON.parse(localStorage.getItem('USER_MENUS'));
      const changedMenu = userMenu.map((ele1, i) => {
        if (ele1.code == 'RETAIL' || ele1.code == 'RETAIL_SHOP') {
          flag = true;
          return {
            ...ele1,
            children: ele1 ? ele1.children ? ele1.children.map((ele2, k) => {
              const indentMenu = localStorage.getItem('INDENT_BY_ITEM_MENU') ? localStorage.getItem('INDENT_BY_ITEM_MENU') : "false";
              if (ele2.code == 'RETAIL_INDENT_REQUEST_BY_ITEM' && indentMenu == "true") {
                return { ...ele2, url: "/wholesale/indentrequestbybottles/list" }
              } else {
                if (ele2.code == 'RETAIL_INDENT_REQUEST_BY_ITEM') {
                  return { ...ele2, url: "" }
                } else {
                  if (licenseType == "HM1" || licenseType == "CL5C") {
                    if (ele2.code == 'RETAIL_MGQ_CONFIG') {
                      return { ...ele2, url: "/retailpos/retailtransconfigmgq/list" }
                    } else if (ele2.code == 'RETAIL_MGR_CONFIG') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_MGQ_REQUEST') {
                      return { ...ele2, url: "/retailpos/retailtoretailmgqrequest/list" }
                    } else if (ele2.code == 'RETAIL_MGR_REQUEST') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_MGQ_ACCEPTANCE') {
                      return { ...ele2, url: "/retailpos/retailtoretailmgqacceptance/list" }
                    } else if (ele2.code == 'RETAIL_MGR_ACCEPTANCE') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_INDENT_REQUEST_QUOTA') {
                      return { ...ele2, url: "/retailpos/indentrequestbyquota/list" }
                    } else if (ele2.code == 'RETAIL_INDENT_REQUEST_BY_REVENUE') {
                      return { ...ele2, url: "" }
                    } else { return { ...ele2 } }
                  } else {
                    if (ele2.code == 'RETAIL_MGQ_CONFIG') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_MGR_CONFIG') {
                      return { ...ele2, url: "/retailpos/retailtransferconfigurationmgr/list" }
                    } else if (ele2.code == 'RETAIL_MGQ_REQUEST') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_MGR_REQUEST') {
                      return { ...ele2, url: "/retailpos/retailtoretailmgrrequest/list" }
                    } else if (ele2.code == 'RETAIL_MGQ_ACCEPTANCE') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_MGR_ACCEPTANCE') {
                      return { ...ele2, url: "/retailpos/retailtoretailmgracceptance/list" }
                    } else if (ele2.code == 'RETAIL_INDENT_REQUEST_QUOTA') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'RETAIL_INDENT_REQUEST_BY_REVENUE') {
                      return { ...ele2, url: "/retailpos/indentrequestbyrevenue/list" }
                    } else { return { ...ele2 } }
                  }
                }
              }
            }).filter(item => item !== undefined) : '' : '',
          }
        } else if (ele1.code == 'WHOLESALE') {
          flag = true;
          return {
            ...ele1,
            children: ele1 ? ele1.children ? ele1.children.map((ele2, k) => {

              const indentMenu = localStorage.getItem('INDENT_BY_ITEM_MENU') ? localStorage.getItem('INDENT_BY_ITEM_MENU') : "false";

              if (ele2.code == 'WHOLESALE_ORDER_PICKUP_BY_ITEM' && indentMenu == "true") {
                return { ...ele2, url: "/wholesale/orderpickupbyitems/list" }
              } else {
                if (ele2.code == 'WHOLESALE_ORDER_PICKUP_BY_ITEM') {
                  return { ...ele2, url: "" }
                } else {
                  if (licenseType == "CL2" || licenseType == "CL1") {
                    if (ele2.code == 'WHOLESALE_CL2_INDENT_REQUEST') {
                      return { ...ele2, url: "/wholesale/indentrequestcl2/list" }
                    } else if (ele2.code == 'WHOLESALE_INDENT_REQUEST') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'WHOLESALE_IMPORT_PERMIT_REQUEST') {
                      return { ...ele2, url: "" }
                    } else { return { ...ele2 } }
                  }
                  else if (licenseType == "FL2A") {
                    if (ele2.code == 'WHOLESALE_CL2_INDENT_REQUEST') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'WHOLESALE_INDENT_REQUEST') {
                      return { ...ele2, url: "/wholesale/indentrequestnew/list" }
                    } else if (ele2.code == 'WHOLESALE_IMPORT_PERMIT_REQUEST') {
                      return { ...ele2, url: "/wholesale/importpermitrequest/list" }
                    } else { return { ...ele2 } }
                  } else {
                    if (ele2.code == 'WHOLESALE_CL2_INDENT_REQUEST') {
                      return { ...ele2, url: "" }
                    } else if (ele2.code == 'WHOLESALE_INDENT_REQUEST') {
                      return { ...ele2, url: "/wholesale/indentrequestnew/list" }
                    } else if (ele2.code == 'WHOLESALE_IMPORT_PERMIT_REQUEST') {
                      return { ...ele2, url: "" }
                    } else { return { ...ele2 } }
                  }
                }
              }

            }).filter(item => item !== undefined) : '' : '',
          }
        } else if (ele1.code == 'POTABLE_DISTILLERY') {
          flag = true;
          return {
            ...ele1,
            children: ele1 ? ele1.children ? ele1.children.map((ele2, k) => {
              if (licenseType == "CLB1" || licenseType == "CLB2") {
                if (ele2.code == 'POTABLE_TRANSPORT_PASS_FL36') {
                  return { ...ele2, name: "Transport Pass-CLB11" }
                } else if (ele2.code == 'POTABLE_CL2_INDENT_ACCEPTANCE') {
                  return { ...ele2, url: "/wholesale/cl2indentacceptance/list" }
                } else if (ele2.code == 'POTABLE_CL_ACCIDENT_RETURN_TP_ACKNOWLEDGEMENT') {
                  return { ...ele2, url: "/wholesale/accidentreturntpstockin/list" }
                } else if (ele2.code == 'POTABLE_INDENT_ACCEPTANCE') {
                  return { ...ele2, url: "" }
                } else {
                  return { ...ele2 }
                }
              } else {
                if (ele2.code == 'POTABLE_PD20_FL2_ADVANCE_DEPOSIT') {
                  return { ...ele2, name: "Advance Deposit-PD20-FL2" }
                } else if (ele2.code == 'POTABLE_TRANSPORT_PASS_FL36') {
                  return { ...ele2, name: "Transport Pass-FL36" }
                } else if (ele2.code == 'POTABLE_CL_ACCIDENT_RETURN_TP_ACKNOWLEDGEMENT') {
                  return { ...ele2, url: "" }
                } else if (ele2.code == 'POTABLE_CL2_INDENT_ACCEPTANCE') {
                  return { ...ele2, url: "" }
                } else if (ele2.code == 'POTABLE_INDENT_ACCEPTANCE') {
                  return { ...ele2, url: "/wholesale/indentacceptance/list" }
                } else {
                  return { ...ele2 }
                }
              }
            }).filter(item => item !== undefined) : '' : '',
          }
        } else if (ele1.code == 'IMPORT_UNIT') {
          flag = true;
          return {
            ...ele1,
            children: ele1 ? ele1.children ? ele1.children.map((ele2, k) => {
              if (ele2.code == 'BIO') {
                if (licenseType == "IU") {
                  return { ...ele2, type: "collapse" }
                } else {
                  return { ...ele2, type: "" }
                }
              } else if (ele2.code == 'CBW') {
                if (licenseType == "IU") {
                  return { ...ele2, type: "collapse" }
                } else {
                  return { ...ele2, type: "" }
                }
              } else if (ele2.code == 'BIOZERO') {
                if (licenseType == "BIO_ZERO_WITHIN_UP" || licenseType == "BIO_ZERO_OUTSIDE_UP") {
                  return { ...ele2, type: "collapse" }
                } else {
                  return { ...ele2, type: "" }
                }
              }
              else if (ele2.code.trim() == 'BIO_1A') {
                console.log("ele2.code.trim()>>", ele2.code.trim(), ele2.code)
                if (licenseType == "BIO-1A") {
                  return { ...ele2, type: "collapse" }
                } else {
                  return { ...ele2, type: "" }
                }
              } else { return { ...ele2 } }
            }).filter(item => item !== undefined) : '' : '',
          }
        } else { return { ...ele1 } }
      });
      this.setUserMenus(changedMenu);
      return flag;
    } else {
      return flag;
    }
  }
}
