import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UserManagmentService, AuthService, AlertService } from '@app/services';
import { MessagingService } from '@appServices/messaging.service';
import { NavigationItem } from '../../navigation/navigation';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrewerBoxService } from '@app/services/brewery/brewery.service';
import { environment } from '@appEnv/environment';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig, NgbCollapseModule]
})
export class NavRightComponent implements OnInit, OnDestroy {

  @ViewChild('loginOutPopup', { static: false }) loginOutPopup;
  pipe = new DatePipe('en-US');
  logInTime: string;
  dateTime: string;
  profileUrl: string;
  departmentUserProfileUrl: string;
  username: string;
  userId: string;
  notificationEarlierList: any[] = [];
  message;
  firbaseToken: string;
  topicKey = 'uId_';
  reqTokenSubscription;
  messageSubscription;
  newMsgCount = 0;
  resetPasswordUrl: string;
  licenseFlag = false;
  arrLicDet: any = [];
  licenseNumber: any;
  isCustomer: boolean;
  appConfigKeyFlag: boolean = false;
  SSOLoginUrl = environment.SSOLoginUrl;
  SSODashoardUrl = environment.SSODashoardUrl;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private authSer: AuthService,
    private alertSer: AlertService,
    private messagingService: MessagingService,
    private userManSer: UserManagmentService,
    public nav: NavigationItem,
    private http: HttpClient,
    private boxService: BrewerBoxService,
  ) { }

  async ngOnInit() {
    const dataCodeFlag = await this.boxService.getAppConfigValueEntityWise({ appKey: "SSO_VALIDATION_APPKEY", entityCode: localStorage.getItem('entityCode') });
    this.appConfigKeyFlag = dataCodeFlag.responseContent == "true" ? true : false;
    this.logInTime = this.authSer.getLogInTime();
    this.userId = this.authSer.getUserId();
    console.log('this.logInTime::', this.logInTime);
    this.dateTime = this.logInTime === undefined ? 'now' : this.pipe.transform(this.logInTime, 'd MMM y, h:mm a');
    this.profileUrl = this.authSer.getProfileUrl();
    this.departmentUserProfileUrl = '/customer/department-user-profile';
    this.resetPasswordUrl = '/customer/reset-password';
    const authUserName = this.authSer.getUserName();
    this.username = authUserName ? authUserName : 'Admin';
    this.isCustomer = this.authSer.isCustomer();

    this.reqTokenSubscription = this.messagingService.requestPermission().subscribe(
      async (token) => {
        if (token) {
          this.firbaseToken = token;
          // console.log('got-firbaseToken::', token);
          const registerResp: any = await this.messagingService.registerToken(this.firbaseToken, [this.topicKey + this.userId]);
          // console.log('registerResp::', registerResp);
          // const notificationResp: any = await this.messagingService.sendNotification(this.userId, 'Test Title', 'Test Body Data');
          // console.log('notificationResp::', notificationResp);
          this.subscribeForMessage();

          return true;
        } else {
          console.log('token not received');
        }
      },
      (err) => console.error('Unable to get permission to notify.', err)
    );

    setTimeout(() => {
      this.getlicense();
    }, 800);

    // this.findTop5Notification();

    document.addEventListener("visibilitychange", (res) => {
      if (localStorage.getItem('licenseDetails')) {
        if (this.licenseNumber != localStorage.getItem('licenseNumber')) {
          console.log("visibilitychange event");
          console.log('Change license');
          this.licenseNumber = localStorage.getItem('licenseNumber');
        }
      }
    });

    document.addEventListener("click", (res) => {
      if (localStorage.getItem('licenseDetails')) {
        if (this.licenseNumber != localStorage.getItem('licenseNumber')) {
          console.log("click event");
          console.log('Change license');
          this.licenseNumber = localStorage.getItem('licenseNumber');
        }
      }
    });

  }

  getlicense() {
    if (localStorage.getItem('licenseDetails')) {
      this.licenseNumber = localStorage.getItem('licenseNumber');
      this.arrLicDet = JSON.parse(localStorage.getItem('licenseDetails'));
      if (this.arrLicDet.length > 0 && localStorage.getItem('IS_CUSTOMER') == 'true') {
        this.licenseFlag = true;
      }
    }
  }

  subscribeForMessage() {
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    this.messageSubscription = this.messagingService.currentMessage.subscribe((res) => {
      if (res) {
        this.notificationEarlierList.unshift(res.notification);
        this.notificationEarlierList.pop();
        // const [ m1, m2, m3, m4, m5 ] = this.notificationEarlierList;
        // this.notificationEarlierList = [res.notification, m1, m2, m3, m4];
        this.newMsgCount++;
        this.alertSer.showSuccess(res.notification.title, res.notification.body, true);
      }
    });
  }

  findTop5Notification() {
    this.messagingService.findTop5Notification(this.userId).subscribe(
      (res: any) => {
        const { responseCode: respCode, responseMessage: respMsg, content: respContent } = res;
        this.notificationEarlierList = (respCode === 200) ? respContent : [];
      }
    );
  }

  changeIsAllRead() {
    this.messagingService.updateIsReadNotification(this.userId).subscribe(
      (res: any) => {
        (res) ? this.newMsgCount = 0 : console.error('updateIsReadNotification res::', res)

        setTimeout(() => {
          this.router.navigateByUrl('/notification')
        }, 1000);
      },
      (err) => console.error('Unable to get permission to notify.', err)
    );
  }

  onLogoutClick() { this.loginOutPopup.show(); }
  onLogoutPopClose() { this.loginOutPopup.hide(); }

  async onLogout() {
    this.messagingService.unsubscribeTopics(this.firbaseToken, [this.topicKey + this.userId]).subscribe(
      (res: any) => {
        this.messagingService.subscription.unsubscribe();
      }
    );
    this.authSer.logout();
    this.loginOutPopup.hide();
    setTimeout(() => { this.router.navigate(['/']); }, 0);
  }

  setLicense(licenseNumber) {

    if (licenseNumber) {
      const entityObj = this.arrLicDet.find(i => i.licenseNumber === licenseNumber);
      entityObj.entityCode && this.authSer.setEntityCode(entityObj.entityCode);
      entityObj.licenseApplicationNumber && this.authSer.setLicenseApplicationNumber(entityObj.licenseApplicationNumber);
      entityObj.licenseNumber && this.authSer.setLicenseNumber(entityObj.licenseNumber);
      let reqProData = {
        applicationNumber: entityObj.licenseApplicationNumber,
        entityCode: entityObj.entityCode,
        licenseNumber: entityObj.licenseNumber
      }
      this.userManSer.getGetProfile(reqProData).subscribe(
        async (resp: any) => {
          if (resp.statusCode === 0) {
            let obj = resp.responseContent;
            if (obj) {
              obj.entityProfileMap && this.authSer.setLicenseType(obj.entityProfileMap.license_type);
              obj.entityProfileMap && this.authSer.setUnitName(obj.entityProfileMap.entity_name);
              obj.entityProfileMap && this.authSer.setUnitAddress(obj.entityProfileMap.unit_address);
              obj.entityProfileMap && this.authSer.setUnitType(obj.entityProfileMap.unit_type);
              obj.entityProfileMap && this.authSer.setDistrictCode(obj.entityProfileMap.district_code);
              obj.entityProfileMap && this.authSer.setDistrictName(obj.entityProfileMap.district_desc);
              obj.entityProfileMap && this.authSer.setLicenseTypeCode(obj.entityProfileMap.license_type_code);
              // this.router.navigate([this.router.url]);
              const reqappkey = { appKey: "INDENT_BY_ITEM_MODULE_ENABLED", entityCode: entityObj.entityCode };
              const appkey = await this.userManSer.getCustomData(reqappkey);
              if (appkey.statusCode == 0 && appkey.responseContent && appkey.responseContent == 'true') {
                localStorage.setItem('INDENT_BY_ITEM_MENU', 'true');
              } else {
                localStorage.setItem('INDENT_BY_ITEM_MENU', 'false');
              }
            }

            if (localStorage.getItem('USER_MENUS')) {
              const lt = obj ? obj.entityProfileMap && obj.entityProfileMap.license_type : "";
              const flag = await this.authSer.menuShowHide(lt, false);
              console.log('flag===> ', flag)
              if (flag) this.nav.menuFlag = true;
              this.router.navigate(['/welcome']);
            }
          }
        },
        (err: any) => {
          console.log('getEntityCode Err::', err);
        });
    }
  }

  async ngOnDestroy() {
    this.reqTokenSubscription && this.reqTokenSubscription.unsubscribe();
    this.messageSubscription && this.messageSubscription.unsubscribe();
  }

  /* 
  *Single Sign On 
  */
  navigateByMis() {
    console.log('SSOLogin Api is Enter -- {}');
    const url = this.SSOLoginUrl + 'dashboard/singlesignon';
    const httpOptions = {
      headers: new HttpHeaders({
        'logintoken': localStorage.getItem('JWT_TOKEN'),
        'sessionid': localStorage.getItem('USER_ID'),
        'userid': localStorage.getItem('USER_ID'),
        'username': localStorage.getItem('USER_NAME')
      })
    };

    this.http.get(url, httpOptions).subscribe((response: any) => {
      if (response != undefined || response) {
        console.log('SSOLogin Api Sucess After Calling SSOValidate Api-- {}');
        this.dashBoardSSOValidate(response);
      }
    },
      (error: any) => {
        console.log('SSOLogin Api Fail -- {}');
      }
    );
  }

  /* 
  *Single Sign On 
  */
  dashBoardSSOValidate(loginObj) {
    console.log('SSOLoginValidate Api is Enter -- {}');
    const url = this.SSOLoginUrl + 'dashboard/ssonvalidate';
    /* const httpOptions = {
      headers: new HttpHeaders({
        "loginkey": loginKey,
        "username": localStorage.getItem('USER_NAME')
      })
    }; */


    const httpOptions = {
      headers: new HttpHeaders({
        "loginkey": loginObj ? loginObj.loginKey ? loginObj.loginKey : '' : '',
        "username": localStorage.getItem('USER_NAME'),
        "reqSessionId": loginObj ? loginObj.reqSessionId ? loginObj.reqSessionId : '' : ''
      })
    };
    console.log('httpOptions :', httpOptions)

    this.http.get(url, httpOptions).subscribe((response: any) => {
      console.log('response :', response)
      if (response.statusCode == 200) {
        console.log('this.SSODashoardUrl :', this.SSODashoardUrl + '?reqId=' + loginObj.reqSessionId)
        window.open(this.SSODashoardUrl + '?reqId=' + loginObj.reqSessionId, "_blank");
      } else {
        this.alertSer.showError(response)
      }
    })


    /* fetch(url, {
      headers: {
        "loginkey": loginObj ? loginObj.loginKey ? loginObj.loginKey : '' : '',
        "username": localStorage.getItem('USER_NAME'),
        "reqSessionId": loginObj ? loginObj.reqSessionId ? loginObj.reqSessionId : '' : ''
      },
      method: 'GET',
    }).then(result => {
      console.log('result :', result);
      const reqId = loginObj ? loginObj.reqSessionId ? loginObj.reqSessionId : '' : '';
      const nUrl = result.url + '?' + reqId;
      console.log('nUrl :', nUrl);
      console.log('url :', url + '?' + reqId);
      console.log('this.SSODashoardUrl  :', this.SSODashoardUrl + '?reqId=' + reqId);
      window.open(this.SSODashoardUrl + '?reqId=' + reqId, "_blank");
    }), (error => {
      console.log('SSOLoginValidate Api is error -- {}', error);
    }) */

  }

}
