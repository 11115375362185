import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class AccidentRequestService {
  baseURL = environment.parentUnit;
  masterURL = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  constructor(private http: HttpClient) { }

  getMf4getPassNo(): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/getMF4GetPassNo';
    return this.http.get(url);
  }
  getMf4getPassNoDetail(postdata): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/getMF4GetPassNoDetail';
    return this.http.post(url, postdata);
  }
  accidentRqstLazySearch(postdata): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/lazySearch';
    return this.http.post(url, postdata);
  }
  addAccidentRqst(postdata): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/outward/create';
    return this.http.post(url, postdata);
  }
  getAccidentRqstById(id): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/' + id;
    return this.http.get(url);
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=ACCIDENT_REQUEST';
    return this.http.get(url, { responseType: 'blob' });
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'AccidentalRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  getStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/getAccidentalRequestStatusCount?entityType=' + entityType;
    return this.http.get(url);
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }

  downloadPdf(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  postDownload(postdata): any {  
    const url = environment.documentUrl + 'api/document/downloadfiles';
    return this.http.post(url, postdata).toPromise();
  }
  
  downloadaccident(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }

}
