import { Component, OnInit,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-confirm',
  templateUrl: './common-confirm.component.html',
  styleUrls: ['./common-confirm.component.scss']
})
export class CommonConfirmComponent implements OnInit {

  @Input() text: string;
  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit() {
  }

}
