import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import { PdCommonService } from '../pd';

@Injectable({
  providedIn: 'root'
})
export class PrefermentationService {

  baseURL = environment.distilleryBaseURL;
  authToken: any;
  parentunitURL = environment.parentUnit;
  constructor(
    private http: HttpClient,
    private pdCommonService: PdCommonService
  ) {
    const jsonStr = atob(environment.info.substring(5));
    const strParse = JSON.parse(jsonStr);
    this.authToken = strParse.authToken;
  }

  searchpreFermentationdata(data): Observable<any> {
    const url = this.baseURL + apiUrls.prefermentationlazySearch;
    return this.http.post(url, data);
  }
  getpreFermentationbyid(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.prefermentationgetbyid + id;
    return this.http.get(url);
  }
  addpreFermentationdata(data): Observable<any> {
    const url = this.baseURL + apiUrls.prefermentationcreate;
    return this.http.post(url, data);
  }
  updatepreFermentationdata(data): Observable<any> {
    const url = this.baseURL + apiUrls.prefermentationupdate;
    return this.http.put(url, data);
  }

  getcurrentStockBytankid(id): Observable<any> {
    const url = this.baseURL + apiUrls.currentstockbytank + id;
    return this.http.get(url);
  }

  getdistilleytank(): Observable<any> {
    const url = this.baseURL + apiUrls.disttank;
    return this.http.get(url);

  }

  gettanksbyid(id) {
    const ids = `?gradeId=${id}`
    const url = this.baseURL + `${apiUrls.gettanksbyid}${ids}`;
    return this.http.get(url);
  }

  getyeaseldata(id): Observable<any> {
    const url = this.baseURL + apiUrls.viewyeastprocess + id;
    return this.http.get(url);
  }

  getotnumber(): Observable<any> {
    const url = this.baseURL + apiUrls.prefermenterGetotnum;
    return this.http.get(url);
  }

  getotmumberdetails(id): Observable<any> {
    const url = this.baseURL + apiUrls.otdetails + id;
    return this.http.get(url);
  }

  getprefermentorvessalbyotid(id): Observable<any> {
    const url = this.baseURL + apiUrls.prefermentorvessalbyotid + id;
    return this.http.get(url);

  }

  getYeastreferenceNo(id): Observable<any> {
    const url = this.baseURL + apiUrls.yeastrefNobyotid + id;
    return this.http.get(url);

  }
  getyeastrefIdbyyeastdata(id): Observable<any> {
    const url = this.baseURL + apiUrls.getYeastByyeastrefId + id;
    return this.http.get(url);
  }

  getstockvessal(id): Observable<any> {
    const url = this.baseURL + apiUrls.vessalstock + id;
    return this.http.get(url);
  }

  getPermenterList(payload): Observable<any> {
    const url = this.parentunitURL + apiUrls.prefermentationlazySearch;
    return this.http.post(url, payload);
  }
  createdata(payload): Observable<any> {
    const url = this.parentunitURL + apiUrls.prefermentationcreate;
    return this.http.post(url, payload);
  }
  getyeastveeselList(payload): Observable<any> {
    const url = this.parentunitURL + apiUrls.VesselToUniTank;
    return this.http.post(url, payload);
  }
  generatePPFNumber(payload): Observable<any> {
    const url = this.parentunitURL + apiUrls.generatePPFNumber;
    return this.http.get(url, { params: payload });
  }

  getPrefermenterOtDetails(payload): Observable<any> {
    const url = this.parentunitURL + 'otProductionPlan/getPrefermenterOtDetails';
    return this.http.get(url, { params: payload });
  }
  downloadenavalue(payload) {
    const url = this.parentunitURL + 'reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  //  getPreFermenterProcessCount(entityType?): Observable<any> {
  //    const url = this.baseURL + 'preFermentationProcess/getOtPreFermenterProcessCount?entityType=' + entityType;
  //    return this.http.get(url);
  //  }

}