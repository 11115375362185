import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';

@Injectable({
  providedIn: 'root'
})
export class BottlinglineRepairService {

  constructor(private http: HttpClient) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  search(payload: any): Observable<any> {
    console.log(payload);
    const url = this.baseURL + 'bottlingRepair/lazySearch';
    return this.http.post(url, payload);
  }
  detail(id: Number): Observable<any> {
    const url = this.baseURL + 'breweryStockAvailability/' + id;
    return this.http.get(url);
  }
  getdetails(category, licanceNo): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category + '?licenseNumber=' + licanceNo;
    return this.http.get(url);
  }
  addetails(data): Observable<any> {
    const url = this.baseURL + 'bottlingRepair';
    return this.http.post(url, data);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'bottlingRepair/' + id;
    return this.http.get(url);
  }
  getRepairType(): Observable<any> {
    const url = this.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=REPAIR_TYPE'
    return this.http.get(url);
  }
  getline(): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "orderBy": "string",
      "page": 0,
      "pageSize": 40,
      "searchInput": {
        "entityType": "BREWERY",
        "licenseType": "",
        "status": "APPROVED",

      },
      "sortBy": "string"
    }
    return this.http.post(url, payload);
  }
  getlinePD(type,value): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "orderBy": "string",
      "page": 0,
      "pageSize": 40,
      "searchInput": {
        "entityType": type,
        "licenseType": value,
        "status": "APPROVED",

      },
      "sortBy": "string"
    }
    return this.http.post(url, payload);
  }
  bottlinglineSearch(payload): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    return this.http.post(url, payload);
    
}
  getlineSearchList(): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "orderBy": "string",
      "page": 0,
      "pageSize": 40,
      "searchInput": {
        "entityType": "BREWERY",
        "licenseType": "",
        "status": "MAINTANCE",

      },
      "sortBy": "string"
    }
    return this.http.post(url, payload);
  }
  getlinePd(): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "orderBy": "string",
      "page": 0,
      "pageSize": 40,
      "searchInput": {
        "entityType": "PD",
        "licenseType": "",
        "status": "APPROVED",

      },
      "sortBy": "string"
    }
    return this.http.post(url, payload);
  }
  // getStatusCount1(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
  //   //  const url ='http://localhost:3501/scmProduction/mf5Part1/getMf5Part1StatusCount?entityType='
  //   const url = this.baseURL +'bottlingRepair/getStatusCount?entityType='+ entityType
  //   +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
  //   +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
  //   return this.http.get(url);
  // }
  downloadenavalue(payload) {
      const url = this.baseURL + 'reportReceipt/downloadReceipt'
       return this.http.get(url, { responseType: 'blob', params: payload });
     }
    //  getStatusCount(entityType?): Observable<any> {
    //   const url = this.baseURL + 'bottlingRepair/getStatusCount?entityType=' + entityType ;
    //  return this.http.post(url,entityType);
    //   }

      getPdCardCount(payload): Observable<any> {
        const url = this.baseURL + 'pd/getStatusCount'
        return this.http.post(url,payload);
      }
}


//http://65.0.150.210:3503/masterData/api/masterValues/findDropDownMasterOnMaster?dropDownKey=REPAIR_TYPE

