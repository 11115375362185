import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class bondregitrationservice {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.scmproduction;
  licenseURL = environment.licneseBaseUrl;
  domainUrl = environment.domainUrl;
  retailUrl = environment.wholesaleNewURL;
 
search(data){
   const url = this.retailUrl + "customdata/getdata";
   return this.http.post (url,data)
}
submit(params){
  const url = this.retailUrl + "bondregister/saveexportbondregister";
  return this.http.post(url,params);
}
submitDept(params){
  const url = this.retailUrl + "approvalflow/process";
  return this.http.post(url,params);
}
}
