
import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { apiUrls } from '@appEnv/apiurls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WholesaleStockAvailabilityService {
  viewData: any;

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.localDBUrl;
  baseURL2 = environment.devisionUrl;
  parentUnitURL = environment.parentUnit;
  wholesaleNewURL = environment.wholesaleNewURL;

  getLiquorType(obj) {
    const url = this.wholesaleNewURL + "master/data/listmasterdata"
    return this.http.post(url, obj)
  }

  getTempLicense(): Observable<any> {
    const url = this.parentUnitURL + apiUrls.getTempLicense;
    return this.http.get(url);
  }

  getListApi(data): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, data);
  }

  get(url) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.get(requestURL).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }

  post(url, data, from?) {

    let requestURL;
    if (!from) {
      requestURL = this.baseURL + url;
    }
    else {
      requestURL = url;
    }
    // const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.post(requestURL, data).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }

  patch(url, data) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.patch(requestURL, data).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }

  put(url, data) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.put(requestURL, data).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }
};