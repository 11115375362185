import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Inject, Optional  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

declare var google: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
// Map Set up
@ViewChild('map', { static: false }) mapElement: any;
@ViewChild('origin', { static: false }) location!: ElementRef;
// @ViewChild('map', { static: false }) map: ElementRef;
map: google.maps.Map;
drawingManager: any;
lat = 40.73061;
lng = -73.935242;
coordinates = new google.maps.LatLng(this.lat, this.lng);
mapOptions: google.maps.MapOptions = {
  center: this.coordinates,
  zoom: 24,
  scaleControl: true
};

marker = new google.maps.Marker({
  position: this.coordinates,
  map: google.maps.Map,
  animation: google.maps.Animation.BOUNCE,
  draggable: true
});
  constructor(private dialogRef: MatDialogRef<GoogleMapComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    let cord
    this.coordinates = new google.maps.LatLng(this.data.lat.slice(0, -1).replace(`'`, '.'), this.data.lng.slice(0, -1).replace(`'`, '.'));
    this.mapOptions = {
      center: this.coordinates,
      zoom: 8,
      scaleControl: true
    };
  }

  ngAfterViewInit(): void {
    this.mapInitializer();
    // this.googlePlaceAutoCompleteAttach();
  }
  mapInitializer() {
    this.map = new google.maps.Map(this.mapElement.nativeElement,
      this.mapOptions);
    this.marker.setMap(this.map);
    this.marker.setPosition(this.coordinates);
    this.map.setCenter(this.coordinates);
    
  }

  setMapCoordinates(place: any) {
    this.coordinates = new google.maps.LatLng(place.lat, place.lng);
    this.marker.setPosition(this.coordinates);
    this.map.setCenter(this.coordinates);
  }

}
