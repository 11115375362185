import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({ providedIn: 'root' })
export class BottlingPlanMappingService {

    constructor( private http: HttpClient ) { }

    baseURL = environment.wholesaleNewURL;
    licenseURL = environment.licneseBaseUrl;

    addBottlingPlanMapping(data): Observable<any> {
        console.log('data', data)
        let headers = new HttpHeaders();
        const url = this.baseURL + apiUrls.addBottlingPlanMappingData;
        return this.http.post(url, data, { headers: headers });
    }

    // getLicenseDetails(): Observable<any> {
    //     const cat = localStorage.getItem('unitType') ? localStorage.getItem('unitType') : "BOTTLING";
    //     const url = this.licenseURL + 'license/category/' + cat;
    //     return this.http.get(url);
    // }

    getLicenseDetails(postParam): Observable<any> {
        const url = this.baseURL + apiUrls.getprofile;
        return this.http.post(url, postParam);
    }

}
