import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import * as fileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class TransportPassService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.wholesaleNewURL;
  baseURLMaster = environment.baseURLMaster;
  domainUrl = environment.domainUrl;

  getbothdamage(data): Observable<any> {
    let headers = new HttpHeaders();
    const url = this.baseURL + 'accident/savefullydamagewithdoc';
    return this.http.post(url, data, { headers: headers });
  }
  getProductSafe(data): Observable<any> {
    let headers = new HttpHeaders();
    const url = this.baseURL + 'accident/savewithdoc';
    return this.http.post(url, data, { headers: headers });
  }
  getvehiSafe(data): Observable<any> {
    let headers = new HttpHeaders();
    const url = this.baseURL + 'accident/savewithpartialdoc';
    return this.http.post(url, data, { headers: headers });
  }
  search(postdata): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, postdata);
  }
  getNACPaymentDetails(postdata): Observable<any> {
    const url = this.baseURL + 'nacapprovalflow/searchtppaymentadvancedutydetails';
    return this.http.post(url, postdata);
  }
  scanIndentNumber(obj): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  validatedetails(obj): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  validateorderpickupdetails(obj): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  searchindentnumber(obj): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  getApprovalFilterList(postdata): Observable<any> {
    // const url = this.baseURL + 'customdata/getdata';
    const url = this.baseURL + 'departmentapproval/getapprovalfilterlist';
    return this.http.post(url, postdata);
  }
  saveaccident(obj): Observable<any> {
    const url = this.baseURL + 'accident/save';
    return this.http.post(url, obj);
  }
  saveb10fl2tp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/saveb10fl2tp';
    return this.http.post(url, obj);
  }
  saveb10fl1tp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/saveb10fl1tp';
    return this.http.post(url, obj);
  }
  updateb10fl1tp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/updateb10fl1tp';
    return this.http.post(url, obj);
  }
  saveFinalTP(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/savefinaltransportpass';
    return this.http.post(url, obj);
  }
  saveflb11tp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/saveflb11tp';
    return this.http.post(url, obj);
  }

  paidIndentSubmitFL36(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/savefl36tp';
    return this.http.post(url, obj);
  }

  updatefl36tp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/updateflb11tp';
    return this.http.post(url, obj);
  }

  paidIndetUpdateFL36(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/updatefl36tp';
    return this.http.post(url, obj);
  }

  saveflb11withindenttp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/saveflb11withindenttp';
    return this.http.post(url, obj);
  }

  beerPaidIndentFLB11Submit(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/saveflb11tp';
    return this.http.post(url, obj);
  }

  updateflb11withindenttp(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/updateflb11withindenttp';
    return this.http.post(url, obj);
  }
  viewdrafttransportpassitem(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/viewdrafttransportpassitems';
    return this.http.post(url, obj);
  }
  viewfl36transportpassitems(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/viewfl36transportpassitems';
    return this.http.post(url, obj);
  }
  viewfl36transportpassitem(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/viewfl36transportpassitems ';
    return this.http.post(url, obj);
  }

  viewb10fl1transportpassitems(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/viewb10fl1transportpassitems';
    return this.http.post(url, obj);
  }
  viewflb11transportpassitems(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/viewflb11transportpassitems';
    return this.http.post(url, obj);
  }
  viewflb11exporttpitems(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/viewflb11exporttpitems';
    return this.http.post(url, obj);
  }
  approve(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, obj);
  }

  beerPaidIndentFLB11Approval(obj): Observable<any> {
    const url = this.baseURL + 'nacapprovalflow/approvalprocesswithtpdebit';
    return this.http.post(url, obj);
  }

  savevalidationandprocess(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/savevalidationandprocess';
    return this.http.post(url, obj);
  }
  refundStatus(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/updatetprefundstatus';
    return this.http.post(url, obj);
  }
  getStatusMasters() {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMaster?&dropDownKey=ROUTE_TYPE';
    return this.http.get(url);
  }
  getrouterange() {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMaster?&dropDownKey=ROUTE_RANGE';
    return this.http.get(url);
  }
  getAccidentType() {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMaster?dropDownKey=ACCIDENT_TYPE';
    return this.http.get(url);
  }
  getDropDown(postParam): any {
    const url = this.baseURL + apiUrls.listmasterdata;
    return this.http.post(url, postParam);
  }
  getDropDownCustom(postParam): any {
    const url = this.baseURL + apiUrls.customdata;
    return this.http.post(url, postParam);
  }

  savedata(data) {
    const url = this.baseURL + 'transportpass/saveflb11tp';
    return this.http.post(url, data)
  }
  saveflb11export(data) {
    const url = this.baseURL + 'transportpass/saveflb11export';
    return this.http.post(url, data)
  }
  updateflb11export(data) {
    const url = this.baseURL + 'transportpass/updateflb11export';
    return this.http.post(url, data)
  }
  saveflb11noindenttp(data) {
    const url = this.baseURL + 'transportpass/saveflb11noindenttp';
    return this.http.post(url, data)
  }
  updateflb11noindenttp(data) {
    const url = this.baseURL + 'transportpass/updateflb11noindenttp';
    return this.http.post(url, data)
  }
  updateflb11tp(data) {
    const url = this.baseURL + 'transportpass/updateflb11tp';
    return this.http.post(url, data)
  }
  getroutetype(data) {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMaster?&dropDownKey=' + data;
    return this.http.get(url);
  }
  approval(data) {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, data)
  }
  withoutIndentFLB11FinalApproval(data) {
    const url = this.baseURL + 'nacapprovalflow/approvalprocesswithpaymentaccounttype';
    return this.http.post(url, data)
  }

  withoutIndentFLB11Approval(data) {
    const url = this.baseURL + 'paidindenttransportpass/savewithoutindentflb11tpapproval';
    return this.http.post(url, data)
  }

  rejectWithoutIndentFLB11(data) {
    const url = this.baseURL + 'paidindenttransportpass/rejectwithoutindentflb11tp';
    return this.http.post(url, data)
  }

  approve1(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, obj);
  }

  cancelExport(data) {
    const url = this.baseURL + 'exportpermitcancel/cancelexportpermit';
    return this.http.post(url, data)
  }

  cancelExportApprove(data) {
    const url = this.baseURL + 'exportpermitcancel/cancelexportpermitapprove';
    return this.http.post(url, data)
  }

  reject(data) {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, data)
  }

  rejectWithoutIndentB10(data) {
    const url = this.baseURL + 'paidindenttransportpass/rejectwithoutindentb10tp';
    return this.http.post(url, data)
  }
  reqforclarif(data) {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, data)
  }

  downloadTP(tp): Observable<any> {
    // let tpType = "";
    // if (localStorage.getItem('unitType')) {
    //   tpType = (localStorage.getItem('unitType') == "PARENT_UNIT") ? '/IMPORT_TP' : '/INDENT_TP';
    // }
    // const url = this.baseURL + 'tpfile/' + tp + tpType;
    const url = this.baseURL + 'tpfile/' + tp;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadConsTp(tp): Observable<any> {
    const url = this.baseURL + 'tpfile/' + tp;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadExportProforma(exportParam): Observable<any> {
    const url = this.baseURL + 'filedownload/exportfile/' + exportParam + '/PROFORMA_INVOICE';
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadExportPuc(exportParam): Observable<any> {
    const url = this.baseURL + 'filedownload/exportfile/' + exportParam + '/PUC';
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadExportImportOrder(exportParam): Observable<any> {
    const url = this.baseURL + 'filedownload/exportfile/' + exportParam + '/IMPORT_ORDER';
    return this.http.get(url, { responseType: 'blob' });
  }
  fileDownload(exportParam, key): Observable<any> {
    const url = this.baseURL + 'filedownload/exportfile/' + exportParam + "'/" + key;
    return this.http.get(url, { responseType: 'blob' });
  }

  fileSaver(resp, documentName) {
    return fileSaver.saveAs(resp, documentName);
  }

  downloadLegacyProductionPlanFile(tp): Observable<any> {
    const url = this.baseURL + 'filedownload/legacyproductionplanfile/' + tp;
    return this.http.get(url, { responseType: 'blob' });
  }

  getFeesDetails(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/EdpRegistration/particularpackagesearch'
    return this.http.post(url, postParam);
  }

  getFeesDetailsPromise(postParam): any {
    const url = this.domainUrl + 'payandrecon/1.0/EdpRegistration/particularpackagesearch'
    return this.http.post(url, postParam).toPromise();
  }
  getAdvanceDutyListPromise(postParam): any {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get'
    return this.http.post(url, postParam).toPromise();
  }


  getAdvanceDutyList(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get'
    return this.http.post(url, postParam);
  }
  getAdvanceDuty(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search'
    return this.http.post(url, postParam);
  }

  getAdvDutyBalanceForPaidIndent(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/nationalaccount/get'
    return this.http.post(url, postParam);
  }

  getadlist(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get'
    return this.http.post(url, postParam);
  }
  validityExtenction(paylod): Observable<any> {
    const url = this.domainUrl + 'trackandtrace/v1.0.0/tpvalidityext/saveextdate'
    return this.http.post(url, paylod);
  }
  validityExtenction1(paylod): Observable<any> {
    const url = this.baseURL + 'tpvalidityext/saveextdate'
    return this.http.post(url, paylod);
  }
  saveDeptPayment(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd'
    return this.http.post(url, postParam);
  }
  saveDeptPayment1(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd'
    return this.http.post(url, postParam);
  }
  rejectbpc(data): Observable<any> {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, data);
  }
  approvebpc(data): Observable<any> {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, data);
  }
  penalty(data): Observable<any> {
    const url = this.baseURL + 'penalty/savePenaltyDetails';
    return this.http.post(url, data);
  }
  reqforclarifbpc(data): Observable<any> {
    const url = this.baseURL + 'approvalflow/process';
    return this.http.post(url, data);
  }

  savepenalty(data): Observable<any> {
    const url = this.domainUrl + 'trackandtrace/v1.0.0/penalty/savePenaltyDetails';
    return this.http.post(url, data);
  }
  cancelbpc(payload): Observable<any> {
    const url = this.baseURL + 'bottlingplancancellation/addbottlingplancancellation';
    return this.http.post(url, payload);
  }
  transNum(data) {
    const url = this.baseURL + 'customdata/savedata';
    return this.http.post(url, data)
  }

  walletrefund(obj): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/walletrefund'
    return this.http.post(url, obj);
  }

  updatetptransactionBIO(obj): Observable<any> {
    const url = this.baseURL + 'tptransaction/updatetptransaction';
    return this.http.post(url, obj);
  }

  savepaymenttransaction(postParam): Observable<any> {
    const url = this.baseURL + 'paymenttransaction/savepaymenttransaction'
    return this.http.post(url, postParam);
  }

  downloadExportDocument(exportParam): Observable<any> {
    const url = this.baseURL + 'filedownload/exportfile/' + exportParam + '/CBW_EXPORT';
    return this.http.get(url, { responseType: 'blob' });
  }
  bioRefundStatus(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/updatebiotprefundstatus';
    return this.http.post(url, obj);
  }

  paymentLogUpadteAndApprove(obj): Observable<any> {
    const url = this.baseURL + 'transportpass/updatetppaymentandapprovallog';
    return this.http.post(url, obj);
  }

  getAppConfigValueEntityWise(postData): Observable<any> {
    const url = this.baseURL + "appconfig/getentityappconfigvalue";
    return this.http.post(url, postData);
  }

  getMapForTp(postData): Observable<any> {
    const url = this.baseURL + "tracking/getgpsid";
    return this.http.post(url, postData);
  }

  callServerPaymentDeduction(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/processwithtransportpasspaymentupdate';
    return this.http.post(url, obj);
  }

  callServerPaymentRefund(obj): Observable<any> {
    const url = this.baseURL + 'paymentrefund/transportpass';
    return this.http.post(url, obj);
  }

  exportStateApprove(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/processwithexportpermitpaymentupdate';
    return this.http.post(url, obj);
  }

  exportCountryreject(data) {
    const url = this.baseURL + 'paymentrefund/exportpermit';
    return this.http.post(url, data)
  }

  approveB10F1(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/processb10f1';
    return this.http.post(url, obj);
  }
  approveB10F2(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/processb10f2';
    return this.http.post(url, obj);
  }
  saveflb50Spirit(data) {
    const url = this.baseURL + 'transportpass/savefl50export';
    return this.http.post(url, data)
  }

  beerIndentApprove(obj): Observable<any> {
    const url = this.baseURL + 'approvalflow/processwithbeerindentapproval';
    return this.http.post(url, obj);
  }

  beerIndentRejection(obj): Observable<any> {
    const url = this.baseURL + 'beerindent/rejection';
    return this.http.post(url, obj);
  }

  beerIndentApproveAEC(obj): Observable<any> {
    const url = this.baseURL + 'nacapprovalflow/approvalprocesswithindentcredit';
    return this.http.post(url, obj);
  }

  beerIndentModificationApproval(obj): Observable<any> {
    const url = this.baseURL + 'beerindent/modifiedapprove';
    return this.http.post(url, obj);
  }

  beerIndentB10FL2TP(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/saveb10tp';
    return this.http.post(url, obj);
  }

  beerIndentB10FL2TPApproval(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/saveb10tpapproval';
    return this.http.post(url, obj);
  }

  beerIndentRejectApproval(obj): Observable<any> {
    const url = this.baseURL + 'beerindent/refund';
    return this.http.post(url, obj);
  }

  beerIndentCancelSubmit(obj): Observable<any> {
    const url = this.baseURL + 'beerindent/cancelsubmitprocess';
    return this.http.post(url, obj);
  }

  saveBeerPaidIndentb10fl1tp(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/savewithoutindentb10tp';
    return this.http.post(url, obj);
  }

  getAdvDutyBalanceForNACPaidIndent(postParam): any {
    const url = this.domainUrl + 'payandrecon/1.0/nationalaccount/get'
    return this.http.post(url, postParam).toPromise();
  }
  approveBeerPaidIndentB10F1(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/savewithoutindentb10tpapproval';
    return this.http.post(url, obj);
  }

  getFeesDetailsNacRac(postParam): any {
    const url = this.baseURL + 'approvalflow/particularsearchforpdwithoutindentwithaccounttype';
    return this.http.post(url, postParam).toPromise();
  }
  
  saveWithoutIndentFLB11(data) {
    const url = this.baseURL + 'paidindenttransportpass/savewithoutindentflb11tp';
    return this.http.post(url, data)
  }

  updateWithoutIndentFLB11TP(data) {
    const url = this.baseURL + 'paidindenttransportpass/updatewithoutindentflb11tp';
    return this.http.post(url, data)
  }

  accidentAutoIndentApprove(obj): Observable<any> {
    const url = this.baseURL + 'accident/indentapprovalprocess';
    return this.http.post(url, obj);
  }

  FL2BB10Reject(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/rejectb10tp';
    return this.http.post(url, obj);
  }
  FLB11Reject(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/rejectflb11tp';
    return this.http.post(url, obj);
  }
  FL36Reject(obj): Observable<any> {
    const url = this.baseURL + 'paidindenttransportpass/rejectfl36tp';
    return this.http.post(url, obj);
  }
  
  downloadAccidentDestroyFile(tpNumber): Observable<any> {
    const url = this.baseURL + 'filedownload/accidentdistroyfile/' + tpNumber + '/ACCIDENT_DISTROY';
    return this.http.get(url, { responseType: 'blob' });
  }

  callServerPerticularSearch(data) {
    const url = this.baseURL + 'approvalflow/particularpackagesearchforpdwithinindent';
    return this.http.post(url, data)
  }

  advanceDepositeB10FL1(postParam): any {
    const url = this.baseURL + 'approvalflow/particularpackagesearchforpdwithoutindent'
    return this.http.post(url, postParam).toPromise();
  }
}