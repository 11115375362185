import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccidentalrequestOutsideupComponent } from './accidentalrequest-outsideup.component';
import { AddaccidentalrequestComponent } from './addaccidentalrequest/addaccidentalrequest.component';
import { ViewaccidentalrequestComponent } from './viewaccidentalrequest/viewaccidentalrequest.component';
import { PaymentscreenComponent } from './paymentscreen/paymentscreen.component';
import { AknowledgmentscreenComponent } from './aknowledgmentscreen/aknowledgmentscreen.component';


const routes: Routes = [
  {
    path: 'list',
    component: AccidentalrequestOutsideupComponent
  },
  {
    path: 'add',
    component: AddaccidentalrequestComponent
  },
  {
    path: 'edit/:Id',
    component: AddaccidentalrequestComponent
  },
  {
    path: 'view/:Id',
    component: ViewaccidentalrequestComponent
  },
  {
    path: 'payment/:Id',
    component: PaymentscreenComponent
  },
  {
    path: 'aknowledgement/:Id',
    component:  AknowledgmentscreenComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccidentalrequestOutsideupRoutingModule { }
