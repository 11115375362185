import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class B4FormService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'brewingBook';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'brewingBook/lazySearch';
    return this.http.post(url, payload);
  }
  search1(payload): Observable<any> {
    const url = this.baseURL + 'brewingBook/view/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brewIntimation/' + id;
    return this.http.get(url);
  }
  getByb4Id(id): Observable<any> {
    const url = this.baseURL + 'brewingBook/' + id;
    return this.http.get(url);
  }
  getByb4Id1(id): any {
    const url = this.baseURL + 'brewingBook/' + id;
    return this.http.get(url).toPromise();
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  intimationList(value?): Observable<any> {
    const url = this.baseURL + 'brewingBook/brewNo?licenseNo=' + value;
    return this.http.get(url);
  }
  intimationList11(value?): Observable<any> {
    const url = this.baseURL + 'brewingBook/brewNo/UnitCode?unitCode='+value;
    return this.http.get(url);
  }
  intimationList1(value?): Observable<any> {
    const url = this.baseURL + 'brewIntimation/brewPlanNo?licenseNo=' + value;
    return this.http.get(url);
  }
  intimationList3(value?): any {
    const url = this.baseURL + 'brewIntimation/brewPlanNo?licenseNo=' + value;
    return this.http.get(url).toPromise();
  }
  intimationList2(value?): Observable<any> {
    const url = this.baseURL + 'brewIntimation/brewPlanNo/unitCode?unitCode=' + value;
    return this.http.get(url);
  } 



  checkValidation(val){
    const url = this.baseURL + 'brewingBook/VesselToUniTank/validation';
    return this.http.post(url, val);
  }
  getRowMaterialList(licNo, id): Observable<any> {
    const url = this.baseURL + 'findBrewIntimation/b4List/rawMaterial?entityType=BREWERY&licenseNumber=' + licNo + '&id=' + id;
    return this.http.get(url);
  }
  getRowMaterialList1(licNo, id): Observable<any> {
    const url = this.baseURL + 'findBrewIntimation/b4List/rawMaterialByUnitCode?entityType=BREWERY&unitCode='+licNo+'&id='+id;
    return this.http.get(url);
  }
  getRowMaterialList2(licNo, id): any {
    const url = this.baseURL + 'findBrewIntimation/b4List/rawMaterialByUnitCode?entityType=BREWERY&unitCode='+licNo+'&id='+id;
    return this.http.get(url).toPromise();
  }

  planNoList(id) {
    const url = this.baseURL + 'brewIntimation/brewNo?brewPlanNo=' + id;
    return this.http.get(url);
  }
  getVesselDetail(data): Observable<any> {
    const url = this.baseURL + 'findB4BrewBook/dropDown';
    return this.http.post(url, data);
  }
  getApprovedVesselDetail(data): Observable<any> {
    const url = this.baseURL + 'brewingBook/approved/Vessel?licenseNo=' + data;
    return this.http.get(url);
  }
  getApprovedVesselDetail1(data): Observable<any> {
    const url = this.baseURL + 'brewingBook/approved/VesselByUnitCode?unitCode=' + data;
    return this.http.get(url);
  }
  downloadMstIst(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
   return this.http.get(url,{ responseType: 'blob', params:payload });
 }

}
