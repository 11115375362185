import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class DistilleryCommonService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  getdetails(): Observable<any> {
    const url = 'https://devapigateway.upexciseonline.co/licensemanagement/v1.0.0/license/category/PD';
    return this.http.get(url);
  }
  getYearList(): Observable<any> {
    const url = "https://devapigateway.upexciseonline.co/master/v1.0.0/api/masterValues/findDropDownMasterOnMaster?dropDownKey=YEAR_OF_REGISTRATION";
    return this.http.get(url)
  }
  getTankDetails(paramData) {
    const url = this.baseURL + "findApprovedTankDetails/dropdown";
    return this.http.get(url)
  }
  getSearchDropdowns(paramData) {
    const url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    return this.http.get(url)
  }
  getDropDowns(type) {
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type;
    url += '&status=' + 'APPROVED';
    return this.http.get(url);
  }

}
