import { NgModule, ModuleWithProviders, SkipSelf, Injectable } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from './components';
import { DataFilterPipe } from './components/data-table/data-filter.pipe';
import { FilterPipe } from './components/data-table/filter.pipe';
import { InputRestrictionDirective } from './components/data-table/inputvalid.pipe';
import { SomespecialcharDirective } from './components/data-table/somespecialchar.pipe'


import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ApexChartComponent } from './components/chart/apex-chart/apex-chart.component';
import { ApexChartService } from './components/chart/apex-chart/apex-chart.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DataTablesModule } from 'angular-datatables';
import {
  NgbModule,
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MainMaterialModule } from '@app/_shared/material-module';
import { MatNormalTableComponent } from '@app/_shared/mat-normal-table/mat-normal-table.component';
import { CountDownComponent } from '@app/_shared/count-down/count-down.component';
import { RouterModule } from '@angular/router';
import { CommonBreadcrumbComponent } from '@app/_shared/common-breadcrumb/common-breadcrumb.component';
import { ShowErrorsComponent } from '@app/_shared/components/show-errors/show-errors.component';
import { SelectInputComponent } from '@app/_shared/components/select-input/select-input.component';
import { ArrayToStringPipe } from '@app/_shared/array-to-string.pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { NotAllowSpecialCharPipe } from './components/data-table/not-allow-special-char.pipe';
import { TwoDigitDecimaNumberPipe } from './components/data-table/two-digit-decima-number.pipe';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
// import {
// OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
// OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS_FACTORY,
// OwlMomentDateTimeModule
// } from 'ng-pick-datetime-moment';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module';
import { FdigitDirective } from './components/data-table/fdigit.directive';
import { TdigitDirective } from './components/data-table/tdigit.directive';
import { ThreeDigitDecimaNumberPipe } from './components/data-table/three-digit-decima-number.pipe';
// import { MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { QRCodeModule } from 'angularx-qrcode';
import { SixDigitDecimaNumberPipe } from './components/data-table/six-digit-decimal-number.pipe';
import { PreventDoubleClickDirective } from '@app/_shared/prevent-double-click/prevent-double-click.component';
import { TpLogDetailsComponent } from '@app/_shared/tp-log-details/tp-log-details.component';

export const MY_NATIVE_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'l LT',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    DataFilterPipe,
    FilterPipe,
    InputRestrictionDirective,
    SomespecialcharDirective,
    NotAllowSpecialCharPipe,
    SpinnerComponent,
    ApexChartComponent,
    MatNormalTableComponent,
    CommonBreadcrumbComponent,
    TpLogDetailsComponent,
    CountDownComponent,
    ShowErrorsComponent,
    SelectInputComponent,
    ArrayToStringPipe,
    TimeAgoPipe,
    NotAllowSpecialCharPipe,
    TwoDigitDecimaNumberPipe,
    SixDigitDecimaNumberPipe,
    FdigitDirective,
    TdigitDirective,
    ThreeDigitDecimaNumberPipe,
    PreventDoubleClickDirective,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    NgxDaterangepickerMd.forRoot(),
    DataTablesModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbModule,
    TranslateModule,
    MainMaterialModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    QRCodeModule
  ],
  exports: [
    DataFilterPipe,
    FilterPipe,
    InputRestrictionDirective,
    SomespecialcharDirective,
    NotAllowSpecialCharPipe,
    TwoDigitDecimaNumberPipe,
    SixDigitDecimaNumberPipe,
    SpinnerComponent,
    ApexChartComponent,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    NgxDaterangepickerMd,
    DataTablesModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbModule,
    TranslateModule,
    MatNormalTableComponent,
    MainMaterialModule,
    CommonBreadcrumbComponent,
    CountDownComponent,
    ShowErrorsComponent,
    SelectInputComponent,
    RouterModule,
    ArrayToStringPipe,
    TimeAgoPipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    FdigitDirective,
    TdigitDirective,
    ThreeDigitDecimaNumberPipe,
    QRCodeModule,
    PreventDoubleClickDirective,
    TpLogDetailsComponent
  ],
  providers: [
    ApexChartService,
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MY_NATIVE_FORMATS
    },
    // {
    //   provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
    //   useFactory: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS_FACTORY
    // },
  ]
})
export class SharedModule {
  // static forRoot(): ModuleWithProviders {
  //     return {
  //         ngModule: SharedModule
  //     };
  // }

  // constructor(@Optional() @SkipSelf() shared: SharedModule) {
  //   if(shared){
  //     throw new Error('You Shall not run')
  //   }
  // }
}
