import {Component, Input, OnInit} from '@angular/core';
import {NavigationItem} from '@appThemeLayout/admin/navigation/navigation';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: Array<any> = [];

  constructor(
    private route: Router,
    public nav: NavigationItem,
    private titleService: Title
  ) {
    this.navigation = this.nav.get();
    this.type = 'theme2';
    this.setBreadcrumb();
  }

  ngOnInit() {
    this.navigation = this.nav.get();
    let routerUrl: string;
    routerUrl = this.route.url;
    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
    }
  }

  setBreadcrumb() {
    let routerUrl: string;
    this.route.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        const activeLink = router.url;
        // console.log('activeLink::', activeLink);
        this.filterNavigation(activeLink);
      }
    });
  }

  filterNavigation(activeLink) {
    let result = [];
    let title = 'Welcome';
    let i = 1;
    // console.log('this.navigation ->', this.navigation);
    if(this.navigation){
      this.navigation.forEach((a) => {
        // console.log('filterNavigation ' + i + '->', a);
        i++;
        if (a.type === 'item' && 'url' in a && a.url === activeLink) {
          result = [
            {
              url: ('url' in a) ? a.url : false,
              title: a.title,
              breadcrumbs: ('breadcrumbs' in a) ? a.breadcrumbs : true,
              type: a.type
            }
          ];
          title = a.name;
        } else {
          // console.log('not-item');
          if (a.type === 'collapse' && 'children' in a) {
            // console.log('collapse');
            a.children.forEach((b) => {
              if (b.type === 'item' && 'url' in b && b.url === activeLink) {
                result = [
                  {
                    url: ('url' in b) ? b.url : false,
                    title: b.name,
                    breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                    type: b.type
                  }
                ];
                title = b.name;
              } else {
                if (b.type === 'collapse' && 'children' in b) {
                  b.children.forEach((c) => {
                    if (c.type === 'item' && 'url' in c && c.url === activeLink) {
                      result = [
                        {
                          url: ('url' in b) ? b.url : false,
                          title: b.name,
                          breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                          type: b.type
                        },
                        {
                          url: ('url' in c) ? c.url : false,
                          title: c.name,
                          breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
                          type: c.type
                        }
                      ];
                      title = c.name;
                    } else {
                      if (c.type === 'collapse' && 'children' in c) {
                        c.children.forEach((d) => {
                          if (d.type === 'item' && 'url' in d && d.url === activeLink) {
                            result = [
                              {
                                url: ('url' in b) ? b.url : false,
                                title: b.name,
                                breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                                type: b.type
                              },
                              {
                                url: ('url' in c) ? c.url : false,
                                title: c.name,
                                breadcrumbs: ('breadcrumbs' in c) ? c.breadcrumbs : true,
                                type: c.type
                              },
                              {
                                url: ('url' in d) ? d.url : false,
                                title: d.name,
                                breadcrumbs: ('breadcrumbs' in c) ? d.breadcrumbs : true,
                                type: d.type
                              }
                            ];
                            title = d.name;
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
    this.navigationList = result;
    // console.log('result ->', result);
    this.titleService.setTitle(title + ' | UP Excise Admin');
  }

}
