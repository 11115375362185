import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';

@Injectable({
  providedIn: 'root'
})
export class StockAvailabilityService {

  constructor(private http: HttpClient) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  search(payload: any): Observable<any> {
    console.log(payload);
    const url = this.baseURL + 'breweryStockAvailability/lazySearch';
    return this.http.post(url, payload);
  }
  detail(id: Number): Observable<any> {
    const url = this.baseURL + 'breweryStockAvailability/' + id;
    return this.http.get(url);
  }
  getdetails(category, licanceNo): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category + '?licenseNumber=' + licanceNo;
    return this.http.get(url);
  }
}
