import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
@Injectable({
  providedIn: 'root'
})
export class reportevidcompaganexciseService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;
    getdetails(): Observable<any> {
      const url = this.licenseURL + 'license/category/DISTILLERY';// nisha ma'am
      return this.http.get(url);
    }
    
    addReportAgainstExciseOfcl(data): Observable<any> {
        const url = this.baseURL + 'vigilance';
        return this.http.post(url, data);
    }

    downloadReportVal(payload){
      const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
      return this.http.get(url,{ responseType: 'blob', params:payload });
    }
  
    updateReportAgainstExciseOfcl(data): Observable<any> {
      const url = this.baseURL + 'vigilance/reportUpdate/updateVigilance';
      return this.http.post(url, data);
  }
  
    search(payload): Observable<any> {
      const url = this.baseURL + 'vigilance/lazySearch';
      return this.http.post(url, payload);
  }
  search1(payload): Observable<any> {
    const url = this.baseURL + 'vigilance/reportUpdate/search';
    return this.http.post(url, payload);
}

  getapplicationnumbers(data) {
    //const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {});
  }

  getComplaintById(id): Observable<any> {
    const url = this.baseURL + 'vigilance/'+id;
    return this.http.get(url);
  }
  getComplaintById1(id): Observable<any> {
    const url = this.baseURL + 'vigilance/reportUpdate/'+id;
    return this.http.get(url);
  }
  getSearchDropdowns(paramData) {
    const url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    return this.http.get(url)
  }

  // UpdateByComplaintById(payload): Observable<any> {
  //   const url = this.baseURL + 'vigilance/updateWorkFlow';
  //   return this.http.post(url, payload);
  // }
  getStatusMasters() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
    return this.http.get(url)
}

postDownload(postdata): any {
  const url = environment.documentUrl + 'api/document/downloadfiles';
  return this.http.post(url, postdata).toPromise();
}
}
