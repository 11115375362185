import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class MSTISTProcessService {

  constructor(private http: HttpClient) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  domainUrl = environment.domainUrl;

  createMSTIST(params): Observable<any> {
    const url = this.baseURL + 'breweryMstIst';
    return this.http.post(url, params);
  }
  getMSTISTSpirit(params): Observable<any> {
    const url = this.baseURL + 'breweryMstIst/lazySearch';
    return this.http.post(url, params);
  }
  getMSTISTSpiritById(id): Observable<any> {
    const url = this.baseURL + 'breweryMstIst/' + id;
    return this.http.get(url);
  }
  getMstIstDate(params): Observable<any> {
    const url = this.baseURL + 'breweryMstIst/getMstIstData';
    return this.http.post(url, params);
  }
  getDropdownValues(params): Observable<any> {
    const url = this.devisionUrl + apiUrls.findDropDownMasterOnMaster
    return this.http.get(url, { params: params });
  }

  // payment related api's
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'breweryMstIst/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'breweryMstIst/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=MST_IST_RECEIPT';
    return this.http.get(url, { responseType: 'blob' });
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
   getStatusCount(entityType?, mst?): Observable<any> {
    const url = this.baseURL + 'breweryMstIst/getStatusCount?entityType=' + entityType + '&mstIstTypes=' + mst;
    return this.http.get(url);
  }
  getStatusCounts(entityType, mst,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'breweryMstIst/getStatusCount?entityType=' + entityType + '&mstIstTypes=' + mst
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  downloadMstIst(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
   return this.http.get(url,{ responseType: 'blob', params:payload });
 }
 getSugarMillCardCount(payload): Observable<any> {
  const url = this.baseURL + 'sugarMill/getStatusCount'
  return this.http.post(url,payload);
}
getPdCardCount(payload): Observable<any> {
  const url = this.baseURL + 'pd/getStatusCount'
  return this.http.post(url,payload);
}
getmstisttankdetails(payload): Observable<any> {
  //const url = this.baseURL + 'breweryTankRegistration/overview/getOverviewStockRelatedLog?entityType='+payload.entityType+'&tankType='+payload.tankType+'&inspectionDate='+payload.inspectionDate+'&page=0&size=0'
 const url = this.baseURL + 'tankLogs/getLogs'
return this.http.post(url,payload);
}
getconversiondetails(payload): Observable<any> {
  const url = this.baseURL + 'breweryMstIst/getTankDismandleconversionDetails'
return this.http.post(url,payload);
}
getpreviousistdetails(payload): Observable<any> {
  const url = this.baseURL + 'breweryMstIst/getPreviousTankDetails'
return this.http.post(url,payload);
} 
getPendingMstDetails(payload): Observable<any> {
  const url = this.baseURL + 'breweryMstIst/getPendingMst'
return this.http.post(url,payload);
}
 }



