import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class ExportPermitService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'exportPermitC4';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'exportPermitC4/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'exportPermitC4/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getbrewNo(category): Observable<any> {
    const url = this.baseURL + 'brewIntimation/brewPlanNo?licenseNo=' + category;
    return this.http.get(url);
  }
  getrawMaterial(val): Observable<any> {
    const url = this.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MATERIALS&additionalFilter2=' + val;
    return this.http.get(url);
  }
  getoldQuantity(licanceNum, rawCode, entityType): Observable<any> {
    const url = this.baseURL + 'stockActivity/oldQuantity/' + rawCode + '?licenseNo=' + licanceNum + '&entityType=' + entityType;
    return this.http.get(url);
  }
  approveStock(postdata): Observable<any> {
    const url = this.baseURL + 'stockActivity/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  updateStock(postdata): Observable<any> {
    const url = this.baseURL + 'stockActivity/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  getMolassesTypesList(): Observable<any> {
    // const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MOLASSES_TYPE'
    const url = environment.devisionUrl + 'api/masterValues/findDependentValuesMasterOnMaster?filterCode=RAW_MATERIALS&filterSubCode=RM001'
    return this.http.get(url);
  }
}
