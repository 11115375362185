import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import { PdCommonService } from './pd-common.service';
@Injectable({
  providedIn: 'root'
})
export class Pd17Service {
  entityType:any
  constructor(
    private http: HttpClient,
    private pdCommonService: PdCommonService
  ) {
    this.entityType = pdCommonService.getEntityType()
   }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'pd17BondRegister';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    payload.searchInput.entityType = this.entityType
    const url = this.baseURL + 'pd17BondRegister/lazySearch';
    return this.http.post(url, payload);
  }
  searchpd26(payload): Observable<any> {
    const url = this.baseURL + 'pd26/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'pd17BondRegister/' + id;
    return this.http.get(url);
  }
  getByIdpd26(id): Observable<any> {
    const url = this.baseURL + 'pd26/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getTankList(data): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  getOpenBalance(lic?): Observable<any> {
    const url = this.baseURL + 'mf6part1/getOpeningBalance?entityType=PD&licenseNumber=' + lic;
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt';
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
}
