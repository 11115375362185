import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class OrderpickupService {

  wholesaleNewURL = environment.wholesaleNewURL;


  constructor(
    private http: HttpClient
  ) { }


  getAlllist(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }



  getAlllist1(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  savedata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickup/saveorderpickup';
    return this.http.post(url, obj)
  }

  approve1(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/saverecord';
    return this.http.post(url, obj)
  }

  approve2(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/saveorderpickupreleaseapprove';
    return this.http.post(url, obj)
  }

  reject1(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/savedata';
    return this.http.post(url, obj)
  }



  masterdata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'master/data/listmasterdata';
    return this.http.post(url, obj);
  }
  getRecordCount(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }
}
