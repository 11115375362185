import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class BrandlabelbrewService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.scmproduction;

  // paymentListUrl = environment.paymentListUrl;
  licenseTypeBaseUrl = environment.licenseTypeBaseUrl;
  licenseURL = environment.licneseBaseUrl;

  domainUrl = environment.domainUrl;
  getdetails(): any {//work
    const url = this.licenseURL + 'license/category/BREWERY';
    return this.http.get(url).toPromise();
  }
  getdetailsBottling(): any { //work
    const url = this.licenseURL + 'license/category/BOTTLING';
    return this.http.get(url).toPromise();
  }
  getdetailsBottlingwithscreenname(): any { //work
    const url = this.licenseURL + 'license/category/BOTTLING?screenName=BRAND_AND_LABEL_OVERVIEW';
    return this.http.get(url).toPromise();
  } 
  getdetailsGinBoth(): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY%2CBOTTLING';
    return this.http.get(url).toPromise();
  }
  getdetailsparent(): any { //work
    const url = this.licenseURL + 'license/category/PARENT_UNIT';
    return this.http.get(url).toPromise();
  }
  getdetailscsd(): any { //work
    const url = this.licenseURL + 'license/category/CSD_SUPPLY';
    return this.http.get(url).toPromise();
  }
  getdetailsBoth(): any {//work
    const url = this.licenseURL + 'license/category/BREWERY%2CBOTTLING';
    return this.http.get(url).toPromise();
  }
  getDynamicEntityLicense(): Observable<any> {//work
    let entity = localStorage.getItem('entityList');
    const url = this.licenseURL + 'license/category/' + (entity ? entity.replace(/,/g, '%2C') : '');
    return this.http.get(url);
  }
  brandLabelExisting(entityType: any, param?: any): Observable<any> {
    let url = this.baseURL + 'brandLabel/existing';
    url += param ? '?licenseType=' + param : '';
    url += '&entityType=' + entityType;
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  // getapplicationnumbers(data, licensenumber): Observable<any> {//work
  //   const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data + '&licenseNumber=' + licensenumber;
  //   return this.http.post(url, data);
  // }

  getapplicationnumbers(data): any {
    let stringVal: any = 'registrationType=' + data.registrationType;
    // if (data.registrationType) {
    //   stringVal += '&registrationType=' + data.registrationType;
    // }

    if (data.licenseNumber) {
      stringVal += '&licenseNumber=' + data.licenseNumber;
    }
    if (data.liquorType) {
      stringVal += '&liquorType=' + data.liquorType;
    }
    if (data.entityType) {
      stringVal += '&entityType=' + data.entityType;
    }
    const url = this.baseURL + 'generateApplicationNumber?' + stringVal.trim('');
    return this.http.post(url, data).toPromise();
  }
  getApprovedLicenseTypes(licensetype) {//work
    let url = this.licenseTypeBaseUrl + "brandLabel/approved/licenseType/" + licensetype;
    return this.http.post(url, {});

  }
  addetails(data: any): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    return this.http.post(url, data);
  }
  addBrand(data:any):Observable<any>{
    const url = this.baseURL + 'updateDocument';
    return this.http.post(url,data);
  }
  getbrandlabelbyid(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  getbrandlabelbyid1(id): any {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url).toPromise();
  }
  createbrandlabeldetails(data): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    console.log(url);
    return this.http.post(url, data);
  }
  getbrandandlabeldatabyetin(etin): Observable<any> {
    const url = this.baseURL + 'brandLabel/approved/etin/' + etin;
    return this.http.get(url);
  }
  getbrandandlabeldatabylicensetype(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/approved/licenseType/' + licensetype;
    return this.http.get(url);
  }
  searchstockindetails(data): any {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, data).toPromise();
  }
  getdistinctbrandandproduct(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/existing?licenseType=' + licensetype;
    return this.http.get(url);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'brandLabel/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  getbrandlabelstatusdatacount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount';
    return this.http.get(url);
  }
  getbrandlabeldropdowndtls(brandname) {
    const url = this.baseURL + 'brandLabel/getDropDown?type=' + brandname;
    return this.http.get(url)
  }
  getlabeldetails(postParam) {
    const url = this.baseURL + 'brandLabel/overview/labelDetails';
    return this.http.post(url, postParam);
  }
  labelfeepayment(postParam) {
    const url = this.baseURL + "brandLabel/saveUpdatePaymentDetails"
    return this.http.post(url, postParam);
  }
  searchoverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }
  getoverviewstatuscount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/statusCount';
    return this.http.get(url);
  }
  updatelabelstatusoverview(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/workflowCallBack';
    return this.http.post(url, payload);
  }
  getoverviewbrandlabeldata(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }
  getoverviewbrandlabeldata1(id): any {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    // return this.http.get(url);
    return this.http.get(url).toPromise();
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  updatebrandlabelworkflowdtls(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/updateBrandLabelWorkFlowDetails';
    return this.http.post(url, payload);
  }
  validategtin(payload: any): Observable<any> {
    const url = this.licenseTypeBaseUrl + 'gtinValidation';
    return this.http.post(url, payload);
  }
  // overview service
  searchOverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }
  getDropDowns(type,entitytype,iscustomer?) { 
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type+'&entityType='+entitytype; 
    if(iscustomer)
      { url += '&status=' + 'APPROVED'}  
    return this.http.get(url);
  }
  getOverviewById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }
  addLabelDetails(data): Observable<any> {
    const url = this.baseURL + "brandLabel/overview/labelDetails"
    return this.http.post(url, data);
  }

  search(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, payload);
  }
  getbrandlabelcount(entityType?): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount?entityType=' + entityType;
    return this.http.get(url);
  }
  getSearchDropdowns(paramData, entityType?) {
    let url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    if (entityType) {
      url = url + '&entityType=' + entityType;
    }
    return this.http.get(url)
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  getAdvanceDuty(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get';
    return this.http.post(url, payload);
  }
  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  postDownload(url, data) {
    const requestOptions = {
      responseType: 'blob' as 'json'
    };
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.post(requestURL, data, requestOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadBrandLabelReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
    return this.http.get(url, { responseType: 'blob' });
  }

  deleteLable(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/label/' + id;
    return this.http.delete(url);
  }
  downloadbrandlabel(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt' 
   return this.http.get(url,{ responseType: 'blob', params:payload });
 }
 getpaymentforbottling(): Observable<any> {
  const url = this.baseURL + 'getCommonConfigDetail?entityType=UP_EXCISE&type=SCM_BOTTLING_PLAN_PAYMENT_CONFIG';
  return this.http.get(url);
}
getbonddetails(licensenumber,bondlicensenumber?): Observable<any> {//work 
  let bondlicensenumbervalue=bondlicensenumber?'&bondLicenseNumber='+bondlicensenumber:'' 
  const url = this.licenseURL + 'license/getParentUnitBWFLBondDetailsByLicenseNumber?licenseNumber='+licensenumber+bondlicensenumbervalue;
  return this.http.get(url);
}
 
getdetailsbio(type): any {//work
  const url = this.licenseURL + 'license/category/'+ type;
  return this.http.get(url).toPromise();
}

existingsamebrandname(brandname): any { //work
  const url = this.baseURL + 'findByMasterBrandDetails?status=true&brandName='+brandname+'&page=0&size=50';
  return this.http.get(url).toPromise();
}
getpermissiblebottlequantity(entityType,liquor): Observable<any> {
  const url = this.baseURL + 'getCommonConfigDetail?entityType='+entityType+'&type='+liquor+'_BRAND_PERMISSABLE_BOTTLED_QTY' ;
  return this.http.get(url);
}
getbalancequantityforspecial(payloadvalue): Observable<any> {
  const url = this.baseURL + 'bottlingProductionPlan/getSpecialBrandBottlingCount?entityType='+payloadvalue.entityType+'&unitCode='+payloadvalue.unitCode+'&domainCode='+payloadvalue.domainCode +'&domainValue='+payloadvalue.domainValue+'&brandValue='+payloadvalue.brandValue+'&brandCode='+payloadvalue.brandCode ;
  return this.http.get(url);
}
getLicdetails(category, licanceNo): any {
  const url = this.licenseURL + 'license/category/' + category + '?licenseNumber=' + licanceNo;
  return this.http.get(url).toPromise();;
}
  //cutof based configuration license show
getdetailsBothscreenname(): any {//work
  const url = this.licenseURL + 'license/category/BREWERY%2CBOTTLING?screenName=PAYMENT_MODULE';
  return this.http.get(url).toPromise();
}
getdetailsbiowithscreenname(type): any {//work
  const url = this.licenseURL + 'license/category/'+ type+"?screenName=BRAND_AND_LABEL_OVERVIEW";
  return this.http.get(url).toPromise();
}
 
}
