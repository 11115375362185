import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class MappingOverseasService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  baseUrlMaster = environment.baseURLMaster;
  smcProd = environment.scmproduction;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'overseasEtinMapping/';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'overseasEtinMapping/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'overseasEtinMapping/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getOverseasEtin(): Observable<any> {
    const url = this.smcProd + 'brandLabel/approved/licenseType/FL3';
    return this.http.get(url);
  }
  checkMappings(payload): Observable<any> {
    const url = this.baseURL + 'overseasEtinMapping/chekEtinDetails';
    return this.http.post(url, payload);
  }
  getBrand(licenseType): Observable<any> {
    const url = this.smcProd + 'brandLabel/existing?licenseType=' + licenseType;
    return this.http.get(url);
  }
  searchBrand(data): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, data);
  }
  searchBrand1(data): Observable<any> {
    const url = this.baseURL + 'findBrandDetails/dropDown';
    return this.http.post(url, data);
  }
}
