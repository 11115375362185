import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AuthService, DistileryService, DocumentUploadService, SpiritImportPermitService, WorkflowcofigService } from '@app/services';
import * as fileSaver from 'file-saver';
import { RequestForEthanolOrderForOmdService } from '@app/services/pd/request-for-ethanol-order-for-omd.service';
import { LocalUrl } from '@app/app-local-url';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { enaPurchaseOrderService } from '@app/services/pd/enapurchaseorder.service';

function requiredFileType(type) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      var file_name_array = file.split(".");
      if (file_name_array.length > 0) {
        const extension = (file_name_array[file_name_array.length - 1]) ? file_name_array[file_name_array.length - 1].toLowerCase() : '';
        if (type.indexOf(extension) < 0) {
          return {
            requiredFileType: true
          };
        }
      }
      return null;
    }
    return null;
  };
}

function fileSizeValidation(files: FileList) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const fileSize = files.item(0).size;
      const fileSizeInKB = Math.round(fileSize);
      let maxSize: any = 1024 * 1024 * 6;

      if (fileSizeInKB >= maxSize) {
        return {
          fileSizeValidator: true
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
@Component({
  selector: 'app-viewaccidentalomd',
  templateUrl: './viewaccidentalomd.component.html',
  styleUrls: ['./viewaccidentalomd.component.scss']
})
export class ViewaccidentalomdComponent implements OnInit {

  editObject: any
  isPreview = false
  editpd26: any;
  detailsEntityList: any;
  addressDetails: any;
  isProcessingFee: boolean;
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any;
  workflowdata: any;
  logactive: boolean = false;
  applicationNumber: any;
  recordstatus: string;
  sendBacklist: any;
  logshow: any[] = []
  brandForm: FormGroup;
  viewId: any;
  confirmStyatus: string = "";
  @ViewChild('exampleModalCenter', { static: false }) exampleModalCenter;

  isApprovedLoading = false;
  isRejectLoading = false;
  isClarificationLoading = false;
  formSubmitted: boolean;
  totalBlQty: number;
  totalAlQty: any;
  grandBlQty: string;
  grandAlQty: any;
  totalfees: any;
  transactionNumber: any;
  isCustomer: boolean;
  designation: string;
  accidentalDocumentName: any;
  firCopy: any;

  constructor(
    private distilleryService: DistileryService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private worfFlowService: WorkflowcofigService,
    private formBuilder: FormBuilder,
    private exportRquest: SpiritImportPermitService,
    private requestForEthanolOrderForOmdService: RequestForEthanolOrderForOmdService,
    private documentUploadService: DocumentUploadService,
    private authService: AuthService,
    private entPurchaseOrderService: enaPurchaseOrderService
  ) { }


  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const subUrlManage = currentUrlArray[1] + currentUrlArray[3];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.createFormGrp();
    this.route.params.subscribe(params => {
      if (params.id) {
        this.viewId = params.id;
        this.getbyiddata(params.id);
      }
    })
  }
  getbyiddata(id) {
    this.distilleryService.getAccidentalOmd(id).subscribe((responceData: any) => {
      if (responceData.status === 's') {
        this.editObject = responceData.data;
        this.applicationNumber = this.editObject.applicationNumber;
        this.accidentalDocumentName = this.editObject.accidentalRequestOmdDocumentList.filter(res=>res.name == 'Upload Accidental Report')
        this.firCopy = this.editObject.accidentalRequestOmdDocumentList.filter(res=>res.name == 'Upload FIR Copy')
      }
      if(this.designation == 'DEO'){
        this.brandForm.patchValue({
          // wastage: this.editObject.wastage,
          deoRemarks: this.editObject.deoRemarks
        })
      }
      this.getapprovallogsList();
      this.getworkflow();
    })
  }

  closes() {
    this.router.navigate(['/' + this.url + '/omd/accidentalomd/edit/' + this.editObject.id])
  }
  getForm() { return this.brandForm; }
  getFormCntr(element) {
    return this.getForm().get(element);
  }
  public captureScreen() {

    let payload = {
      applicationNumber: this.editObject.applicationNumber,
      id: this.editObject.id,
      // licenseNumber: this.editObject.licenseNumber,
      // entityType: this.editObject.entityType,
      receiptType: 'OMD_ACCIDENTAL_REQUEST'
    }
    this.distilleryService.downloadData(payload).subscribe(
      (resp: any) => fileSaver.saveAs(resp, this.editObject.applicationNumber.replace(/\//g, '_') + '_OMD_ACCIDENTAL_REQUEST'),
      (error: any) => this.alert.showError('Error downloading the file'),
      () => console.info('File downloaded successfully')
    );

  }
  downloadDocument(uuid, documentName) {
    if (uuid && documentName) {
    this.documentUploadService.downloadDocument(uuid, documentName).subscribe((resp: any) => {
    fileSaver.saveAs(resp, documentName);
    },
    (error: any) => this.alert.showError('Error downloading the file', 'Error'),
    () => console.info('File downloaded successfully')
    );
    } else {
    this.alert.showError('File not available', 'Error');
    }
    
    }
  getworkflow() {
    let subModule;
    if (this.editObject.accidentalDomainCode == 'WITHIN_UP') {
      subModule = '_ACCIDENTAL_REQUEST_OMD'
    } else {
      subModule = '_ACCIDENTAL_REQUEST_OMD_OUTSIDE_UP'
    }
    this.worfFlowService.getworkflowbydesignation(this.entityType, this.entityType + subModule).subscribe((resp: any) => {
      this.workflowdata = resp.content;
      if (this.workflowdata) {
        if (this.workflowdata.isSendBack != undefined && this.workflowdata.isSendBack) {
          console.log(this.editObject)
          console.log(this.editObject.sendBackLevel)
          this.editObject.controls['sendBackLevel'].setValidators(Validators.required);
        } else {
          this.editObject.controls['sendBackLevel'].setErrors(null);
        }
      } else {
        this.editObject.controls['sendBackLevel'].setErrors(null);
      }
    });
  }
  getapprovallogsList() {
    this.worfFlowService.getapprovallogs(this.applicationNumber)
      .subscribe((resp: any) => {
        if (resp) {
          this.logactive = true;
          if (this.recordstatus == 'SENDBACK' && resp.length > 0) {
            this.sendBacklist = resp.filter((x) => x.action == 'SENDBACK');
            this.logshow = this.sendBacklist;
          } else {
            this.logshow = resp;
          }
        }
      });
  }
  async uploadDocuments(applicationNumber, fileArrList) {

    const formData = new FormData();
    let inc = 0;
    let uploadFlag = false;

    if (fileArrList) {
      for (let i = 0; i < fileArrList.length; i++) {
        if (this.brandForm.get(fileArrList[i].fileSource).value) {
          uploadFlag = true;
          formData.append('files[' + inc + '].name', fileArrList[i].name);
          formData.append('files[' + inc + '].file', this.brandForm.get(fileArrList[i].fileSource).value);
          inc++;
        }
      }
    }

    formData.append('moduleName', this.moduleName);
    formData.append('screenName', 'Accidental For OMD');
    formData.append('applicationNumber', this.applicationNumber);
    //applicationNumber 
    if (uploadFlag) {
      this.authService.fileUploadFlag = true;
      const res: any = await this.documentUploadService.uploadDocuments(formData);
      this.authService.fileUploadFlag = false;
      const uploadDoc = res.map(item => {
        return {
          documentName: item.documentName,
          name: item.name,
          status: true,
          uuid: item.uuid
        };
      });
      return uploadDoc;
    } else {
      return null;
    }
  }
  async uploaddoc(applicationNumber) {
    let applicantDocuments = [];

    applicantDocuments = [
      { name: 'Upload Accidental Report', fileSource: 'accidentalReportFiles' },
      { name: 'Upload FIR Copy', fileSource: 'firCopyFiles' }]

    const applicantDocumentsUploadedDetails = await this.uploadDocuments(applicationNumber, applicantDocuments);
    return applicantDocumentsUploadedDetails;
  }
  combineArr(oldArr, newArr) {

    if (newArr && newArr.length > 0) {
    newArr.forEach(element => {
    const ind = oldArr.findIndex((item) => item.name === element.name);
    if (ind !== -1) {
    element.id = oldArr[ind].id ? oldArr[ind].id : '';
    element.createdBy = oldArr[ind].createdBy ? oldArr[ind].createdBy : '';
    element.createdDate = oldArr[ind].createdDate ? oldArr[ind].createdDate : '';
    oldArr[ind] = element;
    } else {
    oldArr.push(element);
    }
    });
    
    }
    return oldArr;
    }
  async feedback(data) {
    if(this.designation != 'DEO'){
      this.brandForm.get('accidentalReport').clearValidators();
      this.brandForm.get('accidentalReport').updateValueAndValidity();
      this.brandForm.get('firCopy').clearValidators();
      this.brandForm.get('firCopy').updateValueAndValidity();
      // this.brandForm.get('wastage').clearValidators();
      // this.brandForm.get('wastage').updateValueAndValidity();
      this.brandForm.get('deoRemarks').clearValidators();
      this.brandForm.get('deoRemarks').updateValueAndValidity();
    }
    let documentDetails: any;
    documentDetails = await this.uploaddoc(this.applicationNumber);
    if (this.editObject.accidentalRequestOmdDocumentList.length >0) { 
      documentDetails = this.combineArr(this.editObject.accidentalRequestOmdDocumentList, documentDetails);
      }
    let documentdetaillist = []
    if (documentDetails) {
      documentDetails.map((elee: any) => {
        let element = {
          documentName: elee.documentName,
          status: elee.status,
          name: elee.name,
          uuid: elee.uuid,
          id: elee.id || '',
          createdBy: elee.createdBy || '',
          createdDate: elee.createdDate || ''
        };
        documentdetaillist.push(element);
      });
    }
    const approveObj = {
      applicationNumber: this.editObject.applicationNumber,
      id: parseInt(this.viewId),
      isDigitalSignature: this.workflowdata ? this.workflowdata.isDigitalSignature : true,
      event: data,
      stage: this.editObject.stage,
      stageDesc: this.editObject.stageDesc,
      comments: this.brandForm.value.remark,
      status: data,
      sentBackLevels: this.brandForm.value.sendBackLevel ? this.brandForm.value.sendBackLevel : '',
      level: this.workflowdata ? this.workflowdata.level : 'Level 2',
      entityType: this.editObject.entityType,
      profileType: "ACCIDENTAL_REQUEST_OMD",
      accidentalRequestOmdDocumentList: documentdetaillist ? documentdetaillist : '',
      accidentalDomainCode: this.editObject.accidentalDomainCode,
      // wastage: this.brandForm.value.wastage,
      deoRemarks: this.brandForm.value.deoRemarks
    };

    console.log(approveObj);
    this.distilleryService.saveAccidentalOmd(approveObj).subscribe((responceData: any) => {
      if (responceData.errorCode == 200) {
        this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
        this.router.navigate(['/' + this.url + '/omd/accidentalomd/list']);

      } else {
        this.alert.showError(responceData.userDisplayMesg, 'error');
      }
    })
  }
  createFormGrp() {
    this.brandForm = this.formBuilder.group({
      remark: ['', Validators.required],
      sendBackLevel: [''],
      accidentalReport: [''],
      accidentalReportFiles: [''],
      firCopy: [''],
      firCopyFiles: [''],
      // wastage: [''],
      deoRemarks: ['']
    });
  }
  get brandform() {
    return this.brandForm.controls;
  }
  showConfirmation(status, form) {
    this.confirmStyatus = status;
    this.formSubmitted = false;

    if (status == 'SENDBACK') {
      this.brandForm.get('sendBackLevel').setValidators(Validators.required);
      this.brandForm.controls['sendBackLevel'].updateValueAndValidity();
    }

    else {
      this.brandForm.patchValue({
        sendBackLevel: ''
      })
      this.brandForm.get('sendBackLevel').setErrors(null);
      // this.brandForm.controls['sendBackLevel'].updateValueAndValidity();

    }

    if (form.valid === false) {
      this.formSubmitted = true;
    }
    else {
      this.toggleLoading(status)
      this.exampleModalCenter.show()
    }

  }
  close() {
    this.exampleModalCenter.hide()
    this.isApprovedLoading = false
    this.isRejectLoading = false;
    this.isClarificationLoading = false
  }

  toggleLoading(saveType) {
    switch (saveType) {
      case 'APPROVED':
        this.isApprovedLoading = !this.isApprovedLoading;
        break;
      case 'REJECTED':
        console.log(!this.isRejectLoading)
        this.isRejectLoading = !this.isRejectLoading;
        break;
      case 'SENDBACK':
        console.log(!this.isClarificationLoading)
        this.isClarificationLoading = !this.isClarificationLoading;
        break;
    }
  }
  private validateFileName(fileName: string): boolean {
    const pattern = /^[^.]+(\.[^.]+)?$/;
    return pattern.test(fileName);
  }
  onFileChange(event, sourceName, form, sourceFile) {
    var valtype = ['png', 'jpeg', 'jpg', 'pdf'];
    let fileval = event.target.files[0].name;
    this.brandForm.controls[sourceFile].setValidators([
      Validators.required,
      requiredFileType(valtype),
      fileSizeValidation(event.target.files)
    ]);
    this.brandForm.controls[sourceFile].updateValueAndValidity();

    if (!this.validateFileName(fileval)) {
      this.alert.showError("File name is not valid. Please choose a valid file.")
      this.brandForm.patchValue({
        [sourceFile]: ''
      });
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.brandForm.patchValue({
        [sourceName]: file
      });
    } else {
      this.brandForm.patchValue({
        [sourceName]: ''
      })
    }

  }

  downloadPayment() {
    // if (this.editObject.applicationNumber && this.editObject.licenseNumber) {
    //   this.exportRquest.downloadPd25Document(this.editObject.applicationNumber, this.editObject.licenseNumber, 'Dispatch (License Fee)').subscribe(
    //     (resp: any) => fileSaver.saveAs(resp, this.editObject.applicationNumber.replace(/\//g, '_')+'_OMD_PD25'),
    //       (error: any) => this.alert.showError('Error downloading the file'),
    //       () => console.info('File downloaded successfully')
    //     );
    // } else {
    //   this.alert.showError('File not available');
    // }
  }
  @ViewChild('exampleModalCenter1', { static: false }) exampleModalCenter1;

  imageboolean: boolean;
  imageDocName = ""
  imageSrcPath: any;
  async DownloadDoc(uuid, documentname) {
    let req = {
      "uuids": [
        uuid
      ]
    }
    this.imageSrcPath = '';
    this.imageboolean = true;
    const resp: any = await this.entPurchaseOrderService.postDownload(req);
    this.imageSrcPath = resp[0].bytes;
    this.imageboolean = true;
    this.imageDocName = documentname
    var fileFormat = this.imageDocName.split('.');
    var condition = fileFormat.length - 1;
    if (fileFormat[condition] === 'pdf') {
      this.imageboolean = true;
      const byteArray = new Uint8Array(
        atob(resp[0].bytes)
          .split("")
          .map(char => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(file);
      document.querySelector('iframe').src = url + "#toolbar=0";
    }
    else {
      this.imageboolean = false;
    }
    if (this.exampleModalCenter1) {
      this.exampleModalCenter1.show();
    } else {
      console.error('exampleModalCenter1 is not defined');
    }
  }

}
