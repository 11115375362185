import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
@Injectable({
  providedIn: 'root'
})
export class BottlingLineConversionService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  search(payload: any): Observable<any> {
    console.log(payload);
    const url = this.baseURL + 'bottlingLineConversion/lazySearch';
    return this.http.post(url, payload);
  }


  addetails(data): Observable<any> {
    const url = this.baseURL + 'bottlingLineConversion';
    return this.http.post(url, data);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'bottlingLineConversion/' + id;
    return this.http.get(url);
  }


  getline(value): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "searchInput": {
        "entityType": "BREWERY", "unitName": "", "status": "Approved", "licenseType": value, "licenseNumber": ""
      }, "page": 0, "pageSize": 40
    }


    return this.http.post(url, payload);
  }
  getlinePD(type, value): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "searchInput": {
        "entityType": type, "unitName": "", "status": "Approved", "licenseType": value, "licenseNumber": ""
      }, "page": 0, "pageSize": 40
    }


    return this.http.post(url, payload);
  }
  getlinePd(): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload = {
      "searchInput": {
        "entityType": "PD", "unitName": "", "status": "Approved", "licenseType": "", "licenseNumber": ""
      }, "page": 0, "pageSize": 5
    }


    return this.http.post(url, payload);
  }
  getMaintenanceTypes(): Observable<any> {

    const url = this.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=MAINTENANCE_TYPE'
    return this.http.get(url);
  }
  download(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }

  getStatusCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount';
    return this.http.post(url, payload);
  }

  bottlinglineSearch(payload): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    return this.http.post(url, payload);
  }

  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url, payload);
  }

}
