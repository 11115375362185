<h5 class="m-b-10 ng-star-inserted">
    Add Stock Reconcillation
</h5>
<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"
            routerLinkActive="router-link-active"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'">
        <a>{{'otProductionPlan.list.industrialDistillery' |
            translate}}</a>
    </li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item" routerLink="/{{url}}/omd/omd-stock-in/list"><a href="javascript:">Add Stock
            Reconcillation</a></li>
    <li class="breadcrumb-item">{{addedit}}</li>
</ul>
<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="omdStockInForm" autocomplete="off">
            <div class="col-xl-12">
                <app-card cardTitle="Stock In OMD" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Date Of Application</label>
                                <input type="text" class="form-control" formControlName="dateOfApplication"
                                disabled>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Time Of Application</label>
                                <input type="text" class="form-control" formControlName="timeOfApplication"
                                disabled>
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>

            <div class="col-xl-12">
                <app-card cardTitle="Unit Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">OMD Registration No</label>
                                <select class="form-control" formControlName="licenseNumber" 
                                (change)="getLicenseDetails($event.target.value)">
                                    <option value="">{{'language.select' | translate}}</option>
                                    <option *ngFor="let tank of licenseTypes" value="{{tank.licenseNumber}}">
                                        {{tank.licenseNumber}}</option>
                                </select>
                                <div *ngIf="formSubmitted && omdStockInForm.get('licenseNumber').errors || omdStockInForm.get('licenseNumber').touched "
                                class="error-msg">
                              <span *ngIf="omdStockInForm.get('licenseNumber').errors?.required">OMD Registration No is required </span>
                          </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">OMD Name</label>
                                <input type="text" class="form-control" formControlName="licenseName"
                                placeholder="Enter the OMD Name" disabled>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">OMD Address</label>
                                <input type="text" class="form-control" formControlName="licenseAddress"
                                placeholder="Enter the OMD Address" disabled>
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>

            <div class="col-xl-12">
                <app-card cardTitle="Stock Activity Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Stock Activity</label>
                                <div class="input-group mb-3">
                                    <select class="form-control" formControlName="stockActivityTypeCode">
                                        <option value="">{{'language.select' | translate}}</option>
                                        <option *ngFor="let tank of stockActivityData" value="{{tank.paramKey}}">
                                            {{tank.paramValue}}</option>
                                    </select>
                                </div>
                                <div *ngIf="formSubmitted && omdStockInForm.get('stockActivityTypeCode').errors || omdStockInForm.get('stockActivityTypeCode').touched "
                                class="error-msg">
                              <span *ngIf="omdStockInForm.get('stockActivityTypeCode').errors?.required">Stock Activity is required </span>
                          </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Purchase Type</label>
                                <div class="input-group mb-3">
                                    <select class="form-control" formControlName="purchaseTypeCode">
                                        <option value="">{{'language.select' | translate}}</option>
                                        <option *ngFor="let stockActivity of purchasetype" value="{{stockActivity.paramKey}}">
                                            {{stockActivity.paramValue}}</option>
                                    </select>
                                </div>
                                <div *ngIf="formSubmitted && omdStockInForm.get('purchaseTypeCode').errors || omdStockInForm.get('purchaseTypeCode').touched "
                                class="error-msg">
                              <span *ngIf="omdStockInForm.get('purchaseTypeCode').errors?.required">Purchase Type is required </span>
                          </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                            <div class="form-group">
                                <label class="mandatory">PD25 OMD Pass No</label>
                                <ng-multiselect-dropdown [data]="pd25Details" *ngIf="selectedItems2.length==0" [placeholder]="'Select'"
                                formControlName="omdPd25PassNumber" [settings]="pd25PassDropDownSettings"
                                (onSelect)="getDistilleryDetails($event);" (onFilterChange)="onFilterGetIndentRequest();"
                                (onDropDownClose)="onDropDownCloseBrandFilter()"
                                [ngClass]="pd25PassDropDownSettings.singleSelection ? 'singleselect' : '' ">
                            </ng-multiselect-dropdown>
                            <ng-multiselect-dropdown [data]="pd25Details" *ngIf="selectedItems2.length>0" [(ngModel)]="selectedItems2"
                                [placeholder]="'Select'" formControlName="omdPd25PassNumber" [settings]="pd25PassDropDownSettings"
                                (onSelect)="getDistilleryDetails($event);" (onFilterChange)="onFilterGetIndentRequest()"
                                (onDropDownClose)="onDropDownCloseBrandFilter()"
                                [ngClass]="pd25PassDropDownSettings.singleSelection ? 'singleselect' : '' ">
                            </ng-multiselect-dropdown>
                                <!-- <select class="form-control" formControlName="omdPd25PassNumber"
                                (change)="getDistilleryDetails($event.target.value)">
                                    <option value="">{{'language.select' | translate}}</option>
                                    <option *ngFor="let tank of pd25Details" value="{{tank.applicationNumber}}">
                                        {{tank.applicationNumber}}</option>
                                </select> -->
                            </div>
                            
                            <div *ngIf="formSubmitted && omdStockInForm.get('omdPd25PassNumber').errors || omdStockInForm.get('omdPd25PassNumber').touched "
                            class="error-msg">
                          <span *ngIf="omdStockInForm.get('omdPd25PassNumber').errors?.required">PD25 OMD Pass No</span>
                      </div>
                        </div>
                    </div>
                </app-card>
            </div>

            <div class="col-xl-12">
                <app-card cardTitle="Consignor Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Distillery Name</label>
                                <input type="text" class="form-control" formControlName="distilleryName"
                                placeholder="Enter the Distillery Name" disabled>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">License Type</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" formControlName="distilleryLicenseType"
                                    placeholder="Enter the License Type" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">License No</label>
                                <input type="text" class="form-control" formControlName="distilleryLicenseNumber"
                                placeholder="Enter the License No" disabled>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Distillery Address</label>
                                <input type="text" class="form-control" formControlName="distilleryAddress"
                                placeholder="Enter the Distillery Address" disabled>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-2 list-panel add-panel">
                                <table class="table table-bordered text-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>{{'parentunit.intimationForm.se_no' | translate}}</th>
                                            <th class="mandatory">Raw Material Type</th>
                                            <th class="mandatory">Spirit Name</th>
                                            <th class="mandatory">Strength (%V/V)</th>
                                            <th class="mandatory">Issued Qty (in BL)</th>
                                            <th class="mandatory">Issued Qty (in AL)</th>
                                            <th class="mandatory">Received Qty (in BL)</th>
                                            <th class="mandatory">Received Qty (in AL)</th>
                                            <th class="mandatory">Wastage (in BL)</th>
                                            <th class="mandatory">Wastage (in AL)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td>1</td>
                                        <td>
                                            <select class="form-control" formControlName="rawMaterialTypeCode" disabled
                                            (change)="getSpiritName()">
                                                <option value="">{{'language.select' | translate}}</option>
                                                <option *ngFor="let tank of spirityType" value="{{tank.paramKey}}">
                                                    {{tank.paramValue}}</option>
                                            </select>
                                            
                                <div *ngIf="formSubmitted && omdStockInForm.get('rawMaterialTypeCode').errors || omdStockInForm.get('rawMaterialTypeCode').touched "
                                class="error-msg">
                              <span *ngIf="omdStockInForm.get('rawMaterialTypeCode').errors?.required">Raw Material Type is Required</span>
                          </div>
                                        </td>
                                        <td>
                                            <input type="text" disabled class="form-control" placeholder="Ethanol Type"
                                                formControlName='spiritNameValue' name="spiritNameValue">
                                            <!-- <select class="form-control" formControlName="spiritNameCode">
                                                <option value="">{{'language.select' | translate}}</option>
                                                <option *ngFor="let tank of spiritNameList" value="{{tank.paramKey}}">
                                                    {{tank.paramValue}}</option>
                                            </select> -->
                                            <div *ngIf="formSubmitted && omdStockInForm.get('spiritNameValue').errors || omdStockInForm.get('spiritNameValue').touched "
                                class="error-msg">
                              <span *ngIf="omdStockInForm.get('spiritNameValue').errors?.required">Spirit Name is Required</span>
                          </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="strength" disabled
                                            placeholder="Enter the Strength" appTwoDigitDecimaNumber maxlength="5" (input)="calculateIssueQtyAl();calculateReceivedQtyAl($event.target.value);calculateWastageAl($event.target.value)">
                                            <div *ngIf="formSubmitted && omdStockInForm.get('strength').errors || omdStockInForm.get('strength').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('strength').errors?.required">Strength is Required</span>
                                      </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="issuedQuantityBl" disabled
                                            placeholder="Enter the Issued Quantity BL" appTwoDigitDecimaNumber maxlength="11" (input)="calculateIssueQtyAl()">
                                            <div *ngIf="formSubmitted && omdStockInForm.get('issuedQuantityBl').errors || omdStockInForm.get('issuedQuantityBl').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('issuedQuantityBl').errors?.required">Issued Quantity (in BL) is Required</span>
                                      </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="issuedQuantityAl"
                                            placeholder="Enter the Issued Quantity AL" appTwoDigitDecimaNumber maxlength="11" disabled>
                                            <div *ngIf="formSubmitted && omdStockInForm.get('issuedQuantityAl').errors || omdStockInForm.get('issuedQuantityAl').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('issuedQuantityAl').errors?.required">Issued Qunatity (in AL) is Required</span>
                                      </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="receivedQuantityBl"
                                            placeholder="Enter the Received Quantity BL" appTwoDigitDecimaNumber maxlength="11" (input)="calculateReceivedQtyAl($event.target.value)">
                                            <div *ngIf="formSubmitted && omdStockInForm.get('receivedQuantityBl').errors || omdStockInForm.get('receivedQuantityBl').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('receivedQuantityBl').errors?.required">Received Qunatity (in BL) is Required</span>
                                      </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="receivedQuantityAl"
                                            placeholder="Enter the Received Quantity AL" appTwoDigitDecimaNumber maxlength="11" disabled>
                                            <div *ngIf="formSubmitted && omdStockInForm.get('receivedQuantityAl').errors || omdStockInForm.get('receivedQuantityAl').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('receivedQuantityAl').errors?.required">Received Qunatity (in AL) is Required</span>
                                      </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="wastageBl"
                                            placeholder="Enter the Wastage BL" appTwoDigitDecimaNumber maxlength="11" (input)="calculateWastageAl($event.target.value)">
                                            <div *ngIf="formSubmitted && omdStockInForm.get('wastageBl').errors || omdStockInForm.get('wastageBl').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('wastageBl').errors?.required">Wastage (in BL) is Required</span>
                                      </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="wastageAl"
                                            placeholder="Enter the Wastage AL" appTwoDigitDecimaNumber maxlength="11" disabled>
                                            <div *ngIf="formSubmitted && omdStockInForm.get('wastageAl').errors || omdStockInForm.get('wastageAl').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('wastageAl').errors?.required">Wastage (in AL) is Required</span>
                                      </div>
                                        </td>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">Remarks</label>
                                <input type="text" class="form-control" formControlName="remarks"
                                placeholder="Enter the Remarks" maxlength="500">
                            </div>
                            <div *ngIf="formSubmitted && omdStockInForm.get('remarks').errors || omdStockInForm.get('remarks').touched "
                                            class="error-msg">
                                          <span *ngIf="omdStockInForm.get('remarks').errors?.required">Remarks is Required</span>
                                      </div>
                        </div>
                    </div>
                </app-card>
                <app-card cardTitle="{{'language.remarks' | translate}}" *ngIf="isSendback"
                class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.remarks' | translate}}
                            </label>
                            <input type="text" class="form-control" formControlName="comments"
                                placeholder="Enter Remarks">
                                <div *ngIf="formSubmitted && omdStockInForm.get('comments').errors || omdStockInForm.get('comments').touched "
                                class="error-msg">
                              <span *ngIf="omdStockInForm.get('comments').errors?.required">Remarks is Required</span>
                          </div>
                            <!-- <app-show-errors eleName="Remarks" [frmSub]="formSubmitted"
                                [control]="getFormCntr('comments')">
                            </app-show-errors> -->
                        </div>
                    </div>
                </div>
            </app-card>
            </div>
        </form>
    </div>

    <div class="col-12 text-right mb-4">
        <!-- <button type="button" class="btn btn-primary ml-2 mb-2" (click)="preview(addFormGroup,'DRAFT', true)">Preview</button> -->

        <button type="button" class="btn btn-warning ml-2 mb-2" *ngIf="!isSendback"
            (click)="save(omdStockInForm,'DRAFT')">{{'brewery.common.draft' |
            translate }}</button>
        <button type="button" class="btn btn-success ml-2 mb-2"
            (click)="save(omdStockInForm,'SUBMITTED')">{{'brewery.common.submit' | translate }}</button>
        <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/{{url}}/omd/omd-stock-in/list"
            routerLinkActive="router-link-active">{{'brewery.common.cancel' | translate }}</button>
    </div>
</div>