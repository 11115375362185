<div class="row">
    <div class="col-md-8">
        <h1 mat-dialog-title>Google View Map</h1>
    </div>
    <div class="col-md-4">
        <i class="fas fa-window-close float-right" mat-dialog-close></i>
    </div>
</div>
<div mat-dialog-content style="text-align:end">
    <div class="row" style="margin-top:20px">
        <div class="col-md-12">
            <div #map style="width:80%;height:300px;margin: auto;"></div>
        </div>
    </div>
</div>

