
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class SpiritImportPermitService {
  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  domainUrl = environment.domainUrl;
  masterURL = environment.devisionUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'spiritImportPermit';
    return this.http.post(url, data);
  }
  spiritImportExportValidityExtension(payload):Observable<any>{
    const url = this.baseURL + 'spiritImportPermit/sub/updateValidityExtension';
    return this.http.post(url, payload);
  }

  search(payload): Observable<any> {
    const url = this.baseURL + 'spiritImportPermit/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'spiritImportPermit/' + id;
    return this.http.get(url);
  }
  getByIdAsync(id): any {
    const url = this.baseURL + 'spiritImportPermit/' + id;
    return this.http.get(url).toPromise();
  }
  getByIdPd18(id): Observable<any> {
    const url = this.baseURL + 'pd18/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data) {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {});
  }
  getLicenseData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getspiritTypes(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=SPIRIT_TYPE'
    return this.http.get(url);
  }
  findDropDownMasterOnMaster(key): Observable<any> {
    const url = this.masterURL + apiUrls.findDropDownMasterOnMaster + '?dropDownKey=' + key;
    return this.http.get(url);
  }
  getdetails(screenname?): any {
    const url = this.licenseURL + 'license/category/DISTILLERY'+(screenname?screenname:'');
    return this.http.get(url).toPromise();
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  getStates(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=STATE_MASTER'
    return this.http.get(url);
  }
  getCountry(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=COUNTRY_MASTER'
    return this.http.get(url);
  }
  downloadenavalue(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  getAdvanceDuty(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get'
    return this.http.post(url, payload)
  }
  getAdvanceDutyAsync(payload): any {
    const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get'
    return this.http.post(url, payload).toPromise();
  }
  getProcessingFee(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search'
    return this.http.post(url, payload)
  }
  getProcessingFeeAsync(payload): any {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search'
    return this.http.post(url, payload).toPromise();
  }
  getrawmaterialTypes(key): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=' + key
    return this.http.get(url);
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'spiritImportPermit/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  saveupdatepaymentdetailsPd18(payload): Observable<any> {
    const url = this.baseURL + 'pd18/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  saveupdatepaymentOmddetails(payload): Observable<any> {
    const url = this.baseURL + 'omdEthanolOrder/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'spiritImportPermit/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findpaymentdetailsPd18(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'pd18/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=SPIRIT_IMPORT_PERMIT';

    return this.http.get(url, { responseType: 'blob' });
  }
  downloadDocumentExportSpiritPD18(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=EXPORT_SPIRIT_PD18';
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadOmdDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=OMD_ETHANOL_ORDER';

    return this.http.get(url, { responseType: 'blob' });
  }
  downloadPd25Document(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=OMD_PD25_PAYMENT_RECEIPT';

    return this.http.get(url, { responseType: 'blob' });
  }
  downloadfl16PD25Document(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=FL16_PD25_PAYMENT_RECEIPT';

    return this.http.get(url, { responseType: 'blob' });
  }
  downloadSchoolPD25Document(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=DISPATCH_TO_SCHOOLS_PAYMENT_RECEIPT';

    return this.http.get(url, { responseType: 'blob' });
  }
  getSpiritImportCount(entityType, isSpiritImport): Observable<any> {
    const url = this.baseURL + 'spiritImportPermit/getSpiritImportCount?entityType=' + entityType + '&isSpiritImport=' + isSpiritImport;
    return this.http.get(url);
  }
  postDownload(postdata): any {
    const url = environment.documentUrl + 'api/document/downloadfiles';
    return this.http.post(url, postdata).toPromise();
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }

}