import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class PdCommonService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  baseURLMaster = environment.baseURLMaster;
  getdetails(screens?): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY'+(screens?screens:'');
    return this.http.get(url).toPromise();
  }

  getdetails1(): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY?optionalCategory=DISTILLERY ';
    return this.http.get(url).toPromise();
  }

  getdetailsBottling(): any {//work
    const url = this.licenseURL + 'license/category/BOTTLING';
    return this.http.get(url).toPromise();
  }
  getdetailsBoth(screens?): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY%2CBOTTLING'+(screens?screens:'');
    return this.http.get(url).toPromise();
  }
  getEntityType(): any {
    let entity = localStorage.getItem('entityList');
    let entityType = entity?entity.split(','):[];
    if (entityType && entityType.length > 0) {
      let entityVal = 'PD';
      entityType.forEach(element => {
        if (element == 'PD' || element == 'ID' || element == "MIXED") {
          entityVal = element;
        }
      });
      return entityVal;
    } else {
      return 'PD'
    }
  }
  getEntitys(): any {
    let entity = localStorage.getItem('entityList');
    let entityType = entity.split(',');
    if (entityType && entityType.length > 0) {
      let entityVal = '';
      entityType.forEach(element => {
        if (element == 'PD' || element == 'ID' || element == "MIXED") {
          if (entityVal) {
            entityVal = ',' + element;
          } else {
            entityVal = element;
          }
        }
      });
      if (entityVal != '') {
        return entityVal;
      } else {
        return 'PD'
      }
    } else {
      return 'PD'
    }
  }
  //getSugarMillDetails(): Observable<any> {
  getSugarMillDetails(): any {
    const url = this.licenseURL + 'license/category/PD?optionalCategory=SUGAR_MILL';
    return this.http.get(url).toPromise();
  }

  //getSugarMillDetails(): Observable<any> {
  getSugarMillDetails1(entityType): any {
    const url = this.licenseURL + 'license/category/' + entityType + '?optionalCategory=SUGAR_MILL';
    return this.http.get(url).toPromise();
  }
  getSugarMillListDetails(entityType, groupName, isCaptive): any {
    const url = this.licenseURL + 'license/category/sugarMill/SUGAR_MILL?groupName='+ groupName +'&captiveDistilleries='+ isCaptive +'&page=0&size=0';
    return this.http.get(url).toPromise();
  }
  getSugarMillListDetailsWihoutIsCaptiveParam(groupName): any {
    const url = this.licenseURL + 'license/category/sugarMill/SUGAR_MILL?groupName='+ groupName +'&page=0&size=0';
    return this.http.get(url).toPromise();
  }
  getSugarMillListDetailsWithoutGroup(): any {
    const url = this.licenseURL + 'license/category/sugarMill/SUGAR_MILL?page=0&size=0';
    return this.http.get(url).toPromise();
  }
  getAllotMentList(licenseNo): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/approved/licenseNumbers?licenseNo=' + licenseNo;
    return this.http.get(url);
  }
  getOmdList(param): Observable<any> {
    const url = this.baseURL + 'omdEthanolOrder/lazySearch';
    return this.http.post(url, param);
  }
  getIndentList(licenseNo): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/approved/licenseNumbers?licenseNo=' + licenseNo;
    return this.http.get(url);
  }
/*
Get the balance quantity of the Allotment or EnaMolassesAdjustment based on the request
*/
getAllotMentQuantity(
  licenseNo,
  entityType,
  isAllotmentBasedIndentRequest
): Observable<any> {
  const url =
    this.baseURL +
    "pdIndentRequest/getAllotmentPendingQty?allotmentNumber=" +
    licenseNo +
    "&entityType=" +
    entityType +
    "&isAllotmentBasedRequest=" +
    isAllotmentBasedIndentRequest;
  return this.http.get(url);
}

getAllotMentQuantityNew(
  licenseNo,
  entityType,
  isAllotmentBasedIndentRequest,
  isEditScreen,
  applicationNumber
): Observable<any> {
  const url =
    this.baseURL +
    "pdIndentRequest/getAllotmentPendingQty?allotmentNumber=" +
    licenseNo +
    "&entityType=" +
    entityType +
    "&isAllotmentBasedRequest=" +
    isAllotmentBasedIndentRequest + 
    "&isEditScreen="+
    isEditScreen +
    "&applicationNumber="+
    applicationNumber;
  return this.http.get(url);
}
  getIntendQuantity(licenseNo): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/getAllotmentPendingQty?allotmentNumber=' + licenseNo + '&entityType=PD';
    return this.http.get(url);
  }
  getIntendQuantity1(licenseNo, entityType): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/getAllotmentPendingQty?allotmentNumber=' + licenseNo + '&entityType=' + entityType;
    return this.http.get(url);
  }
  getYearList(): Observable<any> {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=YEAR_OF_REGISTRATION';
    return this.http.get(url)
  }
  getModeOfTransfer(): Observable<any> {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=MODE_OF_TRANSFER';
    return this.http.get(url)
  }
  getRouteType(): Observable<any> {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=ROUTE_TYPE';
    return this.http.get(url)
  }
  getBlendingList(model): Observable<any> {
    const url = this.baseURL + 'blendTransfer/lazySearch';
    return this.http.post(url, model);
  }
  getBlendingnTankDetail(model) {
    const url = this.baseURL + 'blendingProcess/lazySearch';
    return this.http.post(url, model);
  }
  getMolasssersBal(model) {
    const url = this.baseURL + 'molasses/openingBalnce';
    return this.http.post(url, model);
  }
  getOpenMmolassesBalance(model) {
    const url = this.baseURL + 'pdAllotmentRequest/openingBalnace/reservedAndUnReserved?licenseNo=' + model.licenseNumber + '&financialYearCode=' + model.year;
    return this.http.get(url);
  }
  getYearData(): Observable<any> {
    const url = this.baseURL + 'financialYears/dropDown';
    return this.http.get(url)
  }
  getMolassesYearList(): Observable<any> {
    const url = this.licenseURL + 'license/findMolassessYear';
    return this.http.get(url)
  }
  getMolassesYearDetail(): Observable<any> {
    const url = this.baseURL + 'utility/molasses/forecastDetails';
    return this.http.get(url)
  }
  getTankDetails(paramData) {
    const url = this.baseURL + "findApprovedTankDetails/dropdown";
    return this.http.get(url)
  }
  getSearchDropdowns(paramData) {
    const url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    return this.http.get(url)
  }
  getDropDowns(type) {
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type;
    url += '&status=' + 'APPROVED';
    return this.http.get(url);
  }
  getDesMaterial(type) {
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type;
    url += '&status=' + 'APPROVED';
    return this.http.get(url);
  }
  getDesgianatedMat(type?) {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=DESIGNATED_MATERIAL';
    return this.http.get(url);
  }
  getDesgianatedMat1(type, licenseNumber) {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=DESIGNATED_MATERIAL&licenseNo=' + licenseNumber;
    return this.http.get(url);
  }
  getMolassesGrade(type?) {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MOLASSES_TYPE';
    return this.http.get(url);
  }
  getconsumptiontype(key) {
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=' + key;
    return this.http.get(url);
  }

  getSugarMillDetailsWithinUp(): any {
    const url = this.licenseURL + 'license/category/SUGAR_MILL';
    return this.http.get(url).toPromise();
  }

  getDistillerySugamillDetais(entityType,distilleryLicenseNumber,sugarMillLicenseNumber):any{
    const url = this.baseURL + 'pdIndentRequest/getProductionAndLiabilityDetails?entityType='+entityType+'&distilleryLicenseNumber='
    +distilleryLicenseNumber+'&sugarMillLicenseNumber='+sugarMillLicenseNumber;
    return this.http.get(url);
  }

  getIndentDistillerySugamillDetais(entityType,distilleryLicenseNumber,sugarMillLicenseNumber,isDistilleryUser):any{
    const url = this.baseURL + 'pdIndentRequest/getIndentRequestProductionAndLiabilityDetails?entityType='+entityType+'&distilleryLicenseNumber='
    +distilleryLicenseNumber+'&sugarMillLicenseNumber='+sugarMillLicenseNumber+'&isDistilleryUser='+isDistilleryUser;
    return this.http.get(url);
  }

  getIndentDistillerySugamillDetais1(entityType,distilleryLicenseNumber,sugarMillLicenseNumber,isDistilleryUser,unitCode):any{
    const url = this.baseURL + 'pdIndentRequest/getIndentRequestProductionAndLiabilityDetails1?entityType='+entityType+'&distilleryLicenseNumber='
    +distilleryLicenseNumber+'&sugarMillLicenseNumber='+sugarMillLicenseNumber+'&isDistilleryUser='+isDistilleryUser+'&distilleryLicenseUnitCode='+unitCode;
    return this.http.get(url);
  }
  
  getEnaDetailsforIndentRequest(entityType,distilleryLicenseNumber,sugarMillLicenseNumber,distilleryLicenseUnitCode):any{
    const url = this.baseURL + 'pdIndentRequest/getEnaMolassesAdjustmentDetails?entityType='+entityType+'&distilleryLicenseNumber='+distilleryLicenseNumber+
    '&sugarMillLicenseNumber='+sugarMillLicenseNumber+'&distilleryLicenseUnitCode='+distilleryLicenseUnitCode;
    return this.http.get(url);
  }
}
