<div class="container"
  *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header" *ngIf="!reqKey">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <!-- <a (click)="navigate()" class="b-brand" style="cursor: pointer;"> -->
      <a routerLink="/welcome">
        <img id="main-logo" src="assets/images/logo-d-dark.png" alt="" class="logo"
          *ngIf="this.nextConfig.navBrandColor !== 'brand-default' || this.nextConfig.headerBackColor !== 'header-default'">
        <img src="assets/images/logo-dark.png" alt="" class="logo"
          *ngIf="this.nextConfig.navBrandColor === 'brand-default' && this.nextConfig.headerBackColor === 'header-default'">
        <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
      </a>
    <!-- </a> -->
    <a class="mob-toggler cust-mob-radius" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()">
      <!-- <i         class="feather icon-more-vertical"></i> -->
      <i class="icon feather icon-user"></i>
    </a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle" *ngIf="!reqKey">
    <app-nav-left class="mr-auto"></app-nav-left>
    <p class="text-center text-white text-black-res font-weight-bold mb-0">{{ username }}</p>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>

</ng-template>
<app-ui-modal #timeoutModal dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Timeout</h5>
    <!-- (click)="confirmConfirmation.hide()" -->
    <!--        <button type="button" (click)="timeoutModal.hide()" class="close basic-close" data-dismiss="modal" aria-label="Close"><span-->
    <!--                aria-hidden="true">&times;</span></button>-->
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-12" style="color: black;">
        <p>Your session is going to expire in <strong>{{ waitingPeriodInSeconds }}</strong> seconds. Please click on
          continue to Continue or close the session.</p>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <div class="row">
      <div class="col-12 text-right">
        <button type="submit" class="btn btn-primary" (click)="continueSession()">Continue</button>
        <button type="submit" class="btn btn-secondary ml-2" (click)="closeSession()">Close</button>
      </div>
    </div>
  </div>
</app-ui-modal>