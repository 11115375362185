import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';

@Injectable({
  providedIn: 'root'
})
export class BottlinglinemaintenanceService {

  constructor(private http: HttpClient) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  search(payload: any): Observable<any> {
    console.log(payload);
    const url = this.baseURL + 'bottlingLineMainetenance/lazySearch';
    return this.http.post(url, payload);
  }
  
 
  addetails(data): Observable<any> {
    const url = this.baseURL + 'bottlingLineMainetenance';
    return this.http.post(url, data);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'bottlingLineMainetenance/' + id;
    return this.http.get(url);
  }
 

  getline(): Observable<any>{
   const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
   let payload={
    "searchInput":{"entityType":"BREWERY","unitName":"","status":"Approved","licenseType":"","licenseNumber":""
  },"page":0,"pageSize":5}

  
  return this.http.post(url, payload);
  }
  getMaintenanceTypes(): Observable<any>{

    const url = this.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=MAINTENANCE_TYPE'
    return this.http.get(url);
  }
}



