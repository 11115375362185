import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class BrandlabelbrewService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.scmproduction;
  licenseTypeBaseUrl = environment.licenseTypeBaseUrl;
  licenseURL = environment.licneseBaseUrl;
  domainUrl = environment.domainUrl;
  // getdetails(): Observable<any> {//work
  //   const url = this.licenseURL + 'license/category/PD';
  //   return this.http.get(url);
  // }
  getdetailsdistillerybottlings(): any {//work 
    const url = this.licenseURL + 'license/category/DISTILLERY%2CBOTTLING';
    return this.http.get(url).toPromise();

  }
  licensedetail(value): any {//work 
    const url = this.licenseURL + 'license/category/'+value;
    return this.http.get(url).toPromise();

  }
  getdetails(): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    return this.http.get(url).toPromise();

  }
  //change toPromise
  getdetails2(): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    return this.http.get(url).toPromise();
  }

  async getFl39() {//work
    const url = this.licenseURL + 'license/category/INDUSTRIAL_ALCOHOL?subCategory=FL39';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  //
  getEntityType(): any {
    let entity = localStorage.getItem('entityList');
    let entityType = entity.split(',');
    if (entityType && entityType[0]) {
      return entityType[0]
    } else {
      return 'PD'
    }
  }
  getdetailsBottling():any {//work
    const url = this.licenseURL + 'license/category/BOTTLING';
    return this.http.get(url).toPromise();
  }

  async newgetdetailsBottling() {
    const url = this.licenseURL + 'license/category/BOTTLING';
    const response = await this.http.get(url).toPromise();
    // const json = await response.json();
    // console.log("json", response);
    return response
  }
  async newgetdetailsVINTINERY(license) {
    const url = this.licenseURL + 'license/category/'+license+'%2CBOTTLING';
    const response = await this.http.get(url).toPromise();
    // const json = await response.json();
    // console.log("json", response);
    return response
  }

  async getdetailsBoth() {//work
    const url = this.licenseURL + 'license/category/DISTILLERY%2CBOTTLING';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  async getOmdLicenese() {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  async getOmdUserLicenese() {//work
    const url = this.licenseURL + 'license/category/INDUSTRIAL_ALCOHOL';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  getdetails1(): any {//work
    const url = this.licenseURL + 'license/category/SUGAR_MILL';
    return this.http.get(url).toPromise();
  }
  async getPassBookLicense() {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  async getdetailsGinBoth() {//work
    const url = this.licenseURL + 'license/category/DISTILLERY%2CBOTTLING';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  getDynamicEntityLicense(): Observable<any> {//work
    let entity = localStorage.getItem('entityList');
    const url = this.licenseURL + 'license/category/' + (entity ? entity.replace(/,/g, '%2C') : '');
    return this.http.get(url);
  }

  brandLabelExisting(entityType: any, param?: any): Observable<any> {
    let url = this.baseURL + 'brandLabel/existing';
    url += param ? '?licenseType=' + param : '';
    url += '&entityType=' + entityType;
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  // getapplicationnumbers(data, licensenumber): Observable<any> {//work
  //   const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data + '&licenseNumber=' + licensenumber;
  //   return this.http.post(url, data);
  // }

  getapplicationnumbers(data): any {

    let stringVal: any = 'registrationType=' + data.registrationType;
    // if (data.registrationType) {
    //   stringVal += '&registrationType=' + data.registrationType;
    // }

    if (data.licenseNumber) {
      stringVal += '&licenseNumber=' + data.licenseNumber;
    }
    if (data.liquorType) {
      stringVal += '&liquorType=' + data.liquorType;
    }
    if (data.entityType) {
      stringVal += '&entityType=' + data.entityType;
    }
    const url = this.baseURL + 'generateApplicationNumber?' + stringVal.trim('');


    // const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber + '&liquorType=' + data.liquorType+ '&entityType=' + data.entityType;
    return this.http.post(url, data).toPromise();
  }

  getApprovedLicenseTypes(licensetype) {//work
    let url = this.licenseTypeBaseUrl + "brandLabel/approved/licenseType/" + licensetype;
    return this.http.post(url, {});

  }

  addetails(data: any): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    return this.http.post(url, data);
  }


  getbrandlabelbyid(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  
  getbrandlabelbyid1(id):any {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url).toPromise();
  }
  createbrandlabeldetails(data): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    console.log(url);
    return this.http.post(url, data);
  }
  getbrandandlabeldatabyetin(etin): Observable<any> {
    const url = this.baseURL + 'brandLabel/approved/etin/' + etin;
    return this.http.get(url);
  }
  getbrandandlabeldatabylicensetype(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/approved/licenseType/' + licensetype;
    return this.http.get(url);
  }
  searchstockindetails(data): any {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    //return this.http.post(url, data);
    return this.http.post(url, data).toPromise();
  }

  searchBL(data): any {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, data);
  }

  getdistinctbrandandproduct(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/existing?licenseType=' + licensetype;
    return this.http.get(url);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'brandLabel/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  getbrandlabelstatusdatacount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount';
    return this.http.get(url);
  }
  getbrandlabeldropdowndtls(brandname) {
    const url = this.baseURL + 'brandLabel/getDropDown?type=' + brandname;
    return this.http.get(url)
  }
  getlabeldetails(postParam) {
    const url = this.baseURL + 'brandLabel/overview/labelDetails';
    return this.http.post(url, postParam);
  }
  labelfeepayment(postParam) {
    const url = this.baseURL + "brandLabel/saveUpdatePaymentDetails"
    return this.http.post(url, postParam);
  }

  searchoverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }
  getoverviewstatuscount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/statusCount';
    return this.http.get(url);
  }

  updatelabelstatusoverview(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/workflowCallBack';
    return this.http.post(url, payload);
  }

  getoverviewbrandlabeldata(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }
  getoverviewbrandlabeldata1(id):any {
    const url = this.baseURL + 'brandLabel/overview/' + id; 
    return this.http.get(url).toPromise();
  }

  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }

  updatebrandlabelworkflowdtls(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/updateBrandLabelWorkFlowDetails';
    return this.http.post(url, payload);
  }

  validategtin(payload: any): Observable<any> {
    const url = this.licenseTypeBaseUrl + 'gtinValidation';
    return this.http.post(url, payload);
  }

  // overview service
  searchOverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }


  getDropDowns(type,entitytype) {
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type+'&entityType='+entitytype;
    url += '&status=' + 'APPROVED';
    return this.http.get(url);
  }

  getOverviewById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }


  addLabelDetails(data): Observable<any> {
    const url = this.baseURL + "brandLabel/overview/labelDetails"
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, payload);
  }
  getbrandlabelcount(entityType): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount?entityType=' + entityType;
    return this.http.get(url);
  }
  getSearchDropdowns(paramData, entityType?) {
    let url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    if (entityType) {
      url = url + '&entityType=' + entityType;
    }
    return this.http.get(url)
  }

  async getPaymentDetail(payload) {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    // return this.http.post(url, payload);

    // const url = this.licenseURL + 'license/category/BOTTLING'; 
    const response = await this.http.post(url, payload).toPromise();
    // const json = await response.json();
    // console.log("json", response);
    return response

  }



  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }

  postDownload(url, data) {
    const requestOptions = {
      responseType: 'blob' as 'json'
    };
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.post(requestURL, data, requestOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadBrandLabelReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
    return this.http.get(url, { responseType: 'blob' });
  }

  deleteLable(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/label/' + id;
    return this.http.delete(url);
  }
  getpaymentforbottling(): Observable<any> {
    const url = this.baseURL + 'getCommonConfigDetail?entityType=UP_EXCISE&type=SCM_BOTTLING_PLAN_PAYMENT_CONFIG';
    return this.http.get(url);
  }
  existingsamebrandname(brandname): any { //work
    const url = this.baseURL + 'findByMasterBrandDetails?status=true&brandName='+brandname+'&page=0&size=50';
    return this.http.get(url).toPromise();
  } 
  async getFlLicense() {//work
    const url = this.licenseURL + 'license/category/BOND_WAREHOUSE?subCategory=FL41,FL16,FL40&optionalCategory=INDUSTRIAL_ALCOHOL';
    const response = await this.http.get(url).toPromise();
    return response;
  }
  async getFl32License() {//work
    const url = this.licenseURL + 'license/category/BOND_WAREHOUSE?subCategory=FL32,FL32_GOVT_MEDICAL_INSTITUTIONS,FL32_CENTRAL_LABORATORY,FL32_NON_GOVT_MEDICAL_INSTITUTIONS,FL32_GOVT_EDUCATIONAL_INSTITUTIONS,FL32_STATE_LABORATORY,FL32_GOVT_NON_GOVT_INSTITUTIONS,FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS&optionalCategory=INDUSTRIAL_ALCOHOL';
    const response = await this.http.get(url).toPromise();
    return response;
  }
}
