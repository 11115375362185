import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class WholesaleStockInHandService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.localDBUrl;
  baseURL1 = environment.wholesaleNewURL;
  baseURL2 = environment.devisionUrl;
  get(url) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.get(requestURL).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }

  post(url, data) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.post(requestURL, data).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }

  patch(url, data) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.patch(requestURL, data).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }

  put(url, data) {
    const requestURL = this.baseURL + url;
    const promise = new Promise((resolve, reject) => {
      this.http.put(requestURL, data).toPromise()
        .then(res => { // Success
          resolve(res);
        }, msg => { // Error
          reject(msg);
        });
    });
    return promise;
  }
  lazySearch(postData) {
    const url = this.baseURL1 + apiUrls.stockInhandlazySearch;
    return this.http.post(url, postData)
  }
  getLiquiorType() {
    const url = this.baseURL2 + apiUrls.getAllLiquorType
    return this.http.get(url)
  }
  findBrandDetails(postdata) {
    const url = this.baseURL1 + apiUrls.stockInHandDropDown
    return this.http.post(url, postdata)
  }

  getList(data) {
    const url = this.baseURL1 + "customdata/getdata"
    return this.http.post(url, data);
  }

  getLiqoretype(obj) {
    const url = this.baseURL1 + "master/data/listmasterdata";
    return this.http.post(url, obj)
  }
  getcustomerdata(obj) {
    const url = this.baseURL1 + "customdata/getdata";
    return this.http.post(url, obj)
  }
  submitdispatch(obj) {
    const url = this.baseURL1 + "stockdispatch/processdispatch"
    return this.http.post(url, obj)
  }

  processconsolidateddispatch(obj) {
    const url = this.baseURL1 + "stockdispatch/processconsolidateddispatch"
    return this.http.post(url, obj)
  }
  stockInHandView(postParam): any {
    const url = this.baseURL1 + 'stockdata/daywisestockinhand'
    return this.http.post(url, postParam).toPromise();
  }

  beerIndentPaidDispatch(obj) {
    const url = this.baseURL1 + "stockdispatch/beerindentdispatchprocess";
    return this.http.post(url, obj)
  }

};