export const paths = {
  auth: 'auth',
  // cache: "cache",
  fake: 'fake',
  error: 'error',
  profiler: 'profiler',
  notify: 'notify',
  header: 'header',
  convert: 'convert',
  loader: 'loader',
  // https: "https"
};
