import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IdleMonitoringService } from '@app/_helpers/idle-monitoring.service';
import { Subscription, timer } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { NextConfig } from '../../../../app-config';
import { Router } from '@angular/router';
import { AuthService } from '@app/services';
import { DisableRightClickService } from '@app/_helpers/disable-right-click.service';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
	public nextConfig: any;
	public menuClass: boolean;
	public collapseStyle: string;
	public windowWidth: number;
	public username: string;

	@Output() onNavCollapse = new EventEmitter();
	@Output() onNavHeaderMobCollapse = new EventEmitter();

	@ViewChild('timeoutModal', { static: false }) timeoutModal;
	public waitingPeriodInSeconds: number;
	private logoutTimer: Subscription;
	private timeoutServiceSub$: Subscription;
	reqKey: boolean = false;

	constructor(
		private router: Router,
		private idleMonitoringService: IdleMonitoringService,
		private disableRightClickService: DisableRightClickService,
		private authSer: AuthService
	) {
		this.nextConfig = NextConfig.config;
		this.menuClass = false;
		this.collapseStyle = 'none';
		this.windowWidth = window.innerWidth;
	}

	private registerUserConfig() {
		const userConfigArr = JSON.parse(localStorage.getItem('USER_ACTIVITY_CONFIG'));
		// const rightClickConfig: any = userConfigArr.find(res => res.code === 'RIGHT_CLICK');
		const userConfig: any = userConfigArr.find(res => res.code === 'SESSION_TIMEOUT_TIME_POP_COUNTER');

		// if (rightClickConfig.value.toLowerCase() === 'yes') {
		// 	this.disableRightClickService.disableRightClick();
		// }

		// const tabConfig: any = userConfigArr.find(res => res.code === 'TAB_LOGOUT');
		// const sessionValue = sessionStorage.getItem('NEW_TAB_WILL_LOGOUT') || false;
		// if (tabConfig.value.toLowerCase() === 'yes' && !sessionValue) {
		// 	this.closeSession();
		// 	return;
		// }

		const popupCounterInSeconds = (parseInt(userConfig.value) * 60);
		this.registerTimeoutHandler(popupCounterInSeconds);
	}

	ngOnInit() {
		const authUserName = this.authSer.getUserName();
		this.username = authUserName ? authUserName : 'Admin';
		this.reqKey = localStorage.getItem('REQ_KEY') ? true : false;
		this.registerUserConfig();
	}

	toggleMobOption() {
		this.menuClass = !this.menuClass;
		this.collapseStyle = (this.menuClass) ? 'block' : 'none';
	}

	navCollapse() {
		if (this.windowWidth >= 992) {
			this.onNavCollapse.emit();
		} else {
			this.onNavHeaderMobCollapse.emit();
		}
	}

	navigate() {
		const userId = localStorage.getItem('USER_ID');
		let url = '/dashboard/analytics';
		if (userId && userId === '7') {
			url = '/supplychainmanagement/dashboard';
		}
		this.router.navigate([url]);
	}

	registerTimeoutHandler(waitingPeriod) {
		this.timeoutServiceSub$ = this.idleMonitoringService.setIdleTimer().subscribe(timedOut => {
			this.logoutTimer && this.logoutTimer.unsubscribe();
			if (timedOut) {
				this.idleMonitoringService.stopTimer();
				this.timeoutModal.show();
				this.waitingPeriodInSeconds = waitingPeriod;
				this.logoutTimer = timer(1000, 1000).pipe(
					takeWhile(() => waitingPeriod > 0),
					tap(() => waitingPeriod--)
				).subscribe(() => {
					if (waitingPeriod === 0) {
						this.authSer.logout();
						window.location.reload();
					}
					this.waitingPeriodInSeconds = waitingPeriod;
				});
			}
		});
	}

	continueSession() {
		this.logoutTimer.unsubscribe();
		this.timeoutModal.hide();
		const userConfig: any = JSON.parse(localStorage.getItem('USER_ACTIVITY_CONFIG')).find(res => res.code === 'SESSION_TIMEOUT_TIME_POP_COUNTER');;
		const popupCounterInSeconds = (parseInt(userConfig.value) * 60);
		this.registerTimeoutHandler(popupCounterInSeconds);
	}

	closeSession() {
		this.authSer.logout();
		window.location.reload();
	}

	ngOnDestroy() {
		if (this.logoutTimer && this.logoutTimer.unsubscribe) {
			this.logoutTimer.unsubscribe();
		}
		this.timeoutServiceSub$ && this.timeoutServiceSub$.unsubscribe();
	}

}
