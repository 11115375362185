import { Injectable } from '@angular/core';
// import swal, { SweetAlertOptions} from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  errortitle: any;
  successtitle: any;
  infotitle: any;
  warningtitle: any;

  constructor(private toaster: ToastrService,
    private translate:TranslateService) {
      this.translate.stream('wholesale.common.error').subscribe((data: any) => {
        this.errortitle = data
      });
      this.translate.stream('wholesale.common.success').subscribe((data: any) => {
        this.successtitle = data
      });
      this.translate.stream('wholesale.common.info').subscribe((data: any) => {
        this.infotitle = data
      });
      this.translate.stream('wholesale.common.warning').subscribe((data: any) => {
        this.warningtitle = data
      });
     }

  msg( toasterType, message, title, isNotification?) {
    const inst = this.toaster;
    switch (toasterType) {
      case 'success':
        if(isNotification) {
          inst.success(title, message);
        } else {
          inst.success(message, title,{  enableHtml: true });
        }        
        break;
      case 'error':
        inst.error(message, title);
        break;
      case 'info':
        inst.info(message, title);
        break;
      case 'warning':
        inst.warning(message, title);
        break;
      default:
        inst.info(message, title);
        break;
    }
    // inst.success(message, title);
  }

  showSuccess(message, title = this.successtitle, isNotification?) {
    this.msg('success', message, title, isNotification);
  }

  showError(message, title = this.errortitle) {
    this.msg('error', message, title);
  }

  showInfo(message, title = this.infotitle) {
    this.msg('info', message, title);
  }

  showWarning(message, title = this.warningtitle) {
    this.msg('warning', message, title);
  }
  showAlert(text, type, outsideClick: boolean = false) {
    return Swal.fire({
        icon: type,
        title: text,
        allowOutsideClick: outsideClick
    });
}
  // showAlert(alertText, alertType, outsideClick: boolean = true, timer?: number) {
  //   const swalOptions: SweetAlertOptions = {
  //     text: alertText,
  //      icon: alertType,
  //     allowOutsideClick: outsideClick,
  //     showConfirmButton: false
  //   };
  //   if (timer) {
  //     swalOptions.timer = timer;
  //   }
  //   swalOptions.timer = 2000;
  //   return swal.fire(swalOptions);
  // }

}
