<h5 class="m-b-10 ng-star-inserted">
    Stock In OMD
    <button type="submit" class="btn btn-success pull-right custom_add_button"
    routerLink="/{{url}}/omd/omd-stock-in/add" *ngIf="isCustomer">
        <i class="feather icon-plus mr-2"></i>
        Stock In OMD
    </button>
</h5>
<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"
            routerLinkActive="router-link-active"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'">
        <a>{{'otProductionPlan.list.industrialDistillery' |
            translate}}</a>
    </li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item" routerLink="/{{url}}/omd/omd-stock-in/list"><a href="javascript:">Stock In OMD</a></li>
    <li class="breadcrumb-item">List</li>
  </ul>
  <div class="row">
  <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg4 animate__animated animate__backInUp">
    <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="changeStatus('normal');getStockInOmd('APPROVED')">
      <div class="row align-items-center">
        <div class="col">
          <span>{{ 'brewery.brandlabelreglist.brandapproved' | translate }}</span>
          <h3 class="mt-2 strong">{{dataCount?.totalApprovedApplications}}</h3>
          <i class="fas fa-thumbs-up"></i>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg1 animate__animated animate__backInUp" *ngIf="isCustomer">
    <app-card [hidHeader]="true" cardClass="comp-card linkClass"
      (click)="changeStatus('current');getStockInOmd('IN_PROGRESS')">
      <div class="row align-items-center">
        <div class="col">
          <span>{{ 'brewery.brandlabelreglist.brandreqpending' | translate }}</span>
          <h3 class="mt-2 strong">{{dataCount?.totalPendingApplications}}</h3>
          <i class="fas fa-hourglass-half"></i>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg1 animate__animated animate__backInUp" *ngIf="!isCustomer">
    <app-card [hidHeader]="true" cardClass="comp-card linkClass"
      (click)="changeStatus('current');getStockInOmd('IN_PROGRESS')">
      <div class="row align-items-center">
        <div class="col">
          <span>{{ 'brewery.brandlabelreglist.brandreqpending' | translate }} (Current
            User)</span>
          <h3 class="mt-2 strong">{{dataCount?.totalPendingApplicationsCurrentOfficer}}</h3>
          <i class="fas fa-hourglass-half"></i>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg5 animate__animated animate__backInUp" *ngIf="!isCustomer">
    <app-card [hidHeader]="true" cardClass="comp-card linkClass"
      (click)="changeStatus('Officer');getStockInOmd('IN_PROGRESS')">
      <div class="row align-items-center">
        <div class="col">
          <span style="position: absolute;width: 100%;">{{ 'brewery.brandlabelreglist.brandreqpending' | translate }}
            (All Other Officer)</span><br>
          <h3 class="mt-2 strong">{{dataCount?.totalPendingApplicationsOtherOfficer}}</h3>
          <i class="fas fa-hourglass-half"></i>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg3 animate__animated animate__backInUp">
    <app-card [hidHeader]="true" cardClass="comp-card bg-danger linkClass"
      (click)="changeStatus('normal');getStockInOmd('REJECTED')">
      <div class="row align-items-center">
        <div class="col">
          <span>{{ 'brewery.brandlabelreglist.brandreqrejected' | translate }}</span>
          <h3 class="mt-2 strong">{{dataCount?.totalRejectedApplications}}</h3>
          <i class="fas fa-thumbs-down text-danger"></i>
        </div>
      </div>
    </app-card>
  </div>
</div>
<div class="row">
    <div class="col-xl-12">
        <app-card cardTitle="Search" class="search-panel">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Application No</label>
                        <input type="text" class="form-control" placeholder="Enter Application No" [(ngModel)]="accidentalForOmd['applicationNumber']">
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>Date Of Application</label>
                        <div class="row" style="margin-left: auto; align-items: center;">
                            <input type="text" [(ngModel)]="accidentalForOmd['date']" class="form-control" [ngModelOptions]="{standalone: true}"
                                [owlDateTimeTrigger]="dtPicker1" [owlDateTime]="dtPicker1"
                                style="width: 95%;" id="date" name="date" #date="ngModel"
                                (keydown)="$event.preventDefault()" placeholder="DD-MM-YYYY">
                            <i class="trigger" [owlDateTimeTrigger]="dtPicker1"
                                style="margin-left: -25px;" class="fas fa-calendar-alt prefix"></i>
                            <owl-date-time #dtPicker1 [pickerType]="'calendar'"></owl-date-time>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>OMD Name</label>
                        <input type="text" class="form-control" placeholder="Enter the OMD Name" [(ngModel)]="accidentalForOmd['distilleryName']">
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="form-group">
                        <label>{{'master.common.status' | translate}}</label>
                        <select class="form-control" [(ngModel)]="accidentalForOmd['status']">
                            <option value="">Select</option>
                            <option value="{{status}}" *ngFor="let status of statusList">{{status}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <div>
                        <button type="submit" class="btn btn-primary mr-2" (click)="searchConData()">Search</button>
                        <button type="submit" class="btn btn-secondary" (click)="resetKey()">Cancel</button>
                    </div>
                </div>
            </div>
        </app-card>
    </div>
</div>

<div class="row">
    <div class="col-xl-12">
        <app-card cardTitle="Stock In OMD List" blockClass="table-border-style" class="list-panel">
            <div class="table-responsive mb-2">
                <app-mat-normal-table [columns]="columns" [actionBtnBasedOnCond]="actionBtnBasedOnCond"
                    [actionKeys]="actionKeys" [dataSource]="dataSource" [pageSize]="pageSize" [totalCount]="totalCount"
                    (paginationEvent)="handlePagination($event)" (editAction)="onEdit($event)"
                    (viewAction)="onView($event)">
                </app-mat-normal-table>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div ngbDropdown>
                        <button class="btn btn-success" ngbDropdownToggle type="button">
                            {{ 'language.download' | translate }}</button>
                        <div ngbDropdownMenu>
                            <a class="dropdown-item" (click)="ExportTOExcel()" href="javascript:">
                                {{ 'language.excel' | translate }}</a>
                            <a class="dropdown-item" (click)="generatePdf()" href="javascript:">
                                {{ 'language.pdf' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>
    </div>
</div>

