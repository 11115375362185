import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class BrandlabelregService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  getdetails(): Observable<any> {
    const url = this.licenseURL + 'license/category/WHOLESALE%2CWAREHOUSE%2CDISTILLERY';
    return this.http.get(url);
  }
  addetails(data): Observable<any> {
    const url = this.baseURL + 'brandLabel';
    return this.http.post(url, data);
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brandLabel?id=' + id;
    return this.http.get(url);
  }
  workflow(postParam): Observable<any> {
    const url = this.baseURL + 'brandLabel/updateBrandLabelRequestStatusForWorkFLow';
    return this.http.post(url, postParam);
  }
  brandLabelExisting(param?): Observable<any> {
    let url = this.baseURL + 'brandLabel/existing';
    url += param ? '?licenseType=' + param : '';
    return this.http.get(url);
  }
  getbrandlabelcount(): Observable<any> {
    const url = this.baseURL + 'brandLabel/getBrandLabelStatusCount';
    return this.http.get(url);
  }
  getBrands() {
    const url = this.devisionUrl + "brand/getAllBrand";
    return this.http.get(url)
  }
  getSearchDropdowns(paramData) {
    const url = this.baseURL + "brandLabel/getDropDown?type=" + paramData;
    return this.http.get(url)
  }
  labelfeepayment(postParam) {
    const url = this.baseURL + "brandLabel/saveUpdatePaymentDetails"
    return this.http.post(url, postParam);
  }
  addLabelDetails(data): Observable<any> {
    const url = this.baseURL + "brandLabel/overview/labelDetails"
    return this.http.post(url, data);
  }
  searchOverView(payload): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/search';
    return this.http.post(url, payload);
  }
  getbyId(id): Observable<any> {
    const url = this.baseURL + 'brandLabel/overview/' + id;
    return this.http.get(url);
  }
  getDropDowns(type) {
    let url = this.baseURL + apiUrls.getDropDown + '?type=' + type;
    url += '&status=' + 'APPROVED';
    return this.http.get(url);
  }
  getApprovedLicenseTypes(licensetype) {
    let url = "http://65.0.150.210:3519/parentunit/brandLabel/approved/licenseType/" + licensetype;
    return this.http.get(url);

  }
  getdistinctbrandandproduct(licensetype): Observable<any> {
    const url = this.baseURL + 'brandLabel/existing?licenseType=' + licensetype;
    return this.http.get(url);
  }
}

