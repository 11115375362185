import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class Mf9Service {
  baseURL = environment.parentUnit;
  baseURLMaster = environment.baseURLMaster;
  constructor(private http: HttpClient) { }
  getAllMf9(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllMf9;
    return this.http.get(url);
  }
  getMf9ById(id): Observable<any> {
    const url = this.baseURL + apiUrls.mf9GetById + id;
    return this.http.get(url);
  }
  getMf9FortnightById(id): Observable<any> {
    const url = this.baseURL + apiUrls.mf9FortnightById + id;
    return this.http.get(url);
  }
  addMf9(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addMf9;
    return this.http.post(url, postdata);
  }
  addMf9FortNight(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addMf9FortNight;
    return this.http.post(url, postdata);
  }
  updateMf9(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.updateMf9;
    return this.http.put(url, postdata);
  }
  searchMf9(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mf9Search;
    return this.http.post(url, postdata);
  }
  searchMf9Fortnight(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.fortnightSearch;
    return this.http.post(url, postdata);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }

  getmf9details(): Observable<any> {
    const url = this.baseURL + apiUrls.getmf9details;
    return this.http.get(url);
  }

  getmf9Forecastdetails(): Observable<any> {
    const url = this.baseURL + apiUrls.getmf9Forecasedetails;
    return this.http.get(url);
  }

  gettanktypes(pid, gid?: any) {
    let ids;
    if (gid) {
      ids = `?productTypeId=${pid}&gradeId=${gid}`
    } else {
      ids = `?productTypeId=${pid}`
    }
    const url = this.baseURL + `${apiUrls.gettankbyproducttype}${ids}`;
    return this.http.get(url);
  }

  getFinancialYearList(): Observable<any> {
    // const url = "https://devapigateway.upexciseonline.co/master/v1.0.0/api/masterValues/findDropDownMasterOnMaster?dropDownKey=YEAR_OF_REGISTRATION";
   //const url = "http://3.109.7.107:3503/masterData/api/masterValues/findDropDownMaster?dropDownKey=YEAR_OF_REGISTRATION";
    const url = this.baseURLMaster + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=YEAR_OF_REGISTRATION';
    return this.http.get(url)
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, data);
  }
  getPreviousYearData(year): Observable<any> {
    const url = this.baseURL + apiUrls.getPreviousYearData;
    return this.http.post(url, year);
  }
  getPreviousFortnightData(year): Observable<any> {
    const url = this.baseURL + apiUrls.getPreviousFortnightData;
    return this.http.post(url, year);
  }
  getMF9RemainingQuantity(year): Observable<any> {
    const url = this.baseURL + apiUrls.getMF9RemainQtl + year;
    return this.http.get(url, year);
  }
  getUnitankList(data): Observable<any> {
    const url = this.baseURL + 'usageTank/dropDown';
    return this.http.post(url, data);
  }
  getTankList(data): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  getMolassesYearDetail(): Observable<any> {
    const url = this.baseURL + 'utility/molasses/forecastDetails';
    return this.http.get(url)
  }

  getMF9ProductionForecastCommonConfig(): Observable<any> {
    const url = this.baseURL + 'mf9productionforecast/getMF9ProductionForecastCommonConfig?entityType=SUGAR_MILL';
    return this.http.get(url);
  }
  getMF5StockIn(): Observable<any> {
    const url = this.baseURL + 'mf5StockIn/getMF5StockInConfig?entityType=SUGAR_MILL';
    return this.http.get(url);
  }
  getMF9FortNightCommonConfig(): Observable<any>{
    const url = this.baseURL + 'mf9fortnight/getMF9FortNightCommonConfig?entityType=SUGAR_MILL';
    return this.http.get(url);
  }
  getMf9FortnightStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'mf9fortnight/getMf9FortnightStatusCount?entityType=' + entityType;
    // const url = 'http://localhost:3501/scmProduction/mf9productionforecast/getMf9ProductionStatusCount?entityType=SUGAR_MILL';
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getstatuscount(payload):Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount';
    return this.http.post(url,payload);
  }
}
