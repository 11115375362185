
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AlertService, DistrictMasterService, TalukMasterService, StatemasterService,
  ValidationService, MunicipalityMasterService, TransportGenService, SugarmillService, PreviewService, WorkflowcofigService,
  DocumentUploadService,
  AuthService
} from '@app/services';
import { AccidentRequestService } from '@app/services/sugarmill/accident-request.service';
import { LicenseConstants } from '@app/containers/licencemgnt/license.constants';
import { GatepassCancellationService } from '@app/services/sugarmill/gatepass-cancellation.service';
import * as moment from 'moment';
import { PinCodeMasterService } from '@app/services/locationmaster';
import { LicenseCommonService } from '@appLicMgntShared/license-common.service';
import { LocalUrl } from '@app/app-local-url';
import { Mf4ReceivedService, PdCommonService } from '@app/services/pd';
import { RequestForEthanolOrderForOmdService } from '@app/services/pd/request-for-ethanol-order-for-omd.service';
import * as fileSaver from 'file-saver';

const {
  docuPropObj, FF_APPLI, FF_ADDRESS, FF_ATTACHMENTS, FF_BANK, FF_BUSS_UNIT,
  SEC_ADDR_APPLICANT, SEC_ADDR_PREMISES, APPLI_CODE_WHOLESALE,
  PROP_APPLI_DET, PROP_ADDRESS_DET, PROP_BANK_DET, PROP_LICENSE_WL, PROP_ATTACHMENT_WL, PROP_BUSSIN_UNIT, PROP_SCHEDULE_WL,
  CODE_SALUTATION, CODE_GENDER, CODE_LOCALITYTYPE, CODE_BANK, CODE_ENTITYOFFIRM, CODE_ITR, CODE_OWNERSHIP_TYPE,
  WHOLESALE_LICENSE_CATEGORY, WHOLESALE_LICENSE_CATEGORY_DESC, WHOLESALE_FL2_SUBCATEGORY,
  WHOLESALE_FL2B_SUBCATEGORY, WHOLESALE_CL2_SUBCATEGORY, WHOLESALE_FL2A_SUBCATEGORY, WHOLESALE_ID3_SUBCATEGORY,
  STATUS_DRAFT, STATUS_SAVE, STATUS_SUBMITTED,
  LOCALITY_URBAN, LOCALITY_RURAL, UP_STATE_CODE
} = LicenseConstants;

@Component({
  selector: 'app-addaccidentalrequest',
  templateUrl: './addaccidentalrequest.component.html',
  styleUrls: ['./addaccidentalrequest.component.scss']
})
export class AddaccidentalrequestComponent implements OnInit {
  accidentType: any = "";
  selectOption = "";
  editId: any;
  addedit: any;
  formSubmitted = false;
  screenSubType: string;

  sdate: any;
  eDate: any;
  thisYrSdate: any;
  thisYrEdate: any;
  isTransport: boolean = false;
  isPaymentEnable: boolean = true;
  licConsts = LicenseConstants;

  public startdate = moment();
  public maxDate = moment(this.startdate).add(72, 'days').format('YYYY-MM-DD');
  public minDate = moment(this.startdate).subtract(6, 'months').format('YYYY-MM-DD');
  public maxAccidentDate = moment(this.startdate).add(0, 'days').format('YYYY-MM-DD');
  public minAccidentDate = moment(this.startdate).subtract(3, 'months').format('YYYY-MM-DD');

  public maxSDate: any;

  default: string = 'productAndVehicleBothDamagedDetails';


  /* accident address param */
  stateList: any;
  districtList: any;
  countryList: any;
  districtListByState = {
    [SEC_ADDR_APPLICANT]: [],
    [SEC_ADDR_PREMISES]: []
  };
  // talukList = {
  //   [SEC_ADDR_APPLICANT]: [],
  //   [SEC_ADDR_PREMISES]: []
  // };
  pinCodeList = {
    [SEC_ADDR_APPLICANT]: [],
    [SEC_ADDR_PREMISES]: []
  };

  talukList: any[] = []
  municipalityList: any;
  accidentFormGroup: FormGroup;

  //accident request details param
  currDate = new Date();
  mf4PassNoList: any;
  mf4PassNoDetail: any;
  currentseason: any;
  licensedetail: any;
  fiscalyear: any;
  licenseDetailList: any[] = [];
  bottlingUnitAddress: any = {
    address: ''
  };

  // license details param
  licenseNum: any;
  licenseType: any;
  licensenodata: any;
  actualLicensedetail: any;
  myDate: any = new Date();
  editdata: any = {};
  isDraftLoading: boolean;
  isSubmitLoading: boolean;
  workflowdata: any;
  transportPasstypeList: any = [];

  showAppliPinInput: any;
  showAddrPinInput: boolean = true;
  showAppliTehsilInput: boolean = false;
  showAddrTehsilInput: boolean = false;

  premisesAddrLocality = '';
  isDrafthide = true;
  isSendback: boolean;
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any;
  // options = ['vehicledamagedbutproductgood','productdamagedbutvehiclegood','bothdamaged']
  // selectData = new FormControl('vehicledamagedbutproductgood');
  // // optionSelected: string;

  // public accidentTypeList = ["Vehicle damaged but product good", "Product damaged but vehicle good", "Both damaged"];
  public accidentTypeList = [
    { code: 'VEHICLE_DAMAGED_BUT_PRODUCT_GOOD', value: "Vehicle damaged but product good" },
    { code: 'PRODUCT_DAMAGED_BUT_VEHICLE_GOOD', value: "Product damaged but vehicle good" },
    { code: 'BOTH_DAMAGED', value: "Both damaged" }
    ];

  transVehicledata: any;
  routeDetails: any = [];
  bottlingdetaildetails: any = [];
  Obj: any = {
    requestDate: '',
    applicationNumber:'',
    requestId: '',
    gatePassNo: '',
    accidentType: '',
    status:''
  };
  mf4Details: any;
  tankDetails: any;
  paymentData: any;
  isCustomer: boolean;
  designation: string;
  sugarMillTypes: any;
  outsideupvalidation: string;
  constructor(
    private stateSer: StatemasterService,
    private districtSer: DistrictMasterService,
    private talukSer: TalukMasterService,
    private municipSer: MunicipalityMasterService,
    private pinCodeSer: PinCodeMasterService,
    private alert: AlertService,
    private preview: PreviewService,
    private fb: FormBuilder,
    private validateSer: ValidationService,
    private transportGenService: TransportGenService,
    private brandlabelregService: SugarmillService,
    private accidentRqstService: AccidentRequestService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private worfFlowService: WorkflowcofigService,
    private gatepassCancellationService: GatepassCancellationService,
    private licenseCommonSer: LicenseCommonService,
    private pdCommonService: PdCommonService,
    private datepipe: DatePipe,
    private mf4ReceivedService: Mf4ReceivedService,
    private requestForEthanolOrderForOmdService: RequestForEthanolOrderForOmdService,
    private documentUploadService: DocumentUploadService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.outsideupvalidation = currentUrlArray[2]
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const subUrlManage = currentUrlArray[1] + currentUrlArray[2];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.minDate = this.startdate.subtract(6, "months").format('YYYY-MM-DD');
    this.getGatePasstype();
    this.bottlingdetaildetail();
    this.getSugarMillType();
    this.route.params.subscribe(params => {
      console.log(params, '...params');
      if (params.Id) {
        this.editId = params.Id;
        this.setData(this.editId);
      } else if (this.preview.setData) {
        this.accidentFormGroup.patchValue(this.preview.setData);
      }
    });
    this.addedit = this.router.url.includes('add') == true ? 'Add' : 'Edit';
    this.getMolassesYearDetail();
    this.accidentFormGroup = this.fb.group({
      licenseType: ['', this.validateSer.valiReqOnly],
      licenseNo: ['', this.validateSer.valiReqOnly],
      licenseDetailsResponse: [''],
      requestDate: [''],
      applicationDate: [''],
      accidentDate: ['', this.validateSer.valiReqOnly],
      mf4GatePassNo: ['', this.validateSer.valiReqOnly],
      accidentalType: ['', this.validateSer.valiReqOnly],
      transportPassTypeCode: ['', this.validateSer.valiReqOnly],
      sugarMillName:['', this.validateSer.valiReqOnly],
      distilleryName:['', this.validateSer.valiReqOnly],
      quantity:['', this.validateSer.valiReqOnly],
      grade:[''],
      accidentalTypeDistrictCode:[''],
      // accidentalTypeTehsilCode:['',this.validateSer.valiReqOnly],
      pinCode:[''],
      FineImposed:['',this.validateSer.valiReqOnly],
      fineType:['',this.validateSer.valiReqOnly],
      fees:['',Validators.required],
      penaltyRemark:['',Validators.required],
      deoUploadAccidentalReport:[''],
      deoUploadAccidentalReportFile:[''],
      uploadAccidentalTanker:[''],
      uploadAccidentalTankerFile:[''],
      eWayBill:[''],
      eWayBillFile:[''],
      taxInvoice:[''],
      taxInvoiceFile:[''],
      firCopy:[''],
      firCopyFile:[''],
      molassesWastage:[''],
      // accident address group
      addressList: this.fb.group({
        country: [{ value: 'INDIA', disabled: true }],
        pinCode: ['', this.validateSer.valiReqOnly],
        accidentalTypeStateCode: ['', this.validateSer.valiReqOnly],
        accidentalTypeStateValue: [""],
        accidentalTypeDistrictCode: ['', this.validateSer.valiReqOnly],
        accidentalTypeDistrictValue: [""],
        accidentalTypeTehsilCode: ['',this.validateSer.valiReqOnly],
        accidentalTypeTehsilValue: ['', this.validateSer.valiReqOnly],
        localityType: ['', this.validateSer.valiReqOnly],
        localityTypeDesc: [''],
        municipalityName: ['',Validators.required],
        ward: ["",Validators.required],
        block: ['',Validators.required],
        village: ['',Validators.required],
        policeStation: ['', this.validateSer.valiReqOnly],
        policeStationCode: [''],
        policeStationDesc: [''],
        locality: ['', this.validateSer.valiReqOnly],
        street: ['', this.validateSer.valiReqOnly],
      }),
      productAndVehicleBothDamagedDetails: this.fb.group({
        accidentalRequest: {},
        createdBy: 0,
        createdDate: "2022-05-29T14:20:36.663Z",
        gpsId: "",
        id: "",
        modifiedBy: 0,
        modifiedDate: "2022-05-29T14:20:36.663Z",
        routeDetails: "",
        transporterName: "",
        vehicleDriverName: "",
        vehicleNo: "",
        wantToSendBack: "",        
      modeOfTransport:"",
      startReading:"",
      endReading:""
      }),
      productDamagedButVehicleGoodDetails: this.fb.group({
        accidentalRequest: {},
        createdBy: 0,
        createdDate: "2022-05-29T14:20:36.663Z",
        damagedQuantityOfMolasses: "",
        id: "",
        modifiedBy: 0,
        modifiedDate: "2022-05-29T14:20:36.663Z",
        totalQuantityOfMolasses: ""
      }),
      vehicleDamagedButProductGoodDetails: this.fb.group({
        accidentalRequest: {},
        changeTheVehicleDetails: "",
        createdBy: 0,
        createdDate: "2022-05-29T14:20:36.663Z",
        extendTransportPassValidity: "",
        gpsId: "",
        id: "",
        majorRoute: "",
        modifiedBy: 0,
        modifiedDate: "2022-05-29T14:20:36.663Z",
        remainingKms: "",
        routeDetails: "",
        routeType: "",
        transporterName: "",
        vehicleDriverName: "",
        vehicleNo: "",
        validUpTo: new Date(),
        modeOfTransport:"",
        startReading:"",
        endReading:""
      }),
    })
    this.selectOption = "Select";
    this.loadFormControl();
    this.accidentFormGroup.controls.requestDate.setValue(this.datePipe.transform(this.currDate, 'dd-MM-yyyy HH:mm:ss'));
    this.accidentFormGroup.controls.applicationDate.setValue(this.datePipe.transform(this.currDate, 'dd-MM-yyyy HH:mm:ss'));
    // this.accidentFormGroup.controls.accidentDate.setValue(this.datePipe.transform(this.currDate, 'dd-MM-yyyy HH:mm:ss'));
  }

  setData(id?) {
    if (id) {
      this.accidentRqstService.getAccidentRqstById(id).subscribe((responseData: any) => {
        
        setTimeout(() => {
          this.getlicensenumber(this.accidentFormGroup.value.licenseType);
          this.scanNumber();
          this.changeAccidentType(this.accidentFormGroup.value.accidentalType);
          this.onLocalityChange(this.editdata.localityType,this.licConsts.SEC_ADDR_APPLICANT)
        }, 500);
        if (responseData.status === 's') {
          this.editdata = responseData.data;
          if(this.editdata.urbanOrRuralValue== 'Urban'){
            this.accidentFormGroup.controls['addressList'].get('block').clearValidators();
            this.accidentFormGroup.controls['addressList'].get('block').updateValueAndValidity();
            this.accidentFormGroup.controls['addressList'].get('village').clearValidators();
            this.accidentFormGroup.controls['addressList'].get('village').updateValueAndValidity();
            this.accidentFormGroup.controls['addressList'].get('municipalityName').setValidators(Validators.required);
            this.accidentFormGroup.controls['addressList'].get('municipalityName').updateValueAndValidity();
            this.accidentFormGroup.controls['addressList'].get('ward').setValidators(Validators.required);
            this.accidentFormGroup.controls['addressList'].get('ward').updateValueAndValidity();
          }else if(this.editdata.urbanOrRuralValue == 'Rural'){
            this.accidentFormGroup.controls['addressList'].get('block').setValidators(Validators.required);
            this.accidentFormGroup.controls['addressList'].get('block').updateValueAndValidity();
            this.accidentFormGroup.controls['addressList'].get('village').setValidators(Validators.required);
            this.accidentFormGroup.controls['addressList'].get('village').updateValueAndValidity();
            this.accidentFormGroup.controls['addressList'].get('municipalityName').clearValidators();
            this.accidentFormGroup.controls['addressList'].get('municipalityName').updateValueAndValidity();
            this.accidentFormGroup.controls['addressList'].get('ward').clearValidators();
            this.accidentFormGroup.controls['addressList'].get('ward').updateValueAndValidity();
          }
          
          if( (this.editdata.productAndVehicleBothDamagedDetails!= null && this.editdata.productAndVehicleBothDamagedDetails.modeOfTransport=='Pipe Line')
          || (this.editdata.vehicleDamagedButProductGoodDetails!= null && this.editdata.vehicleDamagedButProductGoodDetails.modeOfTransport=='Pipe Line' )){
            this.isTransport = true;
          }else{
            this.isTransport = false;
          }
          if (this.editdata.fineImposed == true) {
            this.accidentFormGroup.get('fineType').setValidators(this.validateSer.valiReqOnly);
            this.accidentFormGroup.get('fineType').updateValueAndValidity();
            this.accidentFormGroup.get('fees').setValidators(this.validateSer.valiReqOnly);
            this.accidentFormGroup.get('fees').updateValueAndValidity();
            this.isPaymentEnable = true;
          } else if (this.editdata.fineImposed  == false) {
            this.accidentFormGroup.get('fineType').clearValidators();
            this.accidentFormGroup.get('fineType').updateValueAndValidity();
            this.accidentFormGroup.get('fees').clearValidators();
            this.accidentFormGroup.get('fees').updateValueAndValidity();
            this.isPaymentEnable = false;
          }
          if (this.editdata.status == 'SENDBACK') {
            // this.mstIstForm.controls['comments'].setValidators(Validators.required);
            this.isSendback = true;
            this.isDrafthide = false;
          }
          setTimeout(() => {
            this.findTalukaByDistrictCode(this.editdata.accidentalTypeDistrictCode,this.licConsts.SEC_ADDR_APPLICANT)
            this.onStateChange(this.editdata.accidentalTypeStateCode, this.licConsts.SEC_ADDR_APPLICANT);
            this.onDistrictChange(this.editdata.accidentalTypeDistrictCode, this.licConsts.SEC_ADDR_APPLICANT);
            this.onTalukChange(this.editdata.tehsilCode, this.licConsts.SEC_ADDR_APPLICANT);
            this.getLicenseaddress(this.editdata.licenseNumber)
            this.accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].patchValue({
              gpsId: this.editdata.productAndVehicleBothDamagedDetails.gpsId,
              routeDetails: this.editdata.productAndVehicleBothDamagedDetails.routeDetails,
              transporterName: this.editdata.productAndVehicleBothDamagedDetails.transporterName,
              vehicleDriverName: this.editdata.productAndVehicleBothDamagedDetails.vehicleDriverName,
              vehicleNo: this.editdata.productAndVehicleBothDamagedDetails.vehicleNo,
              wantToSendBack: this.editdata.productAndVehicleBothDamagedDetails.wantToSendBack,
              modeOfTransport:this.editdata.productAndVehicleBothDamagedDetails.modeOfTransport,
              startReading:this.editdata.productAndVehicleBothDamagedDetails.startReading,
              endReading:this.editdata.productAndVehicleBothDamagedDetails.endReading
            })
          }, 1000);
          setTimeout(()=>{
            this.accidentFormGroup.controls['productDamagedButVehicleGoodDetails'].patchValue({
              totalQuantityOfMolasses: this.editdata.productDamagedButVehicleGoodDetails.totalQuantityOfMolasses
            })
          },1500)
          setTimeout(() => {
            this.accidentFormGroup.controls['addressList'].patchValue({
              accidentalTypeDistrictCode: this.editdata.accidentalTypeDistrictCode,
              districtValue: this.editdata.districtValue,
              accidentalTypeTehsilCode: this.editdata.accidentalTypeTehsilCode,
              accidentalTypeTehsilValue:this.editdata.accidentalTypeTehsilValue,
              pinCode: this.editdata.pinCode,
            });
            this.accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].patchValue({
              gpsId: this.editdata.productAndVehicleBothDamagedDetails.gpsId,
              routeDetails: this.editdata.productAndVehicleBothDamagedDetails.routeDetails,
              transporterName: this.editdata.productAndVehicleBothDamagedDetails.transporterName,
              vehicleDriverName: this.editdata.productAndVehicleBothDamagedDetails.vehicleDriverName,
              vehicleNo: this.editdata.productAndVehicleBothDamagedDetails.vehicleNo,
              wantToSendBack: this.editdata.productAndVehicleBothDamagedDetails.wantToSendBack,
              modeOfTransport:this.editdata.productAndVehicleBothDamagedDetails.modeOfTransport,
              startReading:this.editdata.productAndVehicleBothDamagedDetails.startReading,
              endReading:this.editdata.productAndVehicleBothDamagedDetails.endReading
            });
            this.accidentFormGroup.controls['productDamagedButVehicleGoodDetails'].patchValue({
              accidentalRequest: {},
              createdBy: this.editdata.productDamagedButVehicleGoodDetails.createdBy,
              createdDate: this.editdata.productDamagedButVehicleGoodDetails.createdDate,
              damagedQuantityOfMolasses: this.editdata.productDamagedButVehicleGoodDetails.damagedQuantityOfMolasses,
              id: this.editdata.productDamagedButVehicleGoodDetails.id,
              modifiedBy: this.editdata.productDamagedButVehicleGoodDetails.modifiedBy,
              modifiedDate: this.editdata.productDamagedButVehicleGoodDetails.modifiedDate,
              totalQuantityOfMolasses: this.editdata.productDamagedButVehicleGoodDetails.totalQuantityOfMolasses
            });
          }, 1500);
          setTimeout(()=>{
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].patchValue({
              accidentalRequest: {},
              // createdDate: this.editdata. vehicleDamagedButProductGoodDetails.createdDate,
              changeTheVehicleDetails:this.editdata.vehicleDamagedButProductGoodDetails.changeTheVehicleDetails,
              gpsId: this.editdata.vehicleDamagedButProductGoodDetails.gpsId,
              id: this.editdata.vehicleDamagedButProductGoodDetails.id,
              modifiedBy: this.editdata.vehicleDamagedButProductGoodDetails.modifiedBy,
              majorRoute: this.editdata.vehicleDamagedButProductGoodDetails.majorRoute,
              routeType: this.editdata.vehicleDamagedButProductGoodDetails.routeType,
              modifiedDate: this.editdata.vehicleDamagedButProductGoodDetails.modifiedDate,
              remainingKms: this.editdata.vehicleDamagedButProductGoodDetails.remainingKms,
              routeDetails: this.editdata.vehicleDamagedButProductGoodDetails.routeDetails,
              transporterName: this.editdata.vehicleDamagedButProductGoodDetails.transporterName,
              vehicleDriverName: this.editdata.vehicleDamagedButProductGoodDetails.vehicleDriverName,
              vehicleNo: this.editdata.vehicleDamagedButProductGoodDetails.vehicleNo,
              extendTransportPassValidity:this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity,
              // extendTransportPassValidity: this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity ? new Date(this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity).toISOString().split("T")[0] : '',
              wantToSendBack: this.editdata.vehicleDamagedButProductGoodDetails.wantToSendBack,
              // validUpTo: this.editdata.vehicleDamagedButProductGoodDetails.validUpTo
              validUpTo:this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity ? new Date(this.editdata.vehicleDamagedButProductGoodDetails.validUpTo).toISOString().split("T")[0] : '',
              modeOfTransport:this.editdata.vehicleDamagedButProductGoodDetails.modeOfTransport,
              startReading:this.editdata.vehicleDamagedButProductGoodDetails.startReading,
              endReading:this.editdata.vehicleDamagedButProductGoodDetails.endReading
            })
          },2500)
          console.log(this.editdata, '...this.editdata...');
          // this.applicationNumber = responseData.data.ApplicationNumber;
          this.accidentFormGroup.patchValue({
            accidentDate: this.editdata.accidentDate,
            accidentalType: this.editdata.accidentalTypeCode,
            accidentalTypeValue: this.editdata.accidentalTypeValue,
            applicationDate: this.editdata.applicationDate,
            applicationNumber: this.editdata.applicationNumber,
            comments: this.editdata.comments,
            createdBy: this.editdata.createdBy,
            createdDate: this.editdata.createdDate,
            // districtCode: this.editdata.districtValue,
            // districtValue: this.editdata.districtValue,
            entityType: this.editdata.entityType,
            event: this.editdata.event,
            id: this.editdata.id,
            isDigitalSignature: this.editdata.isDigitalSignature,
            isEditable: this.editdata.isEditable,
            isShowButtons: this.editdata.isShowButtons,
            level: this.editdata.level,
            // licenseDetailsResponse: this.editdata.licenseDetailsResponse,
            licenseNo: this.editdata.licenseNumber,
            licenseType: this.editdata.licenseType,
            locality: this.editdata.locality,
            mf4GatePassNo: this.editdata.mf4GatePassNo,
            modifiedBy: this.editdata.modifiedBy,
            modifiedDate: this.editdata.modifiedDate,
            // productAndVehicleBothDamagedDetails: this.editdata.productAndVehicleBothDamagedDetails,
            // productDamagedButVehicleGoodDetails: this.editdata.productDamagedButVehicleGoodDetails,
            requestDate: this.editdata.requestDate,
            stage: this.editdata.stage,
            stageDesc: this.editdata.stageDesc,

            status: this.editdata.status,
            statusDesc: this.editdata.statusDesc,
            unitName: this.editdata.unitName,
            accidentalTypeDistrictCode: this.editdata.accidentalTypeDistrictCode,
            accidentalTypeDistrictValue: this.editdata.accidentalTypeDistrictValue,
            accidentalTypeTehsilCode: this.editdata.accidentalTypeTehsilCode,
            accidentalTypeTehsilValue:this.editdata.accidentalTypeTehsilValue,
              pinCode: this.editdata.pinCode,
            // vehicleDamagedButProductGoodDetails: this.editdata.vehicleDamagedButProductGoodDetails,
            addressList: {},
            productAndVehicleBothDamagedDetails: {},
            productDamagedButVehicleGoodDetails: {},
            vehicleDamagedButProductGoodDetails: {},
            fees:this.editdata.fees,
            fineImposed:this.editdata.fineImposed  == true? this.accidentFormGroup.get('FineImposed').setValue(true) : this.accidentFormGroup.get('FineImposed').setValue(false),
            fineType:this.editdata.fineType,
            penaltyRemark:this.editdata.penaltyRemark,
            molassesWastage: this.editdata.molassesWastage
          });

          this.accidentFormGroup.controls['addressList'].patchValue({
            pinCode: this.editdata.pinCode,
            accidentalTypeStateCode: this.editdata.accidentalTypeStateCode,
            stateDesc: this.editdata.stateDesc,
            accidentalTypeDistrictCode: this.editdata.accidentalTypeDistrictCode,
            accidentalTypeDistrictValue: this.editdata.accidentalTypeDistrictValue,
            accidentalTypeTehsilCode: this.editdata.accidentalTypeTehsilCode,
            accidentalTypeTehsilValue:this.editdata.accidentalTypeTehsilValue,
            localityType: this.editdata.urbanOrRuralValue,
            localityTypeDesc: this.editdata.urbanOrRuralValue,
            municipalityName: this.editdata.municipalAreaCode,
            ward: this.editdata.ward,
            block: this.editdata.block,
            village: this.editdata.village,
            policeStation: this.editdata.policeStation,
            // policeStationCode: this.editdata.policeStationCode,
            // policeStationDesc: this.editdata.policeStationDesc,
            locality: this.editdata.locality,
            street: this.editdata.street
          });
          console.log(this.accidentFormGroup.controls['addressList'])
          console.log(this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'], '...vehicle');
          // setTimeout(() => {
          this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].patchValue({
            accidentalRequest: {},
            // createdDate: this.editdata. vehicleDamagedButProductGoodDetails.createdDate,
            changeTheVehicleDetails:this.editdata.vehicleDamagedButProductGoodDetails.changeTheVehicleDetails,
            gpsId: this.editdata.vehicleDamagedButProductGoodDetails.gpsId,
            id: this.editdata.vehicleDamagedButProductGoodDetails.id,
            modifiedBy: this.editdata.vehicleDamagedButProductGoodDetails.modifiedBy,
            majorRoute: this.editdata.vehicleDamagedButProductGoodDetails.majorRoute,
            routeType: this.editdata.vehicleDamagedButProductGoodDetails.routeType,
            modifiedDate: this.editdata.vehicleDamagedButProductGoodDetails.modifiedDate,
            remainingKms: this.editdata.vehicleDamagedButProductGoodDetails.remainingKms,
            routeDetails: this.editdata.vehicleDamagedButProductGoodDetails.routeDetails,
            transporterName: this.editdata.vehicleDamagedButProductGoodDetails.transporterName,
            vehicleDriverName: this.editdata.vehicleDamagedButProductGoodDetails.vehicleDriverName,
            vehicleNo: this.editdata.vehicleDamagedButProductGoodDetails.vehicleNo,
            extendTransportPassValidity:this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity,
            // extendTransportPassValidity: this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity ? new Date(this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity).toISOString().split("T")[0] : '',
            wantToSendBack: this.editdata.vehicleDamagedButProductGoodDetails.wantToSendBack,
            // validUpTo: this.editdata.vehicleDamagedButProductGoodDetails.validUpTo
            validUpTo:this.editdata.vehicleDamagedButProductGoodDetails.extendTransportPassValidity ? new Date(this.editdata.vehicleDamagedButProductGoodDetails.validUpTo).toISOString().split("T")[0] : '',
          })
          console.log(this.accidentFormGroup.controls['productAndVehicleBothDamagedDetails'], '...this.accidentFormGroup.controls');
          this.accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].patchValue({
            accidentalRequest: {},
            createdBy: this.editdata.productAndVehicleBothDamagedDetails.createdBy,
            createdDate: this.editdata.productAndVehicleBothDamagedDetails.createdDate,
            gpsId: this.editdata.productAndVehicleBothDamagedDetails.gpsId,
            id: this.editdata.productAndVehicleBothDamagedDetails.id,
            modifiedBy: this.editdata.productAndVehicleBothDamagedDetails.modifiedBy,
            modifiedDate: this.editdata.productAndVehicleBothDamagedDetails.modifiedDate,
            routeDetails: this.editdata.productAndVehicleBothDamagedDetails.routeDetails,
            transporterName: this.editdata.productAndVehicleBothDamagedDetails.transporterName,
            vehicleDriverName: this.editdata.productAndVehicleBothDamagedDetails.vehicleDriverName,
            vehicleNo: this.editdata.productAndVehicleBothDamagedDetails.vehicleNo,
            wantToSendBack: this.editdata.productAndVehicleBothDamagedDetails.wantToSendBack
          })
          this.accidentFormGroup.controls['productDamagedButVehicleGoodDetails'].patchValue({
            accidentalRequest: {},
            createdBy: this.editdata.productDamagedButVehicleGoodDetails.createdBy,
            createdDate: this.editdata.productDamagedButVehicleGoodDetails.createdDate,
            damagedQuantityOfMolasses: this.editdata.productDamagedButVehicleGoodDetails.damagedQuantityOfMolasses,
            id: this.editdata.productDamagedButVehicleGoodDetails.id,
            modifiedBy: this.editdata.productDamagedButVehicleGoodDetails.modifiedBy,
            modifiedDate: this.editdata.productDamagedButVehicleGoodDetails.modifiedDate,
            totalQuantityOfMolasses: this.editdata.productDamagedButVehicleGoodDetails.totalQuantityOfMolasses
          })
          // }, 1000 );
        }
      }
      )
    }

  }
  applicationNo: any;
  getSugarMillType() {
    this.pdCommonService.getconsumptiontype('SUGARMILL_TYPE').subscribe((responceData: any) => { 
      if (responceData.status) {
        this.sugarMillTypes = responceData.data.downDropDownMasterValueList.sort(function (a, b) { return a.id - b.id }).filter(x=>x.paramValue != 'Within Up');
      }
    })
  }
  loadFormControl() {
    this.commonDatas();
  }
  commonDatas() {
    // this.getMf4PassNo();
    this.getAllStates();
    this.getapplicationNumber();
    // this.getAllDistricts();
    // this.getAllMunicipal();
  }
  getapplicationNumber() {
    if (!this.applicationNo) {
      this.transportGenService.getapplicationnumbers('SM_').subscribe((res: any) => {
        this.applicationNo = res.content.ApplicationNumber.replace(/\s+/g, '');
        console.log("applicationno", this.applicationNo);
      });
    }
  }

  getMolassesYearDetail() {
    this.transportGenService.getMolassesYearDetail().subscribe((resp: any) => {
      if (resp) {
        // console.log('season resp', resp);
        this.currentseason = resp.data.currentSeason;
        this.accidentFormGroup.patchValue({
          season: this.currentseason
        });
      }
    });
    this.getCurrentFinancialYear();
  }
  restrictSpecialChar(event) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }


  getCurrentFinancialYear() {
    this.fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      this.fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      this.fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    this.bottlingdetaildetail();
  }
  async bottlingdetaildetail() {
    let data = [];
    let responceData = await this.pdCommonService.getdetails();//.subscribe((responceData: any) => {
    this.licensedetail = responceData.content;
    if (responceData.content.length > 0) {

      responceData.content.forEach(element => {
        if (element.parentDetails != null) {
          this.bottlingdetaildetails.push(element)
        }
      });
      const uniqueLicenses = this.bottlingdetaildetails.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.licenseSubCategory === obj.licenseSubCategory)
      );
      this.bottlingdetaildetails = uniqueLicenses
    } else {
      this.bottlingdetaildetails = responceData.content;
      const uniqueLicenses = this.bottlingdetaildetails.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.licenseSubCategory === obj.licenseSubCategory)
      ); 
      this.bottlingdetaildetails=uniqueLicenses
    }
    if(this.bottlingdetaildetails.length==1){
      this.setsinglelicensetypevalue(this.bottlingdetaildetails)
     }

  }
  setsinglelicensetypevalue(array){
    console.log("array",array)
    this.accidentFormGroup.patchValue({
      licenseType:array[0].licenseSubCategory
    })
    this.getlicensenumber(array[0].licenseSubCategory)
    }
  getLicenseaddress(licensenumber) {
    if (licensenumber) {
      this.licenseNum = licensenumber;
      this.bottlingUnitAddress = this.bottlingdetaildetails.find(ele => ele.licenseNumber === licensenumber);
      this.accidentFormGroup.patchValue({
        licenseDetailsResponse: this.bottlingUnitAddress
      })
      this.bottlingUnitAddress.address = (this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : '');
    }
  }
  getlicensenumber(value) {
    if (value) {
      this.licenseType = value;
      this.licensenodata = [];
      if (this.bottlingdetaildetails) {
        this.bottlingdetaildetails.filter(ele => {
          if (ele.licenseSubCategory === value) {
            const Obj = {
              licenseno: ele.licenseNumber
            };
            this.licensenodata.push(Obj);
          }
          if( this.licensenodata.length==1){
            console.log()
            this.accidentFormGroup.patchValue({
              licenseNo: this.licensenodata[0].licenseno,
            });
            this.getLicenseaddress(this.licensenodata[0].licenseno)
          }
          console.log(this.licensenodata)
        });
      }
    }
  }
  changeAccidentType(selectedValue) {
    this.accidentType = selectedValue;
    console.log(this.mf4Details)
    this.accidentFormGroup.get('productAndVehicleBothDamagedDetails').patchValue({
      modeOfTransport: this.mf4Details.modeOfTransportValue,
      startReading: this.mf4Details.transportVehicleDetails.startReading,
      endReading:this.mf4Details.transportVehicleDetails.endReading,
      transporterName:this.mf4Details.transportVehicleDetails.vehicleAgencyName,
      vehicleNo:this.mf4Details.transportVehicleDetails.vehicleNumber,
      vehicleDriverName:this.mf4Details.transportVehicleDetails.driverName,
      routeDetails:this.mf4Details.transportVehicleDetails.mf4routeDetails[0] ?this.mf4Details.transportVehicleDetails.mf4routeDetails[0].majorRouteValue:"",
      routeType: this.mf4Details.transportVehicleDetails.routeValue,
    })
    this.accidentFormGroup.get('vehicleDamagedButProductGoodDetails').patchValue({
      modeOfTransport: this.mf4Details.modeOfTransportValue,
      startReading: this.mf4Details.transportVehicleDetails.startReading,
      endReading:this.mf4Details.transportVehicleDetails.endReading
    })
    if(this.accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].get('modeOfTransport').value=='Pipe Line'){
      this.isTransport = true;
    }else{
      this.isTransport = false;
    }
  }
  scanNumber() {
    if (this.accidentFormGroup.value.mf4GatePassNo) {
      const payload = {
        filters: {
          mf4GatePassGenerationDate: null,
          gatePassNo: this.accidentFormGroup.value.mf4GatePassNo,
          indentNo: null,
          status: null,
          isAdmin:this.isCustomer?'' : 'ADMIN'
        },
        pageNo: 0,
      paginationSize: 0
      };
      this.transportGenService.searchTransGen(payload).subscribe((responceData: any) => {
        this.mf4Details = responceData.data.contents[0]
        this.tankDetails = this.mf4Details.tankDetailList
        this.accidentFormGroup.patchValue({
          sugarMillName:this.mf4Details.sugarMillName,
          distilleryName:this.mf4Details.distilleryEntityNameValue,
          quantity:this.mf4Details.indentedQuantity,
          grade:this.mf4Details.grade
        })
        if (responceData.status === 's') {
          this.alert.showSuccess(responceData.userDisplayMesg, 'Success');

          this.transportPasstypeList.forEach(element => {
            this.accidentFormGroup.patchValue({
              transportPassTypeCode: element
            });
            this.accidentFormGroup.controls['transportPassTypeCode'].disable();
          });
        } else {
                this.alert.showError(responceData.userDisplayMesg, 'error');
                this.accidentFormGroup.controls['transportPassTypeCode'].enable();
              }
      });
    }
  }

  // getMf4PassNo() {
  //   this.accidentRqstService.getMf4getPassNo()
  //     .subscribe((resp: any) => {
  //       if (resp.errorCode === 200) {
  //         this.mf4PassNoList = resp.data;
  //       } else {
  //         this.alert.showError(resp.userDisplayMesg);
  //       }
  //     });
  // }

  clickChangeVehicle(val) {
    if (val === 'No') {
      if (this.accidentFormGroup.value.mf4GatePassNo) {
        const postData = {
          filters: {
            gatePassNo: this.accidentFormGroup.value.mf4GatePassNo
          },
          pageNo: 0,
          paginationSize: 10
        };
        this.transportGenService.searchTransGen(postData).subscribe((resp: any) => {
          if (resp.data) {
            this.transVehicledata = resp.data.contents[0];

            // this.transVehicledata.transportVehicleDetails.mf4routeDetails.forEach(element => {
            //   this.routeDetails.push(element.roteName);
            // });
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].patchValue({
              transporterName: this.mf4Details.transportVehicleDetails.vehicleAgencyName,
              vehicleNo: this.mf4Details.transportVehicleDetails.vehicleNumber,
              vehicleDriverName: this.mf4Details.transportVehicleDetails.driverName,
              routeType: this.mf4Details.transportVehicleDetails.routeValue,
              routeDetails: this.mf4Details.transportVehicleDetails.mf4routeDetails[0].majorRouteValue,
              majorRoute: this.mf4Details.transportVehicleDetails.majorRoute,
              // gpsId: this.transVehicledata.transportVehicleDetails.gpsDeviceId,
              remainingKms: 0,
              validUpTo: this.datePipe.transform(this.mf4Details.transportVehicleDetails.validUpTo, 'yyyy-MM-dd'),
            });
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('transporterName').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleNo').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleDriverName').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('routeType').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('routeDetails').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('majorRoute').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('gpsId').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('remainingKms').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('startReading').disable();
            this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('endReading').disable();
            // this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('validUpTo').disable();
          }
        });
      }
    }
    if (val === 'Yes') {
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('transporterName').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleNo').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleDriverName').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('routeType').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('routeDetails').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('majorRoute').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('gpsId').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('remainingKms').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('validUpTo').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('transporterName').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleNo').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleDriverName').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('routeType').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('routeDetails').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('majorRoute').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('gpsId').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('remainingKms').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('validUpTo').setValue('');
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('startReading').enable();
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('endReading').enable();
    }
  }

  getMf4PassNoDetail() {
    const payload = {
      id: null,
      getPassNo: ""
    }
    this.accidentRqstService.getMf4getPassNoDetail(payload).subscribe((resp: any) => {
      this.mf4PassNoDetail = resp.content;
    });
    console.log("brand list", this.mf4PassNoDetail);
  }
  // get all address related code

  appliAddrLocality = '';
  localityTypeList: string[] = [];

  selectedState = '';
  onSelected(value: string): void {
    console.log(this.selectedState);
  }
  alphanumericSomeSplChar(event): boolean { return this.licenseCommonSer.alphanumericSomeSplChar(event); }

  getDistrictStateCodeByPin(event, key) {
    const { value } = event.target;
    value.length === 6 ? this.findStateDistrictByPinCode(value, key) : value;
  }

  findStateDistrictByPinCode(pinCode, key) {
    if (pinCode !== '') {
      this.pinCodeSer.findStateDistrictyByPinCode(pinCode)
        .subscribe((resp: any) => {
          const { responseCode, responseMessage, content } = resp;
          if (responseCode === 200) {
            if (content && Object.keys(content).length > 0) {
              this.getFormCntr(key).patchValue({ accidentalTypeStateCode: content.accidentalTypeStateCode });
              this.onStateChange(content.accidentalTypeStateCode, key)
              this.getFormCntr(key).patchValue({ accidentalTypeDistrictCode: content.accidentalTypeDistrictCode, pinCode: pinCode });
              this.findTalukaByDistrictCode(content.accidentalTypeDistrictCode, key);
            }
          } else {
            this.alert.showError(responseMessage);
          }
        });
    }
  }


  getAllStates(countryCode = 'IN') {
    console.log('countryCode::', countryCode);
    if (countryCode !== '') {
      this.stateSer.findStateByCountryCode(countryCode)
        .subscribe((resp: any) => {
          const { responseCode, responseMessage, content } = resp;
          if (responseCode === 200) {
            this.selectOption = "Select";
            this.stateList = content;
          } else {
            this.alert.showError(resp.responseMessage);
          }
        });
    }
  }
  selectedStateValue: any[] = []
  onStateChange(stateCode, key) {

    const isUPState = (stateCode === UP_STATE_CODE);

    if (key === SEC_ADDR_APPLICANT) {
      this.showAppliPinInput = !isUPState;
      this.showAppliTehsilInput = !isUPState;
    } else {
      this.showAddrPinInput = !isUPState;
      this.showAddrTehsilInput = !isUPState;
    }
    this.selectedStateValue = this.stateList.filter(function (entry) { return entry.code === stateCode; });
    console.log(this.selectedStateValue, '...this.results...')
    //this.getAddrFormCntl(key).patchValue({ districtCode: '', tahsilCode: '' });
    // this.selectedState = value;


    this.accidentFormGroup.controls['addressList'].get('accidentalTypeDistrictCode').setValue('');
    this.accidentFormGroup.controls['addressList'].get('pinCode').setValue('');
    this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').setValue('');


    this.getDistrictByStateCode(stateCode, key);
    console.log(this.showAppliTehsilInput)
    if(!this.showAppliTehsilInput){
      console.log(this.showAppliTehsilInput)
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').setValidators(this.validateSer.valiReqOnly);
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').clearValidators();
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').updateValueAndValidity();
    }else if(this.showAppliTehsilInput){
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').setValidators(this.validateSer.valiReqOnly);
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').clearValidators();
      this.accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').updateValueAndValidity();
    }
  }

  // getAddrFormCntl(key) {
  //   let formContrl;
  //   switch (key) {
  //     case SEC_ADDR_APPLICANT:
  //       formContrl = this.getAppliAddrFormCntr();
  //       break;
  //   }
  //   return formContrl;
  // }
  // get all form related stuff
  getAddrCtByIdx(idx) { return this.getAddressFormCntr().get(idx); }
  getAddressFormCntr() { return this.getFormCntr(PROP_ADDRESS_DET); }
  getFormCntr(element) { return this.getForm().get(element); }
  getForm() { return this.accidentFormGroup; }

  getGatePasstype() {

    this.transportPasstypeList = ["MF4"];
    // this.gatepassCancellationService.getGatePassTypeMaster().subscribe((responceData: any) => {
    //   if (responceData.errorCode === 200) {
    //     this.gatepasstypelist = responceData.data.downDropDownMasterValueList;
    //   }
    // });
  }

  // selGatePassType(event) {
  //   let value = event.target.value;
  //   this.gatepasstypelist.forEach(element => {
  //     if (element.paramKey === value) {
  //       this.accidentFormGroup.patchValue({
  //         gatePassTypeValue: element.paramValue
  //       });
  //     }
  //   });
  // }

  getDistrictByStateCode(stateCode, key) {
    console.log(this.districtListByState[key], '...this.districtListByState[key]...');
    // console.log('stateCode::', stateCode);
    if (stateCode !== '') {
      this.districtSer.findDistrictByStateCode(stateCode)
        .subscribe((resp: any) => {
          const { responseCode, responseMessage, content } = resp;
          if (responseCode === 200) {
            this.districtListByState[key] = content;
          } else {
            this.alert.showError(resp.responseMessage);
          }
        });
    }
  }

  dateChange(value, seldate) {
    if (seldate === 'sDate') {
      this.sdate = value;
    }
    if (seldate === 'eDate') {
      this.eDate = value;
    }
    if (seldate === 'thisYrsDate') {
      this.thisYrSdate = null;
      let tempedate: any;
      tempedate = moment(value).format('DD');
      tempedate = parseInt(tempedate);
      if (tempedate < 15) {
        this.thisYrSdate = value;
        this.minDate = this.thisYrSdate;
        this.maxDate = moment(this.minDate).add(15, 'days').format('YYYY-MM-DD');
      } else {
        this.thisYrSdate = value;
        this.minDate = this.thisYrSdate;
        this.maxDate = moment(this.minDate).endOf('month').format('YYYY-MM-DD');
      }
    }
    if (seldate === 'thisYreDate') {
      this.thisYrEdate = value;
      this.thisYrEdate = moment(this.thisYrEdate);
      this.thisYrSdate = moment(this.thisYrSdate);
      //this.getPreviousYearData();
    }
    let thisYrtDays = Math.ceil(Math.abs((this.thisYrEdate - this.thisYrSdate) / (24 * 60 * 60 * 1000)));
    //this.mf9FormGroup.get('thisyearDays').setValue(thisYrtDays);
  }
  selectedDistrict: any[] = []
  // onDistrictChange(districtCode, key) {
  //   this.selectedDistrict = this.districtListByState[key].filter(function (entry) { return entry.code === districtCode; });
  //   console.log(this.selectedDistrict, '...this.selectedDistrict..');
  //   //this.getAddrFormCntl(key).patchValue({ tahsilCode: '' });
  //   this.findTalukaByDistrictCode(districtCode, key);
  // }

  onDistrictChange(districtCode, key) {
    this.selectedDistrict = this.districtListByState[key].filter(function (entry) { return entry.code === districtCode; });
    console.log(this.selectedDistrict, '...this.selectedDistrict..');
    //this.getAddrFormCntl(key).patchValue({ tahsilCode: '' });
    //this.getFormCntr(key).patchValue({ tahsilCode: '', pinCode: '' });
    this.findTalukaByDistrictCode(districtCode, key);
    (!this.showAppliPinInput || !this.showAddrPinInput) && this.findPinCodeByDistrictCode(districtCode, key);
  }

  findPinCodeByDistrictCode(districtCode, key) {
    if (districtCode !== '') {
      this.districtSer.findPinCodeByDistrictCode(districtCode)
        .subscribe((resp: any) => {
          const { responseCode, responseMessage, content } = resp;
          if (responseCode === 200) {

            if (key === SEC_ADDR_APPLICANT) {
              this.showAppliPinInput = content.length > 0 ? false : true;
            } if (key === SEC_ADDR_PREMISES) {
              this.showAddrPinInput = content.length > 0 ? false : true;
            }

            this.pinCodeList[key] = content;
          } else {
            this.alert.showError(responseMessage);
          }
        });
    }
  }



  findTalukaByDistrictCode(districtCode, key) {
    console.log('districtCode::', districtCode);
    if (districtCode !== '') {
      this.talukSer.findTalukaByDistrictCode(districtCode)
        .subscribe((resp: any) => {
          const { responseCode, responseMessage, content } = resp;
          if (responseCode === 200) {
            // this.talukList[key] = content;
            this.talukList = content;
          } else {
            this.alert.showError(resp.responseMessage);
          }
        });
    }

    this.localityTypeList = ["Urban", "Rural"];
  }
  selectedTaluk: any[] = []
  onTalukChange(talukId, key) {
    console.log('talukId', talukId);
    this.selectedTaluk = this.talukList.filter(function (entry) { return entry.code === talukId; });
    console.log(this.selectedTaluk, '...this.selectedTaluk...');
  }

  getPremisesAddrFormCntr(element = '') {
    const premisAddrInst = this.getAddrCtByIdx('1');
    return (premisAddrInst && element) ? premisAddrInst.get(element) : premisAddrInst;
  }

  onLocalityChange(localityType, type) {
    console.log(localityType)
    console.log(type)
    if(localityType == 'Urban'){
      this.accidentFormGroup.controls['addressList'].get('block').clearValidators();
      this.accidentFormGroup.controls['addressList'].get('block').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('village').clearValidators();
      this.accidentFormGroup.controls['addressList'].get('village').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('municipalityName').setValidators(Validators.required);
      this.accidentFormGroup.controls['addressList'].get('municipalityName').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('ward').setValidators(Validators.required);
      this.accidentFormGroup.controls['addressList'].get('ward').updateValueAndValidity();
    }else if(localityType == 'Rural'){
      this.accidentFormGroup.controls['addressList'].get('block').setValidators(Validators.required);
      this.accidentFormGroup.controls['addressList'].get('block').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('village').setValidators(Validators.required);
      this.accidentFormGroup.controls['addressList'].get('village').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('municipalityName').clearValidators();
      this.accidentFormGroup.controls['addressList'].get('municipalityName').updateValueAndValidity();
      this.accidentFormGroup.controls['addressList'].get('ward').clearValidators();
      this.accidentFormGroup.controls['addressList'].get('ward').updateValueAndValidity();
    }
    // let formContrl: any;
    // const resType = (localityType === LOCALITY_URBAN) ? LOCALITY_URBAN :
    //   ((localityType === LOCALITY_RURAL) ? LOCALITY_RURAL : '');
    //   console.log(type , '.....type...' + SEC_ADDR_APPLICANT);
    // switch (type) {
    //   case SEC_ADDR_APPLICANT:
    //     formContrl = this.getAppliAddrFormCntr();
    //     this.appliAddrLocality = resType;
    //     break;
    // }
    // this.validateSer.localityChangeValidatation(formContrl, localityType);
    // this.patchMasVal(this.localityTypeList, localityType, formContrl, 'localityTypeDesc');
    // return true;

    let lType = localityType.toUpperCase();

    let formContrl;
    const resType = (lType === LOCALITY_URBAN) ? LOCALITY_URBAN :
      ((lType === LOCALITY_RURAL) ? LOCALITY_RURAL : '');
    switch (type) {
      case SEC_ADDR_APPLICANT:
        formContrl = this.getFormCntr("localityType");
        this.appliAddrLocality = resType;
        break;
      case SEC_ADDR_PREMISES:
        formContrl = this.getPremisesAddrFormCntr();
        this.premisesAddrLocality = resType;
        break;
    }
    if (resType !== '' && this.localityTypeList) {
      this.validateSer.localityChangeValidatation(formContrl, localityType, null, this.screenSubType);
      this.patchMasVal(this.localityTypeList, localityType, formContrl, 'localityTypeDesc');
    }
    return true;
  }

  patchMasVal(list, code, formCntl: any, key) {
    const obj = list.find((resp: any) => resp.paramKey === code);
    // formCntl.patchValue({ [key]: obj.paramValue });
  }

  handleResponse(respData: any) {
    if (respData.status == 's') {
      this.alert.showSuccess(respData.userDisplayMesg, 'Success');
      this.router.navigate(['sugarmill/'+ this.outsideupvalidation+'/list']);
    }
    // else {
    // this.alert.showError(respData.userDisplayMesg, 'error');
    // }
  }

  onlyDigits(event) {
    let code = event.charCode;
    return (code >= 48 && code <= 57);
  }

  onSubmit(saveType, isPreview: any = false) {
    this.toggleLoading(saveType, isPreview);
  }
  toggleLoading(saveType, isPreview) {
    switch (saveType) {
      case STATUS_DRAFT:
        this.saveAccidentRequest(saveType, true, isPreview = isPreview);
        this.isDraftLoading = !this.isDraftLoading;
        break;
      case STATUS_SUBMITTED:
        this.saveAccidentRequest(saveType, false, isPreview = isPreview);
        this.isSubmitLoading = !this.isSubmitLoading;
        break;
    }
  }

  findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  async uploaddoc(applicationno) {
    const applicantDocuments = [
      { name: 'Deo upload the accidental report', fileSource: 'deoUploadAccidentalReportFile' },
      { name: 'Upload the photo of accidental tanker', fileSource: 'uploadAccidentalTankerFile' },
      { name: 'E-Way Bill', fileSource: 'eWayBillFile'},
      { name: 'Tax invoice', fileSource: 'taxInvoiceFile'},
      { name: 'FIR copy', fileSource: 'firCopyFile'},
    ];
    const applicantDocumentsUploadedDetails = await this.uploadDocuments(applicationno, applicantDocuments);
    return applicantDocumentsUploadedDetails;
  }
  async uploadDocuments(applicationNo, fileArrList?) {
    const formData = new FormData();
    let inc = 0;
    let uploadFlag = false;
    if (fileArrList) {
      for (let i = 0; i < fileArrList.length; i++) {
        if (this.accidentFormGroup.get(fileArrList[i].fileSource).value) {
          uploadFlag = true;
          formData.append('files[' + inc + '].name', fileArrList[i].name);
          formData.append('files[' + inc + '].file', this.accidentFormGroup.get(fileArrList[i].fileSource).value);
          inc++;
        }
      }
    }

    formData.append('moduleName', this.moduleName);
    formData.append('screenName', 'Accidental Request');
    formData.append('applicationNumber', applicationNo);
    if (uploadFlag) {
      this.authService.fileUploadFlag = true;
      const res: any = await this.documentUploadService.uploadDocuments(formData);
      this.authService.fileUploadFlag = false;
      const uploadDoc = res.map(item => {
        return {
          documentName: item.documentName,
          name: item.name,
          status: true,
          uuid: item.uuid
        };
      });
      return uploadDoc;
    } else {
      return null;
    }
  }
  combineArr(oldArr, newArr) {
    if (newArr && newArr.length > 0) {

      newArr.forEach(element => {
        const ind = oldArr.findIndex((item) => item.name === element.name);

        if (ind !== -1) {
          element.id = oldArr[ind].id;
          element.createdBy = oldArr[ind].createdBy;
          element.createdDate = oldArr[ind].createdDate;

          oldArr[ind] = element;
        } else {
          oldArr.push(element);
        }
      });
    }

    console.log("oldArr============", oldArr);
    return oldArr;
  }

  async saveAccidentRequest(method, confirmFlag?, isPreview = false) {
    let documentDetails: any;
        documentDetails = await this.uploaddoc(this.applicationNo);
        if(this.editdata.accidentalRequestDocumentList){
          documentDetails = this.combineArr(this.editdata.accidentalRequestDocumentList, documentDetails);
        }
        let documentdetaillist = []
        if (documentDetails) {
          documentdetaillist = documentDetails.map((elee: any) => {
            return {
              documentName: elee.documentName,
              status: elee.status,
              name: elee.name,
              uuid: elee.uuid,
              id: elee.id || '',
              createdBy: elee.createdBy || '',
              createdDate: elee.createdDate || ''
    
            };
          });
        }
    if(!this.isCustomer){
      this.accidentFormGroup.get('licenseType').clearValidators();
      this.accidentFormGroup.get('licenseType').updateValueAndValidity();
      this.accidentFormGroup.get('licenseNo').clearValidators();
      this.accidentFormGroup.get('licenseNo').updateValueAndValidity();
      // this.accidentFormGroup.get('FineImposed').clearValidators();
      // this.accidentFormGroup.get('FineImposed').updateValueAndValidity();
      // this.accidentFormGroup.get('fineType').clearValidators();
      // this.accidentFormGroup.get('fineType').updateValueAndValidity();
      // this.accidentFormGroup.get('fees').clearValidators();
      // this.accidentFormGroup.get('fees').updateValueAndValidity();
      // this.accidentFormGroup.controls['addressList'].get('village').clearValidators();
      // this.accidentFormGroup.controls['addressList'].get('village').updateValueAndValidity();
      // this.accidentFormGroup.controls['addressList'].get('block').clearValidators();
      // this.accidentFormGroup.controls['addressList'].get('block').updateValueAndValidity();
    }
    if(this.isCustomer){
      this.accidentFormGroup.get('FineImposed').clearValidators();
      this.accidentFormGroup.get('FineImposed').updateValueAndValidity();
      this.accidentFormGroup.get('fineType').clearValidators();
      this.accidentFormGroup.get('fineType').updateValueAndValidity();
      this.accidentFormGroup.get('fees').clearValidators();
      this.accidentFormGroup.get('fees').updateValueAndValidity();
    }
    this.formSubmitted = false;
    console.log('invalid', this.findInvalidControls(this.accidentFormGroup));
    if (method !== 'DRAFT' && this.accidentFormGroup.valid === false) {
      this.formSubmitted = true;
    } else {
      const addressListGroup = (this.accidentFormGroup.controls['addressList'] as FormGroup);
      const productAndVehicleBothDamagedDetailsGroup = (this.accidentFormGroup.controls['productAndVehicleBothDamagedDetails'] as FormGroup);
      const productDamagedButVehicleGoodDetailsGroup = (this.accidentFormGroup.controls['productDamagedButVehicleGoodDetails'] as FormGroup);
      const vehicleDamagedButProductGoodDetailsGroup = (this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'] as FormGroup);
      // console.log('raw value', this.accidentFormGroup.getRawValue().transportPassTypeCode);
      console.log(addressListGroup.value, '.....addressListGroup....');
      console.log(productAndVehicleBothDamagedDetailsGroup.value.startReading)
      let productAndVehicleBothDamagedDetails = {
        accidentalRequest: {},
        createdBy: 0,
        createdDate: new Date().toISOString(),
        gpsId: productAndVehicleBothDamagedDetailsGroup.value.gpsId,
        id: this.editdata.productAndVehicleBothDamagedDetails ? this.editdata.productAndVehicleBothDamagedDetails.id : '',
        modifiedBy: 0,
        modifiedDate: new Date().toISOString(),
        routeDetails: productAndVehicleBothDamagedDetailsGroup.value.routeDetails,
        transporterName: productAndVehicleBothDamagedDetailsGroup.value.transporterName,
        vehicleDriverName: productAndVehicleBothDamagedDetailsGroup.value.vehicleDriverName,
        vehicleNo: productAndVehicleBothDamagedDetailsGroup.value.vehicleNo,
        wantToSendBack: productAndVehicleBothDamagedDetailsGroup.value.wantToSendBack,
        modeOfTransport:productAndVehicleBothDamagedDetailsGroup.value.modeOfTransport,
        startReading:productAndVehicleBothDamagedDetailsGroup.value.startReading,
        endReading:productAndVehicleBothDamagedDetailsGroup.value.endReading
      };
      let productDamagedButVehicleGoodDetails = {
        accidentalRequest: {},
        createdBy: 0,
        createdDate: new Date().toISOString(),
        damagedQuantityOfMolasses: productDamagedButVehicleGoodDetailsGroup.value.damagedQuantityOfMolasses,
        id:this.editdata.productDamagedButVehicleGoodDetails ? this.editdata.productDamagedButVehicleGoodDetails.id : '',
        modifiedBy: 0,
        modifiedDate: new Date().toISOString(),
        totalQuantityOfMolasses: productDamagedButVehicleGoodDetailsGroup.value.totalQuantityOfMolasses
      };
      let vehicleDamagedButProductGoodDetails = {
        accidentalRequest: {},
        changeTheVehicleDetails: vehicleDamagedButProductGoodDetailsGroup.value.changeTheVehicleDetails,
        createdBy: 0,
        createdDate: new Date().toISOString(),
        gpsId: vehicleDamagedButProductGoodDetailsGroup.value.gpsId,
        id: vehicleDamagedButProductGoodDetailsGroup.value.id,
        majorRoute: vehicleDamagedButProductGoodDetailsGroup.value.majorRoute,
        modifiedBy: 0,
        modifiedDate: new Date().toISOString(),
        remainingKms: vehicleDamagedButProductGoodDetailsGroup.value.remainingKms,
        routeDetails: vehicleDamagedButProductGoodDetailsGroup.value.routeDetails,
        routeType: vehicleDamagedButProductGoodDetailsGroup.value.routeType,
        transporterName: vehicleDamagedButProductGoodDetailsGroup.value.transporterName,
        vehicleDriverName: vehicleDamagedButProductGoodDetailsGroup.value.vehicleDriverName,
        vehicleNo: vehicleDamagedButProductGoodDetailsGroup.value.vehicleNo,
        extendTransportPassValidity: vehicleDamagedButProductGoodDetailsGroup.value.extendTransportPassValidity,
        validUpTo: vehicleDamagedButProductGoodDetailsGroup.value.validUpTo,
        modeOfTransport:vehicleDamagedButProductGoodDetailsGroup.value.modeOfTransport,
        startReading:vehicleDamagedButProductGoodDetailsGroup.value.startReading,
        endReading:vehicleDamagedButProductGoodDetailsGroup.value.endReading
      }
      console.log(this.editId, '....this.editId....');
        // this.requestForEthanolOrderForOmdService.getPaymentDetail(payload1).subscribe(async res => {
        //   this.paymentData = res.data.contents[0]
        console.log(this.paymentData)
      if (this.editId) {
        console.log(this.accidentFormGroup, '...this.accidentFormGroup.valid...');
        console.log("adsfad" + this.entityType)
        const payloadEdit = {

          "accidentDate": this.accidentFormGroup.value.accidentDate,
          "accidentalTypeCode": this.accidentFormGroup.value.accidentalType,
          "accidentalTypeValue": this.accidentTypeList.find(res=>res.code == this.accidentFormGroup.value.accidentalType).value,
          "applicationDate": this.accidentFormGroup.value.applicationDate,
          "comments": "comment",
          "createdBy": localStorage.getItem('USER_ID'),
          "createdDate": new Date().toISOString(),
          "accidentalTypeDistrictCode": addressListGroup.value.accidentalTypeDistrictCode,
          "accidentalTypeDistrictValue": this.editdata.accidentalTypeDistrictValue,
          "entityType": "SUGAR_MILL",
          "event": (method === 'SUBMITTED' || method == "DRAFT") ? 'INITIATE' : method,
          "id": this.editId,
          "isDigitalSignature": true,
          "isEditable": true,
          "isShowButtons": true,
          "level": 'Level 1',
          // "licenseDetailsResponse": this.accidentFormGroup.value.licenseDetailsResponse,
          "licenseNumber": this.isCustomer? this.editdata.licenseNumber:this.mf4Details.licenseNumber,
          "licenseType": this.isCustomer? this.accidentFormGroup.value.licenseType:this.mf4Details.licenseType,
          "locality": addressListGroup.value.locality,
          "mf4GatePassNo": this.accidentFormGroup.value.mf4GatePassNo,
          "modifiedBy": 0,
          "modifiedDate": new Date().toISOString(),
          "municipalAreaCode": addressListGroup.value.municipalityName,
          "municipalAreaValue": "",
          "pinCode": addressListGroup.value.pinCode == undefined ? 0 : addressListGroup.value.pinCode,
          "policeStation": addressListGroup.value.policeStation,

          "productAndVehicleBothDamagedDetails": this.accidentType == "BOTH_DAMAGED" ? productAndVehicleBothDamagedDetails : null,
          "productDamagedButVehicleGoodDetails": this.accidentType == "PRODUCT_DAMAGED_BUT_VEHICLE_GOOD" ? productDamagedButVehicleGoodDetails : null,

          "requestDate": new Date().toISOString(),
          "stage": "stage",
          "stageDesc": "stageDesc",
          "accidentalTypeStateCode": addressListGroup.value.accidentalTypeStateCode,
          "accidentalTypeStateValue": this.editdata.accidentalTypeStateValue,
          "status": method,
          "statusDesc": method,
          "street": addressListGroup.value.street,
          "accidentalTypeTehsilCode": addressListGroup.value.accidentalTypeTehsilCode,
          "accidentalTypeTehsilValue": this.editdata.accidentalTypeTehsilValue,
          "unitName": "",
          "urbanOrRuralCode": "",
          "urbanOrRuralValue": addressListGroup.value.localityType,
          "vehicleDamagedButProductGoodDetails": this.accidentType == "VEHICLE_DAMAGED_BUT_PRODUCT_GOOD" ? vehicleDamagedButProductGoodDetails : null,
          "ward": addressListGroup.value.ward,
          "sugarMillName": this.mf4Details.sugarMillName,
          "distilleryName": this.mf4Details.distilleryEntityNameValue,
          "quantity":this.mf4Details.indentedQuantity,
          "grade":this.mf4Details.grade,
          "g6Code":this.isCustomer?this.paymentData.g6 :'',
          "g6Value":this.isCustomer?this.paymentData.g6Name:'',
          "treasureyHeadCode":this.isCustomer?this.paymentData.treasuryHead:'',
          "treasureyHeadValue":this.isCustomer?this.paymentData.treasuryHeadName:'',
          "isOfficerApplication":this.isCustomer?false: true,
          // "entitySubCategory": this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : ""),
          "fees": this.accidentFormGroup.value.fees? this.accidentFormGroup.value.fees : 0,
          "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : this.outsideupvalidation === "accidental-request-sugarmill-outsideup" ? "SUGARMILL_OUTSIDE_UP" : ""),
          "FineImposed":this.accidentFormGroup.value.FineImposed,
          "fineType":this.accidentFormGroup.value.fineType,
          "penaltyRemark":this.accidentFormGroup.value.penaltyRemark,
          "accidentalRequestDocumentList": documentdetaillist ||'',
          "molassesWastage": this.accidentFormGroup.value.molassesWastage ||''
          // "officerId":this.mf4Details.createdBy 
        }
        console.log(method);
        console.log(this.accidentFormGroup.valid, '...this.accidentFormGroup.valid...');
        console.log(this.accidentFormGroup.controls, '...this.accidentFormGroup.controls..');
        
        payloadEdit[`id`] = +(this.editId);
        payloadEdit[`applicationNumber`] = this.editdata.applicationNumber,
        payloadEdit[`accidentalRequestDocumentList`] = documentdetaillist ||'',
        payloadEdit[`molassesWastage`] = this.accidentFormGroup.value.molassesWastage ||''
        
        // payloadEdit[`FineImposed`] = this.accidentFormGroup.value.FineImposed == 'Yes'?true : false
        if (method !== 'DRAFT') {
          this.accidentRqstService.addAccidentRqst(payloadEdit).subscribe((responceData: any) => {
            if (responceData.errorCode == 200 || responceData.status == "s") {
              if (isPreview) {
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/preview' + responceData.data.id], {
                  queryParams: {
                    Id: responceData.data.id,
                    unitName: this.bottlingUnitAddress ? this.bottlingUnitAddress.unitName : '', street: this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '', tahsilDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '', districtDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '',
                    stateDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '', pinCode: this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : ''
                  }
                });
              } else {
                this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/list']);
              }
            } else {
              this.alert.showError(responceData.userDisplayMesg);
            }
          });
        } else {
          this.accidentRqstService.addAccidentRqst(payloadEdit).subscribe((responceData: any) => {
            console.log(responceData.data, '..responceData..');
            if (responceData.errorCode == 200 || responceData.status == "s") {
              if (isPreview) {
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/preview' + responceData.data.id], {
                  queryParams: {
                    Id: responceData.data.id,
                    unitName: this.bottlingUnitAddress ? this.bottlingUnitAddress.unitName : '', street: this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '', tahsilDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '', districtDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '',
                    stateDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '', pinCode: this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : ''
                  }
                });
              } else {
                this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/list']);
              }
            } else {
              this.alert.showError(responceData.userDisplayMesg);
            }
          });
        }
      } else {
        console.log(this.paymentData)
        let payload = {
          "accidentDate": this.accidentFormGroup.value.accidentDate,
          "accidentalTypeCode": this.accidentFormGroup.value.accidentalType,
          "accidentalTypeValue": this.accidentTypeList.find(res=>res.code == this.accidentFormGroup.value.accidentalType).value,
          "applicationDate": this.accidentFormGroup.value.applicationDate,
          "applicationNumber": this.applicationNo,
          "comments": "comment",
          "createdBy": localStorage.getItem('USER_ID'),
          "createdDate": new Date().toISOString(),
          "accidentalTypeDistrictCode": addressListGroup.value.accidentalTypeDistrictCode,
          "accidentalTypeDistrictValue": this.selectedDistrict.length > 0 ? this.selectedDistrict[0].value : "",
          "entityType":"SUGAR_MILL",
          "event": (method === 'SUBMITTED' || method == "DRAFT") ? 'INITIATE' : method,
          "id": null,
          "isDigitalSignature": true,
          "isEditable": true,
          "isShowButtons": true,
          "level": 'Level 1',
          // "licenseDetailsResponse": this.accidentFormGroup.value.licenseDetailsResponse,
          "licenseNumber": this.isCustomer? this.accidentFormGroup.value.licenseNo: this.mf4Details.licenseNumber,
          "licenseType": this.isCustomer? this.accidentFormGroup.value.licenseType: this.mf4Details.licenseType,
          "locality": addressListGroup.value.locality,
          "mf4GatePassNo": this.accidentFormGroup.value.mf4GatePassNo,
          "gatePassTypeCode": this.accidentFormGroup.getRawValue().transportPassTypeCode,
          "gatePassTypeValue": this.accidentFormGroup.getRawValue().transportPassTypeCode,
          "modifiedBy": 0,
          "modifiedDate": new Date().toISOString(),
          "municipalAreaCode": addressListGroup.value.municipalityName,
          "municipalAreaValue": "",
          "pinCode": addressListGroup.value.pinCode == undefined ? 0 : addressListGroup.value.pinCode,
          "policeStation": addressListGroup.value.policeStation,

          "productAndVehicleBothDamagedDetails": this.accidentType == "BOTH_DAMAGED" ? productAndVehicleBothDamagedDetails : null,
          "productDamagedButVehicleGoodDetails": this.accidentType == "PRODUCT_DAMAGED_BUT_VEHICLE_GOOD" ? productDamagedButVehicleGoodDetails : null,

          "requestDate": new Date().toISOString(),
          "stage": "stage",
          "stageDesc": "stageDesc",
          "accidentalTypeStateCode": addressListGroup.value.accidentalTypeStateCode,
          "accidentalTypeStateValue": this.selectedStateValue.length > 0 ? this.selectedStateValue[0].value : "",
          "status": method,
          "statusDesc": method,
          "street": addressListGroup.value.street,
          "accidentalTypeTehsilCode": addressListGroup.value.accidentalTypeTehsilCode,
          "accidentalTypeTehsilValue": this.selectedTaluk.length > 0 ? this.selectedTaluk[0].value : addressListGroup.value.accidentalTypeTehsilValue,
          "unitName": "",
          "urbanOrRuralCode": "",
          "urbanOrRuralValue": addressListGroup.value.localityType,
          "vehicleDamagedButProductGoodDetails": this.accidentType == "VEHICLE_DAMAGED_BUT_PRODUCT_GOOD" ? vehicleDamagedButProductGoodDetails : null,
          "ward": addressListGroup.value.ward,
          "sugarMillName": this.mf4Details.sugarMillName,
          "distilleryName": this.mf4Details.distilleryEntityNameValue,
          "quantity":this.mf4Details.indentedQuantity,
          "grade":this.mf4Details.grade,
          "g6Code":this.isCustomer?this.paymentData.g6:'',
          "g6Value":this.isCustomer?this.paymentData.g6Name:'',
          "treasureyHeadCode":this.isCustomer?this.paymentData.treasuryHead:'',
          "treasureyHeadValue":this.isCustomer?this.paymentData.treasuryHeadName:'',
          "isOfficerApplication":this.isCustomer?false: true,
          // "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : ""),
          "fees": this.accidentFormGroup.value.fees? this.accidentFormGroup.value.fees : 0,
          "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : this.outsideupvalidation === "accidental-request-sugarmill-outsideup" ? "SUGARMILL_OUTSIDE_UP" : ""),
          "fineImposed":this.accidentFormGroup.value.FineImposed,
          "fineType":this.accidentFormGroup.value.fineType,
          "penaltyRemark":this.accidentFormGroup.value.penaltyRemark,
          "accidentalRequestDocumentList": documentdetaillist ||'',
          "molassesWastage": this.accidentFormGroup.value.molassesWastage ||''
          // "officerId":this.mf4Details.createdBy
        }
        // payload[`FineImposed`] = this.accidentFormGroup.value.FineImposed == "Yes" ? true : false;
        if (method !== 'DRAFT') {
          this.accidentRqstService.addAccidentRqst(payload).subscribe((responceData: any) => {
            if (responceData.errorCode == 200 || responceData.status == "s") {
              if (isPreview) {
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/preview' + responceData.data.id], {
                  queryParams: {
                    Id: responceData.data.id,
                    unitName: this.bottlingUnitAddress ? this.bottlingUnitAddress.unitName : '', street: this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '', tahsilDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '', districtDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '',
                    stateDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '', pinCode: this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : ''
                  }
                });
              } else {
                this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/list']);
              }
            } else {
              this.alert.showError(responceData.userDisplayMesg);
            }
          });
          
        } else {
          this.accidentRqstService.addAccidentRqst(payload).subscribe((responceData: any) => {
            console.log(responceData.data, '..responceData..');
            if (responceData.errorCode == 200 || responceData.status == "s") {
              if (isPreview) {
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/preview' + responceData.data.id], {
                  queryParams: {
                    Id: responceData.data.id,
                    unitName: this.bottlingUnitAddress ? this.bottlingUnitAddress.unitName : '', street: this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '', tahsilDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '', districtDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '',
                    stateDesc: this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '', pinCode: this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : ''
                  }
                });
              } else {
                this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
                this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/list']);
              }
            } else {
              this.alert.showError(responceData.userDisplayMesg);
            }
          });
        }
      }
    // })
    }
  }

  checkLength(value, length) {
    if (value.length == length) return false;
  }

  previewcategory(form) {
    this.preview.setData = form.value;
    this.router.navigate([this.url+'/stock/accidentrequest/preview']);
  }

  getworkflowdata() {
    this.worfFlowService.getworkflowbydesignation(this.workflowModule, this.workflowSubModule).subscribe((resp: any) => {
      this.workflowdata = resp.content;
    })
  }
  valueChange(){
    this.accidentFormGroup.get('accidentalType').setValue('');
  }

  validityExtend(event){
    console.log(event)
    if(event == 'Yes'){
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('validUpTo').enable();
    }else if(event == 'No') {
      this.accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('validUpTo').disable();
    }
  }
  getFineAmount() {
    if (this.accidentFormGroup.get('FineImposed').value == true) {
      this.accidentFormGroup.get('fineType').setValidators(this.validateSer.valiReqOnly);
      this.accidentFormGroup.get('fineType').updateValueAndValidity();
      this.accidentFormGroup.get('fees').setValidators(this.validateSer.valiReqOnly);
      this.accidentFormGroup.get('fees').updateValueAndValidity();
      this.isPaymentEnable = true;
    } else if (this.accidentFormGroup.get('FineImposed').value  == false) {
      this.accidentFormGroup.get('fineType').clearValidators();
      this.accidentFormGroup.get('fineType').updateValueAndValidity();
      this.accidentFormGroup.get('fees').clearValidators();
      this.accidentFormGroup.get('fees').updateValueAndValidity();
      this.isPaymentEnable = false;
    }
  }
  downloadDocument(uuid, documentName) {

    if (uuid && documentName) {
      this.documentUploadService.downloadDocument(uuid, documentName).subscribe((resp: any) => {
        fileSaver.saveAs(resp, documentName);
      },
        (error: any) => this.alert.showError('Error downloading the file', 'Error'),
        () => console.info('File downloaded successfully')
      );
    } else {
      this.alert.showError('File not available', 'Error');
    }

  }
  getFineLimit(event) {
    const penaltyFeeControl = this.accidentFormGroup.get('fees');
    if (event == 'Penalty') {
      this.formSubmitted = true;
      penaltyFeeControl.setValidators([Validators.required, Validators.min(1), Validators.max(50000)]);
    } else if (event == 'Compounding') {
      this.formSubmitted = true;
      penaltyFeeControl.setValidators([Validators.required, Validators.min(1), Validators.max(100000)]);
    }
    penaltyFeeControl.updateValueAndValidity();
  }
  onFileChange(event, sourceName, form, sourceFile) {
    let maxSize: any = 1024 * 6144;
    let fileSize: any;
    let fileName = event.target.value;
    let fileval = event.target.files[0].name;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      fileSize = file.size;
      if (fileSize > maxSize) {
        this.alert.showWarning('Please upload less than 6 Mb size', 'warning');
        this.accidentFormGroup.patchValue({
          [sourceFile]: ''
        });
      } else {
        console.log(file)
        this.accidentFormGroup.patchValue({
          [sourceName]: file
        });
      }
    }
    if (!this.validateFileName(fileval)) {
      this.alert.showError("File name is not valid. Please choose a valid file.")
      this.accidentFormGroup.patchValue({
        [sourceFile]: ''
      });
    }
    
  }
  private validateFileName(fileName: string): boolean {
    const pattern = /^[^.]+(\.[^.]+)?$/;
    return pattern.test(fileName);
  }
  getDocName(name) {
    if (this.editdata.accidentalRequestDocumentList) {
      if (this.editdata.accidentalRequestDocumentList.find(data => data.name === name)) {
        return this.editdata ? this.editdata.accidentalRequestDocumentList.find(data => data.name === name) : '';
      }
    }
    return false;
  }
}
function stringToBoolean(myString: any) {
  throw new Error('Function not implemented.');
}

