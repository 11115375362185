import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@appThemeShared/shared.module';

import { AccidentalrequestOutsideupRoutingModule } from './accidentalrequest-outsideup-routing.module';
import { AddaccidentalrequestComponent } from './addaccidentalrequest/addaccidentalrequest.component';
import { ViewaccidentalrequestComponent } from './viewaccidentalrequest/viewaccidentalrequest.component';
import { AccidentalrequestOutsideupComponent } from './accidentalrequest-outsideup.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PaymentscreenComponent } from './paymentscreen/paymentscreen.component';
import { AknowledgmentscreenComponent } from './aknowledgmentscreen/aknowledgmentscreen.component';

@NgModule({
  declarations: [AddaccidentalrequestComponent, ViewaccidentalrequestComponent, AccidentalrequestOutsideupComponent, PaymentscreenComponent, AknowledgmentscreenComponent],
  imports: [
    CommonModule,
    SharedModule,
    AccidentalrequestOutsideupRoutingModule,
    NgMultiSelectDropDownModule
  ]
})
export class AccidentalrequestOutsideupModule { }
