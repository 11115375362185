import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment'; 
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

 constructor(
   private http: HttpClient
  ) { }
  paymentBaseUrl = environment.paymentBaseUrl; 

  getPaymentHash(data): Observable<any> {
    const url = this.paymentBaseUrl + 'payu/request';
    return this.http.post(url, data);
  }
  getdetails(): Observable<any> {
    const url = this.paymentBaseUrl + 'license/category/PD';
    return this.http.get(url);
  }
  
  paymentStatus(payload) {
    const url = this.paymentBaseUrl + 'payu/statusCheck';
    return this.http.post(url, payload);
  }


  saveUpdatePaymentDetails(payload): Observable<any> {
    const url = this.paymentBaseUrl + apiUrls.saveUpdatePaymentDetails;
    return this.http.post(url, payload);
  }
 
}
