import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovaltransferforredistillationService {
  entityType: any
  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl
  masterurl = environment.baseURLMasterIpAddr
  licenseURL = environment.licneseBaseUrl;

  addDetails(data): Observable<any> {
    const url = this.baseURL + 'reDistillationTransfer';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'reDistillationTransfer/lazySearch';
    //const url = 'http://localhost:3501/scmProduction/reDistillationTransfer/lazySearch'
    return this.http.post(url, payload);
  }


  getById(id): Observable<any> {
    const url = this.baseURL + 'reDistillationTransfer/' + id;
    return this.http.get(url);
  }
  getDropdownValues(params): Observable<any> {
    const url = this.devisionUrl + apiUrls.findDropDownMasterOnMaster
    return this.http.get(url, { params: params });
  }
  getSourceTanks(data: any): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  searchApplicationNo(payload): Observable<any> {
    const url = this.baseURL + 'reDistillationSpirit/lazySearch';
    return this.http.post(url, payload);
  }

  searchStockApplicationNo(payload): Observable<any> {
    const url = this.baseURL + 'reDistillationBottledStock/lazySearch';
    return this.http.post(url, payload);
  }

  searchApplicationNoreDistillationBottledStock(payload): Observable<any> {
    const url = this.baseURL + 'reDistillationBottledStock/lazySearch';
    return this.http.post(url, payload);
  }


  getdetails(): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    return this.http.get(url).toPromise();

  }
  getApplicationNo(data: any): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, data);
  }

  downloadDocument(appnumber, id, receiptType): any {
    const url = this.baseURL + 'reportReceipt/downloadReceipt' + '?applicationNumber=' + appnumber + '&id=' + id + '&receiptType=' + receiptType;
    return this.http.get(url, { responseType: 'blob' });
  }
 
  // getStatusCount(entityType?): Observable<any> {
  //   const url = this.baseURL + 'reDistillationTransfer/getStatusCount?entityType='+ entityType ;
  //   return this.http.post(url,entityType);
  // }

  // getReDistillationTransferStatusCount(entityType, isCurrentOfficerAllRecords, isAllOfficerRecords, isOtherOfficerAllRecords): Observable<any> {
  //   const url = this.baseURL + 'reDistillationTransfer/getStatusCount?entityType=' + entityType
  //     + '&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords
  //     + "&isOtherOfficerAllRecords=" + isOtherOfficerAllRecords;
  //   // const url = 'http://localhost:3501/scmProduction/reDistillationTransfer/getStatusCount?entityType=' + entityType 
  //   // +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
  //   // +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
  //   return this.http.get(url);
  // }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload); 
  }

  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, postdata);
  }
 
}
