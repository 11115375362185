import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import { map, tap } from 'rxjs/operators';
import { PdCommonService } from './pd-common.service';
@Injectable({
  providedIn: 'root'
})
export class Pd18IdService {
  entityType:any
  constructor(
    private http: HttpClient,
    private pdCommonService: PdCommonService
  ) {
    this.entityType = pdCommonService.getEntityType()
   }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  baseURLIpAddr = environment.baseURLMasterIpAddr;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'pd18';
    return this.http.post(url, data);
  }
  downloadenavalue(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  search(payload): Observable<any> {
    //payload.searchInput.entityType = this.entityType
    const url = this.baseURL + 'pd18/lazySearch';
    return this.http.post(url, payload);
  }
  search1(payload): any {
    payload.searchInput.entityType = this.entityType
    const url = this.baseURL + 'pd18/lazySearch';
    return this.http.post(url, payload).toPromise();
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'pd18/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getTankList(data): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  getOpenBalance(lic?): Observable<any> {
    const url = this.baseURL + 'mf6part1/getOpeningBalance?entityType=PD&licenseNumber=' + lic;
    return this.http.get(url);
  }
  getModeOfTransfer(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=MODE_OF_TRANSFER&additionalFilter2=PD34'
    return this.http.get(url);
  }
  getPD18Count(entityType?): Observable<any> {
    const url = this.baseURL + 'pd18/getPD18Count?entityType=' + entityType;
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getStatusCount(entityType,profileType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    //  const url ='http://localhost:3501/scmProduction/mf5Part1/getMf5Part1StatusCount?entityType='
    const url = this.baseURL + 'pd18/getStatusCount?entityType='+ entityType +'&profileType='+ profileType
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  getAllRouteRange1() {
    const url = this.devisionUrl + apiUrls.getAllRouteRange;
    return this.http.get(url).toPromise();
  }
  findDistrictByStateCode(stateCode): Observable<any> {
    return this.http
      .get(this.baseURLIpAddr + 'location/findDistrictByStateCode?stateCode=' + stateCode)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  getRadarDetails(vatCode,requestId,unitCode): Observable<any>{
    const url = this.baseURL + 'tankRaidorBasedLog/getRedorDetail?vatCode=' + vatCode +
    '&requestId=' +requestId + '&unitCode=' +unitCode
    return this.http.get(url);
  }
  getRequestId(key,vatCode,upordown): Observable<any>{
    const url = this.baseURL + 'tankRaidorBasedLog/getDropDown?type=' + key
    +'&tankRedorVatCode=' +vatCode +'&upDown=' +upordown
    return this.http.get(url);
  }
}
