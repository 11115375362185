<h5 class="m-b-10 ng-star-inserted">
    {{addedit}} Accidental Request
</h5>

<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'SUGAR_MILL'"><a href="javascript:">
        {{'language.sugarmill' | translate}} </a></li>
    <li class="breadcrumb-item"><a href="javascript:">Accidental Request</a></li>
    <li class="breadcrumb-item">Add</li>
</ul>
<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="accidentFormGroup" autocomplete="off">
            <app-card cardTitle="{{ 'sugarmill.mf4transportpass.unitdetails' | translate }}"
                class="list-panel add-panel" *ngIf="isCustomer">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">
                            {{'sugarmill.mf4transportpass.financialYear'| translate}}
                            <span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{ fiscalyear }}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">
                            {{'sugarmill.mf4transportpass.datetime'| translate}}
                            <span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{ myDate | date:'dd/MM/yyyy hh:mm:ss a' }}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">
                            License Type
                            <span class="mandatory"></span><span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <select class="form-control" formControlName="licenseType"
                            (change)="getlicensenumber($event.target.value)">
                            <option value="">{{'sugarmill.common.select' | translate}}</option>
                            <option *ngFor="let tank of bottlingdetaildetails" value="{{tank.licenseSubCategory}}">
                                {{tank.licenseSubCategory}}
                            </option>
                            <app-show-errors eleName="{{ 'brewery.brandlabelregadd.licensetype' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('licenseType')">
                            </app-show-errors>
                        </select>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('licenseType').errors || accidentFormGroup.get('licenseType').touched "
                            class="error-msg">
                            <span *ngIf="accidentFormGroup.get('licenseType').errors?.required"><i
                                    class="fa fa-times"></i>
                                {{'language.registrationtype' | translate}} is required
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">
                            License Number
                            <span class="mandatory"></span><span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <select class="form-control" formControlName="licenseNo"
                            (change)="getLicenseaddress($event.target.value)">
                            <option value="">{{'sugarmill.common.select' | translate}}</option>
                            <option *ngFor="let tank of licensenodata" value="{{tank.licenseno}}">
                                {{tank.licenseno}}
                            </option>
                            <app-show-errors eleName="{{'sugarmill.mf4transportpass.licensenumber' | translate}}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('licenseNo')">
                            </app-show-errors>
                        </select>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('licenseNo').errors || accidentFormGroup.get('licenseNo').touched "
                            class="error-msg">
                            <span *ngIf="accidentFormGroup.get('licenseNo').errors?.required"><i
                                    class="fa fa-times"></i>
                                {{'language.registrationNumber' | translate}} is required
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">
                            {{'sugarmill.mf4transportpass.unitname' | translate}}
                            <span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{ bottlingUnitAddress?.unitName }}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">
                            {{'sugarmill.mf4transportpass.unitaddress' | translate}}
                            <span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{ bottlingUnitAddress?.address }}
                    </div>
                </div>
            </app-card>

            <app-card cardTitle="{{'language.applicationDetails' | translate}}" class="list-panel add-panel">
                <div class="row">
                    <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <label>{{'bondWarehouse.accidentalcase.transportno' | translate}}</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control"
                                placeholder="Scan Barcode"
                                aria-label="Recipient's username" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button"><i
                                        class="fas fa-qrcode"></i></button>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                        <label class="mandatory">{{'language.mf4gatepassno' | translate}}</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" formControlName="mf4GatePassNo"
                                placeholder="{{ 'language.scanbarcode' | translate }}"
                                (keypress)="checkLength($event.target.value, 12)" (change)="valueChange()">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" (click)="scanNumber()"
                                    [disabled]="!accidentFormGroup.value.mf4GatePassNo">
                                    <i class="fas fa-qrcode"></i>
                                </button>
                            </div>
                        </div>
                        <app-show-errors eleName="{{'language.mf4gatepassno' | translate}}" [frmSub]="formSubmitted"
                            [control]="getFormCntr('mf4GatePassNo')">
                        </app-show-errors>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <label class="mandatory">{{'language.transportpasstype' | translate}}</label>
                        <div class="d-flex box-bg-grey flex-wrap">
                            <select class="form-control" formControlName="transportPassTypeCode">
                                <option value="">{{ 'usermanagement.common.select' | translate }}</option>
                                <option *ngFor="let item of transportPasstypeList" value="{{item}}">{{item}}</option>
                            </select>
                        </div>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('transportPassTypeCode').errors || accidentFormGroup.get('transportPassTypeCode').touched "
                        class="error-msg">
                       <span *ngIf="accidentFormGroup.get('transportPassTypeCode').errors?.required">Transport Pass Type is required </span>
                  </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Sugar Mill Name</label>
                            <input type="text" class="form-control" formControlName="sugarMillName" disabled
                                placeholder="">
                        </div>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('sugarMillName').errors || accidentFormGroup.get('sugarMillName').touched "
                        class="error-msg">
                       <span *ngIf="accidentFormGroup.get('sugarMillName').errors?.required">Sugar Mill Name is required </span>
                  </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Distillery Name</label>
                            <input type="text" class="form-control" formControlName="distilleryName" disabled
                                placeholder="">
                        </div>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('distilleryName').errors || accidentFormGroup.get('distilleryName').touched "
                        class="error-msg">
                       <span *ngIf="accidentFormGroup.get('distilleryName').errors?.required">Distillery Name is required </span>
                  </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Quantity</label>
                            <input type="text" class="form-control" formControlName="quantity" disabled placeholder="">
                        </div>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('quantity').errors || accidentFormGroup.get('quantity').touched "
                        class="error-msg">
                       <span *ngIf="accidentFormGroup.get('quantity').errors?.required">Quantity is required </span>
                  </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Grade</label>
                            <input type="text" class="form-control" formControlName="grade" disabled placeholder="">
                        </div>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="{{'language.accidentallocationaddress' | translate}}" class="list-panel add-panel">
                <div class="row" formGroupName="addressList">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.country' | translate}}</label>
                            <input type="text" class="form-control" formControlName="country" [disabled]="true"
                                placeholder="">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.state' | translate}}</label>
                            <select class="form-control" formControlName="accidentalTypeStateCode"
                                (change)="onStateChange($event.target.value, licConsts.SEC_ADDR_APPLICANT)">
                                <option value="">{{selectOption}}</option>
                                <option *ngFor="let item of stateList" value="{{item.code}}">{{item.value}}</option>
                            </select>
                            <app-show-errors eleName="{{'language.state' | translate}}" [frmSub]="formSubmitted"
                                [control]="getFormCntr('accidentalTypeStateCode')">
                            </app-show-errors>
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('accidentalTypeStateCode').errors || accidentFormGroup.controls['addressList'].get('accidentalTypeStateCode').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('accidentalTypeStateCode').errors?.required"><i
                                        class="fa fa-times"></i>
                                    State is Required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.district' | translate}}</label>
                            <select class="form-control" formControlName='accidentalTypeDistrictCode'
                                (change)="onDistrictChange($event.target.value, licConsts.SEC_ADDR_APPLICANT)">
                                <option value="">{{ 'usermanagement.common.select' | translate }}</option>
                                <option *ngFor="let item of districtListByState[licConsts.SEC_ADDR_APPLICANT]"
                                    value="{{item.code}}">{{item.value}}</option>
                            </select>
                            <app-show-errors eleName="{{'language.district' | translate}}" [frmSub]="formSubmitted"
                                [control]="getFormCntr('accidentalTypeDistrictCode')">
                            </app-show-errors>
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('accidentalTypeDistrictCode').errors || accidentFormGroup.controls['addressList'].get('accidentalTypeDistrictCode').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('accidentalTypeDistrictCode').errors?.required"><i
                                        class="fa fa-times"></i>
                                    District is Required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="!showAppliTehsilInput">
                        <div class="form-group">
                            <label class="mandatory">{{'language.tehsil' | translate}}</label>
                            <select class="form-control" formControlName='accidentalTypeTehsilCode'
                                (change)="onTalukChange($event.target.value, licConsts.SEC_ADDR_APPLICANT)">
                                <option disabled selected value="">Select</option>
                                <option *ngFor="let item of talukList" value="{{item.value}}">
                                    {{item.value}}</option>
                            </select>
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').errors || accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilCode').errors?.required"><i
                                        class="fa fa-times"></i>
                                        Tehsil is Required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="showAppliTehsilInput">
                        <div class="form-group">
                            <label class="mandatory">{{'language.tehsil' | translate}}</label>
                            <input type="text" min="0" (keypress)="onlyDigits($event)"
                                (input)="onTalukChange($event.target.value, licConsts.SEC_ADDR_APPLICANT)"
                               maxlength="20" class="form-control"
                                formControlName="accidentalTypeTehsilValue"
                                placeholder="{{'language.enter' | translate}} {{'language.tehsil' | translate}}">
                                <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').errors || accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('accidentalTypeTehsilValue').errors?.required"><i
                                        class="fa fa-times"></i>
                                        Tehsil is Required
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.urban/rural' | translate}} </label>
                            <select class="form-control" formControlName='localityType'
                                (change)="onLocalityChange($event.target.value, licConsts.SEC_ADDR_APPLICANT)">
                                <option disabled selected value="">{{'language.select' | translate}}</option>
                                <option *ngFor="let item of localityTypeList" value="{{item}}">
                                    {{item}}</option>
                            </select>

                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('localityType').errors || accidentFormGroup.controls['addressList'].get('localityType').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('localityType').errors?.required"><i
                                        class="fa fa-times"></i>
                                    Urban/Rural is Required
                                </span>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="appliAddrLocality == licConsts.LOCALITY_URBAN">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">{{'licenseMgnt.municipalityText' | translate}}</label>
                                <input type="text" class="form-control" formControlName="municipalityName"
                                    (keypress)="alphanumericSomeSplChar($event)"
                                    placeholder="{{'licenseMgnt.municipalityText' | translate}}" maxlength="20">
                                    <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('municipalityName').errors || accidentFormGroup.controls['addressList'].get('municipalityName').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['addressList'].get('municipalityName').errors?.required"><i
                                            class="fa fa-times"></i>
                                       Municipal Area is Required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">{{'licenseMgnt.wardText' | translate}}</label>
                                <input type="text" class="form-control" formControlName="ward"
                                    (keypress)="alphanumericSomeSplChar($event)"
                                    placeholder="{{'licenseMgnt.wardPlaceHold' | translate}}" maxlength="20">
                                    <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('ward').errors || accidentFormGroup.controls['addressList'].get('ward').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['addressList'].get('ward').errors?.required"><i
                                            class="fa fa-times"></i>
                                       Ward is Required
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="appliAddrLocality == licConsts.LOCALITY_RURAL">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">{{'licenseMgnt.blockText' | translate}}</label>
                                <input type="text" class="form-control" formControlName="block"
                                    (keypress)="alphanumericSomeSplChar($event)"
                                    placeholder="{{'licenseMgnt.blockPlaceHold' | translate}}" maxlength="20">
                                    <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('block').errors || accidentFormGroup.controls['addressList'].get('block').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['addressList'].get('block').errors?.required"><i
                                            class="fa fa-times"></i>
                                       Block is Required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">{{'licenseMgnt.villageText' | translate}}</label>
                                <input type="text" class="form-control" formControlName="village"
                                    (keypress)="alphanumericSomeSplChar($event)"
                                    placeholder="{{'licenseMgnt.villagePlaceHold' | translate}}" maxlength="20">
                                    <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('village').errors || accidentFormGroup.controls['addressList'].get('village').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['addressList'].get('village').errors?.required"><i
                                            class="fa fa-times"></i>
                                       Village is Required
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'bondWarehouse.accidentalcase.municipalarea' |
                                translate}}</label>
                            <input type="text" required class="form-control" formControlName="municipalityName"
                                placeholder="Enter Municipal Area" />
                        </div>
                        <app-show-errors eleName="{{'bondWarehouse.accidentalcase.municipalarea' | translate}}"
                            [frmSub]="formSubmitted" [control]="getFormCntr('municipalityName')">
                        </app-show-errors>
                        <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('municipalityName').errors || accidentFormGroup.controls['addressList'].get('municipalityName').touched "
                            class="error-msg">
                            <span
                                *ngIf="accidentFormGroup.controls['addressList'].get('municipalityName').errors?.required"><i
                                    class="fa fa-times"></i>
                                Municipality Area is Required
                            </span>
                        </div>
                    </div> -->

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.policestation' | translate}}</label>
                            <input type="text" class="form-control" formControlName="policeStation"
                                placeholder="{{'language.enter' | translate}} {{'language.policestation' | translate}}" />
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('policeStation').errors || accidentFormGroup.controls['addressList'].get('policeStation').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('policeStation').errors?.required"><i
                                        class="fa fa-times"></i>
                                    Police Station {{'language.isrequired' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.locality' | translate}}</label>
                            <input type="text" class="form-control" formControlName="locality"
                                placeholder="{{'language.enter' | translate}} {{'language.locality' | translate}}" />
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('locality').errors || accidentFormGroup.controls['addressList'].get('locality').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('locality').errors?.required"><i
                                        class="fa fa-times"></i>
                                    {{'language.locality' |translate}} {{'language.isrequired' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.street' | translate}}</label>
                            <input type="text" class="form-control" formControlName="street"
                                placeholder="{{'licenseMgnt.streetPlaceHold' | translate}}" maxlength="50" />
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('street').errors || accidentFormGroup.controls['addressList'].get('street').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('street').errors?.required"><i
                                        class="fa fa-times"></i>
                                    {{'language.street' |translate}} {{'language.isrequired' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.pincode' | translate}}</label>
                            <input type="text" min="0" (keypress)="onlyDigits($event)"
                                (input)="getDistrictStateCodeByPin($event,licConsts.SEC_ADDR_APPLICANT)"
                                *ngIf="showAppliPinInput" maxlength="6" class="form-control" formControlName="pinCode"
                                placeholder="{{'language.enter' | translate}} {{'language.pincode' | translate}}" />

                            <select class="form-control" *ngIf="!showAppliPinInput" formControlName='pinCode'>
                                <option value="" [disabled]="true">--Select--</option>
                                <ng-container *ngFor="let item of pinCodeList[licConsts.SEC_ADDR_APPLICANT]">
                                    <option [value]="item.code" [disabled]="item.disabled">{{ item.value }}</option>
                                </ng-container>
                            </select>
                            <div *ngIf="formSubmitted && accidentFormGroup.controls['addressList'].get('pinCode').errors || accidentFormGroup.controls['addressList'].get('pinCode').touched "
                                class="error-msg">
                                <span
                                    *ngIf="accidentFormGroup.controls['addressList'].get('pinCode').errors?.required"><i
                                        class="fa fa-times"></i>
                                    {{'language.pincode' |translate}} {{'language.isrequired' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="{{'language.accidentaldetails' | translate}}" class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.accidentaltype' | translate}}</label>
                            <select formControlName="accidentalType" class="form-control"
                                (change)="changeAccidentType($event.target.value)">
                                <option value="">{{'sugarmill.common.select' | translate}}</option>
                                <option *ngFor="let el of accidentTypeList" value="{{el.code}}">{{el.value}}</option>
                            </select>
                            <app-show-errors eleName="{{'language.accidentaltype' | translate}}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('accidentalType')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'language.accidentdate' | translate}}</label>
                            <input type="date" class="form-control" formControlName="accidentDate" [min]="minAccidentDate" [max]="maxAccidentDate"
                                (keydown)="$event.preventDefault()" placeholder="dd-mm-yyyy">
                            <app-show-errors eleName="{{'language.accidentdate' | translate}}" [frmSub]="formSubmitted"
                                [control]="getFormCntr('accidentDate')">
                            </app-show-errors>
                        </div>
                    </div>
                </div>
            </app-card>

            <ng-container *ngIf="accidentType == 'VEHICLE_DAMAGED_BUT_PRODUCT_GOOD'">
                <app-card cardTitle="{{'language.vehicledamagedproductgood' | translate}}" class="list-panel add-panel">
                    <div class="row" formGroupName="vehicleDamagedButProductGoodDetails">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">{{'language.doyouwantchangevehicledtls' | translate}}</label>
                                <div class="segment">
                                    <input type="radio" name="changeTheVehicleDetails" id="tab1" value="Yes"
                                        (click)="clickChangeVehicle($event.target.value)"
                                        formControlName="changeTheVehicleDetails">
                                    <label class="" for="tab1">{{'language.yes' | translate}}</label>
                                    <input type="radio" name="changeTheVehicleDetails" id="tab2" value="No"
                                        (click)="clickChangeVehicle($event.target.value)"
                                        formControlName="changeTheVehicleDetails">
                                    <label class="" for="tab2">{{'language.no' | translate}}</label>
                                </div>

                                <div *ngIf="formSubmitted && accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('changeTheVehicleDetails').errors || accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('changeTheVehicleDetails').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('changeTheVehicleDetails').errors?.required"><i
                                            class="fa fa-times"></i>
                                        {{'language.doyouwantchangevehicledtls' | translate}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label>Mode Of Transport</label>
                                <input type="text" disabled class="form-control" formControlName="modeOfTransport"
                                    placeholder="Enter Mode Of Transport">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport">
                            <div class="form-group">
                                <label>Start Reading</label>
                                <input type="text" class="form-control" formControlName="startReading"
                                    placeholder="Enter Start Reading">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport">
                            <div class="form-group">
                                <label>End Reading</label>
                                <input type="text" class="form-control" formControlName="endReading"
                                    placeholder="Enter End Reading">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.transportername' | translate}}</label>
                                <input type="text" class="form-control" formControlName="transporterName"
                                    placeholder="{{'language.enter' | translate}} {{'language.transportername' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.vehicleno' | translate}}</label>
                                <input type="text" class="form-control" maxlength="10"
                                    (keypress)="restrictSpecialChar($event)" formControlName="vehicleNo"
                                    placeholder="{{'language.enter' | translate}} {{'language.vehicleno' | translate}}">
                                <!-- <div *ngIf="formSubmitted && accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleNo').errors || accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleNo').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('vehicleNo').errors?.required"><i
                                            class="fa fa-times"></i>
                                        {{'language.vehicleno' |translate}} {{'language.isrequired' | translate}}
                                    </span>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.vehicledrivername' | translate}}</label>
                                <input type="text" class="form-control" formControlName="vehicleDriverName"
                                    placeholder="{{'language.enter' | translate}} {{'language.vehicledrivername' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.routetype' | translate}}</label>
                                <input type="text" class="form-control" formControlName="routeType"
                                    placeholder="{{'language.enter' | translate}} {{'language.routetype' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.routedetails' | translate}}</label>
                                <input type="text" class="form-control" formControlName="routeDetails"
                                    placeholder="{{'language.enter' | translate}} {{'language.routedetails' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.majorroute' | translate}}</label>
                                <input type="text" class="form-control" formControlName="majorRoute"
                                    placeholder="{{'language.enter' | translate}} {{'language.majorroute' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.gpsid' | translate}}</label>
                                <input type="number" class="form-control" formControlName="gpsId"
                                    placeholder="{{'language.gpsid' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mandatory">{{'language.doyouwantextendtranspassvalidity' | translate}}
                                </label>
                                <div class="segment">
                                    <input type="radio" name="extendTransportPassValidity" id="tab3" value="Yes"
                                    (click)="validityExtend($event.target.value)"
                                    formControlName="extendTransportPassValidity">
                                <label class="" for="tab3">{{'language.yes' | translate}}</label>
                                <input type="radio" name="extendTransportPassValidity" id="tab4" value="No"
                                (click)="validityExtend($event.target.value)"
                                    formControlName="extendTransportPassValidity">
                                <label class="" for="tab4">{{'language.no' | translate}}</label>
                                </div>

                                <div *ngIf="formSubmitted && accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('extendTransportPassValidity').errors || accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('extendTransportPassValidity').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['vehicleDamagedButProductGoodDetails'].get('extendTransportPassValidity').errors?.required"><i
                                            class="fa fa-times"></i>
                                        {{'language.doyouwantextendtranspassvalidity' | translate}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.remainingkms' | translate}}</label>
                                <input type="text" class="form-control" formControlName="remainingKms"
                                    placeholder="{{'language.enter' | translate}} {{'language.remainingkms' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label>{{'language.validupto' | translate}}</label>
                                <input type="text" formControlName="validUpTo" [min]="minAccidentDate" [max]="maxAccidentDate"
                                    (keydown)="$event.preventDefault()" placeholder="DD/MM/YYYY" class="form-control"
                                    [owlDateTimeTrigger]="dtPickerValidupto" [owlDateTime]="dtPickerValidupto"
                                    id="validUpTo">
                                <owl-date-time #dtPickerValidupto [pickerType]="'calendar'"></owl-date-time>
                            </div>
                        </div>
                    </div>
                </app-card>
            </ng-container>

            <ng-container *ngIf="accidentType == 'PRODUCT_DAMAGED_BUT_VEHICLE_GOOD'">
                <!--/product damaged but vehicle good -->
                <app-card cardTitle="Product Damaged but Vehicle good" class="list-panel add-panel">
                    <div class="row" formGroupName="productDamagedButVehicleGoodDetails">
                        <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <label>{{'language.scanbarcodeqrcode' | translate}}</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" formControlName="mf4GatePassNo"
                                    placeholder="{{ 'language.scanbarcode' | translate }}"
                                    (keypress)="checkLength($event.target.value, 12)">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button" (click)="scanNumber()"
                                        [disabled]="!accidentFormGroup.value.mf4GatePassNo">
                                        <i class="fas fa-qrcode"></i>
                                    </button>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label>Total Quantity (Qtls) </label>
                                <input type="text" appTwoDigitDecimaNumber maxlength="5" required class="form-control"
                                    formControlName="totalQuantityOfMolasses"
                                    placeholder="Enter Total No. of Cases Scanned">
                            </div>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label>Total No. of Bottles Scanned</label>
                                <input type="text" maxlength="5" required class="form-control"
                                    formControlName="damagedQuantityOfMolasses"
                                    placeholder="Enter Total No. of Bottles Scanned">
                            </div>
                        </div> -->
                    </div>
                </app-card>
            </ng-container>
            <!--/both damaged-->

            <ng-container *ngIf="accidentType == 'BOTH_DAMAGED'">
                <app-card cardTitle="{{'language.bothdamaged' | translate}}" class="list-panel add-panel">
                    <div class="row" formGroupName="productAndVehicleBothDamagedDetails">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group">
                                <label>Mode Of Transport</label>
                                <input type="text" disabled class="form-control" formControlName="modeOfTransport"
                                    placeholder="Enter Mode Of Transport">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport">
                            <div class="form-group">
                                <label>Start Reading</label>
                                <input type="text" disabled class="form-control" formControlName="startReading"
                                    placeholder="Enter Start Reading">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport">
                            <div class="form-group">
                                <label>End Reading</label>
                                <input type="text" disabled class="form-control" formControlName="endReading"
                                    placeholder="Enter End Reading">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.transportername' | translate}}</label>
                                <input type="text" class="form-control" disabled formControlName="transporterName"
                                    placeholder="{{'language.bothdamaged' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label class="mandatory">{{'language.vehicleno' | translate}}</label>
                                <input type="text" class="form-control" maxlength="10"
                                    disabled formControlName="vehicleNo">
                                <!-- <div *ngIf="formSubmitted && accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].get('vehicleNo').errors || accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].get('vehicleNo').touched "
                                    class="error-msg">
                                    <span
                                        *ngIf="accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].get('vehicleNo').errors?.pattern"><i
                                            class="fa fa-times"></i>
                                        Invalid {{'language.vehicleno' |translate}}
                                    </span>
                                    <span
                                        *ngIf="accidentFormGroup.controls['productAndVehicleBothDamagedDetails'].get('vehicleNo').errors?.required"><i
                                            class="fa fa-times"></i>
                                        {{'language.vehicleno' |translate}} {{'language.isrequired' | translate}}
                                    </span>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <div class="form-group" *ngIf="isTransport==false">
                                <label>{{'language.vehicledrivername' | translate}}</label>
                                <input type="text" class="form-control" disabled formControlName="vehicleDriverName"
                                    placeholder="{{'language.vehicledrivername' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.routedetails' | translate}}</label>
                                <input type="text" class="form-control" disabled formControlName="routeDetails"
                                    placeholder="{{'language.routedetails' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="isTransport==false">
                            <div class="form-group">
                                <label>{{'language.gpsid' | translate}}</label>
                                <input type="text" class="form-control" formControlName="gpsId"
                                    placeholder="{{'language.gpsid' | translate}}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label>Do you want to send back?
                                </label>
                                <div class="segment">
                                    <input type="radio" name="wantToSendBack" value="Yes" id="tab1"
                                        formControlName="wantToSendBack" checked>
                                    <label class="" for="tab1">Yes</label>
                                    <input type="radio" name="wantToSendBack" value="No" id="tab2"
                                        formControlName="wantToSendBack">
                                    <label class="" for="tab2">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-card>
            </ng-container>
            <app-card cardTitle="Upload Documents and Wastage" class="list-panel add-panel" *ngIf="designation == 'DEO'">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12" *ngIf="accidentFormGroup.value.accidentalType == 'BOTH_DAMAGED'">
                        <div class="form-group">
                            <label>Deo upload the accidental report<span
                                class="text-primary">&#42;</span>
                            </label>

                            <div class="input-group cust-file-button">
                                <div class="custom-file">
                                    <input type="file" formControlName="deoUploadAccidentalReport" id="customFilessss"
                                        (change)="onFileChange($event, 'deoUploadAccidentalReportFile', accidentFormGroup, 'deoUploadAccidentalReport')"
                                        accept="image/png,image/jpeg,image/jpg,application/pdf,image/x-png"
                                        class="custom-file-input" name="deoUploadAccidentalReport" id="deoUploadAccidentalReport">
                                    <label class="custom-file-label"></label>
                                    <label class="custom-file-label" for="customFilessss"></label>
                                </div>
                            </div>
                            <div *ngIf="getDocName('Deo upload the accidental report')">
                                {{getDocName('Deo upload the accidental report').documentName}}
                                <button class="btn btn-primary" (click)="downloadDocument(getDocName('Deo upload the accidental report').uuid, 
                                        getDocName('Deo upload the accidental report').documentName)">
                                    <i class="feather icon-download"></i>
                                </button>
                            </div>
                            <!-- <app-show-errors eleName="Deo upload the accidental report" [frmSub]="formSubmitted"
                                [control]="getFormCntr('deoUploadAccidentalReport')">
                            </app-show-errors> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Upload the photo of accidental tanker<span
                                class="text-primary">&#42;</span>
                            </label>

                            <div class="input-group cust-file-button">
                                <div class="custom-file">
                                    <input type="file" formControlName="uploadAccidentalTanker" id="customFilessss"
                                        (change)="onFileChange($event, 'uploadAccidentalTankerFile', accidentFormGroup, 'uploadAccidentalTanker')"
                                        accept="image/png,image/jpeg,image/jpg,application/pdf,image/x-png"
                                        class="custom-file-input" name="uploadAccidentalTanker" id="uploadAccidentalTanker"
                                        >
                                    <label class="custom-file-label"></label>
                                    <label class="custom-file-label" for="customFilessss"></label>
                                </div>
                            </div>
                            <div *ngIf="getDocName('Upload the photo of accidental tanker')">
                                {{getDocName('Upload the photo of accidental tanker').documentName}}
                                <button class="btn btn-primary" (click)="downloadDocument(getDocName('Upload the photo of accidental tanker').uuid, 
                                        getDocName('Upload the photo of accidental tanker').documentName)">
                                    <i class="feather icon-download"></i>
                                </button>
                            </div>
                            <!-- <app-show-errors eleName="Upload the photo of accidental tanker" [frmSub]="formSubmitted"
                                [control]="getFormCntr('uploadAccidentalTanker')">
                            </app-show-errors> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>E-Way Bill<span
                                class="text-primary">&#42;</span>
                            </label>

                            <div class="input-group cust-file-button">
                                <div class="custom-file">
                                    <input type="file" formControlName="eWayBill" id="customFilessss"
                                        (change)="onFileChange($event, 'eWayBillFile', accidentFormGroup, 'eWayBill')"
                                        accept="image/png,image/jpeg,image/jpg,application/pdf,image/x-png"
                                        class="custom-file-input" name="eWayBill" id="eWayBill"
                                        >
                                    <label class="custom-file-label"></label>
                                    <label class="custom-file-label" for="customFilessss"></label>
                                </div>
                            </div>
                            <div *ngIf="getDocName('E-Way Bill')">
                                {{getDocName('E-Way Bill').documentName}}
                                <button class="btn btn-primary" (click)="downloadDocument(getDocName('E-Way Bill').uuid, 
                                        getDocName('E-Way Bill').documentName)">
                                    <i class="feather icon-download"></i>
                                </button>
                            </div>
                            <!-- <app-show-errors eleName="Upload the photo of accidental tanker" [frmSub]="formSubmitted"
                                [control]="getFormCntr('uploadAccidentalTanker')">
                            </app-show-errors> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Tax invoice<span
                                class="text-primary">&#42;</span>
                            </label>

                            <div class="input-group cust-file-button">
                                <div class="custom-file">
                                    <input type="file" formControlName="taxInvoice" id="customFilessss"
                                        (change)="onFileChange($event, 'taxInvoiceFile', accidentFormGroup, 'taxInvoice')"
                                        accept="image/png,image/jpeg,image/jpg,application/pdf,image/x-png"
                                        class="custom-file-input" name="taxInvoice" id="taxInvoice"
                                        >
                                    <label class="custom-file-label"></label>
                                    <label class="custom-file-label" for="customFilessss"></label>
                                </div>
                            </div>
                            <div *ngIf="getDocName('Tax invoice')">
                                {{getDocName('Tax invoice').documentName}}
                                <button class="btn btn-primary" (click)="downloadDocument(getDocName('Tax invoice').uuid, 
                                        getDocName('Tax invoice').documentName)">
                                    <i class="feather icon-download"></i>
                                </button>
                            </div>
                            <!-- <app-show-errors eleName="Upload the photo of accidental tanker" [frmSub]="formSubmitted"
                                [control]="getFormCntr('uploadAccidentalTanker')">
                            </app-show-errors> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>FIR copy<span
                                class="text-primary">&#42;</span>
                            </label>

                            <div class="input-group cust-file-button">
                                <div class="custom-file">
                                    <input type="file" formControlName="firCopy" id="customFilessss"
                                        (change)="onFileChange($event, 'firCopyFile', accidentFormGroup, 'taxInvoice')"
                                        accept="image/png,image/jpeg,image/jpg,application/pdf,image/x-png"
                                        class="custom-file-input" name="taxInvoice" id="taxInvoice"
                                        >
                                    <label class="custom-file-label"></label>
                                    <label class="custom-file-label" for="customFilessss"></label>
                                </div>
                            </div>
                            <div *ngIf="getDocName('FIR copy')">
                                {{getDocName('FIR copy').documentName}}
                                <button class="btn btn-primary" (click)="downloadDocument(getDocName('FIR copy').uuid, 
                                        getDocName('FIR copy').documentName)">
                                    <i class="feather icon-download"></i>
                                </button>
                            </div>
                            <!-- <app-show-errors eleName="Upload the photo of accidental tanker" [frmSub]="formSubmitted"
                                [control]="getFormCntr('uploadAccidentalTanker')">
                            </app-show-errors> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>Wastage of molasses</label>
                            <input type="text" appTwoDigitDecimaNumber maxlength="10" class="form-control"
                                formControlName="molassesWastage" placeholder="Enter the Wastage Of Molasses">
                        </div>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="Upload Documents and Wastage" class="list-panel view-panel" *ngIf="designation != 'DEO'">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                                *ngFor="let i of uploaddata let i = index">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <label class="w-100 view-label">{{i.name}}<span
                                                class="float-right">:</span></label>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <span class="download-text">{{i.documentName}} <button
                                                class="btn btn-icon btn-primary mb-2" type="button"
                                                (click)="downloadDocument(i.uuid,i.documentName)">
                                                <i class="feather icon-download"></i></button></span>
                                        <span class="download-text ml-1"><button class="btn btn-icon btn-primary mb-2"
                                                type="button" (click)="DownloadDoc(i.uuid,i.documentName)">
                                                <i class="feather icon-eye"></i></button></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Wastage of molasses<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editdata?.molassesWastage}}
                    </div>
                </div>

            </app-card>
            <app-card cardTitle="Penalty Details" class="list-panel view-panel" *ngIf="designation == 'DEO'">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Fine to be Imposed</label>
                            <div class="segment">
                                <input type="radio" name="FineImposed" id="tab11" [value]="true"
                                    formControlName="FineImposed" (change)="getFineAmount()">
                                <label class="" for="tab11">{{'language.yes' | translate}}</label>
                                <input type="radio" name="FineImposed" id="tab12" [value]="false"
                                    formControlName="FineImposed" (change)="getFineAmount()">
                                <label class="" for="tab12">{{'language.no' | translate}}</label>
                            </div>
                        </div><br>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('FineImposed').errors || accidentFormGroup.get('FineImposed').touched "
                            class="error-msg">
                            <span *ngIf="accidentFormGroup.get('FineImposed').errors?.required"> Fine to be Imposed is
                                required </span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="isPaymentEnable==true">
                        <div class="form-group">
                            <label class="mandatory">Fine Type</label>
                            <div class="segment">
                                <input type="radio" name="fineType" id="tab13" value="Penalty" formControlName="fineType"
                                (change)="getFineLimit($event.target.value)">
                                <label class="" for="tab13">Penalty</label>
                                <input type="radio" name="fineType" id="tab14" value="Compounding" formControlName="fineType"
                                (change)="getFineLimit($event.target.value)">
                                <label class="" for="tab14">Compounding</label>
                            </div>
                        </div><br>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('fineType').errors || accidentFormGroup.get('fineType').touched "
                        class="error-msg">
                        <span *ngIf="accidentFormGroup.get('fineType').errors?.required">Fine Type is
                            required </span>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="isPaymentEnable==true">
                        <div class="form-group">
                            <label class="mandatory">Fine Amount (Rs.)</label>
                            <input type="text" appTwoDigitDecimaNumber maxlength="10" class="form-control" formControlName="fees" placeholder="Enter the Fees">
                        </div>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('fees').errors || accidentFormGroup.get('fees').touched "
                        class="error-msg">
                        <span *ngIf="accidentFormGroup.get('fees').errors?.required"> Fees is
                            required </span>
                            <span *ngIf="accidentFormGroup.get('fees').errors?.min">Penalty Fees should be greater than 0</span>
                            <span *ngIf="accidentFormGroup.get('fees').hasError('max') && accidentFormGroup.get('fineType').value == 'Penalty'">Penalty Fees should be less than 50000</span>
                            <span *ngIf="accidentFormGroup.get('fees').hasError('max') && accidentFormGroup.get('fineType').value == 'Compounding'"> compounding Fees should be less than 100000</span>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Remark</label>
                            <input type="text" class="form-control" placeholder="Enter Remarks"
                                formControlName="penaltyRemark">
                        </div>
                        <div *ngIf="formSubmitted && accidentFormGroup.get('penaltyRemark').errors || accidentFormGroup.get('penaltyRemark').touched "
                        class="error-msg">
                        <span *ngIf="accidentFormGroup.get('penaltyRemark').errors?.required">Remark is Required</span>
                    </div>
                    </div>
                </div>
            </app-card>
        </form>
    </div>


    <div class="col-12 text-right mb-4">
        <div class="col-12 text-right mb-4">
            <!-- <button type="button" class="btn btn-info ml-2 mb-2" *ngIf="isDrafthide"
                (click)="onSubmit('DRAFT', true)">{{'parentunit.common.preview'
                |
                translate}}</button> -->
            <button type="button" class="btn btn-warning ml-2 mb-2" *ngIf="isDrafthide"
                (click)="onSubmit('DRAFT')">{{'parentunit.common.saveasdraft' | translate}}</button>
            <button type="button" class="btn btn-primary ml-2 mb-2"
                (click)="onSubmit('SUBMITTED')">{{'parentunit.common.submit'
                | translate}}</button>
            <button type="button" class="btn btn-secondary ml-2 mb-2"
                routerLink="/sugarmill/{{outsideupvalidation}}/list">{{'parentunit.common.cancel' |
                translate}}</button>
        </div>
    </div>
</div>
