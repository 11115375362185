import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class StockInService {
  baseURL = environment.parentUnit;
  masterTypeURL = environment.baseURLMasterIpAddr;
  constructor(private http: HttpClient) { }
  getAllStockIn(): Observable<any> {
    const url = this.baseURL + apiUrls.stockInList;
    return this.http.get(url);
  }
  getStockInById(id): Observable<any> {
    const url = this.baseURL + apiUrls.getStockById + id;
    return this.http.get(url);
  }
  getMolassesTypeById(id): Observable<any> {
    const url = this.baseURL + apiUrls.getMolassesTypeById + id;
    return this.http.get(url);
  }
  addStockIn(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addStock;
    return this.http.post(url, postdata);
  }
  updateStockIn(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.updateStock;
    return this.http.put(url, postdata);
  }
  searchStockIn(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.stockinSearch;
    return this.http.post(url, postdata);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }
  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.searchTankReg;
    return this.http.post(url, postdata);
  }

  gettankbyid(id) {
    const ids = `?tankId=${id}`
    const url = this.baseURL + `${apiUrls.gettankbyid}${ids}`;
    return this.http.get(url);
  }

  gettanksbyid(id) {
    const ids = `?gradeId=${id}`
    const url = this.baseURL + `${apiUrls.gettanksbyid}${ids}`;
    return this.http.get(url);
  }
  getTanksById(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.gettanksbyid;
    return this.http.post(url, postdata);
  }
  gettanksbyproductid(id) {
    const ids = `?productTypeId=${id}`
    const url = this.baseURL + `${apiUrls.gettankbyproductid}${ids}`;
    return this.http.get(url);
  }

  getlatestfortnight() {
    const url = this.baseURL + apiUrls.getlatestfortnight;
    return this.http.get(url);
  }
  getTankDetail(id) {
    const ids = `?tankId=${id}`
    const url = this.baseURL + `${apiUrls.transferTank}${ids}`;
    return this.http.get(url);
  }
  getTanksByGrade(id) {
    const ids = `?gradeId=${id}`
    const url = this.baseURL + `${apiUrls.getTankByGradeId}${ids}`;
    return this.http.get(url);
  }
  getTankDetails(id) {
    const ids = `?id=${id}`
    const url = this.baseURL + `${apiUrls.getTankDetailsTransfer}${ids}`;
    return this.http.get(url);
  }
  getAllProductType(id): Observable<any> {
    const ids = `?dropDownKey=${id}`;
    const url = this.masterTypeURL + `${apiUrls.findDropDownMasterOnMaster}${ids}`;
    return this.http.get(url);
  }
  getAllMolassesType(id): Observable<any> {
    const ids = `?dropDownKey=${id}`;
    const url = this.masterTypeURL + `${apiUrls.findDropDownMasterOnMaster}${ids}`;
    return this.http.get(url);
  }
  getTanRegById(id): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration?id='+ id;
    return this.http.get(url, id);
  }
  getTankDetailsByProductType(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getTankByProductType;
    return this.http.post(url, postdata);
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= '+ data;
    return this.http.post(url, data);
  }
  getStockinRemainingQuantity(date): Observable<any> {
    const url = this.baseURL + apiUrls.getStockinRemainQty + date;
    return this.http.get(url, date);
  }
  getStockInStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'mf5StockIn/getStockInStatusCount?entityType=' + entityType;
    return this.http.get(url);
  }
  getStatusCountSugarmill(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'mf5StockIn/getStockInStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  getPreviousFortNight(entityType?): Observable<any> {
    const url = this.baseURL + 'mf5StockIn/getPreviousFortNightExists?entityType=' + entityType;
    return this.http.get(url);
  }
  download(payload){
     const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getstatuscount(payload):Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount';
    return this.http.post(url,payload);
  }
}
