import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
@Injectable({
  providedIn: 'root'
})
export class ReturnFilingService {

  constructor(private http: HttpClient) {

  }
  baseURL = environment.retailURL;  
  licenseURL = environment.licneseBaseUrl;
  licensemanagement=environment.licencemanagementURL;
  scmproduction = environment.scmproduction;  
  parentUnit=environment.parentUnit
  
  
  search1(payload: any): Observable<any> {
   // console.log(payload);
    //const url = this.baseURL + 'customdata/getdata';
    const url = this.baseURL + 'customdata/getdata';   
    return this.http.post(url, payload);
  }
  
  search2(data): Observable<any> {
    const url = this.licenseURL+'license/category/'+ data;
    return this.http.get(url);
  }
  search3(data): Observable<any> {   
  const url = this.licensemanagement+'license/category/BOTTLING?licenseNumber='+data;
  return this.http.get(url);
  }
  liquortype(payload): Observable<any> {
    //const url = 'https://testapigateway.upexciseonline.co/master/v1.0.0/api/masterValues/findDropDownMaster?dropDownKey=LIQUOR_TYPE&additionalFilter2='+val;
   // const url='http://65.0.150.210:3503/masterData/api/masterValues/findDropDownMaster?dropDownKey=LIQUOR_TYPE&additionalFilter2='+val;
    //return this.http.get(url);
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, payload);
  } 

  getlicensetype(payload)
  { 
    const url =  this.baseURL + 'userallocatedunit/getapplicationnumbers';
     return this.http.post(url, payload);
   }

   getprofile(payload)
   { 
     const url =  this.baseURL + 'entityprofile/getprofile';
      return this.http.post(url, payload);
    }
 getlist(payload){
  const url =  this.scmproduction + 'returnFiling/LazySearch';
  return this.http.post(url, payload);
 }
 
 getrandomnumber(payload): Observable<any> {
  const url = this.parentUnit+'generateApplicationNumber?registrationType=RF_';
  //return this.http.post(url,payload);
  return this.http.post(url, payload);
}
submitreturnfile(payload): Observable<any> {
  const url = this.parentUnit+'returnFiling';
  //return this.http.post(url,payload);
  return this.http.post(url, payload);
}
 //list page api started
 getreturnfiling(payload): Observable<any> {
  const url = this.parentUnit+'returnFiling/LazySearch';
  //return this.http.post(url,payload);
  return this.http.post(url, payload);
}

getreturnfilingview(data): Observable<any> {
  const url = this.parentUnit+'returnFiling/'+ data;
  return this.http.get(url);
}

downloadData(payload) {
  const url = this.parentUnit + 'reportReceipt/downloadReceipt';
  //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt'
  // const url = 'http://192.168.3.144:3501/scmProduction/reportReceipt/downloadReceipt';
  
  return this.http.get(url, { responseType: 'blob', params: payload });
  }
  

 
}
 