import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OmdStockInComponent } from './omd-stock-in.component';
import { AddOmdStockInComponent } from './add-omd-stock-in/add-omd-stock-in.component';
import { ViewOmdStockInComponent } from './view-omd-stock-in/view-omd-stock-in.component';


const routes: Routes = [
  {path:'list', component:OmdStockInComponent},
  {path:'add', component:AddOmdStockInComponent},
  {path:'edit/:id', component:AddOmdStockInComponent},
  {path:'view/:id',component:ViewOmdStockInComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OmdStockInRoutingModule { }
