import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
@Injectable({
  providedIn: 'root'
})
export class AddcomplaintagainstexciseofclService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;

    getdetails(): Observable<any> {
     // const url = this.licenseURL + 'license/category/SUGAR_MILL';// nisha ma'am
      const url = this.licenseURL + 'license/category/BREWERY';
      return this.http.get(url);
    }

    addComplaintAgainstExciseOfcl(data): Observable<any> {
          const url = this.baseURL + 'vigilance';
        // const url = 'http://localhost:3501/scmProduction/vigilance';
        return this.http.post(url, data);
    }

    downloadcomplaint(payload){
      const url = this.baseURL + 'reportReceipt/downloadReceipt'
      //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
      return this.http.get(url,{ responseType: 'blob', params:payload });
    }

    search(payload): Observable<any> {
      const url = this.baseURL + 'vigilance/lazySearch';
      return this.http.post(url, payload);
  }

  getapplicationnumbers(data) {
    //const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    const url = this.baseURL + 'generateApplicationNumber?entityType=' +'VIGILANCE' + '&registrationType=' + data.registrationType ;+ '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {});
  }

  getComplaintById(id): Observable<any> {
    const url = this.baseURL + 'vigilance/'+id;
    return this.http.get(url);
  }

  // UpdateByComplaintById(payload): Observable<any> {
  //   const url = this.baseURL + 'vigilance/updateWorkFlow';
  //   return this.http.post(url, payload);
  // }

  getStatusMasters() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
    return this.http.get(url)
}
getDropdownKey(){

}

getapplicationnumber(data): Observable<any> {
  const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
  return this.http.post(url, data);
}
getPdCardCount(payload): Observable<any> {
  const url = this.baseURL + 'pd/getStatusCount'
  return this.http.post(url,payload);
}
  
}
