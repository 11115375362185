import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class Mf6PartOneService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'mf6part1';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'mf6part1/lazySearch';
    // const url = 'http://localhost:3501/scmProduction/mf6part1/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'mf6part1/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getTankList(data): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  getOpenBalance(lic?, enty = 'PD'): Observable<any> {
    const url = this.baseURL + 'mf6part1/getOpeningBalance?entityType='+enty+'&licenseNumber=' + lic;
    return this.http.get(url);
  }
  // getMF6Part1RegisterCount(entityType): Observable<any> {
  //   const url = this.baseURL + 'mf6part1/getMf6Part1RegisterCount?entityType=' + entityType;
  //   // const url = 'http://localhost:3501/scmProduction/mf6part1/getMf6Part1RegisterCount?entityType=PD';
  //   return this.http.get(url);
  // }
  downloadPdf(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }

  getMF6Part1RegisterCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'mf6part1/getStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    // const url =  'http://localhost:3501/scmProduction/mf6part1/getMf6Part1RegisterCount?entityType=' + entityType 
    // +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    // +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
}
