import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiUrls } from "@appEnv/apiurls";
import { environment } from "@appEnv/environment";
import { _throw } from "rxjs/observable/throw";
import { LicenceManagementService } from "./license-managment.service";
import { DatePipe } from "@angular/common";
import { Observable, throwError } from "rxjs";
import { BrandlabelbrewService } from '@app/services/brewery';
import { catchError, map } from "rxjs/operators";
 @Injectable({
  providedIn: "root",
})
export class CustomScmService {
  private readonly SCM_GETALLOCATED_LICENSENUMBER = "SCM_GETALLOCATED_LICENSENUMBER";
  private readonly SCM_SETENTITYTYPEFOROFFICER = "SCM_SETENTITYTYPEFOROFFICER";
  private readonly SCM_SUBUSER_ADD_ACCESS = "SCM_SUBUSER_ADD_ACCESS";
  authToken: any;
  private baseURL = environment.baseURLUser;
  private domainUrl = environment.domainUrl; 
  //user and licensenumber api call added
  constructor(private http: HttpClient,  private datepipe: DatePipe,
    private licenseSer: LicenceManagementService,private BrandlabelbrewService:BrandlabelbrewService) {}
  async getvalue() { 
    let value = await this.getUserdetailsbyid(localStorage.getItem("USER_ID")); 
    if (value.responseCode == 200) { 
      if (value.content.parentUserId) {
        //let getparentdetailsrole = await this.getparentdetailsrole();
        let getrolevalue = await this.getrolesbasedonsubuser(); 
        if(getrolevalue.content &&getrolevalue.responseCode==200 ){
          let containsEditValue = this.containsEdit(getrolevalue.content); 
          this.getparentdetailsid(containsEditValue) 
        } 
       
      }
      else{
        this.getparentdetailsid(true)
      }
    } 
    let officers= ['AECBREWERY','INSPECTORBREWERY','AECDISTILLERY','INSPECTORDISTILLERY','SUBINSSUG','VINTINERY_INS']; //selected officer mapped license number 
    if (localStorage.getItem("IS_CUSTOMER") == "false" &&   officers.includes(localStorage.getItem("DESIGNATION").toUpperCase())  ) {
      this.setenitytypeforofficer(true);
      let getlicensedetails = await this.getuserallocatedunit();
      if (getlicensedetails.responseCode == 200) { 
        let licensedetailsarray = getlicensedetails.content.map(item => ({licenseNumber: item.licenseNumber, licenseSubCategory: item.licenseSubCategory})); 
        this.setAllocatedlicensenumber(licensedetailsarray );
      }
    }
  } 

  containsEdit(obj) {
    for (let key in obj) {
      if(key.includes("EDIT")){
        return true;
      }
    }
    return false;
}
  setAllocatedlicensenumber(obj) {
    return localStorage.setItem(
      this.SCM_GETALLOCATED_LICENSENUMBER,
      JSON.stringify(obj)
    );
  }
  getparentdetailsid(obj) {
    return localStorage.setItem(this.SCM_SUBUSER_ADD_ACCESS, JSON.stringify(obj));
  }
setenitytypeforofficer(obj) {
    return localStorage.setItem(this.SCM_SETENTITYTYPEFOROFFICER, JSON.stringify(obj));
  }
  getuserallocatedunit(): any {
    const url = this.baseURL + "user/findUserAllocatedUnit";
    return this.http.get(url).toPromise();
  }
  getparentdetailsrole(): any {
    const url = this.baseURL + "roleMaster/findActiveRolesByUserId";
    return this.http.get(url).toPromise();
  }

  getUserdetailsbyid(userId): any {
    const url = this.baseURL + apiUrls.getCustomerById + "?id=" + userId;
    return this.http.get(url).toPromise();
  }
  getrolesbasedonsubuser(): any {
    const url = this.baseURL + 'roleMaster/findActiveRolesByUserId';
    return this.http.get(url).toPromise();
  }
  generateapplicationnumber(registrationType, entityType){
    let date = new Date()
    let year: number = date.getFullYear();
    let month: number = date.getMonth() + 1;
    let day: number = date.getDate();
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    let seconds: number = date.getSeconds();  
    return localStorage.getItem('userId')+ '/' + registrationType + '/' + entityType + '/' + year + month + day + hours + minutes + seconds;
  }
  OTHER_SOURCE_SYSTEM_EXIST_LOCAL()
  {
    if(localStorage.getItem('OTHER_SOURCE_SYSTEM')){
      return localStorage.getItem('OTHER_SOURCE_SYSTEM')
    }
    else{
      return '';
    } 
  }

  async backbuttonurlfetch(){
    let value:any=await this.licenseSer.redirectNMPagetopromise(localStorage.getItem('OTHER_APP_NUM')) 
      return value.content
  }
//Brand and label next year validation based on license expiry date
  nextyearbuttonbasedonlicense(details) {  
    if(details) {
    // Get the current year and expiry year 
    const dt = new Date();
    let currentYear: any = dt.getFullYear(); 
    if (dt.getMonth() == 0 || dt.getMonth() == 1 || dt.getMonth() == 2) {
      currentYear = currentYear;
    } else {
      currentYear = currentYear+1;
    } 
    let expiryYear = new Date(details.licenseExpiryDate) .getFullYear(); 
    if(expiryYear==currentYear){
      return 'RF001'
    }
    if (expiryYear > currentYear ) {
      return 'RF002'
    }
    else{return 'RF001'}    
   }
  }
 
  bottlingfeesfilterforbottlingplan(details) {   //CL bottlin plan fees mandatory for new licenses
    if(details) {
    // Get the current year and expiry year 
    const dt = new Date();
    let currentYear: any = dt.getFullYear();   
    let yearvalue;
    if (dt.getMonth() == 0 || dt.getMonth() == 1 || dt.getMonth() == 2) {
      yearvalue = currentYear;
    } else {
      yearvalue = currentYear+1;
    }
    let filteredvalue=details.filter(a=>a.licensetype.includes('CL')&&new Date(a.licenseExpiryDate).getFullYear()==yearvalue+1).map(a=>a.licenseNumber)
    if(filteredvalue&&filteredvalue.length>0){
     return true
    }
    else{
    return false 
    } 
   }
  } 
  //license restriction for autorenewal
  licensefilterrestrictionautorenewal(details) {  
    if(details) {
    // Get the current year and expiry year 
    const dt = new Date();
    let currentYear: any = dt.getFullYear();   
   let filteredvalue=details.filter(a=>new Date(a.licenseExpiryDate).getFullYear()==currentYear+1).map(a=>a.licenseno)
    return filteredvalue 
   }
  }

  // alert api hit ->payment api response for insufficient balance

  insufficientbalanceresponse(postParam){  
    const url = this.domainUrl + 'payandrecon/1.0/license/addalertmessage' 
    return this.http.post(url, postParam).toPromise();
  }
 

  getpermissablequantities(entitytype: string, value: string): Observable<number> {  
    return this.BrandlabelbrewService.getpermissiblebottlequantity(entitytype, value)
      .pipe(map(item => item.errorCode === 200 ? Number(item.data.commonConfigValue) : 0));
  }
  

//pd brewery entity bottlingplan brand status suspended 
  getsuspendedstatusforbottlingplan(entitytype: string, value: string)  {  
    const url = environment.scmproduction + `getCommonConfigDetail?entityType=${entitytype}&type=${value}`;
    
    return this.http.get(url).pipe(
        map((response: any) => {
            if (response.errorCode === 200) {
                let fetchvalue = response.data; 
                if (fetchvalue) { 
                  let currentDate = new Date(); 
                  let beforeExp = fetchvalue.beforeExp; 
                  let penaltyAfterDate = fetchvalue.penaltyAfterDate;  
                  let beforeExpiryDate = new Date(penaltyAfterDate);
                  beforeExpiryDate.setDate(beforeExpiryDate.getDate() - beforeExp);   
                  let currentTimestamp = currentDate.getTime();
                  let beforeExpiryTimestamp = beforeExpiryDate.getTime(); 
                  if (currentTimestamp >= beforeExpiryTimestamp && currentTimestamp <= penaltyAfterDate) {
                    fetchvalue.allowsuspended=true;  
                    console.log(" Current date is within the expiry range.");
                  } else {
                    fetchvalue.allowsuspended=false;
                      console.log(" Current date is NOT within the expiry range.");
                  }
                  
                }
                
                return fetchvalue; 
            }
            return null;  
        })
    ).toPromise();
}

//brewery bottlingplan special brand for specific entity need to pay fl3a fees for fl3 brands
getfl3afeesbrands(payload):Observable<any>
{
 const url = environment.scmproduction +'brandLabel/specialBrandFL3AConfig?entityType='+payload.entityType+'&unitCode='+payload.unitCode+'&constant=SPECIALBRANDFL3ACONFIG&brandCode='+payload.brandCode;
   return this.http.get(url).pipe(
    map((response:any)=>{
      if (response.errorCode === 200) {
        let fetchvalue = response.data; 
        return fetchvalue;
      }
    }),
    catchError((err:any)=>{ 
      return throwError(()=>err.message)
    }) 
  )
}
//bottling plan financial year based on license selection
financialyearbasedonlicenseselect(details ) {  
  console.log(details)
  if(details) { 
    let value={
      financialyear:new Date(details.licenseExpiryDate).getFullYear()
    }
    return  value; 
    }
    
  }
  //multiple license number configuration table checkbrandconfigtable
    oldlicenseandnewlicenseenable(entitytype: string,screenname:string)  {  
    const url = environment.scmproduction + `getCommonConfigDetail?entityType=${entitytype}&type=ENABLE_MULTIPLE_LICENSE_CONFIG`;
    return this.http.get(url).pipe(
      map((response: any) => { 
        console.log(response)
        return response.errorCode === 200   ? (response.data &&response.data.commonConfigValue.split(',').includes(screenname)  ? '?screenName=SCM_LICENSE_CONFIG_MODULE' : '')   : '';
      })).toPromise()
      .catch((error) => {
        console.error('Error fetching license config:', error);
        return '';  // Return empty string on error
      });
    
    }
 
}
