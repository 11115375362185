import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class BottlingProducatioinPlanService {
  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.scmproduction;
  licenseURL = environment.licneseBaseUrl;
  qaurl = environment.wholesaleNewURL;

  getById(id): any {
    const url = this.baseURL + apiUrls.bottlinggetbyid + id;
    //return this.http.get(url);
    return this.http.get(url).toPromise();
  }
  getById1(id): any {
    const url = this.baseURL + apiUrls.bottlinggetbyid + id;
    return this.http.get(url);
  }

  addBottling(data): Observable<any> {
    const url = this.baseURL + apiUrls.addbottlingdata;
    return this.http.post(url, data);
  }

  getBrandDetailsByEtin(etin): Observable<any> {
    const url = this.baseURL + apiUrls.getBrandDetailsByEtin + "/" + etin;
    return this.http.get(url);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + apiUrls.bottlingsearch;
    return this.http.post(url, payload);
  }

  CancelRequest(id): Observable<any> {
    const url = this.baseURL + apiUrls.bottlinggetbyid + id + '/cancel'
    return this.http.put(url, null);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'bottlingProductionPlan/findPaymentDetails?applicationNumber=' + applicationno + '&codeType=' + feetype;
    return this.http.get(url);
  }
  branddropdown(payload) {
    let url = this.baseURL + 'bottlingProductionPlan/bottlingPlanDropDown'
    return this.http.post(url, payload);
  }
  // planIddropdown(payload){
  //   let url = this.baseURL+'bottlingProductionPlan/bottlingPlanDropDown'
  //   return this.http.post(url, payload);
  // }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadBottlingPlanReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=MAPPED';
    return this.http.get(url, { responseType: 'blob' });
  }
  getStatusCount(entityType?, subbottlingPlan?): Observable<any> {
    const url = this.baseURL + 'bottlingProductionPlan/getStatusCount?entityType=' + entityType + '&subbottlingPlan=' + subbottlingPlan;
    return this.http.post(url, entityType, subbottlingPlan);
  }

  pdlazysearchtank(payload) {
    let url = this.baseURL + 'pd25/lazySearch'
    return this.http.post(url, payload);
  }
  pdblendingsearchtank(payload) {
    let url = this.baseURL + 'blendTransfer/lazySearch'
    return this.http.post(url, payload);
  }
  getavailabletankquantity(batchnumber, transfertype) {
    let url = this.baseURL + 'pd18/getBottlingTankDetails?batchNo=' + batchnumber + "&transferType=" + transfertype;
    return this.http.get(url);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url, payload);
  }
  getbreweryCardCount(payload): Observable<any> {
    const url = this.baseURL + 'brewery/getStatusCount'
    return this.http.post(url,payload);
  }
  getbottlingdetailstrackapi(postParam){
    const url = this.qaurl + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
   }
   cancellationbottlingplan(id){ 
    const url = this.baseURL + 'bottlingProductionPlan/'+id+'/cancel'
    return this.http.put(url, id).toPromise();
   }

}
