import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  MasterapiService,
  DistileryService,
  WorkflowcofigService
} from '@app/services';
import { IndentRequestForOmdService } from '@app/services/pd';
import { LocalUrl } from '@app/app-local-url';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { DataService } from '@app/services/payment-and-reconciliation/data.service';
import { iDLocalUrl } from '@app/_constant/id-local-url';

@Component({
  selector: 'app-accidentalomd',
  templateUrl: './accidentalomd.component.html',
  styleUrls: ['./accidentalomd.component.scss']
})
export class AccidentalomdComponent implements OnInit {
  producttypelist: any[] = [];
  gradelist: any = [];
  CurrectUser=true;
  officerUser=false;
  normalStatus=false;
  accidentalForOmd: any = {
    districtCode: '',
    date: '',
    applicationNumber: '',
    status:"",
    distilleryName:""
  };
  entityType: any;
  pageNo = 0;
  public actionBtnBasedOnCond = {
    edit: {
      column: 'isEditable',
      condition: true,
    }
  };
  public actionKeys = ['edit', 'view'];
  public pageSize = 10;
  public totalCount: number;
  public isSearchRes = false;
  productType: any;
  workflowobj: any;
  public dataSource = new MatTableDataSource();
  // common setting 
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  maxDate = moment();
  dataCount: any;

  
  // common setting close
  public columns = [
    {
      name: 'id',
      displayName: 'parentunit.common.sNo',
      type: 'number'
    },
    {
      name: 'applicationNumber',
      displayName: 'Application No',
      type: 'string'
    },
    {
      name: 'createdDate',
      displayName: 'Request Date',
      type: 'date',
      dateFormat: "dd/MM/yyyy"
    },
    {
      name: 'omdPassNo',
      displayName: 'PD25 OMD Transport Pass No',
      type: 'string'
    },
    {
      name: 'consignorUnitName',
      displayName: 'Distillery Name',
      type: 'string'
    },
    {
      name: 'consigneeUnitName',
      displayName: 'OMD Name',
      type: 'string'
    },
    {
      name: 'accidentalDistrictValue',
      displayName: 'Accidental Location (District)',
      type: 'string'
    },
    {
      name: "stageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
      type: 'objstring'
    },
    
    {
      name: "statusDesc",
      displayName: "Status",
      type: 'options',
      badgeColors: {
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'COMPLETED': 'badge-info',
        'Submitted': 'badge-info',
        'Clarification': 'badge-warning',
        'DRAFT': 'badge-primary',
        'Draft': 'badge-primary',
        'Rejected': 'badge-danger'
      }
    }
  ];
  unitName: any;
  statusList = ['DRAFT', 'APPROVED','REJECTED','CLARIFICATION','IN_PROGRESS'];
  isCustomer: boolean;
  iscustomer : boolean = false;

  data: any;
  status: any;
  designation: string;
  constructor(
    private router: Router,
    private distileryService: IndentRequestForOmdService,
    private masterapiService: MasterapiService,
    private workconfig: WorkflowcofigService,
    private distilleryservice: DistileryService,
    private datepipe: DatePipe,
    private dataService: DataService,
  ) { }
  async ngOnInit() {
    // common setting
   
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const subUrlManage = currentUrlArray[1] + currentUrlArray[3];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    // this.iscustomer = (localStorage.getItem('SCM_SUBUSER_ADD_ACCESS') === 'true');

    await this.getAccidentalForOmd();
    this.getstatuscount();
  }
  async getAccidentalForOmd(status?:any,pageno=0,pagination=10) {
    // const paginationSize = pageSize;
    
    this.status = status ? status : this.accidentalForOmd.status;
    sessionStorage.setItem('cardstatus', this.status)
    const createdDate = this.accidentalForOmd.date ? moment(this.accidentalForOmd.date).format("DD-MM-yyyy") : '';
    const payload :any = {
        "filters": {
          "status": this.status,
          "profileType": "ACCIDENTAL_REQUEST_OMD",
          "entityType": this.entityType,
          // "accidentalDomainCode" : ""
          "applicationNumber":this.accidentalForOmd.applicationNumber,
          "createdDate":createdDate,
          "consignorUnitName": this.accidentalForOmd.distilleryName
        },
        "pageNo": pageno,
        "paginationSize": pagination
    };
    if(this.designation == 'DEO'){
      payload.filters.accidentalDomainCode = 'WITHIN_UP'
    }
    if(this.CurrectUser  == true){
      payload.filters['isCurrentOfficerAllRecords']=true;
      payload.filters['isAllOfficerRecords']=false;
      payload.filters['isOtherOfficerAllRecords']=false;
    }else if(this.officerUser == true){
      payload.filters['isCurrentOfficerAllRecords']=false;
      payload.filters['isAllOfficerRecords']=false;
      payload.filters['isOtherOfficerAllRecords']=true;
    }else if(this.normalStatus == true){
      payload.filters['isCurrentOfficerAllRecords']=false;
      payload.filters['isAllOfficerRecords']=true;
      payload.filters['isOtherOfficerAllRecords']=false;
    }
     this.distilleryservice.getListOfAccidentalOmd(payload).subscribe((responceData: any) => {
     
        this.totalCount = responceData.data.totalElements;
        this.dataSource.data = responceData.data.contents;
        this.data = responceData.data.contents;
        console.log(this.dataSource.data)
    });
  }
  changeStatus(status){
    if(status == 'current'){
      this.CurrectUser = true;
      this.officerUser = false;
      this.normalStatus = false;
    }else if(status=='Officer'){
      this.officerUser = true;
      this.CurrectUser = false;
      this.normalStatus = false;
    }else if(status=='normal'){
      this.normalStatus = true;
      this.CurrectUser = false;
      this.officerUser = false;
    }
  }
  getstatuscount() {
    let payload:any = {
      entityType: this.entityType,
      screenName: "ACCIDENTAL_REQUEST_OMD"
    }
    if(this.isCustomer){
      let parentUserId = JSON.parse(localStorage.getItem('USER_DET')).parentUserId;
      if (null != parentUserId && undefined != null) {
        payload.parentUserId = parentUserId;
      }
    }
    let allocatedlicensenumber = localStorage.getItem('SCM_GETALLOCATED_LICENSENUMBER')
      if (allocatedlicensenumber) {
        let officerallocatedlicensedetails = JSON.parse(allocatedlicensenumber).map(obj => obj.licenseNumber);
        payload.licenseNumberlist = officerallocatedlicensedetails;
      }
      this.distilleryservice.getCardCount(payload)
        .subscribe((res: any) => {
          this.dataCount = res.data;
        });
      
    }
  onEdit(event) {
    // const findobj = this.data.find(x => x.id == event.id);
    // if (findobj.status == 'SUBMITTED' && findobj.isProcessingFeePaid == false) {
    //   this.router.navigate([
    //     '/'+this.url+'/omd/accidentalomd/payment/' + event.id,
    //   ]);
    // } else {
      this.router.navigate([
        '/'+this.url+'/omd/accidentalomd/edit/' + event.id,
      ]);
    // }
  }

  onView(event) {
    this.router.navigate(['/'+this.url+'/omd/accidentalomd/view/' + event.id,]);
  }

  handlePagination(event) {
    // const pageNo = event.currentPage;
    // const pageSize = event.pageSize;
    this.pageNo = event.currentPage;
    this.pageSize = event.pageSize;
    //this.Search(this.pageNo, this.pageSize)
    this.getAccidentalForOmd(sessionStorage.getItem('cardstatus'),this.pageNo,this.pageSize);
  }

  searchConData() {
    this.getAccidentalForOmd();
  }

  resetKey() {
    this.accidentalForOmd = {
      districtCode: '',
      applicationNumber: '',
      createdDate: '',
      status:"",
      distilleryName:""
    };
    this.getAccidentalForOmd();
  }
  ExportTOExcel() {
    const data = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    this.dataService.downloadExcel(data, 'Accidental Request For OMD', [this.addressproofListHeader, this.addressproofListTableKeys]);
  }

  addressproofListHeader = [
    'Application Number',
    'Request Date',
    'PD25 OMD Transport Pass No',
    'Distillery Name',
    'OMD Name',
    'Currently With',
    'Status'
  ];

  addressproofListTableKeys = [
    
  
    {
      data: 'applicationNumber',
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },   
    {
      data: 'createdDate',
      render: function (data, type, row) {
        return (data ? this.datepipe.transform(data, 'yyyy-MM-dd') : '');
      }
    },
    {
      data: 'omdPassNo', 
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
    {
      data: 'consignorUnitName',
      render: function (data, type, row) {
        const date = new Date(data);
        return (data ? data : '');
      }
    }, 
    {
      data: 'consigneeUnitName',
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
    {
      data: 'stageDesc',
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
    {
      data: 'statusDesc',
      "className": "text-center",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },

  ]
  generatePdf() {
    // this.addressproofListHeader.splice(0, 0, "S.No");
    let head = [this.addressproofListHeader]
    let data = [];
    this.dataSource.data.forEach((element: any, i: number) => {
      data[i] = [];
      // data[i].push(element ? i + 1 : '')
      data[i].push(element ? element.applicationNumber : '');
      data[i].push(element ? this.datepipe.transform(element.createdDate, 'yyyy-MM-dd') : '');
      data[i].push(element ? element.omdPassNo : '');
      data[i].push(element ? element.consignorUnitName : '');
      data[i].push(element ? element.consigneeUnitName : ''); 
      data[i].push(element ? element.stageDesc : '');
     data[i].push(element ? element.statusDesc : ''); 
    });
    var doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Accidental Request for OMD List', 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0]
      },

      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      headerStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      didDrawCell: data => {
        // console.log(data.column.index)
      }
    })
    this.addressproofListHeader.splice(0, 1);
    // below line for Download PDF document  
    doc.save('Accidental Request for OMD.pdf');
  }
}
