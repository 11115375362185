import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from "@appEnv/apiurls";
@Injectable({
  providedIn: 'root'
})
export class assignofficerforinvestcomplaintService{
  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  masterURL = environment.devisionUrl;
  baseURLUser = environment.baseURLUser;
  getdetails(): Observable<any> {
    const url = this.licenseURL + 'license/category/BREWERY'; //DISTILLERY';
    return this.http.get(url);
  }

      
  addAssignInvestOfficer(data): Observable<any> {
    const url = this.baseURL + 'VigilanceAssignComplaint';
    return this.http.post(url, data);
}


downloadassignofficer(payload){
  const url = this.baseURL + 'reportReceipt/downloadReceipt'
  // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
  return this.http.get(url,{ responseType: 'blob', params:payload });
}

search(payload): Observable<any> {
  const url = this.baseURL + 'VigilanceAssignComplaint/lazySearch';
  return this.http.post(url, payload);
}

getLazyval(payload): Observable<any> {
  const url = this.baseURL + 'vigilance/lazySearch';
  return this.http.post(url, payload);
}

getComplaintNo(payload): Observable<any> {
  const url = this.baseURL + 'VigilanceAssignComplaint/dropDown';
  return this.http.post(url, payload);
}

getapplicationnumbers(data) {
const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
return this.http.post(url, {});
}

getDetailsById(id): Observable<any> {
const url = this.baseURL + 'VigilanceAssignComplaint/'+id;
return this.http.get(url);
}

getStatusMasters() {
  const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
  return this.http.get(url)
}

getActiveEntityType(postParam): Observable<any> {
  const url = this.baseURLUser + apiUrls.getActiveEntityType;
  return this.http.post(url, postParam);
}

getLicenseType1(): Observable<any> {
  const url = this.baseURLUser + apiUrls.getLicenseType +  'RETAIL';
  //const url = this.licenseURL + 'license/category/' + obj;
  return this.http.get(url);
}

getapplicationnumber(data): Observable<any> {
  const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
  return this.http.post(url, data);
}
}