import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class Mf4ReceivedService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'MF4TransportConfirmation/';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'MF4TransportConfirmation/transportLazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'MF4TransportConfirmation?id=' + id;
    return this.http.get(url);
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=MF4_ACKNOWLEDGEMENT_PENALTY';
    return this.http.get(url, { responseType: 'blob' });
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'MF4TransportConfirmation/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'MF4TransportConfirmation/saveUpdatePaymentDetails'
    return this.http.post(url, payload);
  }
  scanDetailSearch(model): Observable<any> {
    const url = this.baseURL + 'MF4transportPass/transportGenSearch';
    return this.http.post(url, model);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getTransportConfirmationCount(entityType): Observable<any> {
    const url = this.baseURL + 'MF4TransportConfirmation/getTransportConfirmationCount?entityType=' + entityType;
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt';
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getTrsAndBrixValidation(): Observable<any> {
    const url = this.baseURL + 'mf5StockIn/getMF5StockInConfig?entityType=SUGAR_MILL';
    return this.http.get(url);
  }
  getAccidentalGatePass(payload): Observable<any> {
    const url = this.baseURL + 'AccidentalRequest/lazySearch';
    return this.http.post(url, payload);
  }
  async mf4ScanDetailSearch(model) {
    const url = this.baseURL + 'MF4transportPass/transportGenSearch';
    const resp = await this.http.post(url, model).toPromise();
    return resp;
  }
  downloadPaymentMf4Receipt(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
      '?applicationNumber=' + appnumber +
      '&licenseNumber=' + liceanseNumber +
      '&feeType=' + feeType + '&receiptType=MF4_ACKNOWLEDGEMENT_PENALTY';

    return this.http.get(url, { responseType: 'blob' });
  }

  getCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
}
