// export * from './applicantstatus.service';
// export * from './applicanttype.service';
export * from './brandmaster.service';
export * from './census.service';
export * from './consumptiontype.service';
export * from './department.service';
export * from './dipmaster.service';
export * from './feemaster.service';
export * from './licence.service';
export * from './licensee.service';
export * from './liquortype.service';
export * from './stage.service';
export * from './status.service';
export * from './supplytype.service';
export * from './tankshape.service';
export * from './tanktype.service';
export * from './vehiclemake.service';
export * from './vehiclemodel.service';
export * from './verificationtype.service';
export * from './yearmaster.service';
export * from './tankmaterial.service';
export * from './licensetype.service';
export * from './liquorcategory.service';
export * from './licensecategory.service';
export * from './cartons.service';
export * from './documenttype.service';
export * from './domain.service';
export * from './entity.service';
export * from './gender.service';
export * from './ingredient.service';
export * from './label.service';
export * from './measurement.service';
export * from './modeofpayment.service';
export * from './modeoftransfer.service';
export * from './molassesType.service';
export * from './packagetype.service';
export * from './penality.service';
export * from './producttype.service';
export * from './rawmaterial.service';
export * from './revenue.service';
export * from './route.service';
export * from './spirit.service';
export * from './tankcover.service';
export * from './strength.service';
export * from './division.service';
export * from './feetype.service';
export * from './headcode.service';
export * from './dropdownconfiguration.service';
export * from './licenseconfig.service';
export * from './retail-group-master.service';