import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class MolassesAdminService {
  baseURL = environment.parentUnit;
  licenseURL = environment.licneseBaseUrl;
  constructor(private http: HttpClient) { }

  getApplicationNo(data: any): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, data);
  }
  getAllMolassesAdmin(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllMolassesAdmin;
    return this.http.get(url);
  }
  getMolassesAdminById(id): Observable<any> {
    const url = this.baseURL + apiUrls.molassesAdminGetById + id;
    return this.http.get(url);
  }
  addMolassesAdmin(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addMolassesAdmin;
    return this.http.post(url, postdata);
  }
  updateMolassesAdmin(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.updateMolassesAdmin;
    return this.http.put(url, postdata);
  }
  searchMolassesAdmin(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.molassesAdminSearch;
    return this.http.post(url, postdata);
  }
  getAdminValues(): Observable<any>
  {
    const url = this.baseURL + apiUrls.getMolassesAdminChargesValue;
    return this.http.get(url);
  }
  getdetails(): any {//work
    const url = this.licenseURL + 'license/category/SUGAR_MILL';
    return this.http.get(url);
  }
  downloadadmin(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getStatusCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'molassesAdminCharge/getStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords 
    +'&isOtherOfficerAllRecords=' +isOtherOfficerAllRecords;
    return this.http.get(url);
  }
  getSugarMillCardCount(payload): Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount'
    return this.http.post(url,payload);
  }
  
}
