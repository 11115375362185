import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
  providedIn: 'root'
})
export class IntimationService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'brewIntimation';
    return this.http.post(url, data);
  }
  finished(data): Observable<any> {
    const url = this.baseURL + 'brewIntimation/isFinished';
    return this.http.post(url, data);
  }
  finishedB4(data): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/isFinishedBrewingList';
    return this.http.post(url, data);
  }
  finilized(data): Observable<any> {

    const url = this.baseURL + 'brewingBook/isFinishedBrewingList';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'brewIntimation/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brewIntimation/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getbrewPlanNo(val): Observable<any> {
    const url = this.baseURL + 'brewIntimation/lastApprovedBrewPlanNo?licenseNumber=' + val;
    return this.http.get(url);
  }
  getbrewPlanNo1(val, licNo): Observable<any> {
    const url = this.baseURL + 'brewIntimation/lastApprovedBrewPlanNo/unitCode?unitCode=' + val + '&licenseNumber=' + licNo;
    return this.http.get(url);
  }

  getLicanceData(category, licenseName): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category + '?licenseNumber=' + licenseName;
    return this.http.get(url);
  }
  getRowData(licNo, licType): Observable<any> {
    const url = this.baseURL + 'findStockActivity/brewIntimation/rawMaterial?entityType=BREWERY&licenseNumber=' + licNo + '&licenseType=' + licType;
    return this.http.get(url);
  }
  getRowData1(licNo, licType): Observable<any> {
    const url = this.baseURL + 'findStockActivity/intimation/rawMaterial?entityType=BREWERY&licenseNumber=' + licNo + '&licenseType=' + licType;
    return this.http.get(url);
  }
  getRowData2(unitCode): Observable<any> {
    const url = this.baseURL + 'stockActivity/getStockRawMaterials?entityType=BREWERY&unitCode=' + unitCode;
    return this.http.get(url);
  }

  approveIntimation(postdata): Observable<any> {
    const url = this.baseURL + 'brewIntimation/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  updateIntimation(postdata): Observable<any> {
    const url = this.baseURL + 'brewIntimation/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  getInitimationCount(entityType?, createdBy?): Observable<any> {
    // const url = this.baseURL + 'stockActivity/getStatusCount?entityType=' + entityType +'&createdBy=' + createdBy;
    const url=this.baseURL + 'brewIntimation/getInitimationCount?entityType=BREWERY&createdBy='+ createdBy;
    return this.http.get(url);
  }
  downloadenavalue(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt'
   return this.http.get(url, { responseType: 'blob', params: payload });
 }
 getPdCardCount(payload): Observable<any> {
  const url = this.baseURL + 'brewery/getStatusCount'
  return this.http.post(url,payload);
}

}
