<!-- my-loader.component.html -->
<div class="progress-loader" [hidden]="!loading">
    <div class="loading">
        <img src="assets/images/loader.svg">
        <span class="loading-message">Please wait...</span>
    </div>
</div>
<div class="progress-loader" [hidden]="!loadingLong">
    <div class="loading">
        <img src="assets/images/loader.svg">
        <span class="loading-message">Please wait...</span>
    </div>
</div>