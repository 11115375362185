import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';

@Injectable({
  providedIn: 'root'
})
export class BottlinglinedismantleService {

  constructor(private http: HttpClient) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  search(payload: any): Observable<any> {
    console.log(payload);
    const url = this.baseURL + 'bottlingLineDismantle/lazySearch';
    return this.http.post(url, payload);
  }
  
 
  addetails(data): Observable<any> {
    const url = this.baseURL + 'bottlingLineDismantle';
    return this.http.post(url, data);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'bottlingLineDismantle/' + id;
    return this.http.get(url);
  }
  bottlinglineSearch(payload): Observable<any> {
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    return this.http.post(url, payload);
    
}

  getline(): Observable<any>{
   const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
   let payload={
    "orderBy": "string",
    "page": 0,
    "pageSize": 40,
    "searchInput": {"entityType":"BREWERY",
    "licenseType":"",
    "status":"APPROVED",
    
     },
    "sortBy": "string"
  }
  return this.http.post(url, payload);
  }
  getlinePd(type = 'PD',value): Observable<any>{
    const url = this.baseURL + 'bottlingLineRegistration/lazySearch';
    let payload={
     "orderBy": "string",
     "page": 0,
     "pageSize": 40,
     "searchInput": {"entityType":type,
     "licenseType":value,
     "status":"APPROVED",
     
      },
     "sortBy": "string"
   }
   return this.http.post(url, payload);
   }
  getMaintenanceTypes(): Observable<any>{

    const url = this.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=MAINTENANCE_TYPE'
    return this.http.get(url);
  }
  download(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt'
   return this.http.get(url, { responseType: 'blob', params: payload });
  }

//   getStatusCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
//     const url = this.baseURL + 'bottlingLineDismantle/getStatusCount?entityType=' + entityType 
//     +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
//     +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords;
//     // const url = 'http://localhost:3501/scmProduction/bottlingLineDismantle/getStatusCount?entityType=' + entityType 
//     // +'&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords 
//     // +'&isOtherOfficerAllRecords=' +isOtherOfficerAllRecords;
//     return this.http.get(url);
// }
getStatusCount(entityType): Observable<any> {
  const url = this.baseURL + 'bottlingLineDismantle/getStatusCount?entityType=' + entityType;
  return this.http.get(url);
}
getStatusCount1(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
  const url = this.baseURL + 'bottlingLineDismantle/getStatusCount?entityType=' + entityType 
  +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
  +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords;
  // const url = 'http://localhost:3501/scmProduction/bottlingLineDismantle/getStatusCount?entityType=' + entityType 
  // +'&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords 
  // +'&isOtherOfficerAllRecords=' +isOtherOfficerAllRecords;
  return this.http.get(url);
}
getPdCardCount(payload): Observable<any> {
  const url = this.baseURL + 'pd/getStatusCount'
  return this.http.post(url,payload);
}
}



