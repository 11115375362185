import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class TankConversionBrewService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;
  url = environment.devisionUrl;

  get(): Observable<any> {
    const url = this.baseURL + apiUrls.listTankConversion;
    return this.http.get(url);
  }

  searchTankConversion(postParam): Observable<any> {
    const url = this.baseURL + 'breweryTankConversion/tankRegistrationLazySearch';
    return this.http.post(url, postParam);
  }

  addTankConversion(postParam): Observable<any> {
    const url = this.baseURL + 'breweryTankConversion';
    return this.http.post(url, postParam);
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'breweryTankConversion/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }

  saveupdatepaymentdetailsomd(payload): Observable<any> {
    const url = this.baseURL + 'pd25/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  updateTankCon(postParam): Observable<any> {
    const url = this.baseURL + apiUrls.putTankConversion;
    return this.http.post(url, postParam);
  }

  getAllTank(): Observable<any> {
    const url = this.url + apiUrls.getallTanks;
    return this.http.get(url);
  }

  getTankConversionById(id): Observable<any> {
    const url = this.baseURL + 'breweryTankConversion?id=' + id;
    return this.http.get(url);
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.baseURL + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=TANK_CONVERSION';
    return this.http.get(url, { responseType: 'blob' });
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'breweryTankConversion/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  approveTankTransfer(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.approveTankTransfer;
    return this.http.post(url, postdata);
  }

  approveTankCon(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.approveTankCon;
    return this.http.post(url, postdata);
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType='+ data;
    return this.http.post(url, data);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }

}