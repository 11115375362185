import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccidentalomdComponent } from './accidentalomd.component';
import { AddaccidentalomdComponent } from './addaccidentalomd/addaccidentalomd.component';
import { ViewaccidentalomdComponent } from './viewaccidentalomd/viewaccidentalomd.component';


const routes: Routes = [
  { path: 'list', component: AccidentalomdComponent },
  { path: 'add', component: AddaccidentalomdComponent },
  { path: 'edit/:id', component: AddaccidentalomdComponent },
  { path: 'view/:id', component: ViewaccidentalomdComponent },
  { path: 'preview/:id', component: ViewaccidentalomdComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccidentalomdRoutingModule { }
