import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class ManualscanningService {

  constructor(private http: HttpClient) { }
  baseURL = environment.wholesaleNewURL;

  getdata(data): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, data);
  }

  // submitData(data): Observable<any> {
  //   const url = this.baseURL + 'customdata/getdata';
  //   return this.http.post(url, data);
  // }

  submitData(data): Observable<any> {
    const url = this.baseURL + 'manaulscanning/saveitem';
    return this.http.post(url, data);
  }
  

  saveboxingplan(data): Observable<any> {
    const url = this.baseURL + 'manaulscanning/saveitem';
    return this.http.post(url, data);
  }

  // searchData(postParam):Observable<any>{
  //   const url=this.baseURL+'customdata/getdata';
  //   return this.http.post(url,postParam)
  // }
  getAppConfigValue(postParam): any {
    const url = this.baseURL + 'appconfig/getappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }
}

