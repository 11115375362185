import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class Mf5Part3Service {
  baseURL = environment.parentUnit;
  constructor(private http: HttpClient) { }
  getAllMf5Part3(): Observable<any> {
    const url = this.baseURL + apiUrls.mfpart3GetAll;
    return this.http.get(url);
  }
  getMf5Part3ById(id): Observable<any> {
    const url = this.baseURL + apiUrls.mfpart3GetById + id;
    return this.http.get(url);
  }
  addMf5Part3(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mfpart3Add;
    return this.http.post(url, postdata);
  }
  updateMf5Part3(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mfpart3GetUpdate;
    return this.http.put(url, postdata);
  }
  searchMf5Part3(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mf5Part3Search;
    return this.http.post(url, postdata);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }
  gettanksDetails(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.gettanksbyid;
    return this.http.post(url, postdata);
  }
  getStockInDetail(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getStockInDetail;
    return this.http.post(url, postdata);
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, data);
  }
  getYear(): Observable<any> {
    const url = this.baseURL + 'utility/molasses/forecastDetails';
    return this.http.get(url);
  }
  getMf9FortnightStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'mf5Part3/getMf5Part3StatusCount?entityType=' + entityType;
    // const url = 'http://localhost:3501/scmProduction/mf9productionforecast/getMf9ProductionStatusCount?entityType=SUGAR_MILL';
    return this.http.get(url);
  }
  getStatusCounts(entityType, isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'mf5Part3/getMf5Part3StatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
}
