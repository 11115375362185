import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BlendingProductionPlanService } from '@app/services/pd/blending-production-plan.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { AccidentRequestService } from '@app/services/sugarmill/accident-request.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DataService } from '@app/services/payment-and-reconciliation/data.service';
import { AlertService, TransportGenService } from '@app/services';
import { LocalUrl } from '@app/app-local-url';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-accidentalrequest-outsideup',
  templateUrl: './accidentalrequest-outsideup.component.html',
  styleUrls: ['./accidentalrequest-outsideup.component.scss']
})
export class AccidentalrequestOutsideupComponent implements OnInit {
  brandDropDownSettings: IDropdownSettings;
  accidentRequestData: any;
  selectOption = "";
  statusCounts: any;
  isCustomer: boolean = false;
  status: string;
  maxDate = moment();
  statues: any;
  mf4PassNoList: any;
  public actionBtnBasedOnCond = {
    edit: {
      column: 'isEditable',
      condition: true,
    },
  };
  
  public pageSize = 10;
  public pageNo = 0;
  public actionKeys = ['view', 'edit'];
  public totalCount: number;
  public isSearchRes = false;
  public dataSource = new MatTableDataSource();
  public statuslist = [{ name: "Submitted", value: "Submitted" }, { name: "Draft", value: "Draft" },
  { name: "In Progress", value: "In Progress" }, { name: "Approved", value: "Approved" }, { name: "Rejected", value: "Rejected" },
  { name: "Clarification", value: "Clarification" }];
  isLoadingResults: boolean = true;
  isSearchLoading:boolean = false
  isClearLoading = false;
  Obj: any = {
    requestDate: '',
    applicationNumber:'',
    requestId: '',
    gatePassNo: '',
    accidentType: '',
    status:''
  };

  //lazysearch filter data

  requestIdList: any [] = [];
  requestDateList: any [] = [];


  public columns = [
    {
      name: "id",
      displayName: "S. No",
      type: "number",
    }
    ,
    {
      name: "applicationNumber",
      displayName: 'Request ID',
      type: "string",
    },
    {
      name: "mf4GatePassNo",
      displayName: 'MF4 Gate Pass No',
      type: "string",
     
    },
    {
      name: "requestDate",
      displayName: 'Transport Pass Date',
      type: "date",
      dateFormat: 'dd/MM/yyyy hh:mm',
    },
    {
      name: "accidentalTypeValue",
      displayName: 'Accident Type',
      type: 'string'
    },
    {
      name: "accidentDate",
      displayName: 'Accident Date',
      type: 'string'
    },
    {
      name: "sugarMillName",
      displayName: 'Sugar Mill Name',
      type: 'string'
    },
    {
      name: "distilleryName",
      displayName: 'Distillery Name',
      type: 'string'
    },
    {
      name: "quantity",
      displayName: 'Quantity',
      type: 'string'
    },
    {
      name: "grade",
      displayName: 'grade',
      type: 'string'
    },
    {
      name: "accidentalTypeDistrictValue",
      displayName: 'Accident Location District',
      type: 'string'
    },
    {
      name: "stageDesc",
      displayName: 'Currently With',
      displayFcn: (item: any) => ( item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
      type: 'objstring'
    },
    {
      name: "statusDesc",
      displayName: 'Status',
      type: "options",
      badgeColors: {
        'Draft': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Rejected': 'badge-danger',
        'Clarification': 'badge-warning'
      }
    }
  ]
  mf4PassNoDetail: any;
  applicationNo: any;
  entityType: any;
  dataCount: any;
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  bottlingdetaildetails: any;
  designation: string;
  outsideupvalidation: string;
  CurrectUser=true;
  officerUser=false;
  normalStatus=false;
  constructor(private router: Router,
    private accidentRqstService: AccidentRequestService,
    private dataService: DataService,
    private alert: AlertService,
    private datepipe: DatePipe,
    private transportGenService: TransportGenService,) { }


  ngOnInit() {
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.outsideupvalidation = currentUrlArray[2];
    const subUrlManage = currentUrlArray[1] + currentUrlArray[2];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.getstatuscount();
    this.getUser();
    this.getList();
    // this.getMf4PassNo();
    this.brandDropDownSettings = {
      singleSelection: true,
      idField: 'mf4GatePassNo',
      textField: 'mf4GatePassNo',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }
  onFilterChangeBrand(item: any) {  }
  onDropDownCloseBrandFilter() {  }
  getstatuscount() {
    
    let payload = {
      entityType: this.entityType,
      screenName: "ACCIDENT_REQUEST_INDUSTRY_INSIDE_UP"
    }
    if(this.outsideupvalidation === "accidental-request-outsideup"){
     payload.screenName = "ACCIDENT_REQUEST_INDUSTRY_OUTSIDE_UP" 
  }

      this.accidentRqstService.getPdCardCount(payload)
        .subscribe((res: any) => {
          this.dataCount = res.data;
        });
      
    }
  getList(status?,pageno=0,pagination=10) {
    this.status = status ? status : '';
    sessionStorage.setItem('cardstatus', this.status)
    const searchObj = {
      filters: {
        applicationNumber: this.Obj["applicationNumber"],
        requestId: this.Obj["requestId"],
        requestDate: this.datepipe.transform(this.Obj["requestDate"], 'dd-MM-yyyy') || null,
        mf4GatePassNo: this.Obj["gatePassNo"],
        accidentalTypeCode: this.Obj["accidentType"],
        status: this.status,
        entityType: "SUGAR_MILL",
        "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : this.outsideupvalidation === "accidental-request-sugarmill-outsideup" ? "SUGARMILL_OUTSIDE_UP" : ""),
      },
      pageNo: pageno,
      paginationSize: pagination
    };
    if (this.status){
      searchObj.filters['isStatusFilter']=true;
     }
    if(this.CurrectUser  == true){
      // searchObj.filters['isStatusFilter']=true;
      searchObj.filters['isCurrentOfficerAllRecords']=true;
      searchObj.filters['isAllOfficerRecords']=false;
      searchObj.filters['isOtherOfficerAllRecords']=false;
    }else if(this.officerUser == true){
      // searchObj.filters['isStatusFilter']=true;
      searchObj.filters['isCurrentOfficerAllRecords']=false;
      searchObj.filters['isAllOfficerRecords']=false;
      searchObj.filters['isOtherOfficerAllRecords']=true;
    }else if(this.normalStatus == true){
      // searchObj.filters['isStatusFilter']=true;
      searchObj.filters['isCurrentOfficerAllRecords']=false;
      searchObj.filters['isAllOfficerRecords']=true;
      searchObj.filters['isOtherOfficerAllRecords']=false;
    }
    this.accidentRqstService.accidentRqstLazySearch(searchObj).subscribe((resp: any) => {
      console.log(resp)
      if (resp.errorCode === 200) {
        this.totalCount = resp.data.totalElements;
        this.dataSource.data = resp.data.contents;
        this.bottlingdetaildetails = resp.data.contents;
          const uniqueLicenses = this.bottlingdetaildetails.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.mf4GatePassNo === obj.mf4GatePassNo)
          ); 
          this.mf4PassNoList=uniqueLicenses
      }
      else {
        this.dataSource.data = [];
        this.totalCount = 0;
      }
    });
  }
  changeStatus(status){
    if(status == 'current'){
      this.CurrectUser = true;
      this.officerUser = false;
      this.normalStatus = false;
    }else if(status=='Officer'){
      this.officerUser = true;
      this.CurrectUser = false;
      this.normalStatus = false;
    }else if(status=='normal'){
      this.normalStatus = true;
      this.CurrectUser = false;
      this.officerUser = false;
    }
  }
  getMf4PassNo() {
    let payload = {
      filters: {
        applicationNumber: "",
        requestId: "",
        requestDate: "",
        mf4GatePassNo: "",
        accidentalTypeCode: "",
        status: "",
        entityType: "SUGAR_MILL",
        "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : this.outsideupvalidation === "accidental-request-sugarmill-outsideup" ? "SUGARMILL_OUTSIDE_UP" : ""),
      },
      pageNo: 0,
      paginationSize: 0
    }
    this.accidentRqstService.accidentRqstLazySearch(payload)
      .subscribe((resp: any) => {
        if (resp.errorCode === 200) { 
          this.bottlingdetaildetails = resp.data.contents;
          const uniqueLicenses = this.bottlingdetaildetails.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.mf4GatePassNo === obj.mf4GatePassNo)
          ); 
          this.mf4PassNoList=uniqueLicenses
          // console.log(resp.data.contents)
        } else {
          this.alert.showError(resp.userDisplayMesg);
        }
      });
  }
  

  search(payload) {
    if (payload) {
      this.toggleLoading('searchstatus')
      this.isLoadingResults = true;
      this.accidentRqstService.accidentRqstLazySearch(payload).subscribe((respData: any) => {
        this.dataSource.data = respData.data.contents;
        this.accidentRequestData = respData.data.contents;
        console.log(respData.data.contents)
        this.isSearchLoading = false
        this.isLoadingResults = false;
        this.totalCount = respData.data.totalElements;
        
      });
    }
  }
  toggleLoading(saveType) {
    if (saveType == 'searchstatus') {
      this.isSearchLoading = !this.isSearchLoading;
    }
    else {
      this.isClearLoading = !this.isClearLoading;
    }
    return
  }
  getUser() {
    if (this.isCustomer) {
      this.columns = this.columns
    } else {
      this.columns = this.columns
    }
  }
  onView(event) {
    // const findobj = this.accidentRequestData.find(x => x.id == event.id)
    if (this.isCustomer) {
      this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/view/' + event.id]);
    } else {
      this.router.navigate(['sugarmill/'+this.outsideupvalidation+'/view/' + event.id]);
    }
  }
  addPlanNav() {
    this.router.navigate([ 'sugarmill/'+this.outsideupvalidation+'/add/']);
  }

  onEdit(event) {
    // const findobj = this.accidentRequestData.find(x => x.id == event.id);
      if(this.isCustomer){
        this.router.navigate(['/sugarmill/'+this.outsideupvalidation+'/payment/', event.id]);
      }else{
        this.router.navigate(['/sugarmill/'+this.outsideupvalidation+'/edit/', event.id]);
      }
  }

  public paginationConfig = {
    pageNo : 0,
    pageSize : 10
  }

  public accidentTypeList = [{ id: 1, name: "Vehicle damaged but product good" }, { id: 2, name: "Product damaged but vehicle good" },
  { id: 3, name: "Both damaged" }];

  // getMf4PassNo() {
  //   this.accidentRqstService.getMf4getPassNo()
  //     .subscribe((respData: any) => {
  //       if (respData.errorCode === 200) {
  //         this.mf4PassNoList = respData.data;
  //       } else {
  //         this.alert.showError(respData.userDisplayMesg);
  //       }
  //     });
  // }

  // getMf4PassNoDetail() {
  //   const payload = {
  //     id: 0,
  //     getPassNo: ""
  //   }
  //   this.accidentRqstService.getMf4getPassNoDetail(payload).subscribe((resp: any) => {
  //     this.mf4PassNoDetail = resp.content;
  //   });
  //   console.log("brand list", this.mf4PassNoDetail);
  // }
  
  // selectedMf4GetPassNo: any = []
  // onChangeMf4Gat(event, id){
  //   this.selectedMf4GetPassNo = this.mf4PassNoList.filter(function (entry) { return entry.getPassNo; });
  //   console.log(this.selectedMf4GetPassNo, '.....this.selectedMf4GetPassNo..');
  // }

  getData(status?,pageno=0,pagination=10) {
    this.status = status ? status : '';
    sessionStorage.setItem('cardstatus', this.status)
    const createdDate = this.Obj.date ? (this.Obj.date.startDate != null) ? moment(this.Obj.date.startDate).format("DD-MM-YYYY") : '' : '';
    let payload = {
      filters: {
        applicationNumber: this.Obj["applicationNumber"],
        requestId: this.Obj["requestId"],
        requestDate: this.datepipe.transform(this.Obj["requestDate"], 'dd-MM-yyyy') || null,
        mf4GatePassNo: this.Obj.gatePassNo ? this.Obj.gatePassNo[0].mf4GatePassNo : null,
        accidentalTypeCode: this.Obj["accidentType"],
        status: this.Obj["status"],
        entityType: "SUGAR_MILL",
        "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : this.outsideupvalidation === "accidental-request-sugarmill-outsideup" ? "SUGARMILL_OUTSIDE_UP" : ""),
      },
      pageNo: pageno,
      paginationSize: pagination
    }
    this.search(payload)
  }
  // pageNo = 0;
  // pageSize = 0;

  handlePagination(event) {
    this.paginationConfig.pageNo = event.pageSize;
    this.paginationConfig.pageSize = event.currentPage;
    this.getList(sessionStorage.getItem('cardstatus'), this.paginationConfig.pageSize,this.paginationConfig.pageNo);
  }
  clearData() {
    this.Obj.accidentType = '';
    this.Obj.requestDate = '';
    this.Obj.applicationNumber = '';
    this.Obj.gatePassNo = '';
    this.Obj.requestId = '';
    this.Obj.status = '';
    this.getList();
  }
  
  // clearsearch(payload) {
  //   if (payload) {
  //     this.toggleLoading('cleardatasearch')
  //     this.isLoadingResults = true;
  //     this.BlendingProductionService.getBlendList(payload).subscribe((responceData: any) => {
  //       this.totalCount = responceData.data.totalElements;
  //       this.dataSource.data = responceData.data.contents;
  //       this.data = responceData.data.contents;
  //       this.isClearLoading = false
  //       this.isLoadingResults = false;
  //     });
  //   }
  // }

  addressproofListHeader = [
    'Request ID',
    'MF4 Gate Pass No',
    'Transport Pass Date',
    'Accident Type',
    'Date of Application',
    'Sugar Mill Name',
    'Distillery Name',
    'Quantity',
    'Grade',
    'Accident Location District',
    'Status'
  ];
  addressproofListTableKeys = [
    {
      data: 'applicationNumber',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
    {
      data: 'mf4GatePassNo',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
    {
      data: 'createdDate',
      render: function (data, type, row) {
        return (data ? moment(data).format("YYYY-MM-DD HH:mm:ss") : '');
      }
    },
    {
      data: 'accidentalTypeValue',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    }, 
    {
      data: 'createdDate',
      render: function (data, type, row) {
        return (data ? moment(data).format("YYYY-MM-DD HH:mm:ss") : '');
      }
    }, 
    {
      data: 'sugarMillName',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },  
    {
      data: 'distilleryName',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },  
    {
      data: 'quantity',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    }, 
    {
      data: 'grade',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    }, 
    {
      data: 'accidentalTypeDistrictValue',
      "className": "text-capitalize",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
    {
      data: 'statusDesc',
      "className": "text-center",
      render: function (data, type, row) {
        return (data ? data : '');
      }
    },
  ]
  ExportTOExcel() {
    const data = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    this.dataService.downloadExcel(data, 'Accidental Request List', [this.addressproofListHeader, this.addressproofListTableKeys]);
  }
  generatePdf() {
    let head = [[
      'S.No',
      'Request ID',
      'MF4 Gate Pass No',
      'Accident Type',
      'Date of Application',
      'Distillery Name',
      'Accident Location District',
      'Status']]
    let data = [];
    this.dataSource.data.forEach((element: any, i: number) => {
      console.log(element.createdDate);
      data[i] = [];
      data[i].push(i + 1);
      data[i].push({
        content: element ? element.applicationNumber : '',
        styles: { cellWidth: 20, cellPadding: 2, fontSize: 10, fontStyle: 'normal' }
      });
      data[i].push({
        content: element ? element.mf4GatePassNo : '',
        styles: { cellWidth: 30, cellPadding: 2, fontSize: 10, fontStyle: 'normal' }
      });
      data[i].push(element ? element.accidentalTypeValue : '')
      data[i].push(element ? moment(element.createdDate).format("YYYY-MM-DD HH:mm:ss") : '')
      data[i].push(element ? element.distilleryName : '')
      data[i].push(element ? element.accidentalTypeDistrictValue : '')
      data[i].push(element ? element.statusDesc : '')
    });
    var doc = new jsPDF();

    doc.setFontSize(0);
    doc.text('Accidental Request List', 11, 8);
    doc.setFontSize(0);
    doc.setTextColor(100);


    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0]
      },

      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      headerStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      didDrawCell: data => {
        console.log(data.column.index)
      }
    })
    // below line for Download PDF document  
    doc.save('Accidental_Request.pdf');
  }

}
