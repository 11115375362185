import { Injectable }                                                 from '@angular/core';
import { environment } from '@appEnv/environment';
import { Observable, fromEvent, merge, Subject, timer, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class IdleMonitoringService {

	private eventListObservable$: Observable<any>;
	private primaryTimer$: Subscription;
	private secondaryTimer$: Subscription;
	private timeoutInMs: number;
	private timerConfigured: boolean = false;

	public expired$: Subject<boolean> = new Subject<boolean>();

	constructor() {
	}

	public setIdleTimer() {
		const userConfig: any = JSON.parse(localStorage.getItem('USER_ACTIVITY_CONFIG')).find(res => res.code === 'SESSION_TIMEOUT_TIME');
		const timeoutInSeconds = (parseInt(userConfig.value) * 60);
		if (this.timerConfigured) {
			this.resetTimer();
			return this.expired$;
		}
		this.timerConfigured = true;
		this.eventListObservable$ = merge(
			fromEvent(document, 'mousemove'),
			fromEvent(document, 'click'),
			fromEvent(document, 'mousedown'),
			fromEvent(document, 'keypress'),
			fromEvent(document, 'DOMMouseScroll'),
			fromEvent(document, 'mousewheel'),
			fromEvent(document, 'touchmove'),
			fromEvent(document, 'MSPointerMove'),
			fromEvent(window, 'mousemove'),
			fromEvent(window, 'resize'),
		);
		this.timeoutInMs = timeoutInSeconds * 1000;
		this.primaryTimer$ = this.eventListObservable$.subscribe((_) => {
			// reset timer here
			this.resetTimer();
		});
		// Start the timer here
		this.startObserving();

		return this.expired$;
	}

	private startObserving() {
		this.secondaryTimer$ = timer(this.timeoutInMs).subscribe((_) => {
			this.expired$.next(true);
		});
	}

	public resetTimer() {
		this.secondaryTimer$.unsubscribe();
		this.startObserving();
	}

	public stopTimer() {
		this.primaryTimer$.unsubscribe();
		this.secondaryTimer$.unsubscribe();
	}
}
