import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DistileryService, ValidationService, TransportGenService, DropdownConfigService, SpiritImportPermitService, DocumentUploadService, AuthService } from '@app/services';
import { TransportPassService } from '@app/services/brewery/transportpass.service';
import { BrandlabelbrewService, IndentRequestForOmdService, PdCommonService } from '@app/services/pd';
import { blendTransferLocalUrl } from '@app/_constant/blendtransfer-local-url';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TankregistartionServicePd } from '@app/services/pd/tankregistartion-pd.service';
import * as fileSaver from 'file-saver';
import { LocalUrl } from '@app/_constant/stock-local-url';
import { NonRegService } from '@app/services/distillery/nonregpd25.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


function requiredFileType(type) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      var file_name_array = file.split(".");
      if (file_name_array.length > 0) {
        const extension = (file_name_array[file_name_array.length - 1]) ? file_name_array[file_name_array.length - 1].toLowerCase() : '';

        if (type.indexOf(extension) < 0) {

          return {
            requiredFileType: true
          };
        }
      }
      return null;
    }
    return null;
  };
}


function fileSizeValidation(files: FileList) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const fileSize = files.item(0).size;
      const fileSizeInKB = Math.round(fileSize);
      let maxSize: any = 1024 * 1024 * 2;
      if (fileSizeInKB >= maxSize) {
        return {
          fileSizeValidator: true
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

@Component({
  selector: 'app-add-pd25-form',
  templateUrl: './add-pd25-form.component.html',
  styleUrls: ['./add-pd25-form.component.scss']
})
export class AddPd25FormComponent implements OnInit {
  myDate: any = new Date();
  pd25Form: FormGroup;
  validUptoHrs: any;
  routeType = []
  licensenodata = []
  districtData: any[];
  routeSearchValue = null;
  routeRangeList: any[] = [];
  tankDrumList: Array<any> = [];
  tankList: Array<any> = [];
  drumList: Array<any> = [];
  enteredQty: number;
  qtyError: any = {
    enteredTareWeight: false,
  };
  formSubmitted = false;
  licenseTypes = []
  editId: any;
  distilleryName: any;
  licenseDetailsResponse: any;
  routeDetailsDropDownSettings: IDropdownSettings;
  editObject: any;
  documentdetaillist: any[];
  public minPermitDate = moment(moment()).subtract(2, 'months').format('YYYY-MM-DD');
  public maxPermitDate = moment(moment()).add(100, 'years').format('YYYY-MM-DD');
  routeDetailsRequired = false;
  pd26RoutDetails: any = [];
  availableQuantity: any;
  paymentDetails: any;
  constructor(
    private formBuilder: FormBuilder,
    private validateService: ValidationService,
    private alert: AlertService, private route: ActivatedRoute,
    private distilleryService: DistileryService, private router: Router,
    private brandlabelbrewService: BrandlabelbrewService,
    public transportpassservice: TransportPassService,
    private transportGenService: TransportGenService,
    private distileryService: IndentRequestForOmdService,
    private datePipe: DatePipe,
    private dropDownService: DropdownConfigService,
    private NonRegService: NonRegService,
     private documentUploadService: DocumentUploadService,
      private authService: AuthService,
      private TankRegistrationService: TankregistartionServicePd,
      private distilleryservice: DistileryService,
  ) {

  }

  // common setting 
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any
  data: any;
  maxDate: any;

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    let subUrlManage = currentUrlArray[1] + currentUrlArray[2];
    subUrlManage = subUrlManage.replace(/-/g, "");
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowModule = LocalUrl[subUrlManage].workflowModule;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;

    this.formInit();
    if (!this.editId) {
      this.getLicenceDetails();
      this.getapplicationnumber();
    }
    this.getRouteTypes();
    this.getDistrict(null);
    this.getalltanks();
    this.getPaymentDetails();
    this.route.params.subscribe(params => {
      if (params.Id) {
        this.editId = params.Id;
       this.getbyiddata(this.editId);
      }
    });

    this.routeDetailsDropDownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }


  async getLicenceDetails() {
    //let response = await this.brandlabelbrewService.getdetailsBottling()
    let response: any = await this.brandlabelbrewService.getdetails();
    // .subscribe((response: any) => {
    // this.licenseTypes = response.content
    response.content.forEach(element => {
      if (element.parentDetails != null || element.licenseCategory == 'PD2') {
        this.licenseTypes.push(element)
        console.log(this.licenseTypes)
        this.getlicensenumber(this.licenseTypes[0].licenseCategory)
      }
    });
    // })
  }
  bottlingUnitAddress: any;
  getlicensenumber(licensenumber) {
    if (licensenumber) {
      this.licensenodata = [];
      if (this.licenseTypes) {
        this.licenseTypes.filter(ele => {
          if (ele.licenseSubCategory === licensenumber) {
            this.licenseDetailsResponse = ele
            this.pd25Form.controls['licenseno'].setValue(ele.licenseNumber)
            this.distilleryName = ele.unitName
            this.bottlingUnitAddress = ele;
            console.log(this.distilleryName)

            this.bottlingUnitAddress.address = (this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '') + ',' +
              (this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '') + ',' +
              (this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '') + ',' +
              (this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '') + ',' +
              (this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : '');
            const Obj = {
              licenseNumber: ele.licenseNumber
            };
            this.licensenodata.push(Obj);
          }
        });
        this.getdistilary(licensenumber)
      }
    }
  }
  getdistilary(licensenumber) {
    if (this.licenseTypes) {
      this.licenseTypes.filter(ele => {
        if (ele.licenseNumber == licensenumber) {
          this.distilleryName = ele.unitName
          this.getapplicationnumber()
        }
      })
    }
  }
  formInit() {
    this.pd25Form = this.formBuilder.group({
      licensetype: [''],
      licenseno: [''],
      dateOfApplication: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      // applicationNumber: [''],
      consigneeEntityType: ['', Validators.required,],
      consigneeEntityName: ['', Validators.required,],
      consigneePanNo: ['', Validators.required,],
      consigneeRegNo: ['', Validators.required,],
      permitNo: ['', Validators.required,],
      permitDate: ['', Validators.required,],
      spiritType: ['', Validators.required],
      permitQty: ['', Validators.required,],
      issueQty: ['', Validators.required,],
      advolaramFee: ['', Validators.required],
      transporterName: ['', [Validators.required, Validators.maxLength(30)]],
      vehicleNo: ['', [Validators.required, Validators.maxLength(16)]],
      driverName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern(/^[a-zA-Z() ]+$/)]],
      routeTypeCode: ['', Validators.required],
      routeTypeValue: [''],
      routeDetails: ['', Validators.required],
      majorRoute: ['', [Validators.required, Validators.maxLength(100)]],
      distance: ['', Validators.required],
      distanceValue:[''],
      validUpTo: ['', Validators.required],
      temperature: [''],
      netWeight: [''],
      validUpToHrs: ['', [Validators.required]],
      tareWeight: [''],
      grossWeight: [''],
      strength: [''],
      indication: [''],
      exportPassFees: [''],
      ethanolTypeCode: [''],
      availableQuantity: [''],
      permitCopyDoc:['', Validators.required],
      permitCopyDocacahmentFiles:[''],
      chemberList: this.formBuilder.array([
        this.labelformgroup()
      ]),
      transferDetails: this.formBuilder.array([
        this.tankformgroup()
      ]),

    })
  }
  addtank(index) {
    let isInvalidtank = (<FormArray>this.pd25Form.get('transferDetails')).controls[index].invalid;
    if (!isInvalidtank) {
      (this.pd25Form.get('transferDetails') as FormArray).push(this.tankformgroup());
    } else {
      this.alert.showError('You can not add more row.. Please fill required details..!', 'Error');
    }

  }
  deletetank(i) {
    (this.pd25Form.get('transferDetails') as FormArray).removeAt(i);
  }
  tankformgroup(data?: any): FormGroup {
    return this.formBuilder.group({
      id: data && data.id || [''],
      createdBy: data && data.createdBy || [''],
      createdDate: data && data.createdDate || [''],
      modifiedBy: data && data.modifiedBy || [''],
      modifiedDate: data && data.modifiedDate || [''],
      tankNameCode: data && data.tankNameCode || ['', Validators.required],
      availableQuantityBl: data && Number(data.availableQuantityBl) || 0,
      availableQuantityAl: data && Number(data.availableQuantityAl) || 0,
      strength: data && Number(data.strength) || ['', Validators.required],
      transferQuantityBl: data && Number(data.transferQuantityBl) || ['', Validators.required],
      transferQuantityAl: data && Number(data.transferQuantityAl) || 0,
      wastageBl: data && Number(data.wastageBl) || ['', Validators.required],
      wastageAl: data && Number(data.wastageAl) || 0,
      remainingQuantityBl: data && Number(data.remainingQuantityBl) || 0,
      remainingQuantityAl: data && Number(data.remainingQuantityAl) || 0,
      // Radar Implementation
      // tankList: this.formBuilder.array(data && data.tankList || []),
      // isRadarApplicable: data && data.isRadarApplicable || [''],
      // radarId: data && data.radarId || [''],
      // radarDateAndTime: data && data.radarDateAndTime || [''],
      // tankRedorRequestId: data && data.tankRedorRequestId || [''],
      // radarStartReading: data && data.radarStartReading || [''],
      // radarEndReading: data && data.radarEndReading || [''],
      // radarBulkLitre: data && data.radarBulkLitre || [''],
    })
  }
  getFormCntr(element) { return this.pd25Form.get(element); }
  getFormCntr1(i, element) {
    let rawcontrol = this.pd25Form.get('transferDetails') as FormArray;
    return rawcontrol.at(i).get(element);
  }
  getFormCntr2(i, element) {
    let tankcontrol = this.pd25Form.get('chemberList') as FormArray;
    return tankcontrol.at(i).get(element);
  }

  labelformgroup(data?: any): FormGroup {
    return this.formBuilder.group({
      chamberNo: data && data.chamberNo || ['', [Validators.required]],
      dip: data && data.dip || ['', [Validators.required]],
      quntityAL: data && data.quntityAL || ['', [Validators.required]],
      quntityBl: data && data.quntityBl || ['', [Validators.required]],
      id: data && data.id || ['']
    })
  }
  deletelabelrow(i) {
    (this.pd25Form.get('chemberList') as FormArray).removeAt(i);
  }
  uploadLabels(labelInd: number): FormArray {
    if (labelInd != undefined) {
      return this.lableDetailss().at(labelInd).get('chemberList') as FormArray;
    }
  }
  get labelDetails() {
    return this.pd25Form.get('chemberList')['controls'];
  }
  lableDetailss(): FormArray {
    return this.pd25Form.get('chemberList') as FormArray;
  }
  addlabelgroup(index) {
    let isInvalid = (<FormArray>this.pd25Form.get('chemberList')).controls[index].invalid;
    if (!isInvalid) {
      (this.pd25Form.get('chemberList') as FormArray).push(this.labelformgroup());
    } else {
      this.alert.showError('You can not add more row.. Please fill required details..!', 'Error');
    }

  }
  applicationNumber = ''
  getapplicationnumber() {
    let model = {
      licenseNumber: this.pd25Form.value.licenseno,
      registrationType: 'PD25_'
    }
    this.distilleryService.getapplicationnumbers(model).subscribe((data: any) => {
      if (data.content) {
        this.applicationNumber = data.content.ApplicationNumber;
      }
    })
  }
  getRouteTypes() {
    // let payload = {
    //   "dropDownKey": 'ROUTE_TYPE'
    // }
    // this.distilleryService.getDropdownValues(payload).subscribe(res => {
    //   this.routeType = res.data.downDropDownMasterValueList
    // });
    this.transportpassservice.getStatusMasters().subscribe((responceData: any) => {
      if (responceData.status === "s") {
        this.routeType = responceData.data.downDropDownMasterValueList
      }
    });
    this.getRouteRangeList();
  }
  changeRouteType(event) {
    if (event == 'OUTSIDE_DISTRICT') {
      this.pd25Form.controls['routeDetails'].reset();
      this.districtData = [];
      this.getDistrict(null);
    } else if (event == 'WITH_IN_DISTRICT') {
      this.pd25Form.controls['routeDetails'].reset();
      this.districtData = [];
      //   let userDetails = JSON.parse(localStorage.getItem('USER_DET'));
      //   this.districtData.push({ 'name': userDetails.userAddressVO.districtDesc, 'code': userDetails.userAddressVO.districtCode });
      this.districtData.push({ 'name': this.bottlingUnitAddress.districtDesc, 'code': this.bottlingUnitAddress.districtCode });
    }
    this.routeType.filter(ele => {
      if (ele.paramKey == event) {
        this.pd25Form.controls['routeTypeValue'].setValue(ele.paramValue)
      }
    })
  }


  onDropDownClose() {
    // if (this.routeSearchValue !== null) {
    //   this.routeSearchValue = null;
    this.getDistrict(this.routeSearchValue);
    //}
  }

  getDistrict(pageNo = 0, pageSize = 10) {
    const requestParam = { "dataCode": "TRANSPORT_PASS_ROUTE_DETAILS", "placeholderKeyValueMap": {} }
    this.transportpassservice.scanIndentNumber(requestParam)
      .subscribe((resp: any) => {
        const { statusCode, responseMessage, responseContent } = resp;
        if (statusCode === 0) {
          const { responseContent: dataList, totalElements, numberOfElements } = responseContent;
          this.districtData = responseContent;
        } else {
          this.alert.showError(resp.responseMessage);
        }
      });
  }
  getRouteRangeList() {
    this.transportGenService.getAllRouteRange().subscribe((responceData: any) => {
      if (responceData.status === "s") {
        this.routeRangeList = responceData.data.downDropDownMasterValueList
      }
    });

  }
  getRouteRange(eveValue, editFlag = false) {
    // if (this.routeRangeList && eveValue) {
    //   let days = this.routeRangeList.find((resp: any) => resp.paramKey === eveValue).additionalValue1;
    //   this.addDays(days, editFlag);
    // }
    if (this.routeRangeList && eveValue) {
      let days = this.routeRangeList.find((resp: any) => resp.paramKey === eveValue).additionalValue1;
      this.pd25Form.patchValue({
        validUpToHrs: this.routeRangeList.find((resp: any) => resp.paramKey === eveValue).additionalValue2
      })
      this.validUptoHrs = this.routeRangeList.find((resp: any) => resp.paramKey === eveValue).additionalValue2
      // this.addDays(validUptoHrs, editFlag);
    }
  }
  addDays(days, editFlag = false) {
    if (!editFlag) {
      this.pd25Form.patchValue({
        validUpTo: moment().add(days, 'day').format("yyyy-MM-DD")
      });
    }
    this.maxDate = moment().add(days, 'day');
  }

  calculateNet() {
    let cal = this.pd25Form.value;
    let tankwiseNet = Number(cal.grossWeight) - Number(cal.tareWeight);
    //const num1 = Math.max(tankwiseNet, 0).toFixed(2);
    this.pd25Form.controls['netWeight'].setValue(tankwiseNet.toFixed(2));
  }
  tempTankTransfer: number;
  tempQty: number;
  checkQuantity() {
    if (this.tempQty < this.tempTankTransfer) {
      //console.log("adsf")
    }
  }
  getValidation(val) {
    if (+this.pd25Form.value.grossWeight <= +val) {
      this.pd25Form.controls['tareWeight'].reset();
      this.alert.showError("TareWeight should be less than GrossWeight", "error");
    }

  }
  calculateAvailableQuantityAL(index) {
    let cal = this.pd25Form.value;
    this.availableQuantityAl = (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].availableQuantityBl) * Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].strength)) / 100;
    const formArray = this.pd25Form.get('transferDetails') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    formGroup.patchValue({
      availableQuantityAl: this.availableQuantityAl.toFixed(2),
    });
  }
  getalltanks(page = 0, pageSize = 0) {
    this.tankDrumList = [];
    const postData = {
      "filters": {
        entityType: this.entityType,
        status: 'APPROVED'
      },
      pageNo: page,
      paginationSize: pageSize
    };
    this.TankRegistrationService.searchTanReg(postData).subscribe((resp: any) => {
      this.tankList = resp.data.contents.filter(m => m.tankAvailableQuantity > 0);
    });
    this.TankRegistrationService.searchDrumReg(postData).subscribe((resp: any) => {
      this.drumList = resp.data.contents.filter(m => m.tankAvailableQuantity > 0);
    });

    setTimeout(() => {
      if (this.tankList.length != 0 || this.drumList.length != 0) {
        this.tankDrumList = this.tankList.concat(this.drumList);
      }
      if (this.tankDrumList.length == 0) {
        let payload = {
          "filters": {
            "entityType": this.entityType,
            "status": "APPROVED",
          },
          "pageNo": 0,
          "paginationSize": 0
        }
        this.TankRegistrationService.searchTanReg(payload).subscribe((resp: any) => {
          this.tankList = resp.data.contents ? resp.data.contents.filter(m => m.tankAvailableQuantity > 0) : [];
        });

        this.TankRegistrationService.searchDrumReg(payload).subscribe((resp: any) => {
          this.drumList = resp.data.contents ? resp.data.contents.filter(m => m.tankAvailableQuantity > 0) : [];
        });
        setTimeout(() => {
          this.tankDrumList = this.tankList.concat(this.drumList);
        }, 2000);
      }
    }, 2000);
  }
  getTankDetails(val, index) {
    this.tankDrumList.forEach(ele => {
      if (val == ele.tankId) {
        let cal = this.pd25Form.value;
        this.pd25Form.get('transferDetails').value.forEach(x => {
          this.availableQuantity = ele.tankAvailableQuantity;
        })
        const formArray = this.pd25Form.get('transferDetails') as FormArray;
        const formGroup = formArray.at(index) as FormGroup;
        formGroup.patchValue({
          // tankNameValue: this.tankDrumList.find(x => x.tankId == val).tankName,
          availableQuantityBl: this.availableQuantity,
          isRadarApplicable: this.tankDrumList.find(x => x.tankId == val).isRedorBased == true ? 'Yes' : 'No',
          radarId: this.tankDrumList.find(x => x.tankId == val).tankRedorVatCode
        });
        // this.getTankRequestId('add', formGroup.value.radarId, index);
      }
    })
    let checkFlag = false;
    let t1: any = (this.pd25Form.get('transferDetails') as FormArray).at(index);
    const arr = this.pd25Form.get('transferDetails')['controls'];
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (index !== i) {
          if (arr[i].value.tankNameCode == val) {
            checkFlag = true;
          }
        }
      }
    }
    if (checkFlag === true) {
      this.alert.showError('Tank Name Already Existed', 'Error');
      (this.pd25Form.get('transferDetails') as FormArray).at(index).reset();
    }
  }
  calculate1(data, index) {
    let total = 0;
    data.transferDetails.forEach((x) => {
      total += parseFloat(x.transferQuantityBl);
    })
    if (Number(total) > Number(this.pd25Form.value.dispatchQty)) {
      this.alert.showError("Sum of transfer quantity should be less than Dispatch quantity");
      (this.pd25Form.controls['transferDetails'] as FormArray).at(index).patchValue({
        transferQuantityBl: ""
      });
    }
  }
  al: number;
  calculate(index) {
    let cal = this.pd25Form.value;
    const formArray = this.pd25Form.get('chemberList') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    const strengthControl = this.pd25Form.get('strength');
    const blQuantityControl = formGroup.get('quntityBl');
    if (!strengthControl.value) {
      this.alert.showWarning('Strength is required before entering blQuantity.');
      blQuantityControl.setValue('');
    }
    // } else {
    // const blQuantity = blQuantityControl.value;
    this.al = (this.pd25Form.get('chemberList')['controls'][index]['value'].quntityBl * cal.strength) / 100
    // this.al = (blQuantity * strengthControl.value) / 100;
    formGroup.patchValue({ quntityAL: this.al });
    let totalBlQuantity =0
    this.pd25Form.value.chemberList.forEach(res=>{
      totalBlQuantity = totalBlQuantity + parseFloat(res.quntityBl)
    })
    if(totalBlQuantity > this.pd25Form.value.issueQty){
      this.alert.showError("Sum Of the BL should not greater than Permit Quantity(BL)")
      blQuantityControl.setValue('');
    }
  }
  qtyErrTag = [];
  checkQuantityRules(event, qty) {
    this.enteredQty = +event.target.value;
    if (qty === 'enteredTareWeight') {
      if (this.enteredQty > +this.pd25Form.get('grossWeight').value) {
        this.qtyError['enteredTareWeight'] = true;
        this.qtyErrTag['enteredTareWeight'] = qty;
        return false;
      } else {
        this.qtyError['enteredTareWeight'] = false;
        this.qtyErrTag['enteredTareWeight'] = "";
        return false;
      }
    }
    return false;
  }
  private validateFileName (fileName: string): boolean {
      const pattern = /^[^.]+(\.[^.]+)?$/;
      return pattern.test(fileName);
    }
  
    onFileChange(event, sourceName, form, sourceFile) {
      // let maxSize: any = 1024 * 1024;
      var valtype = ['png', 'jpeg', 'jpg', 'pdf', 'x-png'];
      let maxSize: any = 1024 * 2048;
      let fileSize: any;
      let fileName = event.target.value;
      let fileval=event.target.files[0].name;
  
      this.pd25Form.controls[sourceFile].setValidators([
        // Validators.required,
        requiredFileType(valtype),
        fileSizeValidation(event.target.files)
      ]);
  
      this.pd25Form.controls[sourceFile].updateValueAndValidity();
  
      if (!this.validateFileName(fileval)) {
        this.alert.showError("File name is not valid. Please choose a valid file.")
        this.pd25Form.patchValue({
          [sourceFile]: ''
        });
      }
      
      // if (sourceFile === "tankcleaningattacahment") {
      //   valtype = ['png', 'jpeg', 'jpg', 'pdf', 'x-png'];
      // }
      
      
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.pd25Form.patchValue({
          [sourceName]: file
        });
        if (file.size > maxSize) {
          this.alert.showError('Please upload less than 2 Mb size', 'error');
          return null
        } else {
          this.pd25Form.patchValue({
            [sourceName]: file
          });
        }
      } else {
        this.pd25Form.patchValue({
          [sourceName]: ''
        })
      }
  
    }
  
  combineArr(oldArr, newArr) {
    if (newArr && newArr.length > 0) {

      newArr.forEach(element => {

        const ind = oldArr.findIndex((item) => item.name === element.name);

        if (ind !== -1) {

          element.id = oldArr[ind].id ? oldArr[ind].id : '';

          element.createdBy = oldArr[ind].createdBy ? oldArr[ind].createdBy : '';

          element.createdDate = oldArr[ind].createdDate ? oldArr[ind].createdDate : '';

          oldArr[ind] = element;

        } else {

          oldArr.push(element);

        }

      });



    }

    return oldArr;

  }

    downloadDocument(uuid, documentName) {
      if (uuid && documentName) {
        this.documentUploadService.downloadDocument(uuid, documentName).subscribe((resp: any) => {
          fileSaver.saveAs(resp, documentName);
        },
          (error: any) => this.alert.showError('Error downloading the file', 'Error'),
          () => console.info('File downloaded successfully')
        );
      } else {
        this.alert.showError('File not available', 'Error');
      }
  
    }
  
    getDocName(name) {
      if (this.editObject) {
        if (this.editObject.nonRegisterPD25DocList.name) {
          return this.editObject ? this.editObject.nonRegisterPD25DocList: '';
        }
      }
      return false;
    }
    async uploaddoc(applicationno) {
  console.log(applicationno)
      const applicantDocuments = 
       [{ name: 'permitCopyDoc', fileSource: 'permitCopyDocacahmentFiles' }];
        console.log(applicantDocuments)
      const applicantDocumentsUploadedDetails = await this.uploadDocuments(applicationno, applicantDocuments);
      return applicantDocumentsUploadedDetails;
    }
  
    async uploadDocuments(applicationNo, fileArrList) {
  
      const form = new FormData();
      let inc = 0;
      let uploadFlag = false;
      
      if (fileArrList) {
        for (let i = 0; i < fileArrList.length; i++) {
          if (this.pd25Form.get(fileArrList[i].fileSource).value) {
            uploadFlag = true;
            form.append('files[' + inc + '].name', fileArrList[i].name);
            form.append('files[' + inc + '].file', this.pd25Form.get(fileArrList[i].fileSource).value);
            inc++;
          }
        }
      }
  
      form.append('moduleName', this.moduleName);
      form.append('screenName', 'dispatchNonPd25');
      form.append('applicationNumber', applicationNo);
      //applicationNo BTRP_108574
      if (uploadFlag) {
        this.authService.fileUploadFlag = true;
        const res: any = await this.documentUploadService.uploadDocuments(form);
        this.authService.fileUploadFlag = false;
        const uploadDoc = res.map(item => {
          return {
            documentName: item.documentName,
            name: item.name,
            status: true,
            uuid: item.uuid
          };
        });
        return uploadDoc;
      } else {
        return null;
      }
    }

    numbersOnly(event): boolean {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 9 || charCode > 18)
      ) {
        return false;
      }
      return true;
    }
  

    async submitForm(status, isPreview?: any) {
    let postParam = this.pd25Form.value;
    let parentUserId = JSON.parse(localStorage.getItem('USER_DET')).parentUserId;

    if (parseFloat(this.pd25Form.get('validUpToHrs').value) > this.validUptoHrs) {
      this.alert.showError("Valid Upto (hrs) should not greater than " + this.validUptoHrs + "(hrs)");
      return false;
    }
    if (status === 'SUBMITTED' && this.pd25Form.valid === false) {
      this.formSubmitted = true;
      return;
    } else {
      let documentDetails = await this.uploaddoc(this.applicationNumber);
    if (this.editId){
      documentDetails = this.combineArr (this.editObject.nonRegisterPD25DocList, documentDetails);
  }
    this.documentdetaillist=[];
    
    if (documentDetails) {
      this.documentdetaillist = documentDetails.map((elee: any) => {
        return {
          documentName: elee.documentName,
          status: elee.status,
          name: elee.name,
          uuid: elee.uuid,
          id: elee.id || '',
          createdBy: elee.createdBy || '',
          createdDate: elee.createdDate || ''
        };
      });
    }
      let pd26RoutDetails = [];
      if (postParam.routeDetails) {
        pd26RoutDetails = postParam.routeDetails.map((element: any) => {
          let rowObj = {
            routeTypeCode: element.code,
            routeTypeValue: element.name,
          }
          if (this.editId) {
            rowObj['id'] = element.id
          }
          return rowObj;
        })
      }

      let tankTransferdetails = []
      tankTransferdetails = this.pd25Form.controls['transferDetails'].value.map((ele, index) => {
        let transferObj = {
          id: ele.id,
          tankId: ele.tankNameCode,
          tankName: ele.tankNameCode ? this.tankDrumList.find(x => x.tankId == ele.tankNameCode).tankName: '',
          strenth: ele.strength,
          availableQtyBl: ele.availableQuantityBl,
          availableQtyAl: ele.availableQuantityAl,
          transferQtyBl: ele.transferQuantityBl,
          transferQtyAl: ele.transferQuantityAl,
          wastageBl: ele.wastageBl,
          wastageAl: ele.wastageAl,
          remainingQtyBl: ele.remainingQuantityBl,
          remainingQtyAl: ele.remainingQuantityAl,
        }
        return transferObj;
      })

      let payload = {
        //"transferType": "PIPELINE",
        "applicationNumber": this.applicationNumber,
        //"brandNameValue": "60 Strong",
        //"date": postParam.dateOfApplication,
        "consignorName":this.licenseDetailsResponse.unitName,
        "consignorLicenseType":this.bottlingUnitAddress.licenseSubCategory,
        "consignorAddress":this.licenseDetailsResponse.address,
        "consigneeEntityType": postParam.consigneeEntityType,
        "consigneeEntityName": postParam.consigneeEntityName,
        "consigneeRegNo": postParam.consigneeRegNo,
        "consigneePanNo": postParam.consigneePanNo,
        "distanceValue": postParam.distance ? this.routeRangeList.find(x => x.paramKey == postParam.distance).paramValue : '',
        "distance": postParam.distance,
        // "distance": postParam.distance ? this.routeRangeList.find(x => x.paramKey == postParam.distance).paramValue : '',
        "entityType": this.entityType,
        "event": "INITIATE",
        "permitNo": postParam.permitNo?postParam.permitNo:'',
        "permitDate": postParam.permitDate != '' ? new Date(postParam.permitDate).toISOString() : '',
        "spiritType": postParam.spiritType ? postParam.spiritType : '',
        "permitQty": postParam.permitQty ? postParam.permitQty : null,
        "issueQty": postParam.issueQty ? postParam.issueQty : '',
        "advolaramFee": postParam.advolaramFee,
        "isDigitalSignature": true,
        "isEditable": true,
        "isShowButtons": true,
        "level": "Level 1",
        "licenseNumber": postParam.licenseno,
        "licenseType": this.bottlingUnitAddress.licenseSubCategory,
        "majorRoute": postParam.majorRoute,
        "modeOfTransfer": "MTMC001",
        "nonRegisterPD25RouteDetailsList": postParam.pd26RoutDetails,
        "routeTypeCode": postParam.routeTypeCode,
        "routeTypeValue": postParam.routeTypeValue,
        "stage": "stage",
        "stageDesc": "stageDesc",
        "status": status,
        "statusDesc": status,
        "driverName": postParam.driverName,
        "vehicleNo": postParam.vehicleNo,
        "unitName": this.distilleryName,
        "indication": postParam.indication,
        "strength": postParam.strength,
        "temperature": postParam.temperature,
        "validUpTo": postParam.validUpTo != '' ? new Date(postParam.validUpTo).toISOString() : '',
        "nonRegisterPD25ChamberDetailsList": postParam.chemberList,
        "transporterName": postParam.transporterName,
        "grossWeight": postParam.grossWeight,
        "tareWeight": postParam.tareWeight,
        "netWeight": postParam.netWeight,
        "validUpToHrs": postParam.validUpToHrs,
        "countryCode": this.bottlingUnitAddress ? this.bottlingUnitAddress.countryCode : this.editId && this.editObject.countryCode ? this.editObject.countryCode : this.bottlingUnitAddress.countryCode,
        "stateCode": this.bottlingUnitAddress ? this.bottlingUnitAddress.stateCode : this.editId && this.editObject.stateCode ? this.editObject.stateCode : this.bottlingUnitAddress.stateCode,
        "districtCode": this.bottlingUnitAddress ? this.bottlingUnitAddress.districtCode : this.editId && this.editObject.districtCode ? this.editObject.districtCode : this.bottlingUnitAddress.districtCode,
        "unitCode": this.bottlingUnitAddress ? this.bottlingUnitAddress.unitCode : this.editId && this.editObject.unitCode ? this.editObject.unitCode : this.bottlingUnitAddress.unitCode,
        "licenseSubCategory": this.bottlingUnitAddress ? this.bottlingUnitAddress.licenseSubCategory : this.editId && this.editObject.licenseSubCategory ? this.editObject.licenseSubCategory : this.bottlingUnitAddress.licenseSubCategory,
        "districtDesc": this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : this.editId && this.editObject.districtDesc ? this.editObject.districtDesc : this.bottlingUnitAddress.districtDesc,
        "parentUserId": JSON.parse(localStorage.getItem('USER_DET')).parentUserId,
        "nonRegisterPD25DocList": this.documentdetaillist,
        "nonRegisterTankDetailsList": tankTransferdetails ? tankTransferdetails: [],
        "g6Code":this.paymentDetails ?this.paymentDetails[0].g6 : this.editObject.g6Code,
        "g6":this.paymentDetails?this.paymentDetails[0].g6Name: this.editObject.g6,
       "treasureyHeadCode":this.paymentDetails?this.paymentDetails[0].treasuryHead:this.editObject.treasureyHeadCode,
        "treasuryHead":this.paymentDetails?this.paymentDetails[0].treasuryHeadName:this.editObject.treasuryHead,
        "param": "NON_DISPATCH_PD25",
        "paymentType": "MPMC010",

      }
      if (this.editId) {
        //payload['omdIndentRequest'].id = this.editObject.omdIndentRequest.id
        payload['id'] = this.editId;
        payload['applicationNumber'] = this.editObject.applicationNumber;
        payload['createdDate'] = this.editObject.createdDate;
        payload['createdBy'] = this.editObject.createdBy;
      }
      // payload['omdIndentEthanolDetails'] = this.pd26TankerForm.controls['omdIndentEthanolDetails'].value
      payload['nonRegisterPD25RouteDetailsList'] = pd26RoutDetails ? pd26RoutDetails : [];
      this.NonRegService.addetails(payload).subscribe((responceData: any) => {
        if (responceData['status'] === 's') {
          if (isPreview) {
            this.router.navigate(['/' + this.url + '/nonregpd25/preview/' + responceData.data.id]);
          } else {
            this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
            this.router.navigate(['/' + this.url + '/nonregpd25/list']);
          }
        } else {
          this.alert.showError(responceData.userDisplayMesg);
        }
      });
    }
  }
  checkLength(value, length) {
    if (value.length == length) return false;
  }
  remain: number;
  remainingAl: number;
  transferQuantityAl: number;
  availableQuantityAl: number;
  calculateRemainingQuantity(index) {
    let cal = this.pd25Form.value;
    const formArray = this.pd25Form.get('transferDetails') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    const tankStrengthControl = formArray.at(index).get('strength');
    const quantityControl = formArray.at(index).get('transferQuantityBl');
    if (tankStrengthControl.value === '') {
      if (quantityControl.value.trim() !== '') {
        this.alert.showWarning('Tank Strength is required before entering Quantity.');
        quantityControl.setValue('');
      }
    }
    if (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].transferQuantityBl) > Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].availableQuantityBl)) {
      this.alert.showError("Transfer Quantity(B.L) should be less than the Available Quantity(B.L)");
      (this.pd25Form.controls['transferDetails'] as FormArray).at(index).patchValue({
        transferQuantityBl: ""
      });
    }
    this.transferQuantityAl = (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].transferQuantityBl) * Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].strength)) / 100;
    this.remain = (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].availableQuantityBl)) - (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].transferQuantityBl) + Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].wastageBl));
    this.remainingAl = (Number(this.remain) * Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].strength)) / 100;
    formGroup.patchValue({
      transferQuantityAl: this.transferQuantityAl.toFixed(2),
      remainingQuantityBl: this.remain.toFixed(2),
      remainingQuantityAl: this.remainingAl.toFixed(2)
    });
  }
  validateWithDispatchQty(index) {
    if (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].transferQuantityBl) > Number(this.pd25Form.value.dispatchQty)) {
      this.alert.showError("Transfer Quantity should be less than or equals to the Dispatch Quantity(BL)");
      (this.pd25Form.controls['transferDetails'] as FormArray).at(index).patchValue({
        transferQuantityBl: ""
      });
    }
  }
  wastageAl: number;
  calculateWastageAL(data, index) {
    if (Number(data) > Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].transferQuantityBl)) {
      this.alert.showError("Wastage(BL) should be less than transfer quantity(BL)");
      (this.pd25Form.controls['transferDetails'] as FormArray).at(index).patchValue({
        wastageBl: ""
      });
    }
    this.wastageAl = (Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].wastageBl) * Number(this.pd25Form.get('transferDetails')['controls'][index]['value'].strength)) / 100;
    const formArray = this.pd25Form.get('transferDetails') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    formGroup.patchValue({
      wastageAl: this.wastageAl.toFixed(2),
    });
  }

   getbyiddata(id) {
      this.NonRegService.getById(id).subscribe((responceData: any) => {
        if (responceData.status === 's') {
          this.editObject = responceData.data;
          this.pd26RoutDetails = []
          this.editObject.nonRegisterPD25RouteDetailsList.forEach(element => {
            this.pd26RoutDetails.push({
              "id": element.id,
              "code": element.routeTypeCode,
              "name": element.routeTypeValue
            })
          })
          this.pd25Form.patchValue({
            licensetype: this.editObject.licenseType,
            licenseno: this.editObject.licenseNumber,

            consignorName: this.editObject.consignorName,
            consignorLicenseType: this.editObject.consignorLicenseType,
            consignorAddress: this.editObject.consignorAddress,
            consigneeEntityType: this.editObject.consigneeEntityType,
            consigneeEntityName: this.editObject.consigneeEntityName,
            consigneeRegNo: this.editObject.consigneeRegNo,
            consigneePanNo:this.editObject.consigneePanNo,
            permitNo:this.editObject.permitNo,
            permitDate: this.editObject.permitDate != null ? this.datePipe.transform(this.editObject.permitDate, 'yyyy-MM-dd') : '',
            spiritType : this.editObject.spiritType ? this.editObject.spiritType : '',
            permitQty : this.editObject.permitQty ? this.editObject.permitQty : '',
            issueQty : this.editObject.issueQty ? this.editObject.issueQty : '',
            advolaramFee : this.editObject.advolaramFee ? this.editObject.advolaramFee : '',
            validUpTo: this.editObject.validUpTo != null ? this.datePipe.transform(this.editObject.validUpTo, 'yyyy-MM-dd') : '',
            //dateOfApplication: this.datePipe.transform(this.editObject.date, 'yyyy-MM-dd'),
            // applicationNumber: this.editObject.indentReqApplicationNo,
            transporterName: this.editObject.transporterName,
            vehicleNo: this.editObject.vehicleNo,
            driverName: this.editObject.driverName,
            routeTypeCode: this.editObject.routeTypeCode,
            routeTypeValue: this.editObject.routeTypeValue,
            routeDetails: this.pd26RoutDetails,
            majorRoute: this.editObject.majorRoute,
            distance: this.editObject.distance,
            distanceValue:this.editObject.distanceValue,
            temperature: this.editObject.temperature,
            netWeight: this.editObject.netWeight,
            tareWeight: this.editObject.tareWeight,
            grossWeight: this.editObject.grossWeight,
            strength: this.editObject.strength,
            indication: this.editObject.indication,
            validUpToHrs: this.editObject.validUpToHrs,
          })
          this.applicationNumber = this.editObject.applicationNumber
          setTimeout(() => {
            this.getLicenceDetails()
            //console.log(this.spiritList)
            this.getlicensenumber(this.editObject.licenseType);
            this.getapplicationnumber();
          }, 4000);
          const tankrawcontrol = this.pd25Form.get('transferDetails') as FormArray;
        (this.pd25Form.get('transferDetails') as FormArray).removeAt(0);
        this.editObject.nonRegisterTankDetailsList.forEach((element, i) => {
          const rawobj = {
            id: element.id,
            createdBy: element.createdBy,
            createdDate: element.createdDate,
            modifiedBy: element.modifiedBy,
            modifiedDate: element.modifiedDate,
            tankNameCode: element.tankId,
            strength: element.strenth,
            availableQuantityBl: element.availableQtyBl,
            availableQuantityAl: element.availableQtyAl,
            transferQuantityBl: element.transferQtyBl,
            transferQuantityAl: element.transferQtyAl,
            wastageBl: element.wastageBl,
            wastageAl: element.wastageAl,
            remainingQuantityBl: element.remainingQtyBl,
            remainingQuantityAl: element.remainingQtyAl,
          };
          tankrawcontrol.push(this.tankformgroup(rawobj));
        });

          
          const rawcontrol = this.pd25Form.get('chemberList') as FormArray;
          (this.pd25Form.get('chemberList') as FormArray).removeAt(0);
          this.editObject.nonRegisterPD25ChamberDetailsList.forEach((element, i) => {
            const rawobj = {
              chamberNo: element.chamberNo,
              dip: element.dip,
              quntityBl: element.quntityBl,
              quntityAL: element.quntityAL,
              id: element.id
            };
            rawcontrol.push(this.labelformgroup(rawobj));
          });
          if(this.editObject.nonRegisterPD25DocList){
            this.pd25Form.get('permitCopyDoc').clearValidators();
            this.pd25Form.get('permitCopyDoc').updateValueAndValidity();
        }
  
        }
      })
    }
  
  afterDecimal: number;
    validuptoValidation(){
      var hours = Math.floor(this.pd25Form.get('validUpToHrs').value); // Extract the whole number part (hours)
      
      let minutes = hoursToMinutes(hours);
       let splitMinutes =this.pd25Form.get('validUpToHrs').value.split('.')
       let minutes1 =parseInt(splitMinutes[1]);
       if(minutes1 > 59){
        this.pd25Form.get('validUpToHrs').setValue('')
       }
       if(minutes1 > 0){
        this.afterDecimal = minutes1
       }else{
        this.afterDecimal =0
       }
      // var minutes1 = (Math.round((this.transportpassFormGroup.get('validUptoHrs').value % 1))); // Extract the decimal part and convert to minutes
      //console.log(hours,minutes,minutes1,splitMinutes)
      this.pd25Form.patchValue({
        validUpTo: moment().add((minutes + this.afterDecimal), 'minute')
      });
    }

    getPaymentDetails(){
      let feeType = "";
      let feeCode = "";
       feeType = "ADVALOREM Fee"
       feeCode="SFPMC0154"
      const payload = {
        
        "filters": {
        "entity":this.entityType,
        "licenseType":"PD2",
        "moduleKey":"MMC002",
        "dispatchTypeKey": "DTMC003",
        "screenName": "spiritdispatchfeesservice",
        "spiritKey" :"SFPMC0169"
          } 
       
      }
      this.distilleryservice.getPaymentDetail(payload).subscribe((responceData: any) => {
        this.paymentDetails = responceData.data.contents.filter(res=>res.subFeesPenalityName == feeType
          || res.subFeesAndPenalityKey == feeCode
        )
        console.log(this.paymentDetails)
      });
    }


}
function hoursToMinutes(hours: number) {
  return hours * 60;
}
