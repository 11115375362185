
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OMDService {
  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'omdRegistration';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'omdRegistration/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'omdRegistration/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data) {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {});
  }
  getLicenseData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getDataByScanCode(code): Observable<any> {
    const url = this.baseURL + 'license/category/' + code;
    return this.http.get(url);
  }

  getOMDRegLazySearch(payload): Observable<any>{
    const url = this.baseURL + 'omdQuotaRegistration/oilMixingDepartmentDetails';
    return this.http.post(url, payload);
  }

  getOMDQuotaLazySearch(payload): Observable<any>{
    const url = this.baseURL + 'omdQuotaRegistration/lazySearch';
    return this.http.post(url, payload);
  }

  getByOMDQuotaId(id): Observable<any> {
    const url = this.baseURL + 'omdQuotaRegistration/' + id;
    return this.http.get(url);
  }

  addOmdQuota(data): Observable<any> {
    const url = this.baseURL + 'omdQuotaRegistration';
    return this.http.post(url, data);
  }

  getByOMDAnnualandLiftedDetails(licenseNo, financialYr): Observable<any> {
    const url = this.baseURL + 'omdEthanolOrder/getOmdConsumptionDetails?omdLicenseNumber=' + licenseNo + '&financialYear=' + financialYr;
    return this.http.get(url);
  }

}