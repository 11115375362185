import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/theme/shared/shared.module';

import { AccidentalomdRoutingModule } from './accidentalomd-routing.module';
import { AccidentalomdComponent } from './accidentalomd.component';
import { AddaccidentalomdComponent } from './addaccidentalomd/addaccidentalomd.component';
import { ViewaccidentalomdComponent } from './viewaccidentalomd/viewaccidentalomd.component';


@NgModule({
  declarations: [AccidentalomdComponent, AddaccidentalomdComponent, ViewaccidentalomdComponent],
  imports: [
    CommonModule,
    AccidentalomdRoutingModule,
    SharedModule
  ]
})
export class AccidentalomdModule { }
