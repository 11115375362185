import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})

export class RequestForEthanolOrderForOmdService {

    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;
    domainUrl = environment.domainUrl;
    // licenseNumber = 'LIC/INDUSTRIALALCOHOL/FL41/BALRAMPUR/5/2023/028218';
    baseURL1 = environment.scmproduction;
    getStatusMasters() {
      const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
      return this.http.get(url)
     }
  
     search(payload)
     {

         const url = this.baseURL + "omdEthanolOrder/lazySearch"
         return this.http.post(url, payload);
     }
  
     // temporary.. change after getting clarification
     getdetails(): Observable<any> {//work
      const url = this.licenseURL + 'license/category/DISTILLERY';
      return this.http.get(url);
    }

    getapplicationnumbers(data): Observable<any> {
      const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
      return this.http.post(url, data);
    }

    addEthanolForOmdDetails(data): Observable<any> {
      const url = this.baseURL + 'omdEthanolOrder';
      return this.http.post(url, data);
  }

  EthanolForOmdDetailsById(id): Observable<any> {
    const url = this.baseURL + 'omdEthanolOrder/' + id;
    return this.http.get(url);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }

  getIndusDistLicensedetails(data): Observable<any> {//work
    const url = this.licenseURL + 'license/category/INDUSTRIAL_DISTILLERY?licenseNumber='+data;
    return this.http.get(url);
  }
  getOmdName(): Observable<any>{
    const url = this.licenseURL+'license/category/INDUSTRIAL_ALCOHOL?subCategory=FL41,FL41_OUTSIDE_UP&optionalCategory=INDUSTRIAL_ALCOHOL'
    return this.http.get(url)

  }
  getPayment(id){
    const url = this.domainUrl+'payandrecon/1.0/config/sub/search/' + id;
    return this.http.get(url);
  }
  getAdvance(payload): Observable<any>{
    const url = this.domainUrl+'payandrecon/1.0/advanceDuty/get';
    return this.http.post(url,payload);
  }
  // getAdvance(payload): Observable<any> {
  //   const url = this.domainUrl + 'payandrecon/1.0/advanceDuty/get';//'config/sub/search';
  //   return this.http.post(url, payload);
  // }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL1 + 'omdEthanolOrder/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findpaymentdetailsOmd(applicationno, feetype): Observable<any> {
    let url = this.baseURL1 + 'pd25/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }

  findpaymentdetailsfl16(applicationno, feetype): Observable<any> {
    let url = this.baseURL1 + 'fl16/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  searchLicenseAppln() {
    const url = this.licenseURL + 'licenseExtension/findDetailsByCategorySubCategory?category=INDUSTRIAL_ALCOHOL&subCategory=FL41';
    return this.http.get(url);
    
}
  }