import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AccidentRequestService } from '@app/services/sugarmill/accident-request.service';
import { PreviewService } from '@appServices/preview.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, DistileryService, WorkflowcofigService, SugarmillService, DocumentUploadService } from '@app/services';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import jspdf from 'jspdf'
import html2canvas from 'html2canvas';
import { LocalUrl } from '@app/app-local-url';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-viewaccidentalrequest',
  templateUrl: './viewaccidentalrequest.component.html',
  styleUrls: ['./viewaccidentalrequest.component.scss']
})
export class ViewaccidentalrequestComponent implements OnInit {

  addedit: string;
  editId: any;
  tehsilValue: any;
  outsideupvalidation: string;
  uploaddata: any;
  constructor(private route: ActivatedRoute, private datepipe: DatePipe,
    private router: Router, private preview: PreviewService, private accidentRqstService: AccidentRequestService,
    private alert: AlertService,
    private formBuilder: FormBuilder,
    private brandlabelregService: SugarmillService,
    private worfFlowService: WorkflowcofigService,
    private documentUploadService: DocumentUploadService,
    private distilleryService: DistileryService,) { }

  /* params */
  viewId;
  accidentRqstObj: any = {
  };
  accidentType: any;
  isPreview = false;
  licenseDetailList: any[] = [];
  bottlingUnitAddress: any = {
    address: ''
  };
  licensenodata: any;
  actualLicensedetail: any;
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any;
  isCustomer: any;
  isPaymentEnable: boolean = true;
  alreadyPayment: boolean = true;
  isTransport: boolean = false;
  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.outsideupvalidation = currentUrlArray[2]

    const subUrlManage = currentUrlArray[1] + currentUrlArray[2];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowModule = LocalUrl[subUrlManage].workflowModule;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.isCustomer = (localStorage.getItem('DESIGNATION'));
    this.createFormGrp();
    this.getLicenseType();
    this.route.params.subscribe(params => {
      console.log(params, '..params..');
      if (params.Id) {
        this.editId = params.Id;
        this.setData(this.editId);
      } else {
        this.isPreview = true;
        this.accidentRqstObj = this.preview.setData;
      }
    });
    this.addedit = this.router.url.includes('preview') == true ? 'Preview' : 'View';
  }
  @ViewChild('exampleModalCenter1', { static: false }) exampleModalCenter1;

  imageboolean: boolean;
  imageDocName = ""
  imageSrcPath: any;
  async DownloadDoc(uuid, documentname) {
    let req = {
      "uuids": [
        uuid
      ]
    }
    this.imageSrcPath = '';
    this.imageboolean = true;
    const resp: any = await this.accidentRqstService.postDownload(req);
    this.imageSrcPath = resp[0].bytes;
    this.imageboolean = true;
    this.imageDocName = documentname
    var fileFormat = this.imageDocName.split('.');
    var condition = fileFormat.length - 1;
    if (fileFormat[condition] === 'pdf') {
      this.imageboolean = true;
      const byteArray = new Uint8Array(
        atob(resp[0].bytes)
          .split("")
          .map(char => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(file);
      document.querySelector('iframe').src = url + "#toolbar=0";
    }
    else {
      this.imageboolean = false;
    }
    this.exampleModalCenter1.show();
  }
  setData(id?: any) {
    if (id) {
      this.accidentRqstService.getAccidentRqstById(id).subscribe((resp: any) => {
        this.accidentRqstObj = resp.data;
        this.uploaddata = this.accidentRqstObj.accidentalRequestDocumentList? this.accidentRqstObj.accidentalRequestDocumentList : '';
        if( (this.accidentRqstObj.productAndVehicleBothDamagedDetails!= null && this.accidentRqstObj.productAndVehicleBothDamagedDetails.modeOfTransport=='Pipe Line')
        || (this.accidentRqstObj.vehicleDamagedButProductGoodDetails!= null && this.accidentRqstObj.vehicleDamagedButProductGoodDetails.modeOfTransport=='Pipe Line' )){
          this.isTransport = true;
        }else{
          this.isTransport = false;
        }
        this.applicationNumber = this.accidentRqstObj.applicationNumber
        if(this.accidentRqstObj.accidentalTypeTehsilValue == ''){
          this.tehsilValue = this.accidentRqstObj.accidentalTypeTehsilCode
        } else if(this.accidentRqstObj.accidentalTypeTehsilCode == ''){
          this.tehsilValue = this.accidentRqstObj.accidentalTypeTehsilValue
        }
        this.accidentRqstService.findpaymentdetails(this.applicationNumber, 'PROCESSING_FEE').subscribe((resp: any) => {
          if (resp.errorCode == 200) {
            if (resp.data.isFeePaid == true) {
              this.brandForm.get('FineImposted').clearValidators();
              this.brandForm.get('FineImposted').updateValueAndValidity();
              this.brandForm.get('fineType').clearValidators();
              this.brandForm.get('fineType').updateValueAndValidity();
              this.brandForm.get('fees').clearValidators();
              this.brandForm.get('fees').updateValueAndValidity();
              this.alreadyPayment = false;
            } else {
              this.alreadyPayment = true;
            }
          }
        })
        this.getapprovallogsList();
        this.getworkflow();
        this.getsigneddocument(this.accidentRqstObj.accidentalRequestDocumentList);
        if (this.accidentRqstObj.productAndVehicleBothDamagedDetails) {
          this.accidentType = "productAndVehicleBothDamagedDetails";
        } else if (this.accidentRqstObj.productDamagedButVehicleGoodDetails) {
          this.accidentType = "productDamagedButVehicleGoodDetails";
        } else if (this.accidentRqstObj.vehicleDamagedButProductGoodDetails) {
          this.accidentType = "vehicleDamagedButProductGoodDetails";
        }
      })
      if (this.isCustomer != 'DEO') {
        this.brandForm.get('FineImposted').clearValidators();
        this.brandForm.get('FineImposted').updateValueAndValidity();
        this.brandForm.get('fineType').clearValidators();
        this.brandForm.get('fineType').updateValueAndValidity();
        this.brandForm.get('fees').clearValidators();
        this.brandForm.get('fees').updateValueAndValidity();
      }
    }
  }
  logactive: boolean = false;
  recordstatus: any;
  sendBacklist: any;
  applicationNumber: any;
  isModified: boolean;
  formSubmitted: boolean;
  brandForm: FormGroup;
  confirmStyatus: string = "";
  logshow: any[] = []
  workflowdata: any;
  requestdate: any;
  submittedLog: any;
  @ViewChild('exampleModalCenter', { static: false }) exampleModalCenter;

  isApprovedLoading = false;
  isRejectLoading = false;
  isPaymentLoading = false;
  isClarificationLoading = false;

  getLicenseType() {
    this.brandlabelregService.getdetails().subscribe((respData: any) => {
      this.actualLicensedetail = respData.content;
      if (respData.content.length > 0) {
        this.licenseDetailList = respData.content.filter((item, ind) =>
          respData.content.findIndex(obj => obj.licenseSubCategory == item.licenseSubCategory) == ind);
        this.licensenodata = respData.content.filter((item, ind) =>
          respData.content.findIndex(obj => obj.licenseNumber == item.licenseNumber) == ind);
        this.getLicenseaddress(respData.content[0].licenseNumber);
      }
      else {
        this.licenseDetailList = respData.content;
        this.getLicenseaddress(respData.content.licenseNumber);
      }
    });
  }
  getLicenseaddress(licensenumber) {
    if (licensenumber) {
      this.bottlingUnitAddress = this.actualLicensedetail.find(ele => ele.licenseNumber === licensenumber);
      this.bottlingUnitAddress.address = (this.bottlingUnitAddress ? this.bottlingUnitAddress.street : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.tahsilDesc : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.districtDesc : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.stateDesc : '') + ',' +
        (this.bottlingUnitAddress ? this.bottlingUnitAddress.pinCode : '');
    }
  }

  getapprovallogsList() {
    this.worfFlowService.getapprovallogs(this.applicationNumber)
      .subscribe((resp: any) => {
        if (resp) {
          this.logactive = true;
          // if (this.recordstatus == 'SENDBACK' && resp.length > 0) {
          //   this.sendBacklist = resp.filter((x) => x.action == 'SENDBACK');
          //   this.logshow = this.sendBacklist;
          // } else {
          this.logshow = resp;
          // }
        }
      });
  }


  createFormGrp() {
    this.brandForm = this.formBuilder.group({
      remark: ['', [Validators.required, Validators.maxLength(100)]],
      FineImposted: ['', Validators.required],
      fees: ['', Validators.required],
      sendBackLevel: [''],
      fineType: ['', Validators.required]
    });
  }
  get brandform() {
    return this.brandForm.controls;
  }

  submitForm() {
    this.formSubmitted = true
    let payload = this.accidentRqstObj
    this.accidentRqstObj['wastagePercentage'] = this.brandForm.value.wastagePercentage
    this.accidentRqstObj['remarks'] = this.brandForm.value.remarks
    this.accidentRqstObj['remarksReply'] = this.brandForm.value.remarksReply
    this.accidentRqstObj['imposedFine'] = this.brandForm.value.imposedFine
    this.accidentRqstObj['penaltyAmount'] = this.brandForm.value.penaltyAmount
    this.accidentRqstObj['fee'] = this.brandForm.value.fee

    console.log("payload", payload);


    this.distilleryService.createmstIstMolasses(payload).subscribe((responceData: any) => {
      if (responceData['status'] === 's') {
        this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
        this.router.navigate(['/' + this.url + '/'+this.outsideupvalidation+'/list']);
      } else {
        this.alert.showError(responceData.userDisplayMesg);
      }
    });
  }

  closes() {
    this.router.navigate(['/' + this.url + '/'+this.outsideupvalidation+'/edit/' + this.editId]);
  }
  getworkflow() {
    console.log(this.workflowModule)
    this.worfFlowService.getworkflowbydesignation(this.workflowModule, this.workflowSubModule).subscribe((resp: any) => {
      this.workflowdata = resp.content;
      console.log(this.workflowdata);
      if (this.workflowdata) {
        if (this.workflowdata.isSendBack != undefined && this.workflowdata.isSendBack) {
          this.brandForm.controls['sendBackLevel'].setValidators(Validators.required);
        } else {
          this.brandForm.controls['sendBackLevel'].setErrors(null);
        }
      } else {
        this.brandForm.controls['sendBackLevel'].setErrors(null);
      }
    });
  }


  showConfirmation(status, form) {
    this.confirmStyatus = status;
    this.formSubmitted = false;

    if (status == 'SENDBACK') {
      this.brandForm.get('sendBackLevel').setValidators(Validators.required);
      this.brandForm.controls['sendBackLevel'].updateValueAndValidity();
    }

    else {
      this.brandForm.patchValue({
        sendBackLevel: ''
      })
      this.brandForm.get('sendBackLevel').setErrors(null);
      // this.brandForm.controls['sendBackLevel'].updateValueAndValidity();

    }
    console.log(form);
    if (form.valid === false) {
      this.formSubmitted = true;
    }
    else {
      this.toggleLoading(status)
      this.exampleModalCenter.show()
    }

  }
  close() {
    this.exampleModalCenter.hide()
    this.isApprovedLoading = false
    this.isRejectLoading = false;
    this.isPaymentLoading = false;
    this.isClarificationLoading = false
  }

  toggleLoading(saveType) {
    switch (saveType) {
      case 'APPROVED':
        this.isApprovedLoading = !this.isApprovedLoading;
        break;
      case 'REJECTED':
        console.log(!this.isRejectLoading)
        this.isRejectLoading = !this.isRejectLoading;
        break;
      case 'SENDBACK':
        console.log(!this.isClarificationLoading)
        this.isClarificationLoading = !this.isClarificationLoading;
        break;
      case 'ACCIDENTAL_REQUEST_FEE':
        console.log(!this.isPaymentLoading)
        this.isPaymentLoading = !this.isPaymentLoading;
        break;
    }
  }
  feedback(data) {
    console.log(this.brandForm.value.fees)
    const approveObj = {
      applicationNumber: this.accidentRqstObj.applicationNumber,
      id: parseInt(this.editId),
      isDigitalSignature: this.workflowdata ? this.workflowdata.isDigitalSignature : true,
      event: data,
      stage: this.accidentRqstObj.stage,
      stageDesc: this.accidentRqstObj.stageDesc,
      comments: this.brandForm.value.remark,
      status: data,
      // isProcessingFeePaid: false,
      sentBackLevels: this.brandForm.value.sendBackLevel ? this.brandForm.value.sendBackLevel : '',
      level: this.workflowdata ? this.workflowdata.level : 'Level 2',
      entityType: this.entityType,
      fees: this.brandForm.value.fees,
      "entitySubCategory":this.outsideupvalidation === "accidental-request-outsideup" ? "INDUSTRY_OUTSIDE_UP" : (this.outsideupvalidation === "accidental-request-insideup" ? "INDUSTRY_INSIDE_UP" : this.outsideupvalidation === "accidental-request-sugarmill-outsideup" ? "SUGARMILL_OUTSIDE_UP" : ""),
    };

    this.accidentRqstService.addAccidentRqst(approveObj).subscribe((responceData: any) => {
      if (responceData.errorCode == 201 || responceData.errorCode == 200) {
        this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
        this.router.navigate(['/' + this.url + '/'+this.outsideupvalidation+'/list']);
      } else {
        this.alert.showError(responceData.userDisplayMesg, 'error');
      }
    })
  }

  

  async ViewDoc(uuid, documentname) {
    let req = {
    "uuids": [
    uuid
    ]
    }
    this.imageSrcPath = '';
    this.imageboolean = true;
    const resp: any = await this.documentUploadService.ViewDocument(req);
    this.imageSrcPath = resp[0].bytes;
    this.imageboolean = true;
    this.imageDocName = documentname
    var fileFormat = this.imageDocName.split('.');
    var condition = fileFormat.length - 1;
    if (fileFormat[condition] === 'pdf') {
    this.imageboolean = true;
    const byteArray = new Uint8Array(
    atob(resp[0].bytes)
    .split("")
    .map(char => char.charCodeAt(0))
    );
    const file = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(file);
    document.querySelector('iframe').src = url + "#toolbar=0";
    }
    else {
    this.imageboolean = false;
    }
    this.exampleModalCenter1.show();
  }

  signeddocument:any=''
  getsigneddocument(value){ 
    if(value){
      value.forEach(element => {
        if(element.name=='DigitallySignedDoc'){
          this.signeddocument=element 
        } 
      }); 
    } 
  }

  downloadDocument(uuid, documentName) {

    if (uuid && documentName) {
      this.documentUploadService.downloadDocument(uuid, documentName).subscribe((resp: any) => {
        // const blob: any = new Blob([resp], { type: 'image/jpeg; charset=utf-8' });
        fileSaver.saveAs(resp, documentName);
      },
        (error: any) => this.alert.showError('Error downloading the file', 'Error'),
        () => console.info('File downloaded successfully')
      );
    } else {
      this.alert.showError('File not available', 'Error');
    }

  }

  generatePdf() {
    var doc: any = new jsPDF();
    //doc.setFontSize(12);
    doc.text('View Accidental Request', 10, 10);
    doc.addImage($(".centerqr img").attr("src"), 'png', 170, 0, 40, 40)
    //{name:'',value: ,name1:" ",value1: }, 
    doc.setFontSize(12);
    doc.text('Unit Details', 15, 35);
    let value = [
      // { name: 'Unit Details', value: "", name1: " ", value1: "" },
      { name: 'Financial Year', value: "", name1: "2022-2023 ", value1: "" },
      { name: 'License Type', value: "", name1: this.accidentRqstObj.licenseType, value1: "" },
      { name: 'License Number', value: "", name1: this.accidentRqstObj.licenseNumber, value1: "" },
      { name: 'Unit Name', value: "", name1: "SUGAR_MILL ", value1: "" },
      { name: 'Unit Address', value: " ", name1: "sdf,Ainavilli,Lucknow,Uttar Pradesh,545454 ", value1: "" },
    ];
    //{name:'',value: ,name1:" ",value1: },         
    let data = []
    let data1 = []
    let data2 = []
    let data3 = []
    let data4 = []
    var height = 0;
    value.forEach((element: any, i: number) => {
      data[i] = [];
      data[i].push(element.name ? element.name : '');
      data[i].push(element.value ? element.value : '');
      data[i].push({ content: ':', valign: 'middle' });
      data[i].push(element.name1 ? element.name1 : '');
      data[i].push(element.value1 ? element.value1 : '');
    });
    (doc as any).autoTable({
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      startY: 40,
      theme: "plain",
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 46 },
        2: { cellWidth: 5 },
        3: { cellWidth: 40 },
        4: { cellWidth: 46 },
      },
      didDrawPage: (d) => height = d.cursor.y,
    })
    doc.setFontSize(12);
    doc.text('Application Details', 15, doc.lastAutoTable.finalY + 5);
    let value1 = [
      //{ name: 'Application Details', value: "", name1: " ", value1: "" },
      { name: 'Request Date', value: moment(this.accidentRqstObj.requestDate).format('DD-MM-yyyy hh:mm a'), name1: "MF-4 Gate Pass No ", value1: this.accidentRqstObj.mf4GatePassNo },
      { name: 'Transport Pass Type', value: 'MF4', name1: " Application Date", value1: this.accidentRqstObj.applicationDate },
    ]
    value1.forEach((element: any, i: number) => {
      data1[i] = [];
      data1[i].push(element.name ? element.name : '');
      data1[i].push({ content: ':', valign: 'middle' });
      data1[i].push(element.value ? element.value : '');
      data1[i].push(element.name1 ? element.name1 : '');
      data1[i].push({ content: ':', valign: 'middle' });
      data1[i].push(element.value1 ? element.value1 : '');
    });
    (doc as any).autoTable({
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      theme: "plain",
      body: data1,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 5 },
        2: { cellWidth: 46 },
        3: { cellWidth: 40 },
        4: { cellWidth: 5 },
        5: { cellWidth: 46 },
      },
      didDrawPage: (d) => height = d.cursor.y,
    })
    doc.setFontSize(12);
    doc.text('Accidental Location', 15, doc.lastAutoTable.finalY + 5);
    let value2 = [
      //{ name: 'Accidental Location', value: "", name1: " ", value1: "" },
      { name: 'State', value: this.accidentRqstObj.stateValue, name1: "District ", value1: this.accidentRqstObj.districtValue },
      { name: 'Tehsil', value: this.accidentRqstObj.tehsilValue, name1: "Urban / Rural ", value1: this.accidentRqstObj.urbanOrRuralValue },
      { name: 'Municipal Area', value: this.accidentRqstObj.municipalAreaCode, name1: "Ward ", value1: this.accidentRqstObj.ward },
      { name: 'Police Station', value: this.accidentRqstObj.policeStation, name1: "Locality ", value1: this.accidentRqstObj.locality },
      { name: 'Street', value: this.accidentRqstObj.street, name1: "Pincode ", value1: this.accidentRqstObj.pinCode },
    ]
    value2.forEach((element: any, i: number) => {
      data2[i] = [];
      data2[i].push(element.name ? element.name : '');
      data2[i].push({ content: ':', valign: 'middle' });
      data2[i].push(element.value ? element.value : '');
      data2[i].push(element.name1 ? element.name1 : '');
      data2[i].push({ content: ':', valign: 'middle' });
      data2[i].push(element.value1 ? element.value1 : '');
    });
    (doc as any).autoTable({
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      theme: "plain",
      body: data2,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 5 },
        2: { cellWidth: 46 },
        3: { cellWidth: 40 },
        4: { cellWidth: 5 },
        5: { cellWidth: 46 },
      },
      didDrawPage: (d) => height = d.cursor.y,
    })
    doc.setFontSize(12);
    doc.text('Accidental Details', 15, doc.lastAutoTable.finalY + 5);
    let value3 = [
      //{ name: 'Accidental Details', value: '', name1: " ", value1: '' },
      { name: 'Accidental Type', value: 'Vehicle Damaged But product Good', name1: "Accident Date", value1: '  20-Apr-2021' },]

    value3.forEach((element: any, i: number) => {
      data3[i] = [];
      data3[i].push(element.name ? element.name : '');
      data3[i].push({ content: ':', valign: 'middle' });
      data3[i].push(element.value ? element.value : '');
      data3[i].push(element.name1 ? element.name1 : '');
      data3[i].push({ content: ':', valign: 'middle' });
      data3[i].push(element.value1 ? element.value1 : '');
    });
    (doc as any).autoTable({
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      theme: "plain",

      body: data3,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 5 },
        2: { cellWidth: 46 },
        3: { cellWidth: 40 },
        4: { cellWidth: 5 },
        5: { cellWidth: 46 },
      },
      didDrawPage: (d) => height = d.cursor.y,
    })

    let value4 = [];
    let heading = ''
    if (this.accidentType == 'vehicleDamagedButProductGoodDetails') {
      heading = 'Both Damaged'
      value4 = [

        {
          name: 'Do you want to change the vehicle details?', value: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.changeTheVehicleDetails
          , name1: "Transporter's Name ", value1: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.transporterName
        },
        {
          name: 'Vehicle No', value: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.vehicleNo
          , name1: " Vehicle Driver Name", value1: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.vehicleDriverName
        },
        {
          name: 'Route Type', value: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.routeType
          , name1: "Route Details ", value1: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.routeDetails
        },
        {
          name: 'Major Route', value: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.majorRoute
          , name1: " GPS ID", value1: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.gpsId
        },
        {
          name: 'Do you want to extend transport pass validity?', value: "Yes"
          , name1: "Remaining KMS ", value1: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.remainingKms
        },
        {
          name: 'Valid Upto', value: this.accidentRqstObj.vehicleDamagedButProductGoodDetails.extendTransportPassValidity
          , name1: " ", value1: ''
        },
      ]
    }
    if (this.accidentType == 'productDamagedButVehicleGoodDetails') {
      heading = 'Product Damaged but Vehicle good'
      value4 = [
        {
          name: 'Total Quantity of Molasses (Qtls)', value: this.accidentRqstObj.productDamagedButVehicleGoodDetails.totalQuantityOfMolasses,
          name1: "Damaged Quantity of Molasses (Qtls) ", value1: this.accidentRqstObj.productDamagedButVehicleGoodDetails.damagedQuantityOfMolasses
        }]
    }
    if (this.accidentType == 'productAndVehicleBothDamagedDetails') {
      heading = 'Both Damaged'
      value4 = [
        //{ name: 'Both Damaged', value: '', name1: " ", value1: '' },
        { name: 'Transporter\'s Name', value: this.accidentRqstObj.productAndVehicleBothDamagedDetails.transporterName, name1: "Vehicle No ", value1: this.accidentRqstObj.productAndVehicleBothDamagedDetails.vehicleNo },
        { name: 'Vehicle Driver Name', value: this.accidentRqstObj.productAndVehicleBothDamagedDetails.vehicleDriverName, name1: "Route Details ", value1: this.accidentRqstObj.productAndVehicleBothDamagedDetails.routeDetails },
        { name: 'GPS ID', value: this.accidentRqstObj.productAndVehicleBothDamagedDetails.gpsId, name1: "Do you want to send back? ", value1: this.accidentRqstObj.productAndVehicleBothDamagedDetails.wantToSendBack },
      ]

    }
    doc.setFontSize(12);
    doc.text(heading, 15, doc.lastAutoTable.finalY + 5);
    value4.forEach((element: any, i: number) => {
      data4[i] = [];
      data4[i].push({ content: element.name ? element.name : '', });
      data4[i].push({ content: ':', valign: 'middle' });
      data4[i].push(element.value ? element.value : '');
      data4[i].push(element.name1 ? element.name1 : '');
      data4[i].push({ content: ':', valign: 'middle' });
      data4[i].push(element.value1 ? element.value1 : '');
    });
    (doc as any).autoTable({
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      theme: "plain",
      body: data4,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 5 },
        2: { cellWidth: 46 },
        3: { cellWidth: 40 },
        4: { cellWidth: 5 },
        5: { cellWidth: 46 },
      },
      didDrawPage: (d) => height = d.cursor.y,
    })
    // (doc as any).autoTable( ({
    //   columnStyles: { europe: { halign: 'center' } }, // European countries centered
    //   body: [
    //     { europe: 'Sweden', america: 'Canada', asia: 'China' },
    //     { europe: 'Norway', america: 'Mexico', asia: 'Japan' },
    //   ],
    //   columns: [
    //     { header: 'Europe', dataKey: 'europe' },
    //     { header: 'Asia', dataKey: 'asia' },
    //   ],
    // }))
    doc.save('AccidentalRequest.pdf');
  }

  // public captureScreen() {
  //   var data = document.getElementById('contentToConvert');  //Id of the table
  //   html2canvas(data).then(canvas => {
  //     // Few necessary setting options  
  //     let imgWidth = 208;
  //     let pageHeight = 295;
  //     // let imgHeight = canvas.height * imgWidth / canvas.width;
  //     let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
  //     var imgHeight = pdf.internal.pageSize.getHeight();
  //     let heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     pdf.save('AccidentalRequest.pdf'); // Generated PDF   
  //   });
  // }

  public captureScreen() {
    let payload = {
      applicationNumber: this.accidentRqstObj.applicationNumber,
      id: this.accidentRqstObj.id,
      receiptType: 'ACCIDENT_REQUEST'
    }
    this.accidentRqstService.downloadaccident(payload).subscribe(
      (resp: any) => fileSaver.saveAs(resp, this.accidentRqstObj.applicationNumber.replace(/\//g, '_') + '_ACCIDENT_REQUEST'),
      (error: any) => this.alert.showError('Error downloading the file'),
      () => console.info('File downloaded successfully')
    );
  }
  getFineAmount(event) {
    if (event == 'Yes') {
      this.brandForm.get('fineType').setValidators(Validators.required);
      this.brandForm.get('fineType').updateValueAndValidity();
      this.brandForm.get('fees').setValidators(Validators.required);
      this.brandForm.get('fees').updateValueAndValidity();
      this.isPaymentEnable = true;
    } else if (event == 'No') {
      this.brandForm.get('fineType').clearValidators();
      this.brandForm.get('fineType').updateValueAndValidity();
      this.brandForm.get('fees').clearValidators();
      this.brandForm.get('fees').updateValueAndValidity();
      this.isPaymentEnable = false;
    }
  }
  downloadRecipt() {
    if (this.accidentRqstObj.applicationNumber && this.accidentRqstObj.licenseNumber) {
      this.accidentRqstService.downloadPayment(this.accidentRqstObj.applicationNumber, this.accidentRqstObj.licenseNumber, 'PROCESSING_FEE').subscribe(
        (resp: any) => fileSaver.saveAs(resp, this.accidentRqstObj.applicationNumber.replace(/\//g, '_') + '_ACCIDENTAL_REQUEST'),
        (error: any) => this.alert.showError('Error downloading the file'),
        () => console.info('File downloaded successfully')
      );
    } else {
      this.alert.showError('File not available');
    }
  }
  getFineLimit(event) {
    const penaltyFeeControl = this.brandForm.get('fees');
    if (event == 'Yes') {
      this.formSubmitted = true;
      penaltyFeeControl.setValidators([Validators.required, Validators.min(1), Validators.max(50000)]);
    } else if (event == 'No') {
      this.formSubmitted = true;
      penaltyFeeControl.setValidators([Validators.required, Validators.min(1), Validators.max(100000)]);
    }
    penaltyFeeControl.updateValueAndValidity();
  }
}
