
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { data } from 'jquery';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BottlingProdService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;
  qaurl = environment.wholesaleNewURL;
  get1(data): Observable<any> {
    const url = this.qaurl + 'customdata/getdata';
    return this.http.post(url, data);
  }

  search(payload): Observable<any> {
    const url = this.baseURL + apiUrls.bottlingsearch;
    return this.http.post(url, payload);
  }

  get(): Observable<any> {
    const url = this.baseURL + apiUrls.getallbottlingprod;
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + apiUrls.bottlinggetbyid + id;
    return this.http.get(url);
  }
  getByIds(id,entity): Observable<any> {
    const url = this.baseURL + "bottlingProductionPlan/sub/getDetailByBottlingPlanId?bottlingPlanId="+id+"&entityType="+entity
    return this.http.get(url);
  }

  getById1(id): any {
    const url = this.baseURL + "bottlingProductionPlan/sub/bottlingPlan/"+id;
        return this.http.get(url);
  }
  addBottling(data): Observable<any> {
    const url = this.baseURL + apiUrls.addbottlingdata;
    return this.http.post(url, data);
  }
  addBottling1(data): Observable<any> {
    const url = this.baseURL + "bottlingProductionPlan/sub/bottlingPlan";
    return this.http.post(url, data);
  }
  workflowdata(data): Observable<any> {
    const url = this.baseURL + apiUrls.bottlingworkflow;
    return this.http.post(url, data);
  }
  getBrandDetailsByEtin(etin): Observable<any> {
    const url = this.baseURL + apiUrls.getBrandDetailsByEtin + "/" + etin;
    return this.http.get(url);
  }
  CancelRequest(id): Observable<any> {
    const url = this.baseURL + apiUrls.bottlinggetbyid + id + '/cancel'
    return this.http.put(url, null);
  }
  modifyTankDetails(data): Observable<any> {
    const url = this.baseURL + apiUrls.modifyProductionPlan
    return this.http.put(url, data);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadBottlingPlanReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=MAPPED';
    return this.http.get(url, { responseType: 'blob' });
  }
  getAppConfigValue(postParam): any {
    const url = this.qaurl + 'appconfig/getappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }
  downloadenavalue(payload) {
     const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
     return this.http.get(url, { responseType: 'blob', params: payload });
   }
   getbottlingdetailstrackapi(postParam){
    const url = this.qaurl + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
   }
   cancellationbottlingplan(id){ 
    const url = this.baseURL + 'bottlingProductionPlan/'+id+'/cancel'
    return this.http.put(url, id).toPromise();
   }

   getbottlingpaymentdetails(payload){ 
    const url = this.baseURL + 'bottlingProductionPlan/refund/productionDeclaration'
    return this.http.post(url, payload).toPromise();
   }
};