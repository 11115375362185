import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DenatureprocessService {

  entityType: any
  constructor(
    private http: HttpClient
  ) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl
  masterurl = environment.baseURLMasterIpAddr
  domainUrl = environment.domainUrl;

  addDetails(data): Observable<any> {
    const url = this.baseURL + 'denaturationProcess';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'denaturationProcess/lazySearch';
    return this.http.post(url, payload);
  }


  getById(id): Observable<any> {
    const url = this.baseURL + 'denaturationProcess/' + id;
    return this.http.get(url);
  }
  getDropdownValues(params): Observable<any> {
    const url = this.devisionUrl + apiUrls.findDropDownMasterOnMaster
    return this.http.get(url, { params: params });
  }
  getSourceTanks(data: any): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }

  getRawMaterial2(key, addi?, add2?): Observable<any> {

    let url = this.masterurl + apiUrls.getDropDownBykey + '?dropDownKey=' + key;
    url += (addi) ? '&additionalFilter2=' + addi : '';
    if (add2) {
      url += (add2) ? '&additionalFilter1=' + add2 : '';
    }
    return this.http.get(url);

  }
  getApplicationNo(data: any): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, data);
  }

  // payment related api's
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'denaturationProcess/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'denaturationProcess/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findAllPaymentDetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'denaturationProcess/findAllPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }

  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=DENATURATION_PROCESS';
    return this.http.get(url, { responseType: 'blob' });
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  downloadenavalue(payload) {
     const url = this.baseURL + 'reportReceipt/downloadReceipt'
     return this.http.get(url, { responseType: 'blob', params: payload });
   }
   getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
}
