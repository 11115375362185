<ng-container *ngIf="frmSub && control?.errors || control?.dirty">
    <div class="error-msg">
        <span *ngIf="control.errors?.required">
            <i class="fa fa-times"></i> {{eleName}} {{'master.common.isrequired' | translate}}
        </span>
        <span *ngIf="control.errors?.email">
            <i class="fa fa-times"></i> {{eleName}} must be a valid email address
        </span>
        <span *ngIf="control.errors?.pattern">
            <i class="fa fa-times"></i> Please enter valid {{eleName}}
        </span>
        <span *ngIf="control.errors?.minlength">
            <i class="fa fa-times"></i>{{eleName}} should have Minimum {{control.errors?.minlength.requiredLength}}
            characters
        </span>
        <span *ngIf="control.errors?.maxlength">
            <i class="fa fa-times"></i>{{eleName}} should have Maximum {{control.errors?.maxlength.requiredLength}}
            characters
        </span>
        <span *ngIf="control.errors?.max">
            <i class="fa fa-times"></i> {{eleName}} should have Maximum {{control.errors?.max.max}}
        </span>
        <span *ngIf="!control.errors?.pattern && control.errors?.latitudeRangeError">
            <i class="fa fa-times"></i> {{eleName}} should fall between {{control.errors?.latitudeRangeError.min}} and {{control.errors?.latitudeRangeError.max}}
        </span>
        <span *ngIf="!control.errors?.pattern && control.errors?.longitudeRangeError">
            <i class="fa fa-times"></i> {{eleName}} should have Maximum {{control.errors?.longitudeRangeError.min}} and {{control.errors?.longitudeRangeError.max}}
        </span>
        <span *ngIf="control.errors?.min">
            <i class="fa fa-times"></i>{{eleName}} should have more than {{control.errors?.min.min}}
        </span>

        <span *ngIf="control.errors?.whitespace">
            <i class="fa fa-times"></i>Please remove whitespace from {{eleName}} 
        </span>

        <span *ngIf="control.errors?.owlDateTimeMin">
            <i class="fa fa-times"></i>Please enter valid {{eleName}} 
        </span>
    </div>
</ng-container>
