<!-- <div class="row">
    <div class="col-xl-12">
        <h5>Welcome, <span>{{username}}</span></h5>
    </div>
</div> -->
<!-- 
<div class="container">
    <div class="row vertical-center">
        <form class="col-md-12">
            <h1 class="text-center welcome_user">Welcome <span>{{username}}</span></h1>
        </form>
    </div>
</div> -->

<!-- <div class="row" *ngIf="designation!=commissioner && designation!=additionalcommissionerld && designation!=additionalcommissioner 
  && designation!=deptcomisioner && designation!=decproduction && designation!=decdistribution && designation!=decLicensing && !showMrp"> -->
<div class="row" *ngIf="isCustomer||!showdashboard">
  <div class="col-xl-6 col-lg-6 col-12 align-self-center">
    <!-- class="welcome-text" -->
    <div>

      <h1>Welcome, <span>{{username}}</span></h1>

      <h2 *ngIf="isOfficier">Home Page</h2>
    </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-12">
    <img src="../../../assets/images/welcomeimage.png" alt="welcome" class="max-width-100 wd-100" />
  </div>
</div>
<!-- <div *ngIf="designation==commissioner || designation==additionalcommissionerld || designation==additionalcommissioner ||
   designation==deptcomisioner|| designation==decproduction|| designation==decdistribution"> -->
<div *ngIf="showdashboard">
  <div class="spiritimport overalllist" style="min-height: 500px;" *ngIf="(licenseCount+totalcardcountscm+edpTotalCount+ConvertToInt(totalCount)+ConvertToInt(totalCount1))==0">
    <div class="recordnotofound">
      <div class="purplecolorsfont font20 text-center">
        No Pending Applications
      </div> 
    </div> 
      </div>  
  <div class="spiritimport overalllist" style="min-height: 500px;" *ngIf="(licenseCount+totalcardcountscm+edpTotalCount+ConvertToInt(totalCount)+ConvertToInt(totalCount1))>0"  >
    <mat-accordion class="welcomemat"> 
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 0" (closed)='removesetitem()' (opened)="setStep(0)"  class="padding_panel_remove_custom"
         *ngIf="licenseCount>0">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            License Management
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records
              <i class="ion-plus-round">{{ licenseCount }}</i>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>


<div class="borderyellow">
        <div class="allotmentofmolasseddiv"  *ngIf="licenseDataCategoryNew ">
          <h3 class="innerheading_value">{{'licenseMgnt.newLicensesRegistrations' | translate}}
            <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
                {{licenseDataResp?.totalCountOtherThanRenew}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of licenseDataCategoryNew;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6   animate__animated animate__backInUp "
                [ngClass]="getLicenseColor(i%4)" *ngIf="brand.value">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="onLicenseClick(brand,'NEW')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{brand.value}}</span>
                      <h3 class="mt-2 strong">{{brand?.id}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <!-- <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg4 animate__animated animate__backInUp">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass">
                <div class="row align-items-center">
                  <div class="col">
                    <span>Total</span>
                    <h3 class="mt-2 strong">{{licenseDataResp?.totalCountOtherThanRenew}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div> -->
          </div>

        </div>

        <div class="row">
          <ng-container *ngFor="let item of licenseData">
            <div class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp" [ngClass]="item.color">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="onLicenseClick(item,'NEW')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{ item.value }}</span>
                    <h3 class="mt-2 strong">{{ item.id }}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>
        </div>
      </div>
        <div class="allotmentofmolasseddiv borderyellow" *ngIf="licenseDataCategoryRenew ">
          <h3 class="innerheading_value">{{'licenseMgnt.renewLicensesRegistrations' | translate}}
            <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
                {{licenseDataResp?.totalCountRenew}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of licenseDataCategoryRenew;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                [ngClass]="getLicenseColor(i%4)" *ngIf="brand.value">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="onLicenseClick(brand,'RENEW')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{brand.value}}</span>
                      <h3 class="mt-2 strong">{{brand?.id}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>

        </div>

        <div class="row">
          <ng-container *ngFor="let item of licenseData">
            <div class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp" [ngClass]="item.color">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="onLicenseClick(item,'RENEW')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{ item.value }}</span>
                    <h3 class="mt-2 strong">{{ item.id }}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="initialLocalStorageValue == 1" (closed)='removesetitem()' (opened)="setStep(1)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails&&((allcarddetails.SIP_ORDER_PROCESSING&&calculatelength(allcarddetails.SIP_ORDER_PROCESSING))||(allcarddetails.SPIRIT_IMPORT_PERMIT&&calculatelength(allcarddetails.SPIRIT_IMPORT_PERMIT)) ||(allcarddetails.SPIRIT_EXPORT_PERMIT&&calculatelength(allcarddetails.SPIRIT_EXPORT_PERMIT)) )">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="font20 purplecolorsfont">
            Spirit Process Approval
          </mat-panel-title>
          <mat-panel-description>


            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.SIP_ORDER_PROCESSING)+totalcalculate(allcarddetails.SPIRIT_IMPORT_PERMIT)+totalcalculate(allcarddetails.SPIRIT_EXPORT_PERMIT)}}</i></button>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow" *ngIf=" allcarddetails.SPIRIT_IMPORT_PERMIT&&calculatelength(allcarddetails.SPIRIT_IMPORT_PERMIT) ">
          <h3 class="innerheading_value">Import Order
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.SPIRIT_IMPORT_PERMIT)}}</i></button>
          </h3>

          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.SPIRIT_IMPORT_PERMIT;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'SPIRIT_IMPORT_PERMIT','spiritimportpermit')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf=" allcarddetails.SIP_ORDER_PROCESSING&&calculatelength(allcarddetails.SIP_ORDER_PROCESSING) ">
          <h3 class="innerheading_value">Spirit Import Permit
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.SIP_ORDER_PROCESSING)}}</i></button>
          </h3>

          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.SIP_ORDER_PROCESSING;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'SIP_ORDER_PROCESSING','import-permit')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf=" allcarddetails.SPIRIT_EXPORT_PERMIT&&calculatelength(allcarddetails.SPIRIT_EXPORT_PERMIT) ">
          <h3 class="innerheading_value">Export Permit <button class="snip1258 purplecolors">Total<i
                class="ion-plus-round">
                {{totalcalculate(allcarddetails.SPIRIT_EXPORT_PERMIT)}}</i></button> </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.SPIRIT_EXPORT_PERMIT;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'SPIRIT_EXPORT_PERMIT','exportpermit-for-spirit')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>

      </mat-expansion-panel> 
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 2" (closed)='removesetitem()' (opened)="setStep(2)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails &&
            (allcarddetails.ALLOTMENT_OF_MOLASSES||allcarddetails.REQUEST_FOR_MOLASSES_PERMIT ||allcarddetails.EXPORT_OF_MOLASSES||allcarddetails.FORECLOSURE_OF_ALLOTMENT
       || allcarddetails.IMPORT_OF_MOLASSES||allcarddetails.INDENT_FORECLOSURE|| allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP||allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP
       ||allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION||allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION||allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION
       ||allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION||allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION||allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION|| allcarddetails.ENA_MOLASSES_ADJUSTMENT)&&
        (calculatelength(allcarddetails.ALLOTMENT_OF_MOLASSES) ||  calculatelength(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT) ||calculatelength(allcarddetails.EXPORT_OF_MOLASSES) || 
         calculatelength(allcarddetails.FORECLOSURE_OF_ALLOTMENT)||calculatelength(allcarddetails.IMPORT_OF_MOLASSES) ||  calculatelength(allcarddetails.INDENT_FORECLOSURE)
        ||calculatelength(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP) ||  calculatelength(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP)||
        calculatelength(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION) ||  calculatelength(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION)||  calculatelength(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION)||
        calculatelength(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION) ||  calculatelength(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION)|| calculatelength(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION)
        || calculatelength(allcarddetails.ENA_MOLASSES_ADJUSTMENT))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Molasses Procurement Process
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.ALLOTMENT_OF_MOLASSES)
                  +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT,'PD')+ totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT,'ID')+totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT,'MIXED') +
                totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP,'SUGAR_MILL')+totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP,'SUGAR_MILL')+
                +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION,'PD')+ totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION,'ID')+totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION,'MIXED') +
                totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION,'SUGAR_MILL')+totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION,'SUGAR_MILL')+
                +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION,'PD')+ totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION,'ID')+totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION,'MIXED') +
                totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION,'SUGAR_MILL')+totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION,'SUGAR_MILL')+
                totalcalculate(allcarddetails.EXPORT_OF_MOLASSES)+totalcalculate(allcarddetails.FORECLOSURE_OF_ALLOTMENT)+
                totalcalculate(allcarddetails.IMPORT_OF_MOLASSES)+totalcalculate(allcarddetails.INDENT_FORECLOSURE)+totalcalculate(allcarddetails.ENA_MOLASSES_ADJUSTMENT)
                }}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="allotmentofmolasseddiv borderyellow"
          *ngIf="allcarddetails.ALLOTMENT_OF_MOLASSES&&calculatelength(allcarddetails.ALLOTMENT_OF_MOLASSES) ">
          <h3 class="innerheading_value">Allotment of Molasses
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.ALLOTMENT_OF_MOLASSES)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.ALLOTMENT_OF_MOLASSES;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'ALLOTMENT_OF_MOLASSES','stock/allotmentrequest')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>

        </div>
        <div class="requestofmolassesdiv borderyellow"
          *ngIf=" (allcarddetails.REQUEST_FOR_MOLASSES_PERMIT ||allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP ||allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP )
          &&(calculatelength(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT)||calculatelengthentitytype(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP,'SUGAR_MILL')||calculatelengthentitytype(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP,'SUGAR_MILL')) ">
          <h3 class="innerheading_value">Request for Molasses Permit
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT,'PD')
                +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT,'ID')
                +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT,'MIXED')
                +totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP,'SUGAR_MILL')+totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP,'SUGAR_MILL')}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.REQUEST_FOR_MOLASSES_PERMIT;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS' && brand.entityType!='SUGAR_MILL'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="brand.entityType =='SUGAR_MILL' ? getList(brand.entityType,brand.status,'REQUEST_FOR_MOLASSES_PERMIT','indent-request-outsideup')
                  : getList(brand.entityType,brand.status,'REQUEST_FOR_MOLASSES_PERMIT','stock/indentrequest')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <ng-container *ngFor="let brand of allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'INDENT_REQUEST_INDUSTRY_INSIDE_UP','indent-request-industry-insideup')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span style="text-transform: uppercase;">Industry (Inside UP)</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <ng-container *ngFor="let brand of allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'INDENT_REQUEST_INDUSTRY_OUTSIDE_UP','indent-request-outsideup')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span style="text-transform: uppercase;">Industry (Outside UP)</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>


          </div>

        </div>
        <div class="cancellationindentrequest borderyellow"
  *ngIf=" (allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION ||allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION ||allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION )
          &&(calculatelength(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION)||calculatelengthentitytype(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION,'SUGAR_MILL')||calculatelengthentitytype(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION,'SUGAR_MILL')) ">
  <h3 class="innerheading_value">Request for Molasses Permit Validity Extension
    <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
        {{totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION,'PD')
        +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION,'ID')
        +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION,'MIXED')
        +totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION,'SUGAR_MILL')+totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION,'SUGAR_MILL')}}</i></button>
  </h3>
  <div class="row">
    <ng-container *ngFor="let brand of allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION;let i=index">
      <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
        *ngIf="brand.status=='IN_PROGRESS' && brand.entityType!='SUGAR_MILL'">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
          (click)="getList(brand.entityType,brand.status,'REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_EXTENSION','stock/indentrequestvalidityextension')">
          <div class="row align-items-center">
            <div class="col">
              <span>{{convertToSentenceCase(brand?.entityType)}}</span>
              <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
              <i class="fas fa-hourglass-half"></i>
            </div>
          </div>
        </app-card>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let brand of allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION;let i=index">
      <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
        *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
          (click)="getList(brand.entityType,brand.status,'INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_EXTENSION','industry-insideup-Validity-Extension')">
          <div class="row align-items-center">
            <div class="col">
              <span style="text-transform: uppercase;">Industry (Inside UP)</span>
              <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
              <i class="fas fa-hourglass-half"></i>
            </div>
          </div>
        </app-card>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let brand of allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION;let i=index">
      <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
        *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
          (click)="getList(brand.entityType,brand.status,'INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_EXTENSION','industry-outsideup-validity-Extension')">
          <div class="row align-items-center">
            <div class="col">
              <span style="text-transform: uppercase;">Industry (Outside UP)</span>
              <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
              <i class="fas fa-hourglass-half"></i>
            </div>
          </div>
        </app-card>
      </div>
    </ng-container>


  </div>

</div>
<div class="requestofmolassesdiv borderyellow"
  *ngIf=" (allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION ||allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION ||allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION )
          &&(calculatelength(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION)||calculatelengthentitytype(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION,'SUGAR_MILL')||calculatelengthentitytype(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION,'SUGAR_MILL')) ">
  <h3 class="innerheading_value">Request for Molasses Permit Cancellation
    <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
        {{totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION,'PD')
        +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION,'ID')
        +totalcalculateentity(allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION,'MIXED')
        +totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION,'SUGAR_MILL')+totalcalculateentity(allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION,'SUGAR_MILL')}}</i></button>
  </h3>
  <div class="row">
    <ng-container *ngFor="let brand of allcarddetails.REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION;let i=index">
      <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
        *ngIf="brand.status=='IN_PROGRESS' && brand.entityType!='SUGAR_MILL'">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
          (click)=" getList(brand.entityType,brand.status,'REQUEST_FOR_MOLASSES_PERMIT_VALIDITY_CANCELLATION','stock/indentrequestcancellation')">
          <div class="row align-items-center">
            <div class="col">
              <span>{{convertToSentenceCase(brand?.entityType)}}</span>
              <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
              <i class="fas fa-hourglass-half"></i>
            </div>
          </div>
        </app-card>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let brand of allcarddetails.INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION;let i=index">
      <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
        *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
          (click)="getList(brand.entityType,brand.status,'INDENT_REQUEST_INDUSTRY_INSIDE_UP__VALIDITY_CANCELLATION','industry-insideup-Cancellation')">
          <div class="row align-items-center">
            <div class="col">
              <span style="text-transform: uppercase;">Industry (Inside UP)</span>
              <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
              <i class="fas fa-hourglass-half"></i>
            </div>
          </div>
        </app-card>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let brand of allcarddetails.INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION;let i=index">
      <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
        *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
          (click)="getList(brand.entityType,brand.status,'INDENT_REQUEST_INDUSTRY_OUTSIDE_UP_VALIDITY_CANCELLATION','industry-outsideup-Cancellation')">
          <div class="row align-items-center">
            <div class="col">
              <span style="text-transform: uppercase;">Industry (Outside UP)</span>
              <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
              <i class="fas fa-hourglass-half"></i>
            </div>
          </div>
        </app-card>
      </div>
    </ng-container>


  </div>

</div>
        <div class="borderyellow" *ngIf="allcarddetails.EXPORT_OF_MOLASSES && calculatelength(allcarddetails.EXPORT_OF_MOLASSES)">
          <h3 class="innerheading_value">Export of Molasses
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.EXPORT_OF_MOLASSES)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.EXPORT_OF_MOLASSES;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="brand.entityType =='SUGAR_MILL'?getList(brand.entityType,brand.status,'EXPORT_OF_MOLASSES','applicationforexportofmolassesindustryoutsideup'):getList(brand.entityType,brand.status,'EXPORT_OF_MOLASSES','stock/applicationforexportofmolasses')">
                 <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails.EXPORT_OF_MOLASSES_ACCEPTANCE && calculatelength(allcarddetails.EXPORT_OF_MOLASSES_ACCEPTANCE)">
          <h3 class="innerheading_value">Export Of Molasses Acceptance
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.EXPORT_OF_MOLASSES_ACCEPTANCE)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.EXPORT_OF_MOLASSES_ACCEPTANCE;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="brand.entityType =='SUGAR_MILL'?getList(brand.entityType,brand.status,'EXPORT_OF_MOLASSES_ACCEPTANCE','molasses-export-permit-acceptance'):
                  getList(brand.entityType,brand.status,'EXPORT_OF_MOLASSES_ACCEPTANCE','stock/molasses-export-permit-acceptance')">
                 <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow"
          *ngIf="allcarddetails.FORECLOSURE_OF_ALLOTMENT && calculatelength(allcarddetails.FORECLOSURE_OF_ALLOTMENT)">
          <h3 class="innerheading_value">Foreclosure of Allotment
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.FORECLOSURE_OF_ALLOTMENT)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.FORECLOSURE_OF_ALLOTMENT;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'FORECLOSURE_OF_ALLOTMENT','stock/allotmentforeclosure')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails.IMPORT_OF_MOLASSES && calculatelength(allcarddetails.IMPORT_OF_MOLASSES)">
          <h3 class="innerheading_value">Import of Molasses
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.IMPORT_OF_MOLASSES)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.IMPORT_OF_MOLASSES;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'IMPORT_OF_MOLASSES','stock/applicationforimportofmolasses')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails.INDENT_FORECLOSURE && calculatelength(allcarddetails.INDENT_FORECLOSURE)">
          <h3 class="innerheading_value">Permit Foreclosure
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.INDENT_FORECLOSURE)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.INDENT_FORECLOSURE;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'INDENT_FORECLOSURE','stock/indentforclosure')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="requestofmolassesdiv borderyellow"
        *ngIf=" (allcarddetails.ENA_MOLASSES_ADJUSTMENT   )
        &&(calculatelength(allcarddetails.ENA_MOLASSES_ADJUSTMENT)) ">
        <h3 class="innerheading_value">ENA Molasses Adjustment 
          <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
              {{totalcalculate(allcarddetails.ENA_MOLASSES_ADJUSTMENT) }}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of allcarddetails.ENA_MOLASSES_ADJUSTMENT;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS' && brand.entityType!='SUGAR_MILL'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)=" getList(brand.entityType,brand.status,'ENA_MOLASSES_ADJUSTMENT','stock/enamolassesadjustment')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container> 
        </div>
      </div>

      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="initialLocalStorageValue == 3" (closed)='removesetitem()' (opened)="setStep(3)" class="padding_panel_remove_custom"
        
        *ngIf="(designation==commissioner || designation==additionalcommissionerld ||designation==decdistribution) && (ConvertToInt(totalCount)+ConvertToInt(totalCount1))>0">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Overseas Export Dispatch Process (Finished Goods)
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{ConvertToInt(totalCount)+ConvertToInt(totalCount1)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"> 
        <h3 class="innerheading_value"> Overseas Export Dispatch Process (Finished Goods)
          <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
              {{ConvertToInt(totalCount)+ConvertToInt(totalCount1)}}</i></button>
        </h3>
        <div class="row">
          <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg3 dashboard_bg100 animate__animated animate__backInUp"
            *ngIf="totalCount>0">
            <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="getListPage('BOTTELING')">
              <div class="row align-items-center">
                <div class="col">
                  <span>Beer Export Dispatch</span>
                  <h3 class="mt-2 strong">{{totalCount}}</h3>
                  <i class="fas fa-hourglass-half"></i>
                </div>
              </div>
            </app-card>
          </div>
          <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg3 animate__animated animate__backInUp"
            *ngIf="totalCount1>0">
            <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="getListPage('DISTILLERY')">
              <div class="row align-items-center">
                <div class="col">
                  <span>IMFL Export Dispatch</span>
                  <h3 class="mt-2 strong">{{totalCount1}}</h3>
                  <i class="fas fa-hourglass-half"></i>
                </div>
              </div>
            </app-card>
          </div>

        </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 4" (closed)='removesetitem()' (opened)="setStep(4)" class="padding_panel_remove_custom"
        
        *ngIf=" (allcarddetails.ACCIDENTAL_REQUEST ||allcarddetails.ACCIDENTAL_REQUEST_OUTSIDE_UP ||allcarddetails.ACCIDENTAL_REQUEST_INSIDE_UP )
        &&(calculatelength(allcarddetails.ACCIDENTAL_REQUEST)||calculatelengthentitytype(allcarddetails.ACCIDENTAL_REQUEST_OUTSIDE_UP,'SUGAR_MILL')||calculatelengthentitytype(allcarddetails.ACCIDENTAL_REQUEST_INSIDE_UP,'SUGAR_MILL')) ">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Accidental Request
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round"> {{totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST,'PD')
                +totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST,'ID')
                +totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST,'MIXED')
                +totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST_OUTSIDE_UP,'SUGAR_MILL')+totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST_INSIDE_UP,'SUGAR_MILL')}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"> 
        <h3 class="innerheading_value">Accidental Request
          <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
            {{totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST,'PD')
            +totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST,'ID')
            +totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST,'MIXED')
            +totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST_OUTSIDE_UP,'SUGAR_MILL')+totalcalculateentity(allcarddetails.ACCIDENTAL_REQUEST_INSIDE_UP,'SUGAR_MILL')}}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of allcarddetails.ACCIDENTAL_REQUEST;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS' && brand.entityType!='SUGAR_MILL'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="getList(brand.entityType,brand.status,'ACCIDENTAL_REQUEST','stock/accidentrequest')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>
          <ng-container *ngFor="let brand of allcarddetails.ACCIDENTAL_REQUEST_INSIDE_UP;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                (click)="getList(brand.entityType,brand.status,'ACCIDENTAL_REQUEST_INSIDE_UP','accidental-request-insideup')">
                <div class="row align-items-center">
                  <div class="col">
                    <span style="text-transform: uppercase;">Industry (Inside UP)</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>
          <ng-container *ngFor="let brand of allcarddetails.ACCIDENTAL_REQUEST_OUTSIDE_UP;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS' && brand.entityType=='SUGAR_MILL'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                (click)="getList(brand.entityType,brand.status,'ACCIDENTAL_REQUEST_OUTSIDE_UP','accidental-request-outsideup')">
                <div class="row align-items-center">
                  <div class="col">
                    <span style="text-transform: uppercase;">Industry (Outside UP)</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container> 
        </div>
        </div>


      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 20" (closed)='removesetitem()' (opened)="setStep(20)" class="padding_panel_remove_custom"
        
*ngIf="allcarddetails && allcarddetails.GENERATE_MF4_ACCIDENTAL&&calculatelength(allcarddetails.GENERATE_MF4_ACCIDENTAL)">
<mat-expansion-panel-header class="mat_exapansion_headers">
  <mat-panel-title class="purplecolorsfont font20">
    Generate MF4 Accidental
  </mat-panel-title>
  <mat-panel-description>
    <button class="snip1258 purplecolors">Pending Records<i
        class="ion-plus-round">{{totalcalculate(allcarddetails.GENERATE_MF4_ACCIDENTAL)}}</i></button>
  </mat-panel-description>
</mat-expansion-panel-header>
<div class="borderyellow"> 
<h3 class="innerheading_value">Generate MF4 Accidental
  <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
      {{totalcalculate(allcarddetails.GENERATE_MF4_ACCIDENTAL)}}</i></button>
</h3>
<div class="row">
  <ng-container *ngFor="let brand of allcarddetails.GENERATE_MF4_ACCIDENTAL;let i=index">
    <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
      *ngIf="brand.status=='IN_PROGRESS'">
      <app-card [hidHeader]="true" cardClass="comp-card linkClass"
      (click)="getList(brand.entityType,brand.status,'GENERATE_MF4_ACCIDENTAL','stock/generatemf4accidental')">
        <div class="row align-items-center">
          <div class="col">
            <span>{{convertToSentenceCase(brand?.entityType)}}</span>
            <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
            <i class="fas fa-hourglass-half"></i>
          </div>
        </div>
      </app-card>
    </div>
  </ng-container>

</div>
</div>

</mat-expansion-panel>

      <mat-expansion-panel [expanded]="initialLocalStorageValue == 5" (closed)='removesetitem()' (opened)="setStep(5)" class="padding_panel_remove_custom"
    
        *ngIf="allcarddetails && (allcarddetails.BRAND_AND_LABEL_REGISTRAION||allcarddetails.BRAND_AND_LABEL_OVERVIEW) && 
(calculatelength(allcarddetails.BRAND_AND_LABEL_REGISTRAION)||calculatelength(allcarddetails.BRAND_AND_LABEL_OVERVIEW))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Brand And Label
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.BRAND_AND_LABEL_REGISTRAION)+totalcalculate(allcarddetails.BRAND_AND_LABEL_OVERVIEW)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"
          *ngIf="allcarddetails &&allcarddetails.BRAND_AND_LABEL_REGISTRAION && calculatelength(allcarddetails.BRAND_AND_LABEL_REGISTRAION)">
          <h3 class="innerheading_value">Brand and Label - New Brands
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.BRAND_AND_LABEL_REGISTRAION)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.BRAND_AND_LABEL_REGISTRAION;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'BRAND_AND_LABEL_REGISTRAION','brand-label-registration')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow"
          *ngIf="allcarddetails &&allcarddetails.BRAND_AND_LABEL_OVERVIEW && calculatelength(allcarddetails.BRAND_AND_LABEL_OVERVIEW)">
          <h3 class="innerheading_value">Brand and Label - Existing Brands
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.BRAND_AND_LABEL_OVERVIEW)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.BRAND_AND_LABEL_OVERVIEW;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'BRAND_AND_LABEL_OVERVIEW','brand-label-registration/overview',true)">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>


      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 6" (closed)='removesetitem()' (opened)="setStep(6)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails &&(allcarddetails.DRUM_REGISTRATION||allcarddetails.TANK_REGISTRATION||allcarddetails.TANK_CONVERSION||
      allcarddetails.TANK_DISMANTLE||allcarddetails.TANK_MIXING||allcarddetails.TANK_REPAIR||allcarddetails.TANK_TRANSFER)
      &&(calculatelength(allcarddetails.DRUM_REGISTRATION)||calculatelength(allcarddetails.TANK_REGISTRATION)||calculatelength(allcarddetails.TANK_CONVERSION)||calculatelength(allcarddetails.TANK_DISMANTLE)||
      calculatelength(allcarddetails.TANK_MIXING)||calculatelength(allcarddetails.TANK_REPAIR)||calculatelength(allcarddetails.TANK_TRANSFER))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Tank Management
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.TANK_REGISTRATION)+totalcalculate(allcarddetails.TANK_CONVERSION)
                +totalcalculate(allcarddetails.TANK_DISMANTLE)
                +totalcalculate(allcarddetails.TANK_MIXING)+totalcalculate(allcarddetails.TANK_REPAIR)
                +totalcalculate(allcarddetails.TANK_TRANSFER)+totalcalculate(allcarddetails.DRUM_REGISTRATION)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"
          *ngIf="allcarddetails && allcarddetails.TANK_REGISTRATION && calculatelength(allcarddetails.TANK_REGISTRATION)">
          <h3 class="innerheading_value">Tank Registration
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.TANK_REGISTRATION)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.TANK_REGISTRATION;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'TANK_REGISTRATION','tank/registration')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails && allcarddetails.TANK_REPAIR && calculatelength(allcarddetails.TANK_REPAIR)">
          <h3 class="innerheading_value">Tank Repair
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.TANK_REPAIR)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.TANK_REPAIR;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'TANK_REPAIR','tank/repair')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div  class="borderyellow" *ngIf="allcarddetails && allcarddetails.TANK_TRANSFER && calculatelength(allcarddetails.TANK_TRANSFER)">
          <h3 class="innerheading_value">Tank Transfer
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.TANK_TRANSFER)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.TANK_TRANSFER;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'TANK_TRANSFER','tank/transfer')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails && allcarddetails.TANK_DISMANTLE && calculatelength(allcarddetails.TANK_DISMANTLE)">
          <h3 class="innerheading_value">Tank Dismantle
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.TANK_DISMANTLE)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.TANK_DISMANTLE;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'TANK_DISMANTLE','tank/dismentle')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow"
          *ngIf="allcarddetails && allcarddetails.TANK_CONVERSION && calculatelength(allcarddetails.TANK_CONVERSION)">
          <h3 class="innerheading_value">Tank Conversion
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.TANK_CONVERSION)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.TANK_CONVERSION;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'TANK_CONVERSION','tank/conversion')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>

        <div class="borderyellow" *ngIf="allcarddetails && allcarddetails.TANK_MIXING && calculatelength(allcarddetails.TANK_MIXING)">
          <h3 class="innerheading_value">Tank Mixing
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.TANK_MIXING)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.TANK_MIXING;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'TANK_MIXING','tank/tankmixing')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails && allcarddetails.DRUM_REGISTRATION && calculatelength(allcarddetails.DRUM_REGISTRATION)">
          <h3 class="innerheading_value">Drum Registration
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.DRUM_REGISTRATION)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.DRUM_REGISTRATION;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'DRUM_REGISTRATION','tank/drumregistration')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>



      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="initialLocalStorageValue == 7" (closed)='removesetitem()' (opened)="setStep(7)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails && (allcarddetails.BOTTLING_LINE_REGISTRATION || allcarddetails.LINE_DISMANTLE|| allcarddetails.BOTTLING_LINE_CONVERSION)
      &&(calculatelength(allcarddetails.BOTTLING_LINE_REGISTRATION) || calculatelength(allcarddetails.LINE_DISMANTLE) || calculatelength(allcarddetails.BOTTLING_LINE_CONVERSION))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Bottling Line
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors ">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.BOTTLING_LINE_REGISTRATION)+totalcalculate(allcarddetails.LINE_DISMANTLE)+totalcalculate(allcarddetails.BOTTLING_LINE_CONVERSION)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"
          *ngIf="allcarddetails.BOTTLING_LINE_REGISTRATION&&calculatelength(allcarddetails.BOTTLING_LINE_REGISTRATION)">
          <h3 class="innerheading_value">Bottling Line
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.BOTTLING_LINE_REGISTRATION)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.BOTTLING_LINE_REGISTRATION;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="brand.entityType=='BREWERY'?getList(brand.entityType,brand.status,'BOTTLING_LINE_REGISTRATION','newlineregistration'):
                getList(brand.entityType,brand.status,'BOTTLING_LINE_REGISTRATION','stock/newlineregistration')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails.LINE_DISMANTLE&&calculatelength(allcarddetails.LINE_DISMANTLE)">
          <h3 class="innerheading_value">Line Dismantle
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.LINE_DISMANTLE)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.LINE_DISMANTLE;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="brand.entityType=='BREWERY'?getList(brand.entityType,brand.status,'LINE_DISMANTLE','bottlinglinedismantle'):
                getList(brand.entityType,brand.status,'LINE_DISMANTLE','stock/bottlinglinedismantle')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails.BOTTLING_LINE_CONVERSION&&calculatelength(allcarddetails.BOTTLING_LINE_CONVERSION)">
          <h3 class="innerheading_value">Line Conversion
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.BOTTLING_LINE_CONVERSION)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.BOTTLING_LINE_CONVERSION;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="brand.entityType=='BREWERY'?getList(brand.entityType,brand.status,'BOTTLING_LINE_CONVERSION','bottlinglinedismantle'):
                getList(brand.entityType,brand.status,'BOTTLING_LINE_CONVERSION','stock/bottlinglineconversion')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="initialLocalStorageValue == 8" (closed)='removesetitem()' (opened)="setStep(8)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails && (allcarddetails.DISTILLERY_SPIRIT_PURCHASE||allcarddetails.DISTILLERY_SPIRIT_SELLER)
        &&(calculatelength(allcarddetails.DISTILLERY_SPIRIT_PURCHASE)||calculatelength(allcarddetails.DISTILLERY_SPIRIT_SELLER))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Spirit Purchase within UP
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors ">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.DISTILLERY_SPIRIT_PURCHASE)+totalcalculate(allcarddetails.DISTILLERY_SPIRIT_SELLER)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow" *ngIf="allcarddetails.DISTILLERY_SPIRIT_PURCHASE&&calculatelength(allcarddetails.DISTILLERY_SPIRIT_PURCHASE) ">
        <h3 class="innerheading_value">Spirit Purchase within UP - Request
          <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
              {{totalcalculate(allcarddetails.DISTILLERY_SPIRIT_PURCHASE)}}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of allcarddetails.DISTILLERY_SPIRIT_PURCHASE;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                (click)="getList(brand.entityType,brand.status,'DISTILLERY_SPIRIT_PURCHASE','enaPurchaseManagement/enapurchaseorder')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>

        </div>
        </div>
        <div class="borderyellow" *ngIf="allcarddetails.DISTILLERY_SPIRIT_SELLER&&calculatelength(allcarddetails.DISTILLERY_SPIRIT_SELLER) ">
          <h3 class="innerheading_value">Spirit Purchase within UP - Acceptance
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.DISTILLERY_SPIRIT_SELLER)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.DISTILLERY_SPIRIT_SELLER;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'DISTILLERY_SPIRIT_SELLER','enaPurchaseManagement/enapurchaseorderacceptance')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
  
          </div>
          </div>


      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 9" (closed)='removesetitem()' (opened)="setStep(9)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails && (allcarddetails.REDISTILLATION_BOTTLED_STOCK ||allcarddetails.REDISTILLATION_SPIRIT_VAT)
        &&(calculatelength(allcarddetails.REDISTILLATION_BOTTLED_STOCK) ||calculatelength(allcarddetails.REDISTILLATION_SPIRIT_VAT))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            ReDistillation
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.REDISTILLATION_BOTTLED_STOCK)+totalcalculate(allcarddetails.REDISTILLATION_SPIRIT_VAT)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"
          *ngIf="allcarddetails.REDISTILLATION_BOTTLED_STOCK&&calculatelength(allcarddetails.REDISTILLATION_BOTTLED_STOCK) ">
          <h3 class="innerheading_value">ReDistillation Bottled Stock
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.REDISTILLATION_BOTTLED_STOCK)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.REDISTILLATION_BOTTLED_STOCK;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'REDISTILLATION_BOTTLED_STOCK','blending/applicationforredistillationofbottledstock')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
        <div class="borderyellow"
          *ngIf="allcarddetails.REDISTILLATION_SPIRIT_VAT&&calculatelength(allcarddetails.REDISTILLATION_SPIRIT_VAT) ">
          <h3 class="innerheading_value">ReDistillation Spirit VAT
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.REDISTILLATION_SPIRIT_VAT)}}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.REDISTILLATION_SPIRIT_VAT;let i=index">
              <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getList(brand.entityType,brand.status,'REDISTILLATION_SPIRIT_VAT','blending/afterapprovaltransferforredistillation')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 10" (closed)='removesetitem()' (opened)="setStep(10)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails && allcarddetails.OMD&&calculatelength(allcarddetails.OMD)">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            OMD
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.OMD)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"> 
        <h3 class="innerheading_value">OMD
          <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
              {{totalcalculate(allcarddetails.OMD)}}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of allcarddetails.OMD;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                (click)="getList(brand.entityType,brand.status,'OMD','omd/request-for-ethanol-order-for-omd')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>

        </div>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 11" (closed)='removesetitem()' (opened)="setStep(11)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails && allcarddetails.APPLICATION_FOR_SHEERA_NIDHI&&calculatelength(allcarddetails.APPLICATION_FOR_SHEERA_NIDHI)">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Sheera Nidhi Release
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.APPLICATION_FOR_SHEERA_NIDHI)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"> 
        <h3 class="innerheading_value"> Sheera Nidhi Release
          <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
              {{totalcalculate(allcarddetails.APPLICATION_FOR_SHEERA_NIDHI)}}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of allcarddetails.APPLICATION_FOR_SHEERA_NIDHI;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                (click)="getList(brand.entityType,brand.status,'APPLICATION_FOR_SHEERA_NIDHI','applicationforreleaseofsheeranidhi')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>

        </div>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 12" (closed)='removesetitem()' (opened)="setStep(12)" class="padding_panel_remove_custom"
        
        *ngIf="allcarddetails && allcarddetails.SPRIT_UNIT_REGISTRATION&&calculatelength(allcarddetails.SPRIT_UNIT_REGISTRATION)">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Registration as Export Unit(Overseas)
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records<i
                class="ion-plus-round">{{totalcalculate(allcarddetails.SPRIT_UNIT_REGISTRATION)}}</i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow">
        <h3 class="innerheading_value"> Registration as Export Unit(Overseas)
          <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
              {{totalcalculate(allcarddetails.SPRIT_UNIT_REGISTRATION)}}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of allcarddetails.SPRIT_UNIT_REGISTRATION;let i=index">
            <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
              *ngIf="brand.status=='IN_PROGRESS'">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                (click)="getList(brand.entityType,brand.status,'SPRIT_UNIT_REGISTRATION','regasdistillery-outsideup')">
                <div class="row align-items-center">
                  <div class="col">
                    <span>{{convertToSentenceCase(brand?.entityType)}}</span>
                    <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>

        </div>
</div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 13" (closed)='removesetitem()' (opened)="setStep(13)"
        *ngIf="(designation==decLicensing || designation==commissioner || designation==additionalcommissionerld || designation==jecHq || designation==exciseHq || designation == jds || designation == addladmin) && (showMrp || showLabelMrp) "
         class="padding_panel_remove_custom" >
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            {{LabelName}}
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors">Pending Records
              <i class="ion-plus-round">{{ edpTotalCount + edpLabelTotalCount }}</i>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"> 
        <h3 class="innerheading_value">{{LabelName}}
          <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
              {{ edpTotalCount + edpLabelTotalCount }}</i></button>
        </h3>
        <div class="row">
          <ng-container *ngFor="let brand of edpDataArray;let i=index">
            <div [ngClass]="getLicenseColor(i%4)"
              class="col-md-6 col-xl-4 col-sm-6  animate__animated animate__backInUp">
              <app-card [hidHeader]="true" cardClass="comp-card linkClass" (click)="getEDPList(brand)">
                <div class="row align-items-center" (click)="getEDPList(brand)">
                  <div class="col" (click)="getEDPList(brand)">
                    <span >{{brand.entityType}}</span>
                    <!-- <span *ngIf="brand.name == 'EBP REGISTRATION'">{{brand.entityType}}</span>
                    <span *ngIf="brand.name == 'EDP REGISTRATION' || brand.name == 'EWP REGISTRATION'">{{brand.entityType}}</span>
                    <span *ngIf="brand.name == 'ECP REGISTRATION'">{{brand.entityType}}</span>
                    <span *ngIf="brand.name == 'ECP LABEL OVERVIEW REGISTRATION'">{{brand.entityType}}</span>
                    <span *ngIf="brand.name == 'EDP LABEL OVERVIEW REGISTRATION'">{{brand.entityType}}</span>
                    <span *ngIf="brand.name == 'EBP LABEL OVERVIEW REGISTRATION'">{{brand.entityType}}</span> -->

                    <br>
                    <span>{{brand.name}}</span>
                    <br>
                    <!-- <span *ngIf="brand.name == 'EBP REGISTRATION'">{{brand.entityType}}</span> -->
                    <h3 *ngIf="brand.status == 'INPROGRESS'">Inprogress<br></h3>
                    <h3 *ngIf="brand.status == 'WAITINGFORDIGITALSIGNATURE'">Waiting for Digital Signature<br></h3>
                    <!-- <h3 *ngIf="brand.name == 'EBP REGISTRATION'" class="mt-2 strong">{{ brand.count }}</h3>
                    <h3 *ngIf="brand.name == 'EDP REGISTRATION' || brand.name == 'EWP REGISTRATION'" class="mt-2 strong">{{ brand.count }}</h3>
                    <h3 *ngIf="brand.name == 'ECP REGISTRATION'" class="mt-2 strong">{{ brand.count }}</h3>
                    <h3 *ngIf="brand.name == 'ECP LABEL OVERVIEW REGISTRATION'">{{brand.count}}</h3>
                    <h3 *ngIf="brand.name == 'EDP LABEL OVERVIEW REGISTRATION'">{{brand.count}}</h3>
                    <h3 *ngIf="brand.name == 'EBP LABEL OVERVIEW REGISTRATION'">{{brand.count}}</h3> -->
                    <h3 >{{brand.count}}</h3> 
                    <i class="fas fa-hourglass-half"></i>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>
        </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 14" (closed)='removesetitem()' (opened)="setStep(14)" class="padding_panel_remove_custom"
         *ngIf="allcarddetails && (allcarddetails.SCHEDULED_INSPECTION || allcarddetails.UNSCHEDULED_INSPECTION
             ||allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL )
            &&(calculatelength(allcarddetails.SCHEDULED_INSPECTION) ||calculatelength(allcarddetails.UNSCHEDULED_INSPECTION)
            ||calculatelength(allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Vigilance
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors ">Pending Records<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.SCHEDULED_INSPECTION)+totalcalculate(allcarddetails.UNSCHEDULED_INSPECTION)
                +totalcalculate(allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL) }}
              </i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow"
          *ngIf="allcarddetails.SCHEDULED_INSPECTION&&calculatelength(allcarddetails.SCHEDULED_INSPECTION)||
              allcarddetails.UNSCHEDULED_INSPECTION&&calculatelength(allcarddetails.UNSCHEDULED_INSPECTION)||
              allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL&&calculatelength(allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL)">
          <h3 class="innerheading_value">Vigilance
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.SCHEDULED_INSPECTION)+totalcalculate(allcarddetails.UNSCHEDULED_INSPECTION)
                +totalcalculate(allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL) }}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.SCHEDULED_INSPECTION;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp dashboard_bg1"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getListwithoutentity(brand.status,'SCHEDULED_INSPECTION','vigilance/scheduledinspection')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>Scheduled Inspection</span> 
                      <h3 class="mt-3 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <ng-container *ngFor="let brand of allcarddetails.UNSCHEDULED_INSPECTION;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp dashboard_bg3"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getListwithoutentity(brand.status,'UNSCHEDULED_INSPECTION','vigilance/unscheduled-inspection')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>Un Scheduled Inspection</span> 
                      <h3 class="mt-3 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <ng-container *ngFor="let brand of allcarddetails.COMPLAINT_AGAINST_EXCISE_OFFICIAL;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp dashboard_bg2"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getListwithoutentity(brand.status,'COMPLAINT_AGAINST_EXCISE_OFFICIAL','vigilance/complaint-against-excise-official')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span style="position: absolute;">Complaint Against Excise Official</span><br>
                      <h3 class="mt-3 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="initialLocalStorageValue == 15" (closed)='removesetitem()' (opened)="setStep(15)" class="padding_panel_remove_custom"
         *ngIf="allcarddetails && (allcarddetails.INTERNAL_AUDIT_PLAN || allcarddetails.INTERNAL_AUDIT_PARA
       ||allcarddetails.EXTERNAL_AUDIT_PLAN )
      &&(calculatelength(allcarddetails.INTERNAL_AUDIT_PLAN) ||calculatelength(allcarddetails.INTERNAL_AUDIT_PARA)
      ||calculatelength(allcarddetails.EXTERNAL_AUDIT_PLAN))">
        <mat-expansion-panel-header class="mat_exapansion_headers">
          <mat-panel-title class="purplecolorsfont font20">
            Audit Management
          </mat-panel-title>
          <mat-panel-description>
            <button class="snip1258 purplecolors ">Pending Records<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.INTERNAL_AUDIT_PLAN)+totalcalculate(allcarddetails.INTERNAL_AUDIT_PARA)
                +totalcalculate(allcarddetails.EXTERNAL_AUDIT_PLAN) }}
              </i></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="borderyellow" *ngIf="allcarddetails.INTERNAL_AUDIT_PLAN&&calculatelength(allcarddetails.INTERNAL_AUDIT_PLAN)||
        allcarddetails.INTERNAL_AUDIT_PARA&&calculatelength(allcarddetails.INTERNAL_AUDIT_PARA)||
        allcarddetails.EXTERNAL_AUDIT_PLAN&&calculatelength(allcarddetails.EXTERNAL_AUDIT_PLAN)">
          <h3 class="innerheading_value">Audit Management
            <button class="snip1258 purplecolors">Total<i class="ion-plus-round">
                {{totalcalculate(allcarddetails.INTERNAL_AUDIT_PLAN)+totalcalculate(allcarddetails.INTERNAL_AUDIT_PARA)
                +totalcalculate(allcarddetails.EXTERNAL_AUDIT_PLAN) }}</i></button>
          </h3>
          <div class="row">
            <ng-container *ngFor="let brand of allcarddetails.INTERNAL_AUDIT_PLAN;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp dashboard_bg1"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="designation==commissioner?getListwithoutentity(brand.status,'INTERNAL_AUDIT_PLAN','auditManagement/internalaudit-auditplan')
                  :getListwithoutentity(brand.status,'INTERNAL_AUDIT_PLAN','auditManagement/internalaudit-auditplan')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>Internal Audit Plan</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <ng-container *ngFor="let brand of allcarddetails.INTERNAL_AUDIT_PARA;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp dashboard_bg3"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getListwithoutentity(brand.status,'INTERNAL_AUDIT_PARA','auditManagement/internalauditor-auditpara')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>Internal Audit Para</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>
            <ng-container *ngFor="let brand of allcarddetails.EXTERNAL_AUDIT_PLAN;let i=index">
              <div class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp dashboard_bg2"
                *ngIf="brand.status=='IN_PROGRESS'">
                <app-card [hidHeader]="true" cardClass="comp-card linkClass"
                  (click)="getListwithoutentity(brand.status,'EXTERNAL_AUDIT_PLAN','auditManagement/externalauditor')">
                  <div class="row align-items-center">
                    <div class="col">
                      <span>External Audit Plan</span>
                      <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                      <i class="fas fa-hourglass-half"></i>
                    </div>
                  </div>
                </app-card>
              </div>
            </ng-container>

          </div>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel  [expanded]="initialLocalStorageValue == 16"  (closed)="removesetitem()"  (opened)="setStep(16)"  class="padding_panel_remove_custom"
  *ngIf="allcarddetails && (allcarddetails.NDT_PERMIT || allcarddetails.NDT_IMPORT_PERMIT || allcarddetails.NDT_EXPORT_PERMIT
      || allcarddetails.NDE_PERMIT)
   &&(calculatelength(allcarddetails.NDT_PERMIT) || calculatelength(allcarddetails.NDT_IMPORT_PERMIT) || calculatelength(allcarddetails.NDT_EXPORT_PERMIT)
    || calculatelength(allcarddetails.NDE_PERMIT))">
  <mat-expansion-panel-header class="mat_exapansion_headers">
    <mat-panel-title class="purplecolorsfont font20"> NDLC </mat-panel-title>
    <mat-panel-description>
      <button class="snip1258 purplecolors">
        Pending Records<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.NDT_PERMIT)+totalcalculate(allcarddetails.NDT_IMPORT_PERMIT)+
            totalcalculate(allcarddetails.NDT_EXPORT_PERMIT)+totalcalculate(allcarddetails.NDE_PERMIT)
          }}
        </i>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div
    class="borderyellow"
    *ngIf="allcarddetails.NDT_PERMIT&&calculatelength(allcarddetails.NDT_PERMIT)||
     allcarddetails.NDT_IMPORT_PERMIT&&calculatelength(allcarddetails.NDT_IMPORT_PERMIT) || 
     allcarddetails.NDT_EXPORT_PERMIT&&calculatelength(allcarddetails.NDT_EXPORT_PERMIT) ||
     allcarddetails.NDE_PERMIT&&calculatelength(allcarddetails.NDE_PERMIT)"
  >
    <h3 class="innerheading_value">
      NDLC
      <button class="snip1258 purplecolors">
        Total<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.NDT_PERMIT)+totalcalculate(allcarddetails.NDT_IMPORT_PERMIT)
            +totalcalculate(allcarddetails.NDT_EXPORT_PERMIT)+totalcalculate(allcarddetails.NDE_PERMIT)
          }}</i
        >
      </button>
    </h3>
    <div class="row">
      <ng-container *ngFor="let brand of allcarddetails.NDT_PERMIT;let i=index">
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg1"
          *ngIf="brand.status=='IN_PROGRESS'">
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'NDT_PERMIT','ndlcpermit/ndtpermit')">
            <div class="row align-items-center">
              <div class="col">
                <span>NDT Permit</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
      <ng-container
        *ngFor="let brand of allcarddetails.NDT_IMPORT_PERMIT;let i=index" >
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg3"
          *ngIf="brand.status=='IN_PROGRESS'" >
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'NDT_IMPORT_PERMIT','ndlcpermit/importpermitnarcoticdrug')" >
            <div class="row align-items-center">
              <div class="col">
                <span>Import Permit for Narcotic Drug</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
      <ng-container
        *ngFor="let brand of allcarddetails.NDT_EXPORT_PERMIT;let i=index" >
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg3"
          *ngIf="brand.status=='IN_PROGRESS'" >
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'NDT_EXPORT_PERMIT','ndlcpermit/exportpermit')" >
            <div class="row align-items-center">
              <div class="col">
                <span>Export Permit for Narcotic Drug</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
      <ng-container
        *ngFor="let brand of allcarddetails.NDE_PERMIT;let i=index" >
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg3"
          *ngIf="brand.status=='IN_PROGRESS'" >
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'NDE_PERMIT','ndlcpermit/ndepermit')" >
            <div class="row align-items-center">
              <div class="col">
                <span>NDE Permit</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel> 
<mat-expansion-panel  [expanded]="initialLocalStorageValue == 17"  (closed)="removesetitem()"  (opened)="setStep(17)"  class="padding_panel_remove_custom"
  *ngIf="allcarddetails && (allcarddetails.FL22_IMPORT_PERMIT) &&(calculatelength(allcarddetails.FL22_IMPORT_PERMIT))">
  <mat-expansion-panel-header class="mat_exapansion_headers">
    <mat-panel-title class="purplecolorsfont font20"> SACRAMENTAL </mat-panel-title>
    <mat-panel-description>
      <button class="snip1258 purplecolors">
        Pending Records<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.FL22_IMPORT_PERMIT)}}
        </i>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div
    class="borderyellow"
    *ngIf="allcarddetails.FL22_IMPORT_PERMIT&&calculatelength(allcarddetails.FL22_IMPORT_PERMIT)">
    <h3 class="innerheading_value">
      SACRAMENTAL
      <button class="snip1258 purplecolors">
        Total<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.FL22_IMPORT_PERMIT)}}</i>
      </button>
    </h3>
    <div class="row">
      <ng-container *ngFor="let brand of allcarddetails.FL22_IMPORT_PERMIT;let i=index">
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg1"
          *ngIf="brand.status=='IN_PROGRESS'">
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'FL22_IMPORT_PERMIT','sacramental/fl22importpermit')">
            <div class="row align-items-center">
              <div class="col">
                <span>FL22 Import permit</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>
<mat-expansion-panel  [expanded]="initialLocalStorageValue == 18"  (closed)="removesetitem()"  (opened)="setStep(18)"  class="padding_panel_remove_custom"
  *ngIf="allcarddetails && (allcarddetails.NDO2_PERMIT) &&(calculatelength(allcarddetails.NDO2_PERMIT))">
  <mat-expansion-panel-header class="mat_exapansion_headers">
    <mat-panel-title class="purplecolorsfont font20"> NDO-2 PERMIT </mat-panel-title>
    <mat-panel-description>
      <button class="snip1258 purplecolors">
        Pending Records<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.NDO2_PERMIT)}}
        </i>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div
    class="borderyellow"
    *ngIf="allcarddetails.NDO2_PERMIT&&calculatelength(allcarddetails.NDO2_PERMIT)">
    <h3 class="innerheading_value">
      NDO-2 PERMIT
      <button class="snip1258 purplecolors">
        Total<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.NDO2_PERMIT)}}</i>
      </button>
    </h3>
    <div class="row">
      <ng-container *ngFor="let brand of allcarddetails.NDO2_PERMIT;let i=index">
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg1"
          *ngIf="brand.status=='IN_PROGRESS'">
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'NDO2_PERMIT','ndo2/ndo2permit')">
            <div class="row align-items-center">
              <div class="col">
                <span>NDO-2 Permit</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>
<mat-expansion-panel  [expanded]="initialLocalStorageValue == 19"  (closed)="removesetitem()"  (opened)="setStep(19)"  class="padding_panel_remove_custom"
  *ngIf="allcarddetails && (allcarddetails.FL39_IMPORT_SPIRIT) &&(calculatelength(allcarddetails.FL39_IMPORT_SPIRIT))">
  <mat-expansion-panel-header class="mat_exapansion_headers">
    <mat-panel-title class="purplecolorsfont font20"> FL39 </mat-panel-title>
    <mat-panel-description>
      <button class="snip1258 purplecolors">
        Pending Records<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.FL39_IMPORT_SPIRIT)}}
        </i>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div
    class="borderyellow"
    *ngIf="allcarddetails.FL39_IMPORT_SPIRIT&&calculatelength(allcarddetails.FL39_IMPORT_SPIRIT)">
    <h3 class="innerheading_value">
      FL39 IMPORT SPIRIT
      <button class="snip1258 purplecolors">
        Total<i class="ion-plus-round">
          {{totalcalculate(allcarddetails.FL39_IMPORT_SPIRIT)}}</i>
      </button>
    </h3>
    <div class="row">
      <ng-container *ngFor="let brand of allcarddetails.FL39_IMPORT_SPIRIT;let i=index">
        <div
          class="col-md-6 col-xl-3 col-sm-6 animate__animated animate__backInUp dashboard_bg1"
          *ngIf="brand.status=='IN_PROGRESS'">
          <app-card
            [hidHeader]="true"
            cardClass="comp-card linkClass"
            (click)="getListwithoutentity(brand.status,'FL39_IMPORT_SPIRIT','fl39/spiritimportfl39')">
            <div class="row align-items-center">
              <div class="col">
                <span>NDO-2 Permit</span>
                <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
                <i class="fas fa-hourglass-half"></i>
              </div>
            </div>
          </app-card>
        </div>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>
<mat-expansion-panel [expanded]="initialLocalStorageValue == 21" (closed)='removesetitem()' (opened)="setStep(21)" class="padding_panel_remove_custom"
        
*ngIf="allcarddetails && allcarddetails.STOCK_ACTIVITY_ACCIDENTAL_MF4&&calculatelength(allcarddetails.STOCK_ACTIVITY_ACCIDENTAL_MF4)">
<mat-expansion-panel-header class="mat_exapansion_headers">
  <mat-panel-title class="purplecolorsfont font20">
    Stock Activity
  </mat-panel-title>
  <mat-panel-description>
    <button class="snip1258 purplecolors">Pending Records<i
        class="ion-plus-round">{{totalcalculate(allcarddetails.STOCK_ACTIVITY_ACCIDENTAL_MF4)}}</i></button>
  </mat-panel-description>
</mat-expansion-panel-header>
<div class="borderyellow"> 
<h3 class="innerheading_value">Stock Activity
  <button class="snip1258 purplecolors">{{'licenseMgnt.totalText' | translate}}<i class="ion-plus-round">
      {{totalcalculate(allcarddetails.STOCK_ACTIVITY_ACCIDENTAL_MF4)}}</i></button>
</h3>
<div class="row">
  <ng-container *ngFor="let brand of allcarddetails.STOCK_ACTIVITY_ACCIDENTAL_MF4;let i=index">
    <div [ngClass]="brand.color" class="col-md-6 col-xl-3 col-sm-6  animate__animated animate__backInUp"
      *ngIf="brand.status=='IN_PROGRESS'">
      <app-card [hidHeader]="true" cardClass="comp-card linkClass"
        (click)="getList(brand.entityType,brand.status,'STOCK_ACTIVITY_ACCIDENTAL_MF4','stock/stockactivity')">
        <div class="row align-items-center">
          <div class="col">
            <span>{{convertToSentenceCase(brand?.entityType)}}</span>
            <h3 class="mt-2 strong">{{brand?.statusRecordCount}}</h3>
            <i class="fas fa-hourglass-half"></i>
          </div>
        </div>
      </app-card>
    </div>
  </ng-container>

</div>
</div>

</mat-expansion-panel>
    </mat-accordion>
  </div>


</div>