<app-navigation class="pcoded-navbar"
  [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.nextConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
  (onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light"
  (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper"
    [ngClass]="{'container': this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'}">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <div class="main-body">
          <div class="page-wrapper">
            <app-breadcrumb></app-breadcrumb>
            <router-outlet></router-outlet>
          </div>
          <div class="footer">
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12 col-12 text-lg-left text-md-left text-center pt-2"> Copyright
                &copy; 2023 Uttar Pradesh Excise Department. India | All Rights Reserved. Version {{version}}

              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-lg-right text-md-right text-center">Powered By <img
                  src="assets/images/poweredby-icon.png" alt="OASYS Cybernetics Pvt Ltd"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-configuration></app-configuration>