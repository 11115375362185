import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class MasterDropdownsService {

  constructor(
    private http: HttpClient
  ) { }

  masterURL = environment.baseURLMaster;
  parentUnitURL = environment.parentUnit;
  wholesaleNewURL = environment.wholesaleNewURL;

  findBrandDropdowns(postParam): Observable<any> {
    const url = this.parentUnitURL + apiUrls.findBrandDropdown;
    return this.http.post(url, postParam);
  }

  findLabelDetails(postParam): Observable<any> {
    const url = this.parentUnitURL + apiUrls.findLabelDetails;
    return this.http.post(url, postParam);
  }

  findBrand(postParam): Observable<any> {
    const url = this.parentUnitURL + apiUrls.postBrandDetails;
    return this.http.post(url, postParam);
  }

  getTempLicense(): Observable<any> {
    const url = this.parentUnitURL + apiUrls.getTempLicense;
    return this.http.get(url);
  }

  findBrandDetails(postParam): any {
    const url = this.parentUnitURL + apiUrls.findBrandDropdown;
    return this.http.post(url, postParam).toPromise();
  }

  getDropDownCustom(postParam): any {
    const url = this.wholesaleNewURL + apiUrls.customdata;
    return this.http.post(url, postParam).toPromise();
  }

  getDropDownCustomList(postParam): any {
    const url = this.wholesaleNewURL + apiUrls.customdata;
    return this.http.post(url, postParam);
  }

  getDropDown(postParam): any {
    const url = this.wholesaleNewURL + apiUrls.listmasterdata;
    return this.http.post(url, postParam).toPromise();
  }

  getDropDownExport(postParam): any {
    const url = this.wholesaleNewURL + apiUrls.customdata;
    return this.http.post(url, postParam).toPromise();
  }

  getappconfigvalue(postParam): any {
    const url = this.wholesaleNewURL + apiUrls.getappconfigvalue;
    return this.http.post(url, postParam).toPromise();
  }

}
