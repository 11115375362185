import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import { entityType } from '@app/containers/pd/pd.constants';
@Injectable({
  providedIn: 'root'
})
export class AllotmentRequestService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/lazySearch';
    return this.http.post(url, payload);
  }
  getSellerAllotmentNumber(purchaserLicenseNo,sellerLicenseNo,sellerUnitName): Observable<any>{
    const url = this.baseURL + 'pdAllotmentRequest/getApprovedAllotment?purchaserLicenseNo=' + purchaserLicenseNo + '&sellerLicenseNo=' + sellerLicenseNo
    + '&sellerUnitName=' +sellerUnitName;
    return this.http.get(url);
  }
  getSellerAllotmentNumberasync(purchaserLicenseNo,sellerLicenseNo,sellerUnitName): any{
    const url = this.baseURL + 'pdAllotmentRequest/getApprovedAllotment?purchaserLicenseNo=' + purchaserLicenseNo + '&sellerLicenseNo=' + sellerLicenseNo
    + '&sellerUnitName=' +sellerUnitName;
    return this.http.get(url).toPromise();
  }
  allotmentValidation(entityType):Observable<any>{
    const url = this.baseURL + 'pdAllotmentRequest/getAllotmentRequestApproval?entityType='+entityType
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/' + id;
    return this.http.get(url);
  }
  getApprovedAllotment(purchaserLicenseNo, sellerLicenseNo): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/getApprovedAllotment?purchaserLicenseNo=' + purchaserLicenseNo + '&sellerLicenseNo=' + sellerLicenseNo;
    return this.http.get(url);
  }
  getAllotmentDetails(allotmentNumber):Observable<any>{
    const url = this.baseURL +'pdAllotmentRequest/getApprovedAllotmentQtyDetails?allotmentNumber=' +allotmentNumber;
    return this.http.get(url);
  }
  getFormula(entityType): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/getAllotmentCommonConfig?entityType=' + entityType;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }

  getreservesupplyData(licensenumber, reservedue): Observable<any> {
    const url = this.baseURL + 'findAllotmentDetails?entityType=PD&licenseNumber=' + licensenumber + '&reservedDue=' + reservedue;
    return this.http.get(url);

  }
  getIndentDetails(licensenumber): Observable<any> {
    const url = this.baseURL + 'MF4transportPass/transportGenSearch'
    return this.http.post(url, licensenumber);
  }
  pendingmolassesstatus(payload): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/lazySearch'
    return this.http.post(url, payload);
  }

  pdAllotmentReqUpdate(data): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/update';
    return this.http.post(url, data);
  }
  getAllotmentRequestCount(entityType?, createdBy?): Observable<any> {
    const url = this.baseURL + 'pdAllotmentRequest/getAllotmentRequestCount?entityType='+entityType+'&createdBy='+ createdBy;
    return this.http.get(url);
  }
  downloadenavalue(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  getReservationTypes(){
    const url = this.baseURL + 'getReservationTypes'
    // const url = 'http://localhost:3501/scmProduction/getReservationTypes'
    return this.http.get(url);
  }
  getPd33UserIdList():Observable<any>{
    const url = this.baseURL + 'getCommonConfigDetail?entityType=PD&type=PD33_USER_LIST';
    return this.http.get(url);
  }
}
