import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';


@Injectable({
  providedIn: 'root'
})

export class enaPurchaseOrderService {
    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;
    usermangmentBaseUrl = environment.userManagerUrl

    search(payload): Observable<any> {
        const url = this.baseURL + 'enaPurchaseOrder/lazySearch';
        return this.http.post(url, payload);
    }
 
    search1(payload): Observable<any> {
      const url = this.baseURL + 'enaPurchaseOrder/lazySearch';
      return this.http.post(url, payload);
  }
  search1111(payload): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase/acceptance/search';
    //const url = 'http://localhost:3501/scmProduction/distillerySpiritPurchase/acceptance/search';
    return this.http.post(url, payload);
}

  search2(payload): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase/lazySearch';
    //  const url ='http://localhost:3501/scmProduction/distillerySpiritPurchase/lazySearch';
    return this.http.post(url, payload);
}
  // getPdCardCount(payload): Observable<any> {
  // const url = this.baseURL + 'pd/getStatusCount'
  // return this.http.post(url,payload);
  // }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  getOrderById(id): Observable<any> {
    const url = this.baseURL + 'enaPurchaseOrder/'+id;
    return this.http.get(url);
  }
  getOrderById1(id): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase/'+id;
    return this.http.get(url);
  }

  addEnaPurchaseOrder(data): Observable<any> {
    const url = this.baseURL + 'enaPurchaseOrder';
    return this.http.post(url, data);
  }

  updateenadetails(data): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase/acceptance/updateSpiritPurchaseDetails';
    return this.http.post(url, data);
  }

 
  downloadenavalue(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  
  getOrderById3(id): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase/acceptance/'+id;
    return this.http.get(url);
  }

  getStatusMasters() {
   const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
   return this.http.get(url)
  }
  getDistilleryById(id): Observable<any> {
      const url = this.baseURL + 'enaPurchaseOrder/' + id;
      //const url ='http://65.0.150.210:3501/scmProduction/enaPurchaseOrder/' + id;
      return this.http.get(url);
  }
  

  getDistilleryById1(id): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase/' + id;
    //const url ='http://65.0.150.210:3501/scmProduction/enaPurchaseOrder/' + id;
    return this.http.get(url);
}
  addDistillery(postdata): Observable<any> {
      const url = this.baseURL + 'enaPurchaseOrder';
     //const url ="http://65.0.150.210:3501/scmProduction/enaPurchaseOrder";
     
      return this.http.post(url, postdata);
  }
  addDistillery1(postdata): Observable<any> {
    const url = this.baseURL + 'distillerySpiritPurchase';
   //const url ="http://65.0.150.210:3501/scmProduction/enaPurchaseOrder";
   
    return this.http.post(url, postdata);
}

getPd33UserIdList():Observable<any>{
  const url = this.baseURL + 'getCommonConfigDetail?entityType=PD&type=PD33_USER_LIST';
  return this.http.get(url);
}
validityExtension(payload):Observable<any>{
  const url = this.baseURL + 'distillerySpiritPurchase/sub/updateValidityExtension';
  return this.http.post(url, payload);
}
  searchDistillery(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.auditplanSearch;
      return this.http.post(url, postdata);
  }
  getapplicationnumbers(data): Observable<any> {
      const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
      return this.http.post(url, data);
  }
  
  findDropDownMasterOnMaster(key): Observable<any> {
      const url = this.masterURL + apiUrls.findDropDownMasterOnMaster + '?dropDownKey=' + key;
      return this.http.get(url);
  }
  getDistrictsByState() {
    const url = this.masterURL + "location/findDistrictByStateCode?stateCode=9";
    return this.http.get(url);
  }
  getdetails(): Observable<any> {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    return this.http.get(url);
  }

  getquantityvalue(payload) {//work
    //const url ="http://65.0.150.210:3501/scmProduction/enaPurchaseOrder/getInstalledQty";
    const url = this.baseURL + 'enaPurchaseOrder/getInstalledQty';
    return this.http.post(url,payload);
  }
 

  //
  getSellerLicenseDetails(subCategory): Observable<any> {//work
    //const url = this.licenseURL + 'licensemanagement/license/findLicenseNumberBySubCategory?subCategory='+subCategory +'&isUserId=false';// + subCategory;
    const url = this.licenseURL + 'license/findLicenseNumberBySubCategory?subCategory='+subCategory +'&isUserId=false';
    return this.http.get(url);
  }
 

  getLicdetails(category, licanceNo): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category + '?licenseNumber=' + licanceNo;
    return this.http.get(url);
  }

  getUnitName(subCategory): Observable<any> {
    const url = this.licenseURL + 'license/category/DISTILLERY?subCategory=' + subCategory + '&optionalCategory=DISTILLERY';
    return this.http.get(url);
  }
  getSellerUnitNameAsync(subCategory): any {
    const url = this.licenseURL + 'license/category/DISTILLERY?subCategory=' + subCategory + '&optionalCategory=DISTILLERY';
    return this.http.get(url).toPromise();
  }

  postDownload(postdata): any {
    const url = environment.documentUrl + 'api/document/downloadfiles';
    return this.http.post(url, postdata).toPromise();
  }
  search3(payload): Observable<any> {
    const url = this.baseURL +'spiritImportPermit/lazySearch';
    return this.http.post(url, payload);
}
getSpiritImportCount1(entityType): Observable<any> {
  const url = this.baseURL + 'distillerySpiritPurchase/getSpiritPurchaseOrderRequestCount?entityType=' + entityType;
  return this.http.get(url);
}
getalldashboardvalue(payload): any{
  const url = this.baseURL + 'getDashBoardModuleCardCount';
  return this.http.post(url, payload).toPromise(); 
}
showdashboardforofficerservice(): Observable<any> {
  const url = this.usermangmentBaseUrl + 'designation/isDashBoardAccess';
  return this.http.get(url); 
}
getRadarDetails(vatCode,requestId,unitCode): Observable<any>{
  const url = this.baseURL + 'tankRaidorBasedLog/getRedorDetail?vatCode=' + vatCode +
  '&requestId=' +requestId + '&unitCode=' +unitCode
  return this.http.get(url);
}
getRequestId(key,vatCode,upordown): Observable<any>{
  const url = this.baseURL + 'tankRaidorBasedLog/getDropDown?type=' + key
  +'&tankRedorVatCode=' +vatCode +'&upDown=' +upordown
  return this.http.get(url);
}
 
}