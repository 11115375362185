import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class VesselToUniTankService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addetails(data): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/lazySearch';
    return this.http.post(url, payload);
  }
  search1(payload?): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/batchNumbers?entityType=BREWERY';
    return this.http.get(url);
  }
  search12(payload?,licensenumber?): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/batchNumbers?entityType=BREWERY&licenseNumber='+licensenumber;
    return this.http.get(url);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getbrewNo(category): Observable<any> {
    const url = this.baseURL + 'brewingBook/approved/brewNo?brewPlanNo=' + category;
    return this.http.get(url);
  }
  getUnitankList(data): Observable<any> {
    const url = this.baseURL + 'usageTank/dropDown';
    return this.http.post(url, data);
  }
  getUnitankList1(data): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  getUnitankListForVessel(data): Observable<any> {
    const url = this.baseURL + 'VesselToUniTank/dropDown';
    return this.http.post(url, data);
  }
  getUnitankListForVessel1(data): Observable<any> {
    const url = this.baseURL + 'VesselToUniTank/unitCode/dropDown';
    return this.http.post(url, data);
  }

  getB4UnitankList(data): Observable<any> {
    const url = this.baseURL + 'vesselTank/b4List/dropDown';
    return this.http.post(url, data);
  }
  getB4UnitankList1(data): Observable<any> {
    const url = this.baseURL + 'vesselTank/b4List/dropDownByUnitCode';
    return this.http.post(url, data);
  }
 

  getBrandValue(data) {
    const url = this.baseURL + 'findBrandDetails/dropDown';
    return this.http.post(url, data);
  }
  getBrandValueLaySearch(data) {
    const url = this.baseURL + 'brandLabel/brandLabelLazySearch';
    return this.http.post(url, data);
  }
  getbatchno(data): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/generateBatchNumber?unitName=' + data;
    return this.http.get(url);
  }
  unitankValue(data) {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }
  getBrewNoCount(planNo) {
    const url = this.baseURL + 'brewingBook/getApprovedBrewNumberCount?brewPlanNo=' + planNo;
    return this.http.get(url);
  }
  getStatusCount(entityType?, createdBy?): Observable<any> {
    const url = this.baseURL + 'brewVesselToUnitank/getStatusCount?entityType=' + entityType +'&createdBy=' + createdBy;
    return this.http.get(url);
  }
  download(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  
  getBreweryCardCount(payload): Observable<any> {
    const url = this.baseURL + 'brewery/getStatusCount'
    return this.http.post(url,payload);
  }
}
