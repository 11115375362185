import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class AdvanceDutyService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'AdvanceDutyRegister';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'AdvanceDutyRegister/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'AdvanceDutyRegister/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  
  
  
  approveStock(postdata): Observable<any> {
    const url = this.baseURL + 'AdvanceDutyRegister/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  updateStock(postdata): Observable<any> {
    const url = this.baseURL + 'AdvanceDutyRegister/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
}
