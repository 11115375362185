import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
  providedIn: 'root'
})

export class GatepassCancellationService {
  baseURL = environment.parentUnit;
  masterURL = environment.devisionUrl;

  constructor(private http: HttpClient) { }

  gatepssCancellationSearch(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.gatepssCancellationSearch;
    return this.http.post(url, postdata);
  }

  getGatePassTypeMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=SM_Gate_Pass";
    return this.http.get(url)
  }

  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, data);
  }

  addGPCancellation(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addGPCancellation;
    return this.http.post(url, postdata);
  }

  getGPCById(id): Observable<any> {
    const url = this.baseURL + apiUrls.gpcGetById + id;
    return this.http.get(url);
  }

  scanGatePassNo(gpNo): Observable<any> {
    const url = this.baseURL + 'sugarmillgatepasscancellation/gatePassNoSearch?gatePassNo=' + gpNo;
    return this.http.get(url);
  }
  getTpCancelStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'sugarmillgatepasscancellation/getTpCancelStatusCount?entityType=' +entityType;
    return this.http.get(url);
  }
  getTpCancelStatusCounts(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'sugarmillgatepasscancellation/getTpCancelStatusCount?entityType=' + entityType
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  downloadgate(payload){
     const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
}
