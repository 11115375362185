import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class Mf5Part1Service {

  baseURL = environment.parentUnit;
  constructor(private http: HttpClient) { }

  getMf5PartOne(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllMF5Part1Register;
    return this.http.get(url);
  }

  getMf5PartOneById(id): Observable<any> {
    const url = this.baseURL + apiUrls.getMF5Part1RegisterById + id;
    return this.http.get(url);
  }

  addMf5PartOne(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addMf5PartOne;
    return this.http.post(url, postdata);
  }

  updateMf5PartOne(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.updateMf5PartOne;
    return this.http.put(url, postdata);
  }

  searchMf5PartOne(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mf5Part1Search;
    // const url ='http://localhost:3501/scmProduction/mf5Part1/lazySearch'
    return this.http.post(url, postdata);
  }

  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }

  getPrintData(): Observable<any> {
    const url = this.baseURL + apiUrls.transportGenPrint;
    return this.http.get(url, { responseType: 'blob' });
  }

  getStockInDetail(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getStockInDetail;
    return this.http.post(url, postdata);
  }

  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= '+ data;
    return this.http.post(url, data);
  }

  getCurrentFortnightPeriod(): Observable<any> {
    const url = this.baseURL + apiUrls.getCurrentFortnightPeriod;
    return this.http.get(url);
  }
  getFortnightBwPeriod(data): Observable<any> {
    const url = this.baseURL + 'mf5Part1/getFortnightPeriod';
    return this.http.post(url, data);
  }
  gettStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'mf5Part1/getMf5Part1StatusCount?entityType=' + entityType;
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }

  getStatusCountPD(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    //  const url ='http://localhost:3501/scmProduction/mf5Part1/getMf5Part1StatusCount?entityType='
    const url = this.baseURL + 'mf5Part1/getMf5Part1StatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
}
