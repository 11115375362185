<button class="btn bg-light" *ngIf="appConfigKeyFlag" style="margin-top: 12px;" (click)="navigateByMis()">MIS</button>
<ul class="navbar-nav ml-auto">
  <li *ngIf="licenseFlag">
    <select class="form-control" style="width: 200px;" (change)="setLicense($event.target.value)">
      <option value="">Select licence</option>
      <option *ngFor="let item of arrLicDet" value="{{item.licenseNumber}}"
        [selected]="item.licenseNumber == licenseNumber">
        {{item.licenseNumber}}
      </option>
    </select>
  </li>
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" class="nofi-bell" (click)="findTop5Notification()">
        <i class="icon feather icon-bell"></i>
        <div class="noti-bell-bg" *ngIf="newMsgCount > 0">
          <p class="text-center">{{newMsgCount}}</p>
        </div>
      </a>
      <!-- createdDate: 1625562205000 -->
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
        </div>
        <ul class="noti-body">
          <ng-container>
            <li class="notification border-bottom" *ngFor="let notification of notificationEarlierList; index as i">
              <div class="media">
                <div class="media-body">
                  <p [class.unread-notification]="!notification.isRead">
                    <strong>{{notification?.title}}</strong>
                    <span class="n-time text-muted"><i
                        class="icon feather icon-clock m-r-10"></i>{{notification?.createdDate | timeAgo}}</span>
                  </p>
                  <p>{{notification?.body}}</p>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
        <div class="noti-footer">
          <a style="cursor: pointer;" (click)="changeIsAllRead()">show all</a>
        </div>
      </div>
    </div>
  </li>
  <app-select-language></app-select-language>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">
          <span>{{username}}</span>
        </div>
        <ul class="last-login">
          <li><span class="text-muted">Last Login</span><br /><i class="feather icon-clock mr-2"></i>{{this.dateTime}}
          </li>
        </ul>
        <ul class="pro-body py-0">
          <hr class="mb-0">
          <li class="m-0" *ngIf="isCustomer"><a href="javascript:" routerLink="{{profileUrl}}" class="dropdown-item"><i
                class="feather icon-user"></i> Profile</a></li>
          <li class="m-0" *ngIf="!isCustomer"><a href="javascript:" routerLink="{{departmentUserProfileUrl}}"
              class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
          <li class="m-0"><a href="javascript:" routerLink="{{resetPasswordUrl}}" class="dropdown-item"><i
                class="fa fa-key"></i> Change Password</a></li>
          <li class="m-0"><a href="javascript:" class="dropdown-item" data-toggle="modal" (click)="onLogoutClick()"><i
                class="feather icon-log-out"></i> Logout</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>

<app-ui-modal #loginOutPopup dialogClass="modal-dialog-centered">
  <div class="app-modal-header">
    <h5 class="modal-title">Confirmation</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="onLogoutPopClose()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <h6 class="text-secondary text-center">Are you sure you want to logout?</h6>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-primary" (click)="onLogout()">Yes</button>
    <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" (click)="onLogoutPopClose()">No</button>
  </div>
</app-ui-modal>