import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
  providedIn: 'root'
})

export class Mf7FortnightlyReportService {

  baseURL = environment.parentUnit;

  constructor(private http: HttpClient) { }

  getFortnightlyById(id): Observable<any> {
    const url = this.baseURL + apiUrls.mf7GetById + id;
    return this.http.get(url);
  }
  getAllTanks(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllTanks;
    return this.http.get(url);
  }
  getTankDetailsById(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getTankDetailsById;
    return this.http.post(url, postdata);
  }
  getTankDetailsByTankId(postdata): Observable<any> {
    const url = this.baseURL + 'mf7Sugarmill/getMstDetailsByTankId';
    return this.http.post(url, postdata);
  }
  getTanks(postdata): Observable<any> {
    const url = this.baseURL + 'mf9fortnight/getDetailsByDate';
    return this.http.post(url, postdata);
  }
  addFortnightly(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addMf7;
    return this.http.post(url, postdata);
  }
  searchFortnightly(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.mf7Search;
    // const url = 'http://localhost:3501/scmProduction/mf7Sugarmill/lazySearch'
    return this.http.post(url, postdata);
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, data);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }
  getSugarMillCardCount(payload): Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount'
    return this.http.post(url,payload);
  }
  // getStatusCount(entityType?): Observable<any> {
  //   const url = this.baseURL + 'mf7Sugarmill/getMf7SugarmillStatusCount?entityType=' + entityType;
  //   return this.http.get(url);
  // }
  getStatusCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'mf7Sugarmill/getMf7SugarmillStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    // const url = 'http://localhost:3501/scmProduction/mf7Sugarmill/getMf7SugarmillStatusCount?entityType=' + entityType 
    // +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    // +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  getFortnightReport(postdata): Observable<any> {
    const url = this.baseURL + 'mf9fortnight/getFortNightReport';
    return this.http.post(url, postdata);
  }
  getStockInTankList(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getStockInTankList;
    return this.http.post(url, postdata);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
}
