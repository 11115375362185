import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import { PdCommonService } from '../pd';

@Injectable({
  providedIn: 'root'
})
export class YeastprocessService {

  baseURL = environment.distilleryBaseURL;
  authToken: any;
  parentunitURL = environment.parentUnit;
  baseURLLM = environment.licencemanagementIPURL;
  masterBaseURL = environment.baseURLL;
  constructor(
    private http: HttpClient,
    private pdCommonService: PdCommonService
  ) {
    const jsonStr = atob(environment.info.substring(5));
    const strParse = JSON.parse(jsonStr);
    this.authToken = strParse.authToken;
  }


  searchpd8data(data): Observable<any> {
    const url = this.baseURL + apiUrls.pd8lazySearch;
    return this.http.post(url, data);
  }
  getvessalbyotplanid(id): Observable<any> {
    const url = this.baseURL + apiUrls.otplanbyyeastvesal + id;
    return this.http.get(url);
  }
  addpd8data(data): Observable<any> {
    const url = this.baseURL + apiUrls.addproductionPD8;
    return this.http.post(url, data);
  }
  updatepd8data(data): Observable<any> {
    const url = this.baseURL + apiUrls.updateProductionPD8;
    return this.http.put(url, data);
  }
  approvalpd8(data): Observable<any> {
    // let data =''
    const url = this.baseURL + apiUrls.approvalProductionPD8 + data;
    return this.http.put(url, data);
  }

  createdata(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.yeastcreate;
    return this.http.post(url, data);
  }

  updateyeastdata(data): Observable<any> {
    const url = this.baseURL + apiUrls.yeastupdate;
    return this.http.put(url, data);
  }

  getotnumber(): Observable<any> {
    const url = this.baseURL + apiUrls.otnumber;
    return this.http.get(url);
  }
  getyeastveesel(id): Observable<any> {
    const url = this.baseURL + apiUrls.VesselToUniTank + id;
    return this.http.get(url);
  }
  getyeastveeselList(): Observable<any> {
    const url = this.parentunitURL + apiUrls.VesselToUniTank;
    return this.http.get(url);
  }
  gettanksbyid(id) {
    const ids = `?gradeId=${id}`
    const url = this.baseURL + `${apiUrls.gettanksbyid}${ids}`;
    return this.http.get(url);
  }


  yeastprocesslist(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.yeastprocesslist;
    return this.http.post(url, data);
  }
  getyeaseldata(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.viewyeastprocess + id;
    return this.http.get(url);
  }

  getyeastveesels(payload): Observable<any> {
    const url = this.parentunitURL + apiUrls.yeastvesslesDropdown;
    return this.http.post(url, payload);
  }
  otTransferTanks(payload): Observable<any> {
    const url = this.parentunitURL + apiUrls.otTransferTanks;
    return this.http.get(url, { params: payload });
  }
  postyeastveesels(payload): Observable<any> {
    const url = this.parentunitURL + 'yeastProcess/all';
    return this.http.post(url, payload);
  }
  getTanks(payload): Observable<any> {
    const url = this.parentunitURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, payload);

  }
//  getOtYeastProcessCount(entityType?): Observable<any> {
//    const url = this.baseURL +'yeastProcess/getOtYeastProcessCount?entityType=' + entityType;
//    return this.http.get(url);
// }
}