import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccidentRequestService, AlertService, DenatureprocessService, SugarmillService } from '@app/services';
import { PdCommonService } from '@app/services/pd';
import { RequestForEthanolOrderForOmdService } from '@app/services/pd/request-for-ethanol-order-for-omd.service';
import { LocalUrl } from '@app/app-local-url';

@Component({
  selector: 'app-paymentscreen',
  templateUrl: './paymentscreen.component.html',
  styleUrls: ['./paymentscreen.component.scss']
})
export class PaymentscreenComponent implements OnInit {

  editId: any;
  accidentRqstObj: any;
  applicationNumber: any;
  paymentData: any;
  advanceDuty: any;
  licensedetail: any;
  findPay: any;
  isPaymentLoading = false;
  isBrandBalanceSufficient = false;
  isBrandPay = false;
  resultR: any;
  applicationNo: any;
  multipleAddList: any;
  saveUpdateRes: any;
  BrandR: { transactionNumber: string; };
  isBrandPayment = false;
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any;
  licenseDetailList: any[] = [];
  licensenodata: any;
  actualLicensedetail: any;
  outsideupvalidation: string;

  constructor(private route: ActivatedRoute,
    private accidentRqstService: AccidentRequestService,
    private requestForEthanolOrderForOmdService: RequestForEthanolOrderForOmdService,
    private pdCommonService: PdCommonService,
    private alert: AlertService,
    private blendTransferReqService: DenatureprocessService,
    private router: Router,
    private brandlabelregService: SugarmillService) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
 
    const subUrlManage = currentUrlArray[1] + currentUrlArray[2];
    this.outsideupvalidation = currentUrlArray[2]
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.route.params.subscribe(params => {
      console.log(params, '..params..');
      if (params.Id) {
        this.editId = params.Id;
        this.setData(this.editId);
      }
    });
  }
  setData(id?: any) {
    if (id) {
      this.accidentRqstService.getAccidentRqstById(id).subscribe((resp: any) => {
        this.accidentRqstObj = resp.data;
        this.applicationNo = this.accidentRqstObj.applicationNumber;
        this.brandlabelregService.getdetails().subscribe((respData: any) => {
          this.actualLicensedetail = respData.content[0];
        const payload1 = {
          "filters": {
            "entity": this.actualLicensedetail.licenseCategory,
            "licenseType": this.actualLicensedetail.licenseSubCategory,
            "moduleKey": "MMC002",
            "domainKey": this.outsideupvalidation == 'accidental-request-sugarmill-outsideup' ? 'D002' : 'D001',
            "supplyKey": "LSC005",
            "liquorTypeKey": "LT001",
            "screenName": "penaltyonaccidentalcases"
          }
        }
        this.requestForEthanolOrderForOmdService.getPaymentDetail(payload1).subscribe(async res => {
          this.paymentData = res.data.contents[0]
        console.log(this.paymentData)
        const payload = {
          "adList": [
            {
              "treasuryPaymentHead": this.paymentData.treasuryHead,
              "licenseNumber": this.accidentRqstObj.licenseNumber,
              "type": "UGL",
              "userId": this.accidentRqstObj.createdBy,
              "param1": "ADVANCE DUTY",
              "unitCode": this.actualLicensedetail.unitCode,
              "g6": this.paymentData.g6,
            }
          ]

        }
        this.requestForEthanolOrderForOmdService.getAdvance(payload).subscribe((resp: any) => {
          this.advanceDuty = resp.data[0].amount;
        })
        this.advanceDuty = this.accidentRqstObj.advanceDutyResponseList[0];
        this.accidentRqstService.findpaymentdetails(this.accidentRqstObj.applicationNumber,'PROCESSING_FEE').subscribe((resp: any) => {
          if (resp.errorCode == 200) {
            this.findPay = resp.data;
          //   this.findPaymentObj = resp.data.spiritImportPermitPaymentLogList.find((resp: any) => resp.type === "DENATURATION_PROCESS");
          //   if (this.findPaymentObj.status == "SUCCESS") {
          //     this.isBrandPayment = true;
          //   }
          // }
          /// check payment 
        //   if (this.data.g6Code) {
        //     if (!this.isBrandPayment) {
        //       if (this.data.advanceDutyResponseList) {
        //         this.brandBlance = this.data.advanceDutyResponseList.find((resp: any) => resp.param1 === 'DENATURATION_PROCESS') ? this.data.advanceDutyResponseList.find((resp: any) => resp.param1 === 'DENATURATION_PROCESS').amount : 0;
  
        //         // check Sufficient balance 
        //         if ((this.brandBlance < this.brandFees || this.brandBlance <= 0 || this.isBrandPayment)) {
        //           this.isBrandBalanceSufficient = true;
        //         }
  
        //         if (this.brandBlance <= 0 || this.isBrandPayment) {
        //           this.isBrandPay = true;
        //         }
        //       }
        //     }
        //     else {
        //       this.alert.showSuccess("Payment already done");
        //       this.router.navigate(['/' + this.url + '/otprocess/denatureprocess/acknowledgement/', this.id]);
        //     }
          }
        })
      })
    });
  })
  }
}
  toggleLoading(saveType) {
    switch (saveType) {
      case 'INITIATE':

        this.isPaymentLoading = !this.isPaymentLoading;
        break;
    }
  }
  async saveUpdatePaymentDetails() {
    if (this.isBrandBalanceSufficient) {
      this.alert.showError("Insufficient wallet balance");
      return false;
    }

    if (this.advanceDuty <= 0) {
      this.alert.showError("Please check Payment Fee Configuration. Unable to proceed with the transaction");
    }

    let newPayload = {
      "adLicense": []
    }
    let responceData = await this.pdCommonService.getdetails();//.subscribe((responceData: any) => {
      console.log(this.accidentRqstObj.licenseNumber)
      this.licensedetail = responceData.content.filter(x=>
        x.licenseNumber == this.accidentRqstObj.licenseNumber)
    if (!this.isBrandPay) {
      newPayload.adLicense.push({
        "userId": localStorage.getItem('userId'),
        "amount": this.accidentRqstObj.fees,
        "applicationNumber": this.applicationNo,
        "treasuryHead": this.paymentData.treasuryHead,
        "g6": this.paymentData.g6,
        "licenseNumber": this.actualLicensedetail.licenseNumber,
        "param1": "ACCIDENTAL_REQUEST",
        "paymentType": "MPMC010",
        unitCode: this.actualLicensedetail.unitCode,
      })
    }
    this.toggleLoading('INITIATE');
    this.blendTransferReqService.licenceAdd(newPayload).subscribe((resp: any) => {
      this.resultR = resp.data;
      this.multipleAddList = this.resultR;

      if (resp.errorCode == 500) {
        this.alert.showError(resp.userDisplayMesg);
        this.toggleLoading('INITIATE');
        return false;
      }


      this.BrandR = { transactionNumber: '' };
      if (!this.isBrandPay) {
        this.BrandR = this.resultR.find((resp: any) => resp.param1 === 'ACCIDENTAL_REQUEST');

      }

      var mainStatus = '';

      mainStatus = 'SUCCESS';
      if (this.BrandR) {
        mainStatus = 'INITIATE';
      } else {
        mainStatus = 'FAILED';
      }
      let payload = 
      {
        "applicationNumber": this.applicationNo,
        "bankBranch": "BANGALORE",
        "bankName": "SBI",
        "createdBy": 0,
        "createdDate": new Date().toISOString(),
        "feeType": "PROCESSING_FEE",
        "id": 0,
        "ifscCode": "SBI435342",
        "initiatePaymentTime": null,
        "licenseNumber": this.actualLicensedetail.licenseNumber,
        "modeOfPayment": 'ADVANCE DUTY',
        "modifiedBy": 0,
        "modifiedDate": new Date().toISOString(),
        "paymentAmount": this.accidentRqstObj.fees,
        "paymentInstrument": "string",
        "responsePaymentTime": null,
        "status": '',
        "isFeePaid": true,
        "sytemChallanNumber": "CHA-111",
        "transactionNumber": '',
        "g6Name": this.paymentData.g6Name,
        "treasuryHeadName": this.paymentData.treasuryHeadName,
        "type": "ACCIDENTAL_REQUEST",
      }
      if (this.BrandR) {
        if (!this.isBrandPayment) {
          payload.status = "SUCCESS";
          payload.transactionNumber = this.BrandR.transactionNumber ? this.BrandR.transactionNumber : "";
        }
      }
      else {
        payload.status = "FAILED";
        payload.transactionNumber = ''
      }

      this.toggleLoading('INITIATE')

      this.accidentRqstService.saveupdatepaymentdetails(payload).subscribe((resp: any) => {
        this.saveUpdateRes = resp.data;
        this.router.navigate([this.url + '/'+this.outsideupvalidation+'/aknowledgement/' + this.editId]);
        // this.router.navigate(['pd/tank/conversion/acknowledge/', this.viewId]);
      });
    });
  }
}
