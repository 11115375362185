import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalUrl } from '@app/app-local-url';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrandlabelbrewService, PdCommonService } from '@app/services/pd';
import { AlertService, DistileryService, DropdownConfigService, WorkflowcofigService } from '@app/services';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-add-omd-stock-in',
  templateUrl: './add-omd-stock-in.component.html',
  styleUrls: ['./add-omd-stock-in.component.scss']
})
export class AddOmdStockInComponent implements OnInit {
  workflowSubModule: any;
  moduleName: any;
  entityType: any;
  registrationType: any;
  url: any;
  designation: string;
  isCustomer: boolean;
  addedit: string;

  omdStockInForm: FormGroup;
  licenseTypes: any=[];
  stockActivityData: any;
  licenseDetails: any;
  pd25Details: any=[];
  distilleryDetailsPd25: any =[];
  spiritNameList: any =[];
  formSubmitted = false;
  applicationNumber: any;
  workflowdata: any;
  spirityType: any;
  editId: any;
  editData: any;
  isSendback: boolean;
  isDrafthide: any = true;
  purchasetype: any;
  selectedItems2=[];
  pd25PassDropDownSettings:IDropdownSettings;

  constructor( private router: Router,
    private formBuilder: FormBuilder,
    private brandlabelbrewService: BrandlabelbrewService,
    private dropDownService: DropdownConfigService,
    private distilleryservice: DistileryService,
    private datepipe: DatePipe,
    private workflowservice: WorkflowcofigService,
    private alert: AlertService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const subUrlManage = currentUrlArray[1] + currentUrlArray[3];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.addedit = this.router.url.includes('add') == true ? 'Add' : 'Edit';

    this.omdStockInForm = this.formBuilder.group({
      dateOfApplication:[''],
      timeOfApplication:[''],
      licenseNumber:['',Validators.required],
      licenseName:[''],
      licenseAddress:[''],
      stockActivityTypeCode:['',Validators.required],
      purchaseTypeCode:['',Validators.required],
      omdPd25PassNumber:['',Validators.required],
      distilleryName:[''],
      distilleryLicenseType:[''],
      distilleryLicenseNumber:[''],
      distilleryAddress:[''],
      rawMaterialTypeCode:['',Validators.required],
      spiritNameCode:[''],
      spiritNameValue:['',Validators.required],
      strength :['',Validators.required],
      issuedQuantityBl :['',Validators.required],
      issuedQuantityAl :['',Validators.required],
      receivedQuantityBl :['',Validators.required],
      receivedQuantityAl :['',Validators.required],
      wastageBl :['',Validators.required],
      wastageAl :['',Validators.required],
      remarks:['',Validators.required],
      comments: ['']
    });
    this.route.params.subscribe(params => {
      if (params.id) {
        this.editId = params.id;
        this.setData(this.editId);
      }
    });
    this.getStockActivityData();
    this.getLicenceDetails();
    this.getIndentRequest();
    this.getSpiritTypeData();
    this.getPurchaseType();
    this.getSpiritName()
    
  this.pd25PassDropDownSettings = {
    singleSelection: true,
    idField: 'applicationNumber',
    textField: 'applicationNumber',
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };
  }
  setData(id){
    setTimeout(() => {
      this.distilleryservice.getStockinOmd(id).subscribe((responceData: any) => {
        if (responceData.status === 's') {
         this.editData = responceData.data;
         if (this.editData.status == 'SENDBACK') {
          this.omdStockInForm.controls['comments'].setValidators(Validators.required);
          this.isSendback = true;
          this.isDrafthide = false;
        }
        setTimeout(() => {
        this.getLicenseDetails(this.editData.licenseNumber);
        // this.getDistilleryDetails(this.editData.omdPd25PassNumber)
        this.getSpiritName()
        },1000);
          this.omdStockInForm.patchValue({ 
            licenseNumber: this.editData.licenseNumber,
            stockActivityTypeCode: this.editData.stockActivityTypeCode,
            purchaseTypeCode: this.editData.purchaseTypeCode,
            omdPd25PassNumber: this.editData.omdPd25PassNumber,
            distilleryName: this.editData.distilleryName,
            distilleryLicenseType: this.editData.distilleryLicenseType,
            distilleryLicenseNumber:this.editData.distilleryLicenseNumber,
            distilleryAddress:this.editData.distilleryAddress,
            rawMaterialTypeCode:this.editData.omdStockInRawMaterialDetails.rawMaterialTypeCode,
            spiritNameCode:this.editData.omdStockInRawMaterialDetails.spiritNameCode,
            spiritNameValue:this.editData.omdStockInRawMaterialDetails.spiritNameValue,
            strength:this.editData.omdStockInRawMaterialDetails.strength,
            issuedQuantityBl:this.editData.omdStockInRawMaterialDetails.issuedQuantityBl,
            issuedQuantityAl:this.editData.omdStockInRawMaterialDetails.issuedQuantityAl,
            receivedQuantityBl:this.editData.omdStockInRawMaterialDetails.receivedQuantityBl,
            receivedQuantityAl:this.editData.omdStockInRawMaterialDetails.receivedQuantityAl,
            wastageBl:this.editData.omdStockInRawMaterialDetails.wastageBl,
            wastageAl:this.editData.omdStockInRawMaterialDetails.wastageAl,
            remarks: this.editData.remarks
          });
          this.selectedItems2.push({ applicationNumber: this.editData.omdPd25PassNumber});
        }
     });
    },400);
  }
  getPurchaseType(){
    this.dropDownService.getDropdownByKey('PURCHASE_REQUEST_TYPE')
    .subscribe((resp: any) => {
      if (resp.errorCode === 200) {
        this.purchasetype = resp.data.downDropDownMasterValueList.filter(res=>res.paramKey == 'OMD_PURCHASE');
      }
    });
  }
  async getLicenceDetails() {
    this.omdStockInForm.patchValue({
      dateOfApplication : this.datepipe.transform(new Date(), 'dd-MM-yyyy'),
      timeOfApplication : this.datepipe.transform(new Date(), 'hh:mm a')
    })
    //let response = await this.brandlabelbrewService.getdetailsBottling()
    let response: any = await this.brandlabelbrewService.getOmdUserLicenese();
    // .subscribe((response: any) => {
    // this.licenseTypes = response.content
    response.content.forEach(element => {
        this.licenseTypes.push(element)
    });
 
    // })
  }
  getLicenseDetails(value){
    this.licenseDetails= this.licenseTypes.filter(res=>res.licenseNumber == value)
    this.omdStockInForm.patchValue({
      licenseName: this.licenseDetails[0].unitName,
      licenseAddress: this.licenseDetails[0].street +','+this.licenseDetails[0].tahsilDesc +
      ','+ this.licenseDetails[0].districtDesc + ',' +this.licenseDetails[0].stateDesc + 
      ',' +this.licenseDetails[0].countryDesc
    })
  }
  getStockActivityData() {
    this.dropDownService.getDropdownByKey('STOCK_ACTIVITY_PD')
      .subscribe((resp: any) => {
        if (resp.errorCode === 200) {
          this.stockActivityData = resp.data.downDropDownMasterValueList.filter(res=>res.paramKey == 'SAPD01');
        }
      });
  }

 getIndentRequest() {
    const payload :any = {
      searchInput: {
        status:'APPROVED',
        profileType: "OMD",
        entityType:this.entityType
      },
      "page": 0,
      "pageSize":5, 
    };
     this.distilleryservice.getPD25(payload).subscribe((responceData: any) => {
      console.log(responceData)
      this.pd25Details =  responceData.data.contents
    });
  }
  onFilterGetIndentRequest(){
    const payload :any = {
      searchInput: {
        status:'APPROVED',
        profileType: "OMD",
        entityType:this.entityType
      },
      "page": 0,
      "pageSize":0, 
    };
     this.distilleryservice.getPD25(payload).subscribe((responceData: any) => {
      console.log(responceData)
      this.pd25Details =  responceData.data.contents
    });
  }
  onDropDownCloseBrandFilter() {  }
  getDistilleryDetails(value){
    console.log(value)
    this.distilleryDetailsPd25 =  this.pd25Details.filter(res=>res.applicationNumber == value.applicationNumber)
    let a=this.distilleryDetailsPd25[0].pd26.omdIndentRequest
    this.omdStockInForm.patchValue({
      distilleryName : this.distilleryDetailsPd25[0].unitName,
      distilleryLicenseType:this.distilleryDetailsPd25[0].licenseType,
      distilleryLicenseNumber:this.distilleryDetailsPd25[0].licenseNumber,
      distilleryAddress:this.distilleryDetailsPd25[0].pd26.omdIndentRequest.address,
      rawMaterialTypeCode:this.spirityType[0].paramKey,
      spiritNameCode:this.spiritNameList.find(res=>res.paramValue == this.distilleryDetailsPd25[0].pd26.omdIndentRequest.ethanolTypeCode).paramKey,
      spiritNameValue:this.distilleryDetailsPd25[0].pd26.omdIndentRequest.ethanolTypeCode,
      strength:this.distilleryDetailsPd25[0].pd26.strength,
      issuedQuantityBl:a.detailsEntityList[0].indentedQty
    })
    this.calculateIssueQtyAl();
  }
  getSpiritTypeData() {
    this.dropDownService.getDropdownByKey('RAW_MATERIAL_TYPE')
      .subscribe((resp: any) => {
        if (resp.errorCode === 200) {
          this.spirityType = resp.data.downDropDownMasterValueList.filter(res=>res.paramKey == 'RMT03');
        }
      });
  }
  getSpiritName() {
    this.dropDownService.getDropdownByKey('OMD_ETHANOL')
    .subscribe((resp: any) => {
      if (resp.errorCode === 200) {
        this.spiritNameList = resp.data.downDropDownMasterValueList;
      }
    });
  }
  getapplicationnumber() {
    let model = {
      licenseNumber: this.omdStockInForm.value.licenseno,
      registrationType: this.entityType + '_'
    }
    this.distilleryservice.getapplicationnumbers(model).subscribe((data: any) => {
      if (data.content) {
        this.applicationNumber = data.content.ApplicationNumber;
      }
    })
  }

  getWorkflowData(value) {
      this.workflowservice.getworkflowbydesignation(this.entityType, this.workflowSubModule).subscribe((resp: any) => {
        this.workflowdata = resp.content;
      })
  }
  save(form,status){
    if (status === 'SUBMITTED' && this.omdStockInForm.valid === false) {
      this.formSubmitted = true;
      return;
    } else {
      let payload = {
        // "transferType": "TANKER",
        "applicationNumber": this.applicationNumber,
        // "comments": "Submitted",
        "distilleryAddress": this.omdStockInForm.value.distilleryAddress,
        "distilleryLicenseNumber": this.omdStockInForm.value.distilleryLicenseNumber,
        "distilleryLicenseType": this.omdStockInForm.value.distilleryLicenseType,
        "distilleryName": this.omdStockInForm.value.distilleryName,
        "entityType": this.entityType,
        "event": "INITIATE",
        "level": this.workflowdata ? this.workflowdata.level : 'Level 1',
        "licenseNumber": this.omdStockInForm.value.licenseNumber,
        "licenseType": this.omdStockInForm.value.licenseType,
        "omdPd25PassNumber": this.omdStockInForm.value.omdPd25PassNumber[0].applicationNumber,
        "omdStockInRawMaterialDetails": {
          "id":'',
          "issuedQuantityAl": this.omdStockInForm.value.issuedQuantityAl,
          "issuedQuantityBl": this.omdStockInForm.value.issuedQuantityBl,
          "rawMaterialTypeCode": this.omdStockInForm.value.rawMaterialTypeCode,
          "rawMaterialTypeValue":this.spirityType.find(res=>res.paramKey == this.omdStockInForm.value.rawMaterialTypeCode).paramValue,
          "receivedQuantityAl": this.omdStockInForm.value.receivedQuantityAl,
          "receivedQuantityBl": this.omdStockInForm.value.receivedQuantityBl,
          "spiritNameCode": this.omdStockInForm.value.spiritNameCode,
          "spiritNameValue": this.omdStockInForm.value.spiritNameValue,
          "strength": this.omdStockInForm.value.strength,
          "wastageAl": this.omdStockInForm.value.wastageAl,
          "wastageBl": this.omdStockInForm.value.wastageBl,
        },
        "purchaseTypeCode": this.omdStockInForm.value.purchaseTypeCode,
        "purchaseTypeValue": this.purchasetype.find(res=>res.paramKey == this.omdStockInForm.value.purchaseTypeCode).paramValue,
        "remarks":  this.omdStockInForm.value.remarks,
        "status": status,
        "statusDesc": status,
        "stockActivityTypeCode": this.omdStockInForm.value.stockActivityTypeCode,
        "stockActivityTypeValue": this.stockActivityData.find(res=>res.paramKey == this.omdStockInForm.value.stockActivityTypeCode).paramValue,
        "unitName": this.omdStockInForm.value.licenseName,
      }
      if (this.editId) {
        payload['id'] = this.editId;
        payload['createdBy'] = this.editData.createdBy;
        payload['createdDate'] = this.editData.createdDate;
        payload['applicationNumber']= this.editData.applicationNumber;
        payload.omdStockInRawMaterialDetails.id= this.editData.omdStockInRawMaterialDetails.id;
        if (this.editData.status == 'SENDBACK') {
          payload['comments'] = this.omdStockInForm.value.comments;
        }
      }
      this.distilleryservice.saveStockinOmd(payload).subscribe((responceData: any) => {
        if (responceData['status'] === 's') {
          this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
          this.router.navigate(['/' + this.url + '/omd/omd-stock-in/list']);
        } else {
          this.alert.showError(responceData.userDisplayMesg);
        }
      });
    }
  }
  calculateIssueQtyAl(){
console.log(this.omdStockInForm.value.issuedQuantityBl)
this.omdStockInForm.patchValue({
  issuedQuantityAl: ((this.omdStockInForm.value.strength * this.omdStockInForm.value.issuedQuantityBl) /100).toFixed(2)
})
  }
  calculateReceivedQtyAl(value){
    if(value>(this.omdStockInForm.value.issuedQuantityBl-this.omdStockInForm.value.wastageBl)) {
      this.alert.showError("Enter a value less than Issued Qty (in BL)");
      this.omdStockInForm.patchValue({ receivedQuantityBl: '' });
    }
    this.omdStockInForm.patchValue({
      receivedQuantityAl: ((this.omdStockInForm.value.strength * this.omdStockInForm.value.receivedQuantityBl) /100).toFixed(2)
    })
  }
  calculateWastageAl(value){
    if(value>(this.omdStockInForm.value.issuedQuantityBl-this.omdStockInForm.value.receivedQuantityBl)){
      this.alert.showError("Enter The value less than Issued Qty (in BL)");
      this.omdStockInForm.patchValue({wastageBl:''})
    }
    this.omdStockInForm.patchValue({
      wastageAl: ((this.omdStockInForm.value.strength * this.omdStockInForm.value.receivedQuantityBl) /100).toFixed(2)
    })
  }
}
