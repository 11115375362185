<!-- <mdt-dynamic-table (click)="vote(false)" [pageSize]="5" [columns]="columns" [dataSource]="dataSource"></mdt-dynamic-table> -->

<div class="example-container mat-elevation-z8">

    <!-- <div class="example-loading-shade" *ngIf="isLoadingResults">
      <mat-spinner></mat-spinner>
    </div> -->
    <div *ngIf="isLoadingResults" [ngClass]="isLoadingResults ? 'parentDisable' : '' ">
        <div class='overlay-box'>
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="example-table-container">

        <table mat-table [dataSource]="dataSource" class="example-table" matSort>

            <ng-container *ngFor="let column of columns;" matColumnDef="{{column.name}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.displayName | translate }}</th>
                <td mat-cell
                    [ngStyle]="{'text-align': column.type == 'number' || column?.align == 'center'? 'center' : '' }"                   
                    *matCellDef="let row; let i = index;">
                    <span *ngIf="column.type == 'number'">
                        {{ ( this.currentPage > 0 ) ? ( ( this.currentPage * pageSize ) + ( i + 1 ) ) : ( i + 1 ) }}
                    </span>
                    <span *ngIf="column.type == 'date'">
                        {{ row[column.name] | date: column.dateFormat ? column.dateFormat : defDateFormat }}
                    </span>
                    <!-- [ngStyle]="{'text-align': column.type == 'string' || column?.align == 'right'? 'right': column?.align == 'right' ? 'right' : ''}" -->
                    <span *ngIf="column.type == 'string' && (column.name != 'status' && isProfile != 'true') && (column.name != 'active' && isProfileSoftwareAssets != 'true')">{{ row[column.name] }}</span>
                    <span *ngIf="column.type == 'string' && column.name == 'status' && isProfile != 'true'"
                           class="badge {{row[column.name] == true ? 'badge-success':'badge-danger' }}">
                           {{ row[column.name] === true || row[column.name] === 'true' ?  'Active' : 'InActive' }}
                    </span>
                    <span *ngIf="column.type == 'string' && column.name == 'active' && isProfileSoftwareAssets == true"
                           class="badge {{row[column.name] == true ? 'badge-success':'badge-danger' }}">
                           {{ row[column.name] === true || row[column.name] === 'true' ?  'Active' : 'InActive' }}
                    </span>
					<!--b4-form changes starts-->
                    <span *ngIf="column.type == 'stringPopup' && (column.name != 'status' && isProfile != 'true')
                    && column.isPopUp == 'b4'">
                        {{ row[column.name] }}  
                        
                        <button style="background-color: #4680ff; border-color: #4680ff; color: #fff;" matTooltip="Click to open pop - up" 
                        *ngIf="actionKeys.indexOf('popup') > -1"  (click)= "onClickData(row.id ? row.id : i, $event, row[column.name])">
                            <mat-icon aria-label="PopUp" >view_list</mat-icon>
                        </button>
                    </span>
                    <!--b4-form changes ends-->
                    <span *ngIf="column.type == 'objstring'">
                        {{ column.displayFcn(row) }}
                    </span>
                   
                    <ng-container *ngIf="categoryType === 'licenseConfig'; else otherType">
                         <span *ngIf="column.type == 'boolean'"
                            class="badge {{row[column.name] == 'true' ? 'badge-success':'badge-danger' }}">
                            {{
                                row[column.name] === 'true' ?
                                ( column.optionVal[0] ? column.optionVal[0] : optionVal[0] ) :
                                ( column.optionVal[1] ? column.optionVal[1] : optionVal[1] )
                                }}
                        </span>
                    </ng-container>

                    <ng-template #otherType>
                        <span *ngIf="column.type == 'boolean' && isApplicantActivate != true"
                            class="badge {{row[column.name] == true ? 'badge-success':'badge-danger' }}">
                            {{
                                row[column.name] ?
                                ( column.optionVal[0] ? column.optionVal[0] : optionVal[0] ) :
                                ( column.optionVal[1] ? column.optionVal[1] : optionVal[1] )
                            }}
                        </span>
                        <span *ngIf="column.type == 'boolean' && isApplicantActivate == true"
                            class="badge {{row[column.name] == false ? 'badge-success':'badge-danger' }}">
                            {{
                                row[column.name] ?
                                ( column.optionVal[0] ? column.optionVal[0] : optionVal[0] ) :
                                ( column.optionVal[1] ? column.optionVal[1] : optionVal[1] )
                            }}
                        </span>
                    </ng-template>
                    <span *ngIf="column.type == 'options' && row[column.name]"
                        class="badge {{ column.badgeColors[ row[column.name] ] ? column.badgeColors[ row[column.name] ] : 'badge-success' }}">
                        {{ row[column.name] }}
                    </span>
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="actions" *ngIf="actionKeys.length > 0">
                <th mat-header-cell *matHeaderCellDef>
                    <span *ngIf="actionName == 'Action'" >
                        {{ 'master.common.action' | translate }}
                    </span>
                    <span *ngIf="actionName != 'Action'">
                        {{actionName}}
                    </span>
                </th>
                <td mat-cell *matCellDef="let row;let i = index;" class="table-action-column text-right">
                    <ng-container *ngIf="actionKeys.indexOf('customButton') > -1">
                        <ng-container *ngFor="let item of customButtons;">
                            <button *ngIf="(item.condition ? checkCustomButton(row, item) : true)"
                                matTooltip="{{item?.tooltip}}"
                                (click)="onCustomActionsClicked(row.id ? row.id : i, $event, row, item)"
                                class="btn btn-{{item?.color}} iconbutton ml-2">
                                <ng-container *ngIf="item.icon">
                                    <mat-icon>{{item.icon}}</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="item.label">
                                    {{item.label}}
                                </ng-container>
                            </button>
                        </ng-container>
                    </ng-container>
                    <button matTooltip="Initiate" class="btn btn-info iconbutton ml-2" color="info"
                        *ngIf="actionKeys.indexOf('initiate') > -1" (click)="onSurrenderClicked(row.id ? row.id : i, $event)">
                        <mat-icon aria-label="View">remove_red_eye</mat-icon>
                    </button> 
                    <button matTooltip="Click to Renew" class="btn btn-primary iconbutton ml-2" color="primary"
                        *ngIf="actionKeys.indexOf('refresh') > -1" (click)="onRenewClicked(row.id ? row.id : i, $event)">
                        <mat-icon aria-label="View">refresh</mat-icon>
                    </button>
                    <button matTooltip="Click to View" class="btn btn-primary iconbutton ml-2" color="primary"
                        *ngIf="actionKeys.indexOf('view') > -1" (click)="onViewClicked(row.id ? row.id : i, $event, row)">
                        <mat-icon aria-label="View">remove_red_eye</mat-icon>
                    </button>
                    <button matTooltip="Click to View" class="btn btn-primary iconbutton ml-2" color="primary"
                    *ngIf="actionKeys.indexOf('viewcond') > -1 && (actionBtnBasedOnCond.viewcond ? checkviewCondButton( row[ actionBtnBasedOnCond.viewcond.column ] ) : showEditButton)"
                    (click)="onViewClicked(row.id ? row.id : i, $event, row)" >
                        <mat-icon aria-label="View">remove_red_eye</mat-icon>
                    </button>
                    <button matTooltip="Click to Edit" class="btn btn-secondary iconbutton ml-2" color="secondary"
                        *ngIf="actionKeys.indexOf('edit') > -1 && (actionBtnBasedOnCond.edit ? checkEditButton( row[ actionBtnBasedOnCond.edit.column ] ) : showEditButton)"
                        (click)="onEditClicked(row.id ? row.id : i, $event, row)">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                    </button>
                    <button matTooltip="Click to Add" class="btn btn-success iconbutton ml-2" color="secondary"
                        *ngIf="actionKeys.indexOf('add') > -1 && (actionBtnBasedOnCond.add ? checkEditButton( row[ actionBtnBasedOnCond.add.column ] ) : showEditButton)"
                        (click)="onEditClicked(row.id ? row.id : i, $event, row)">
                        <mat-icon aria-label="Add">add</mat-icon>
                    </button>
                    <button matTooltip="Click to Delete" class="btn btn-danger iconbutton ml-2" color="danger"
                        *ngIf="actionKeys.indexOf('delete') > -1"
                        (click)="onDeleteClicked(row.id ? row.id : i, $event, row)">
                        <mat-icon aria-label="Delete">delete</mat-icon>
                    </button>
                    <button matTooltip="Click to Download" class="btn btn-success iconbutton ml-2" color="success"
                        *ngIf="actionKeys.indexOf('download') > -1 && (actionBtnBasedOnCond.download ? checkDownloadButton( row[ actionBtnBasedOnCond.download.column ] ) : showDownloadButton)"
                        (click)="onDownloadClicked(row.id ? row.id : i, $event, row)">
                        <mat-icon aria-label="Download">get_app</mat-icon>
                    </button>
                    <button matTooltip="Click to Apply" class="btn btn-primary iconbutton ml-2" color="primary"
                        *ngIf="actionKeys.indexOf('apply') > -1" (click)="onApplyClicked(row.id ? row.id : i, $event)">
                        <span>Apply</span>
                    </button>

                    <input type="radio" class="checkbox d-inline" id="sameAddress"
                        (change)="onCheckboxChecked(row.id ? row.id : i, $event)" name="checkboxRowId"
                        *ngIf="actionKeys.indexOf('checkbox') > -1" />
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <!-- (click)="onRowClicked(row)" -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
    </div>

    <mat-paginator [length]="totalCount" [pageSize]="pageSize" [pageIndex]="currentPage"
        [pageSizeOptions]="pageSizeOptions" (page)="handlePagination($event)">
    </mat-paginator>
</div>