import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class RetailGroupMasterService {

  baseURL = environment.baseURLMaster;
  baseURLUser = environment.baseURLUser;

  constructor(private http: HttpClient) { }

  findRetailLicenseType(postParam): Observable<any> {
    const url = this.baseURLUser + apiUrls.getLicenseType + postParam;
    return this.http.get(url);
  }

  findRetailShopByPanCard(panCard, shopId): Observable<any> {
    const url = this.baseURL + apiUrls.findRetailShopByPanCard + '?panCard=' + panCard + '&shopId=' + shopId;
    return this.http.get(url);
  }

  findRetailShopList(postParam): Observable<any> {
    const url = this.baseURL + apiUrls.findRetailShopList;
    return this.http.post(url, postParam);
  }

  uploadShopMasterExcel(payload) {
    const url = this.baseURL + apiUrls.uploadShopMasterExcel;
    return this.http.post(url, payload).toPromise();
  }

  findAllByLoginUser(): Observable<any> {
    const url = this.baseURL + apiUrls.findAllByLoginUser;
    return this.http.get(url);
  }

  saveElotteryScheduler(payload): Observable<any> {
    const url = this.baseURL + apiUrls.saveElotteryScheduler;
    return this.http.post(url, payload);
  }

  updateElotteryScheduler(payload): Observable<any> {
    const url = this.baseURL + apiUrls.updateElotteryScheduler;
    return this.http.post(url, payload);
  }

  findById(id): Observable<any> {
    const url = this.baseURL + apiUrls.findById + '?id=' + id;
    return this.http.get(url);
  }

  findAll(): Observable<any> {
    const url = this.baseURL + apiUrls.findAll;
    return this.http.get(url);
  }
// https://devapigateway.upexciseonline.co/master/v1.0.0/elottery/scheduler/findById?id=1
  // https://devapigateway.upexciseonline.co/master/v1.0.0/elottery/scheduler/save
  // https://devapigateway.upexciseonline.co/master/v1.0.0/elottery/scheduler/findAllByLoginUser
}
