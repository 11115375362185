import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class WholesaleTransportGenerationService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }
  baseURL = environment.wholesaleURL;
  scmURL = environment.wholesaleURL;
  masterURL = environment.devisionUrl;
  wholesaleNewURL = environment.wholesaleNewURL;
  wholesaleNewURLLicence = environment.licencemanagementURL
  paymentURL = environment.payandreconBaseUrl;

  savetransportpass(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/savetransportpass';
    return this.http.post(url, postData)
  }
  edittransportpass(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/edittransportpass';
    return this.http.post(url, postData)
  }
  canceltransportpass(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/canceltransportpass';
    return this.http.post(url, postData)
  }
  addtransportenpass(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/process';
    return this.http.post(url, postData)
  }
  savefinaltransportpass(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/savefinaltransportpass';
    return this.http.post(url, postData)
  }
  saveb10fl2tp(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/saveb10fl2tp';
    return this.http.post(url, postData)
  }
  viewdrafttransportpassitems(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/viewdrafttransportpassitems';
    return this.http.post(url, postData)
  }
  getAlltransitpass(obj): Observable<any> {
    const url = this.scmURL + 'transportPassGeneration/transportPassLazySearch';
    return this.http.post(url, obj);
  }

  getIndentDetails(indentNo, unitName) {
    const url = this.baseURL + 'orderPickUp/getOrderPickUpByIndentNo?indentNo=' + indentNo + '&unitName=' + unitName;
    return this.http.get(url);
  }
  getStatusMasters() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_TRANSPASS"
    return this.http.get(url)
  }
  getbyid(obj): Observable<any> {
    let url = "";
    if (this.authService.getEntityType() === "PARENT_UNIT") {
      url = this.wholesaleNewURL + 'transportpass/viewparenttransportpassitems';
    } else {
      url = this.wholesaleNewURL + 'transportpass/viewdrafttransportpassitems';
    }
    //  const url = this.baseURL + 'transportPassGeneration/getTransportPassGenById?id=' + id;
    return this.http.post(url, obj);
  }
  getbyidNew(obj): any {
    let url = "";
    if (this.authService.getEntityType() === "PARENT_UNIT") {
      url = this.wholesaleNewURL + 'transportpass/viewparenttransportpassitems';
    } else {
      url = this.wholesaleNewURL + 'transportpass/viewdrafttransportpassitems';
    }
    return this.http.post(url, obj).toPromise();
  }
  getindentbyid(indentNo) {
    const url = this.baseURL + 'orderPickUp/getIndentDetailsByIndentNo?indentNo=' + indentNo;
    return this.http.get(url);
  }

  checkIndentNo(indentNo) {
    const url = this.baseURL + 'indentAcceptance/checkIndent?indentNo=' + indentNo;
    return this.http.get(url);
  }
  getIndentDropDown() {
    const url = this.baseURL + 'indentAcceptance/getIndentNoDropDown?type=INDENT_NO'
    return this.http.get(url)
  }
  getIndentnumberDetails(indentNum, transportNo = '') {
    let tranDet = transportNo ? '&transportNo=' + transportNo : '';
    const url = this.baseURL + 'transportPassGeneration/getIndentDetailsByTransportNo?indentNo=' + indentNum + tranDet;
    return this.http.get(url)
  }
  getAllRouteType() {
    const url = this.masterURL + apiUrls.getAllRouteType;
    return this.http.get(url)
  }
  getAllRouteRange() {
    const url = this.masterURL + apiUrls.getAllRouteRange;
    return this.http.get(url)
  }

  // newly added
  getAll(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }

  getIndentDetialsbyIN(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }

  checkIndentNumber(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  getAlllist(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  savedata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickup/saveorderpickup';
    return this.http.post(url, obj)
  }
  getLicencedata() {
    const url = this.wholesaleNewURLLicence + 'license/category/WHOLESALE';
    return this.http.get(url);
  }
  validityExtention(payload): Observable<any> {
    const url = this.paymentURL + 'trackandtrace/v1.0.0/tpvalidityext/saveextdate';
    return this.http.post(url, payload);
  }
  tpExtenction(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/savedata';
    return this.http.post(url, payload);
  }
  releseOrder(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/saveorderpickupreleaseapprove';
    return this.http.post(url, payload);
  }
  releaseOrder(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/saveorderpickupreleasesubmit';
    return this.http.post(url, payload);
  }
  releaseOrder1(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/saveorderpickupreleaseapprove';
    return this.http.post(url, payload);
  }
  validatereleaseOrder(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, payload);
  }
  releseexportorder(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/savedata';
    return this.http.post(url, payload);
  }
  dispatchTPOrderPickUpRelese(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/savetpwiseorderpickuprelease';
    return this.http.post(url, payload);
  }
  savetransportpassBIO(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/savetransportpassbio';
    return this.http.post(url, postData)
  }
  getappconfigvalue(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getappconfigvalue';
    return this.http.post(url, postData)
  }
  getAppConfigPromise(postParam): any {
    const url = this.wholesaleNewURL + 'appconfig/getappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }

  saveorderpickupv2(obj): Observable<any> {
    // const url = this.wholesaleNewURL + 'orderpickup/saveorderpickupv2';
    const url = this.wholesaleNewURL + 'orderpickup/saveorderpickup';
    return this.http.post(url, obj)
  }

  saveexportorderpickupv3(obj): Observable<any> {
    // const url = this.wholesaleNewURL + 'orderpickup/saveexportorderpickupv3';
    const url = this.wholesaleNewURL + 'orderpickup/saveexportorderpickup';
    return this.http.post(url, obj)
  }

  saveprocessschedulev2(obj): Observable<any> {
    // const url = this.wholesaleNewURL + 'orderpickup/saveprocessschedulev2';
    const url = this.wholesaleNewURL + 'orderpickup/saveprocessschedule';
    return this.http.post(url, obj)
  }

  getDataPromise(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }

  getAppConfigValue(postParam): any {
    const url = this.wholesaleNewURL + 'appconfig/getappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }
  vendorDropdownNameList(): Observable<any> {
    const url = this.wholesaleNewURL + 'tracking/findregisteredvendornames';
    return this.http.get(url)
  }

  vendorGPSIDmatch(request): Observable<any> {
    const url = this.wholesaleNewURL + 'tracking/validategpsidandvendor';
    return this.http.post(url, request)
  }

  vendorTransportPassSubmit(request): Observable<any> {
    const url = this.wholesaleNewURL + 'vehicleTracking/v1.0.0/api/transportpass/startTrip';
    return this.http.post(url, request)
  }

  vendorTransoprtPassEndTrip(request): Observable<any> {
    const url = this.wholesaleNewURL + 'tracking/endtrip';
    return this.http.post(url, request)
  }
  releasedValue(payload: any) {
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload);
  }
  consolidateReleaseOrderpickup(request): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/saveorderpickupreleaseapprove'
    return this.http.post(url, request);
  }
  consolidateOrderPickupRelese(request): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/savetpwiseorderpickuprelease'
    return this.http.post(url, request);
  }
  relesePallet(request): Observable<any> {
    const url = this.wholesaleNewURL + 'palletstock/clearpallet'
    return this.http.post(url, request);
  }

  scanOrderPickupByItems(request): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickup/saveitemwiseorderpickup'
    return this.http.post(url, request);
  }

  particularpackagesearchforbio(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/particularpackagesearchforbio';
    return this.http.post(url, postData)
  }

  saveBrandWisePaymentBIO(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/processwithtransportpasspaymentupdate';
    return this.http.post(url, obj);
  }

  getPaymentDetialsForReturnTP(params): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/processwithtransportpasspaymentupdate';
    return this.http.post(url, params);
  }


}
