import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/theme/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OmdStockInRoutingModule } from './omd-stock-in-routing.module';
import { AddOmdStockInComponent } from './add-omd-stock-in/add-omd-stock-in.component';
import { ViewOmdStockInComponent } from './view-omd-stock-in/view-omd-stock-in.component';
import { OmdStockInComponent } from './omd-stock-in.component';


@NgModule({
  declarations: [AddOmdStockInComponent, ViewOmdStockInComponent, OmdStockInComponent ],
  imports: [
    CommonModule,
    OmdStockInRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule
  ]
})
export class OmdStockInModule { }
