import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class IndentMetallurgyService {
  // baseURL = environment.sugarmillBaseURL;
  baseURL = environment.parentUnit;
  constructor(private http: HttpClient) { }
  approveIndent(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.indentMetallurgyApprove;
    return this.http.post(url,postdata);
  }
  searchIndent(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.searchIndentMetallurgy;
    return this.http.post(url,postdata);
  }
  indentGetById(id): Observable<any> {
    const url = this.baseURL + apiUrls.indentMetallurgyGetById + id;
    return this.http.get(url);
  }
  getUserById(id): Observable<any> {
    const url = "http://65.0.150.210:3518/userManagement/user/getUserById?id=" + id;
    return this.http.get(url);
  }
  getTotalIndentByStatus(key): Observable<any> {
    const ids = `?Domain=${key}`;
    const url = this.baseURL + apiUrls.getTotalIndentByStatusMetallurgy + ids;
    return this.http.get(url);
  }
}
