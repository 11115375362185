import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
  providedIn: 'root'
})

export class SheeraNidhiService {

  baseURL = environment.parentUnit;
  domainUrl = environment.domainUrl;

  constructor(private http: HttpClient) { }

  getSheeraNidhiDetails(): Observable<any> {
    const url = this.baseURL + 'sheeraNidhiRegister/getSheeraNidhiRegisterData';
    return this.http.get(url);
  }

  addSheeraNidhiDetails(postdata): Observable<any> {
    const url = this.baseURL + 'sheeraNidhiRegister';
    return this.http.post(url, postdata);
  }

  searchSheeraNidhiDetails(postdata): Observable<any> {
    const url = this.baseURL + 'sheeraNidhiRegister/lazySearch';
    return this.http.post(url, postdata);
  }
  getSheeraNidhiDetailsbyId(postdata): Observable<any> {
    const url = this.baseURL + 'sheeraNidhiRegister/' + postdata;
    return this.http.get(url);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
    return this.http.post(url, data);
  }
  getEntityType(url): any {
    let entityType = url.split('/')[1];
    entityType == 'sugarmill' ? entityType = 'SUGAR_MILL' : entityType
    return entityType.toUpperCase()
  }

  // payment related api's
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'sheeraNidhiRegister/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    // let url = this.baseURL + 'sampleRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    let url = this.baseURL + 'sheeraNidhiRegister/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findAllPaymentDetails(applicationno, feetype): Observable<any> {
    // let url = this.baseURL + 'sampleRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    let url = this.baseURL + 'sheeraNidhiRegister/findAllPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadSampleFeeReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt'  + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=SHEERA_NIDHI_RECEIPT';
    return this.http.get(url, { responseType: 'blob' });
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }
  getQrCodeDetail(appnumber): Observable<any> {
    const url = this.baseURL + 'sheeraNidhiRegister/getQrCodeDetail' + '?applicationNumber=' + appnumber;
    return this.http.get(url);
  }
  getSheeraNidhiCommonConfig(): Observable<any> {
    const url = this.baseURL + 'getCommonConfigDetail?entityType=SUGAR_MILL&type=SHEERANIDHI_REG';
    return this.http.get(url);
  }
  downloadReportVal(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt';
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  getstatuscount(payload):Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount';
    return this.http.post(url,payload);
  }
}
