import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { isArray } from 'util';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  modalOptions: NgbModalOptions = {};
  modalReference: NgbModalRef;
  closeResult: string;
  constructor(private datePipe: DatePipe, private modalService: NgbModal) { }

  downloadExcel(excelData, excelName: String, objKeys = [], type?: any) {
    var dwnldExcelData = excelData;
    if (objKeys.length == 2) {
      if (isArray(objKeys[0]) && isArray(objKeys[1])) {
        dwnldExcelData = this.convertDatas(excelData, objKeys, type);
      }
    }
    dwnldExcelData.forEach(function (item) {
      delete item.Action;
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dwnldExcelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, excelName + '.xlsx');
    return 'success';
  }

  downloadExcelReports(excelData, excelName: String, objKeys = [], sheetName, type?: any) {
    var dwnldExcelData = excelData;
    if (objKeys.length == 2) {
      if (isArray(objKeys[0]) && isArray(objKeys[1])) {
        dwnldExcelData = this.convertDatas(excelData, objKeys, type);
      }
    }
    dwnldExcelData.forEach(function (item) {
      delete item.Action;
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dwnldExcelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, excelName + '.xlsx');
    return 'success';
  }

  downloadPDF(head, data, title, filename, columnStyles = null, landscape = null) {
    var doc = landscape ? new jsPDF('l') : new jsPDF();
    doc.setFontSize(18);
    doc.text(title, 11, 8);
    doc.setFontSize(10);
    doc.setTextColor(100);
    const obj = {
      head: head,
      body: data,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.4,
        lineColor: [0, 0, 0]
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.2,
      headerStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      didDrawCell: data => {
        // console.log(data.column.index)
      }
    };
    if (columnStyles) {
      obj['columnStyles'] = columnStyles
    }
    (doc as any).autoTable(obj)
    // below line for Download PDF document
    doc.save(filename);
  }

  convertDatas(excelData: Array<{}>, objKeys = [], type?: any) {
    let excelArray = [];
    let excelHeaders = objKeys[0];

    let excelKeys = objKeys[1];
    if (excelData.length <= 0) {
      excelArray = [];
      let objKey = {
        'S.NO': ''
      };
      for (let idx = 0; idx < excelKeys.length; idx++) {
        objKey[excelHeaders[idx]] = '';
      }
      excelArray.push(objKey)
    }
    for (let index = 0; index < excelData.length; index++) {
      const element: any = excelData[index];
      var objKey = {
        'S.NO': (index + 1)
      };
      for (let idx = 0; idx < excelKeys.length; idx++) {
        const innerElement = excelKeys[idx];
        // console.log(innerElement)
        // console.log(innerElement.data)
        if (innerElement.data == "year") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "applicationNumber") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "licenseSubCategoryDesc") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "name") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "districtDesc") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "applicationDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "licenseNumber") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "licenseIssueDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "stageDesc") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "processType") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "statusDesc") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }


        else if (innerElement.data == "createdDate1") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        // else if (innerElement.data == "createdDate") {
        //   objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        // }

        else if (innerElement.data == "stageDesc") {
          objKey[excelHeaders[idx]] = (element.status == 'APPROVED' || element.status == 'DRAFT' || element.status == 'SUBMITTED' || element.stageDesc == 'Completed') ? '-' : element.stageDesc;
        }

        else if (innerElement.data == "isDeactivated") {
          objKey[excelHeaders[idx]] = element[innerElement.data] == true ? 'Deactivated' : 'Activated';
        }

        else {
          objKey[excelHeaders[idx]] = element[innerElement.data];
        }

      }
      excelArray.push(objKey);
    }
    return excelArray;
  }
  openDialogModal(content) {
    this.modalOptions.backdrop = 'static';
    this.modalOptions.keyboard = true;
    this.modalOptions.centered = true;
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  closeDialogModal() {
    this.modalReference.close();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getUniqueArray(data){
    const uniqueArray = data.filter((element, index) => {
      const _INDEXData = JSON.stringify(element);
      return (
        index ===
        data.findIndex((obj) => {
          return JSON.stringify(obj) === _INDEXData;
        })
      );
    });
    return uniqueArray
  }
}