import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
  providedIn: 'root'
})
export class TransportGenService {
  // baseURL = environment.sugarmillBaseURL;
  baseURL = environment.parentUnit;
  masterURL = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  constructor(private http: HttpClient) { }
  getAllTransGen(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllTransportGen;
    return this.http.get(url);
  }
  getAllSugarMilllName(): Observable<any> {
    const url = this.baseURL + apiUrls.sugarmillNameTransGen;
    return this.http.get(url);
  }
  getTransGenById(id): Observable<any> {
    const url = this.baseURL + apiUrls.transportGenGetById + id;
    return this.http.get(url);
  }
  getVendorNames(): Observable<any> {
    const url = this.baseURL + 'tracking/findRegisteredVendorNames';
    return this.http.get(url);
  }
  validateGPSIdandVendor(payload): Observable<any> {
    const url = this.baseURL + 'transportpass/validateGpsIdAndVendor'
    return this.http.post(url, payload);
  }
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'MF4transportPass/saveUpdatePaymentDetails'
    return this.http.post(url, payload);
  }
   findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'MF4transportPass/findPaymentDetails?applicationNumber='+ applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt'  + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=MF4_TRANSPORT_PAYMENT';
    return this.http.get(url, { responseType: 'blob' });
  }
  addTransGen(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.createMf4;
    return this.http.post(url, postdata);
  }
  getAllIndentNumber(supplyType): Observable<any>{
    const url = this.baseURL + 'MF4transportPass/getIndentDetails?supplyTypeCode=' + supplyType;
    return this.http.get(url)
  }
  spiritImportExportValidityExtension(payload):Observable<any>{
    const url = this.baseURL + 'MF4transportPass/sub/updateValidityExtension';
    return this.http.post(url, payload);
  }
  spiritImportExportValidityExtensionStatus(payload):Observable<any>{
    const url = this.baseURL + 'MF4transportPass/updateValidityExtensionStatus';
    return this.http.post(url, payload);
  }
  approveTransGen(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.approveTransGen;
    return this.http.post(url, postdata);
  }
  updateTransGen(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.updateTransportGeneration;
    return this.http.post(url, postdata);
  }
  searchTransGen(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.transportSearch;
    return this.http.post(url, postdata);
  }
  getIndentByFilter(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.searchIndentAcceptance;
    return this.http.post(url, postdata);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(): Observable<any> {
    const url = this.baseURL + apiUrls.transportGenPrint;
    return this.http.get(url, { responseType: 'blob' });
  }
  approvedIndent(id): Observable<any> {
    const url = this.baseURL + apiUrls.approvedIndent + id;
    return this.http.get(url);
  }
  getOutwardIndent(id): Observable<any> {
    const url = this.baseURL + apiUrls.outwardIndentDetails + id;
    return this.http.get(url);
  }
  getMf4Tanks(id, gradeId): Observable<any> {
    const url = `${this.baseURL}${apiUrls.getTanks}?productTypeId=${id}&gradeId=${gradeId}`;
    return this.http.get(url);
  }

  getTankDetails(id): Observable<any> {
    const url = this.baseURL + apiUrls.getMf4TankDetails + id;
    return this.http.get(url);
  }
  getcurrentseason() {
    const url = this.baseURL + apiUrls.currentSeason;
    return this.http.get(url);
  }
  getSupplyTypeMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=SM_SUPPLY_TYPE";
    return this.http.get(url)
  }
  getConsumptionTypeMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=CONSUMPTION_TYPE";
    return this.http.get(url)
  }
  getModeOfTransportMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=MODE_OF_TRANSFER";
    return this.http.get(url)
  }
  getRouteTypetMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=ROUTE_TYPE";
    return this.http.get(url)
  }
  getMolassesYearDetail(): Observable<any> {
    const url = this.baseURL + 'utility/molasses/forecastDetails';
    return this.http.get(url)
  }
  getIndentNumber(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.getIndentNumber;
    return this.http.post(url, postdata)
  }
  getIndentDetailByIndNo(id, isAddScreen): Observable<any> {
    const url = this.baseURL + apiUrls.getindentdetailbyIndentNo + id + '&isAddScreen='+ isAddScreen;
    return this.http.get(url);
  }
  getExportIndentDetails(exportNo, isAddScreen)  : Observable<any>{
    const url = this.baseURL + 'exportOfMolassesAcceptance/getExportPermitDetailsByExportNo?exportNo='+exportNo + '&isAddScreen='+ isAddScreen;
    return this.http.get(url);
  }
  getPendingMolasses(postdata): Observable<any> {
    const url = this.baseURL + 'molasses/openingBalnce';
    return this.http.post(url, postdata);
  }
  gettanksDetails(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.gettanksbyid;
    return this.http.post(url, postdata);
  }
  getLicenseDetails(): Observable<any> {
    const url = this.licenseURL + 'license/category/PD';
    return this.http.get(url);
  }
  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
    return this.http.post(url, data);
  }
  getAllRouteRange() {
    const url = this.masterURL + apiUrls.getAllRouteRange;
    return this.http.get(url)
  }
  getAllRouteRange1() {
    const url = this.masterURL + apiUrls.getAllRouteRange;
    return this.http.get(url).toPromise();
  }
  downloadRecipt(appNo, licNo, con, entityType) {
    const url = this.baseURL + 'reportReceipt/downloadMF4TransportPass?applicationNumber=' + appNo + '&licenseNumber=' + licNo + '&entityType=' + entityType + '&receiptType=' + con;
    // const url ='http://localhost:3501/scmProduction/reportReceipt/downloadMF4TransportPass?applicationNumber=' + appNo + '&licenseNumber=' + licNo + '&entityType=' + entityType + '&receiptType=' + con;
    return this.http.get(url, { responseType: 'blob' })
  }
  getTpGenStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + apiUrls.transportGenCardStatus +entityType;
    // const url = ' https://testapigateway.upexciseonline.co/scmProduction/v1.0.0/MF4transportPass/getMf4StatusCount?entityType=' +entityType
    // const url ='http://localhost:3501/scmProduction/MF4transportPass/getMf4StatusCount?entityType='+entityType
    // const url = "https://testapigateway.upexciseonline.co/scmProduction/v1.0.0/MF4transportPass/getMf4StatusCount?entityType=" +entityType;
    return this.http.get(url);
  }
  getTpGenStatusCounts(entityType, isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'MF4transportPass/getMf4StatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }

  getMinimumUnReservedStockAlertMessage(licenseNumber, rawMaterialCode, rawMaterialValue): Observable<any> {
    //const url = 'http://localhost:3501/scmProdcution/MF4transportPass/getMinimumUnReservedStockAlertMessage?licenseNumber=' + licenseNumber + '&rawMaterialCode=' + rawMaterialCode + '&rawMaterialValue' + rawMaterialValue;
    const url = this.baseURL + 'MF4transportPass/getMinimumUnReservedStockAlertMessage?licenseNumber=' + licenseNumber + '&rawMaterialCode=' + rawMaterialCode + '&rawMaterialValue=' + rawMaterialValue;
    return this.http.get(url);
  }

  getExportIndentNumber(supplyType){
    const url = this.baseURL + 'exportOfMolasses/getValidExportOfMolassesDetails?supplyTypeCode='+supplyType;
    return this.http.get(url);
  }
  getRouteType(passNo, routeType): Observable<any> {
    const url = this.baseURL + 'transportpass/getTPDetailsByTPNumber?transportPassNumber=' + passNo + '&routeType=' + routeType;
    return this.http.get(url);
  }
}
