import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class IndentRequestService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  addetails(data): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest';
    return this.http.post(url, data);
  }
  getIntermOfCheavy(): Observable<any>{
    const url = this.baseURL + 'pdIndentRequest/getIntermsOfCHeavyConfig?entityType=SUGAR_MILL'
    return this.http.get(url);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/' + id;
    return this.http.get(url);
  }
  getTotalIndentQuantity(payload): Observable<any>{
     const url = this.baseURL + 'pdIndentRequest/getTotalIndentedQuantity';
     return this.http.post(url, payload);
  }
  getById1(id):any {
    const url = this.baseURL + 'pdIndentRequest/' + id;
    return this.http.get(url).toPromise();
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getCheavyConfig(entityType?): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/getCheavyConfig?entityType='+entityType;
    // const url = 'http://localhost:3501/scmProduction/pdIndentRequest/getCheavyConfig?entityType='+entityType;
    return this.http.get(url);
  }
  getIndentRequestCount(entityType?,createdBy?): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/getIndentRequestCount?entityType='+ entityType + '&createdBy=' + createdBy;
    return this.http.get(url);
  }
  getReserveIndentDetails(indentAppNumber):Observable<any>{
    const url = this.baseURL + 'pdIndentRequest/getReserveIndentDetails?indentAppNumber=' +indentAppNumber;
    return this.http.get(url);
  }
  getIndentRequestCommonConfigValue(entityType,type):Observable<any>{
    const url = this.baseURL + 'getCommonConfigDetail?entityType=' + entityType + '&type=' + type;
    return this.http.get(url);
  }
  getReservePermitDetails(allotmentNumber):Observable<any>{
    const url = this.baseURL + 'pdIndentRequest/getPreviousIndentDetails?allotmentNumber=' +allotmentNumber;
    return this.http.get(url);
  } 
  getSugarmillDetails(allotmentNumber):Observable<any>{
    const url = this.baseURL + 'pdIndentRequest/getSugarmillDetails?allotmentNumber=' +allotmentNumber;
    return this.http.get(url);
  } 
  downloadenavalue(payload) {
   const url = this.baseURL + 'reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  validityExtension(payload){
    const url = this.baseURL + 'pdIndentRequest/updateValidityExtension'
    return this.http.post(url, payload);
  }
  getsugarmillgroup(): any {
    const url = this.devisionUrl + 'retail/group/findAllActiveGroups';
    return this.http.get(url).toPromise();
  }
  getstatuscount(payload):Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount';
    return this.http.post(url,payload);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'pdIndentRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }

  findpaymentdetailsIndentRequest(applicationno, feetype, processType): Observable<any> {
    let url = this.baseURL + 'pdIndentRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype +'&processType=' +processType;
    return this.http.get(url);
  }
  downloadPaymentForIndentRequest(appnumber, liceanseNumber, feeType,processType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=MOLASSES_PERMIT_PENALTY'
    +'&processType='+processType;
    return this.http.get(url, { responseType: 'blob' });
  }

  getIntermsCalculation(data): Observable<any> {
    const url = this.baseURL + 'pdIndentRequest/getAllotmentBasedIndentedQuantity';
    return this.http.post(url, data);
  }
}
