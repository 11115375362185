<h5 class="m-b-10 ng-star-inserted">Accidental Request</h5>

<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'SUGAR_MILL'"><a href="javascript:">
            {{'language.sugarmill' | translate}} </a></li>
    <li class="breadcrumb-item"><a href="javascript:">Accidental Request</a></li>
    <li class="breadcrumb-item">List</li>
</ul>

<div class="content">
  <div class="container">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <app-card cardTitle="Accidental Request Details" class="list-panel view-panel">
          <h4 class="box-title mt-2 mb-3">Accidental Request Details</h4>
          <div class="row mb-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <label class="w-100 view-label">{{ 'brewery.brandlabelpayment.appno' | translate }}<span
                  class="float-right">:</span></label>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              {{accidentRqstObj?.applicationNumber}}
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <label class="w-100 view-label">{{ 'brewery.brandlabelpayment.appdateandtime' | translate
                }}<span class="float-right">:</span></label>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                {{accidentRqstObj?.createdDate | date:'dd/MM/yyyy HH:mm:ss'}}
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              <label class="w-100 view-label">{{ 'brewery.brandlabelpayment.unitname' | translate }}<span
                  class="float-right">:</span></label>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
              {{accidentRqstObj?.distilleryName}}
            </div>
          </div>
        </app-card>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <app-card cardTitle="Payment Details" class="list-panel view-panel">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Fees/Penalty </th>
                      <th>Amount (₹)</th>
                      <th>Challan <br>Head</th>
                      <th>G6</th>
                      <th>Advance Duty<br>Balance (₹)</th>
                      <th>Mode of<br>Payment </th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td class="text-left">
                        <label class="w-100 view-label" [style.color]="isBrandPayment ? 'green' :'red'">Penalty</label>
                      </td>
                      <td class="text-left">{{accidentRqstObj?.fees}}</td>
                      <td class="text-left">{{paymentData?.treasuryHeadName}}</td>
                      <td class="text-left">{{paymentData?.g6Name}}</td>
                      <td class="text-left">
                        <label class="w-100 view-label" [style.color]="isBrandBalanceSufficient ? 'red' :'green'">
                      {{advanceDuty}}</label>
                      </td>
                      <td class="text-left">
                        <select class="form-control">
                          <option value="">Advance Duty</option>
                        </select>
                      </td>
                      <td>
                        <span class="badge badge-secondary">
                          Pending
                        </span>
                      </td>

                    </tr>

                    <tr>
                      <td></td>
                      <td class="text-left">
                        Total
                      </td>
                      <td class="text-left">
                       
                      </td>
                      <td class="text-left"></td>
                      <td class="text-left"></td>
                      <td class="text-left"> </td>
                      <td class="text-left"></td>
                      <td class="text-left"> </td>
                      <td> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

             <div class="col-12 text-right">
              <button type="button" class="btn btn-primary custom_add_button ml-2 mb-2" [disabled]="isPaymentLoading"
                *ngIf="advanceDuty> 0" (click)="saveUpdatePaymentDetails()"><i class="fa"
                  [ngClass]="{'fa-spin fa-spinner': isPaymentLoading, 'fa-check': !isPaymentLoading}"></i>Proceed
                To Payment</button>
             <input type="button" value="Back" class="btn btn-secondary custom_add_button ml-2 mb-2"
             routerLink="/{{url}}/{{outsideupvalidation}}/list">
            </div> 

          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>

