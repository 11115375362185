import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccidentRequestService, AlertService } from '@app/services';
import { TankConversionBrewService } from '@app/services/brewery/tankconversionbrew.service';
import { LocalUrl } from '@app/app-local-url';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-aknowledgmentscreen',
  templateUrl: './aknowledgmentscreen.component.html',
  styleUrls: ['./aknowledgmentscreen.component.scss']
})
export class AknowledgmentscreenComponent implements OnInit {

  viewId: any;
  accidentRqstObj: any;
  isProcessingFee = false;
  transactionNumber: any;
  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any;
  outsideupvalidation: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private accidentRqstService: AccidentRequestService,
    private service: TankConversionBrewService,
    private alert: AlertService) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
 
    const subUrlManage = currentUrlArray[1] + currentUrlArray[2];
    this.url = LocalUrl[subUrlManage].url;
    this.outsideupvalidation = currentUrlArray[2]
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;
    this.route.params.subscribe((params) => {
      if (params.Id) {
        this.viewId = params.Id;
        this.setData(this.viewId);
      } 
    });
  }
  setData(id?: any) {
    if (id) {
      this.accidentRqstService.getAccidentRqstById(id).subscribe((responceData: any) => {
        this.accidentRqstObj = responceData.data;
        this.isProcessingFee = responceData.data.isProcessingFeePaid;
        this.accidentRqstService.findpaymentdetails(this.accidentRqstObj.applicationNumber,'PROCESSING_FEE').subscribe((resp: any) => {
          this.transactionNumber = resp.data.transactionNumber;
        });
      })
    }
  }
  downloadRecipt() {
    if (this.accidentRqstObj.applicationNumber && this.accidentRqstObj.licenseNumber) {
      this.accidentRqstService.downloadPayment(this.accidentRqstObj.applicationNumber, this.accidentRqstObj.licenseNumber, 'PROCESSING_FEE').subscribe(
        (resp: any) => fileSaver.saveAs(resp, this.accidentRqstObj.applicationNumber.replace(/\//g, '_')+'_ACCIDENTAL_REQUEST'),
          (error: any) => this.alert.showError('Error downloading the file'),
          () => console.info('File downloaded successfully')
        );
    } else {
      this.alert.showError('File not available');
    }
  }

}
