import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class IndentRequestForOmdService {
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;
  constructor(private http: HttpClient) { }
  addetails(data): Observable<any> {
    const url = this.baseURL + 'omdIndentRequest';
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'omdIndentRequest/lazySearch';
    return this.http.post(url, payload);
  }
  pd18ApplicationNumber(payload): Observable<any> {
    const url = this.baseURL + 'pd26/lazySearch';
    return this.http.post(url, payload);
  }
  getFl39List(payload): Observable<any> {
    const url = this.baseURL + 'fl39/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'omdIndentRequest/' + id;
    return this.http.get(url);
  }
  approveIndent(postdata): Observable<any> {
    const url = this.baseURL + 'omdIndentRequest/indentRequestUpdate';
    return this.http.post(url, postdata);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  submitOmdIndentForeClosure(data): Observable<any> {
    const url = this.baseURL + 'omdIndentForeclosure';
    return this.http.post(url, data);
  }
  omdIndentForeClosureLazySearch(data): Observable<any> {
    const url = this.baseURL + 'omdIndentForeclosure/lazySearch';
    return this.http.post(url, data);
  }
  getByIdOmdIndentForeClosure(id): Observable<any> {
    const url = this.baseURL + 'omdIndentForeclosure/' + id;
    return this.http.get(url);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
}
