<div class="auth-wrapper maintenance mb-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">

                <app-card [hidHeader]="true" class="list-panel text-center">
                    <div class="text-center">
                        <h1 class="fa fa-check-circle text-success"></h1>
                        <h3 class="text-success">{{ 'brewery.brandlabelack.appsubmitsuccess' | translate }}</h3>
                    </div>

                    <h5 class="mb-3">{{ 'brewery.brandlabelack.appnumis' | translate }}</h5>
                    <h4><span class="badge badge-success mb-3">{{accidentRqstObj?.applicationNumber}}</span></h4>
                    <h5 class="mb-3"> Submitted Successfully</h5>
                    <h6 class="mb-3"></h6>
                </app-card>
            </div>
            <div class="col-md-10 mt-3">
                <app-card [hidHeader]="true" class="list-panel text-center">
                    <h5 class="mb-3">{{ 'brewery.brandlabelack.paymenttransaction' | translate }}</h5>
                    <h5>
                        <ng-container *ngIf="transactionNumber">
                            <span class="badge badge-success mb-3">Accidental Request Transaction Number :-{{transactionNumber}} </span>
                        </ng-container>

                    </h5>
                </app-card>
            </div>
            <div class="col-md-10 mt-3">
                <app-card [hidHeader]="true" class="list-panel text-center">
                    <div class="mt-3">
                            <button class="btn waves-effect waves-light btn-info ml-2"
                            (click)="downloadRecipt()">Download Receipt</button>
                        <button class="btn waves-effect waves-light btn-primary  ml-2"
                        routerLink="/{{url}}/{{outsideupvalidation}}/list">{{ 'brewery.brandlabelack.close' |
                            translate }}</button>
                    </div>
                </app-card>

            </div>
        </div>
    </div>
</div>



