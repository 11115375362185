import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  WorkSheet as XLSXWorksheet,
  WorkBook as XLSXWorkBook,
  utils as XLSXUtils,
  writeFile as XLSXWriteFile
} from 'xlsx';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  test = 'name';
  constructor(
    private datepipe: DatePipe,
    private translate: TranslateService,
    private alert: AlertService
  ) { }

  dateTransform(date, format) {
    return this.datepipe.transform(date, format);
  }

  getDate() {
    return moment().format('LLLL');
  }

  sumArr(arr) {
    return _.sum(arr);
  }

  createPDF(filename, data) {
    pdfMake.createPdf(data).download(filename);
    return true;
  }

  createXLSX(filename, element) {
    const ws: XLSXWorksheet = XLSXUtils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSXWorkBook = XLSXUtils.book_new();
    XLSXUtils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSXWriteFile(wb, filename);
    return true;

  }

  translateText(text) {
    let string: any;
    this.translate.stream(text)
      .subscribe((resp: any) => {
        string = resp
      });
    return string;
  }

  downloadPDF(head, data, title, filename, columnStyles = null, landscape = null) {
    var doc = landscape ? new jsPDF('l') : new jsPDF();
    doc.setFontSize(18);
    doc.text(title, 11, 8);
    doc.setFontSize(10);
    doc.setTextColor(100);
    const obj = {
      head: head,
      body: data,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0]
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      headerStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      didDrawCell: data => {
        // console.log(data.column.index)
      }
    };
    if (columnStyles) {
      obj['columnStyles'] = columnStyles
    }
    (doc as any).autoTable(obj)
    // below line for Download PDF document  
    doc.save(filename);
  }

  async downloadPDFs(tables: { head: any[], data: any[][], title: string, filename: string }[], columnStyles = null, landscape = null, header: string) {
    var doc = landscape ? new jsPDF('l') : new jsPDF();
    doc.text('', 0, 16);
    tables.forEach((table, index) => {
      if (index > 0) {
        doc.addPage();
      }
      doc.setFontSize(16);
      doc.text('Department of Excise, Uttar Pradesh', 100, 8); // Center the header text horizontally
      doc.text(table.title, 120, 15);
      doc.setFontSize(10);
      doc.setTextColor(100);
      const obj = {
        startY: 20,
        head: table.head,
        body: table.data,
        theme: 'plain',
        bodyStyles: {
          lineWidth: 0.2,
          lineColor: [0, 0, 0]
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.5,
        headStyles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0]
        },
        didDrawCell: data => {
          // console.log(data.column.index)
        }
      };
      if (columnStyles) {
        obj['columnStyles'] = columnStyles
      }
      (doc as any).autoTable(obj);
    });
    doc = await this.addWaterMark(doc);
    // below line for Download PDF document
    doc.save(header);
  }

  async addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      var imgWatermark = new Image();
      imgWatermark.src = 'assets/images/up_logo_watermark_new.png';
      doc.addImage(imgWatermark, 'png', 40, 2);

      var imgLogo = new Image();
      imgLogo.src = 'assets/images/login-up-logo.png';
      doc.addImage(imgLogo, 'png', 12, 2, 15, 15);

      var imgPoweredby = new Image();
      imgPoweredby.src = 'assets/images/poweredby-icon.png';
      doc.addImage(imgPoweredby, 'png', 260, 4);

      doc.setTextColor(150);
      doc.text('', 100, doc.internal.pageSize.height - 100, { angle: 45, });

      const downloadName = localStorage.getItem('USER_NAME');
      const dateTimeString = moment().format("DD-MM-YYYY HH:mm:ss");
      const textString = 'Downloaded By : ' + downloadName + '\nDownload Date & Time : ' + dateTimeString;
      doc = doc.text(textString, 14, doc.internal.pageSize.height - 14);
    }
    return doc;
  }

  async downloadPDFwithLogo(head, data, title, filename, columnStyles = null, landscape = null) {
    var doc = landscape ? new jsPDF('l') : new jsPDF();

    doc.setFontSize(16);
    doc.text('Department of Excise, Uttar Pradesh', 100, 8);
    doc.text(title, 120, 15);
    doc.setFontSize(10);
    doc.setTextColor(100);

    const obj = {
      startY: 20,
      head: head,
      body: data,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [0, 0, 0]
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      headerStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0]
      },
      didDrawCell: data => {
        // console.log(data.column.index)
      }
    };
    if (columnStyles) {
      obj['columnStyles'] = columnStyles
    }
    (doc as any).autoTable(obj);
    doc = await this.addWaterMark(doc);
    // below line for Download PDF document  
    doc.save(filename);
  }

  convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  checkFileSizeFileType(event, acceptList, acceptSize) {
    // let fileTypeArr = event.target.accept;
    // let acceptList = fileTypeArr ? fileTypeArr.split(",") : fileTypeArr;
    // acceptList = acceptList.map((e) => { return e ? e.split("/").pop() : e; });

    const { type: fileType, size: fileSize } = event.target.files[0];
    console.log('fileType', fileType)
    console.log('fileSize', fileSize)
    if (fileType && fileSize) {
      const extensions = fileType ? fileType.split("/") : fileType;
      let ext = extensions ? (extensions[extensions.length - 1]).toLowerCase() : extensions;
      console.log('acceptList', acceptList);
      console.log('ext', ext);
      if (acceptList.includes(ext)) {
        if (acceptSize < fileSize) {
          this.alert.showError('Upload Less Then ' + this.formatBytes(acceptSize) + ' Size');
          return false;
        } else {
          return true;
        }
      } else {
        this.alert.showError('Upload File Format Invalid');
        return false;
      }
    } else {
      this.alert.showError('Invalid File');
      return false;
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}
