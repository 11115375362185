import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalUrl } from '@app/app-local-url';
import { AlertService, DistileryService, WorkflowcofigService } from '@app/services';
import { BrandlabelbrewService } from '@app/services/pd';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-view-omd-stock-in',
  templateUrl: './view-omd-stock-in.component.html',
  styleUrls: ['./view-omd-stock-in.component.scss']
})
export class ViewOmdStockInComponent implements OnInit {
  workflowSubModule: any;
  moduleName: any;
  entityType: any;
  registrationType: any;
  url: any;
  designation: string;
  isCustomer: boolean;
  viewId: any;
  editObject: any;
  applicationNumber: any;
  logactive: boolean = false;
  recordstatus: string;
  sendBacklist: any;
  logshow: any;
  brandForm: FormGroup;
  workflowdata: any;
  confirmStyatus: string = "";
  formSubmitted: boolean;
  @ViewChild('exampleModalCenter', { static: false }) exampleModalCenter;
  isApprovedLoading = false;
  isRejectLoading = false;
  isClarificationLoading = false;
  licenseTypes:any=[]
  licenseDetails: any;
  licenseAddress: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private distilleryService: DistileryService,
    private worfFlowService: WorkflowcofigService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private brandlabelbrewService: BrandlabelbrewService
  ) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const subUrlManage = currentUrlArray[1] + currentUrlArray[3];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;

    this.createFormGrp();
    this.route.params.subscribe(params => {
      if (params.id) {
        this.viewId = params.id;
        this.getbyiddata(params.id);
      }
    })
  }
  getbyiddata(id) {
    this.distilleryService.getStockinOmd(id).subscribe((responceData: any) => {
      if (responceData.status === 's') {
        this.editObject = responceData.data;
        this.applicationNumber = this.editObject.applicationNumber;
      }
      this.getapprovallogsList();
      this.getworkflow();
      // this.getLicenseDetails(this.editObject.licenseNumber)
    })
  }
  async getLicenseDetails(value){
    let response: any = await this.brandlabelbrewService.getOmdUserLicenese();
    // .subscribe((response: any) => {
    // this.licenseTypes = response.content
    response.content.forEach(element => {
        this.licenseTypes.push(element)
    });
    this.licenseDetails= this.licenseTypes.filter(res=>res.licenseNumber == value)
      this.licenseAddress= this.licenseDetails[0].street +','+this.licenseDetails[0].tahsilDesc +
      ','+ this.licenseDetails[0].districtDesc + ',' +this.licenseDetails[0].stateDesc + 
      ',' +this.licenseDetails[0].countryDesc
  }
  getapprovallogsList() {
    this.worfFlowService.getapprovallogs(this.applicationNumber)
      .subscribe((resp: any) => {
        if (resp) {
          this.logactive = true;
          if (this.recordstatus == 'SENDBACK' && resp.length > 0) {
            this.sendBacklist = resp.filter((x) => x.action == 'SENDBACK');
            this.logshow = this.sendBacklist;
          } else {
            this.logshow = resp;
          }
        }
      });
  }
  createFormGrp() {
    this.brandForm = this.formBuilder.group({
      remark: ['', Validators.required],
      sendBackLevel: ['']
    });
  }
  getworkflow() {
    this.worfFlowService.getworkflowbydesignation(this.entityType, this.workflowSubModule).subscribe((resp: any) => {
      this.workflowdata = resp.content;
      if (this.workflowdata) {
        if (this.workflowdata.isSendBack != undefined && this.workflowdata.isSendBack) {
          // console.log(this.editObject)
          // console.log(this.editObject.sendBackLevel)
          this.brandForm.controls['sendBackLevel'].setValidators(Validators.required);
        } else {
          this.brandForm.controls['sendBackLevel'].setErrors(null);
        }
      } else {
        this.brandForm.controls['sendBackLevel'].setErrors(null);
      }
    });
  }
  public captureScreen() {

    let payload = {
      applicationNumber: this.editObject.applicationNumber,
      id: this.editObject.id,
      // licenseNumber: this.editObject.licenseNumber,
      // entityType: this.editObject.entityType,
      receiptType: 'OMD_STOCK_IN'
    }
    this.distilleryService.downloadData(payload).subscribe(
      (resp: any) => fileSaver.saveAs(resp, this.editObject.applicationNumber.replace(/\//g, '_') + '_OMD_STOCK_IN'),
      (error: any) => this.alert.showError('Error downloading the file'),
      () => console.info('File downloaded successfully')
    );

  }
  showConfirmation(status, form) {
    this.confirmStyatus = status;
    this.formSubmitted = false;

    if (status == 'SENDBACK') {
      this.brandForm.get('sendBackLevel').setValidators(Validators.required);
      this.brandForm.controls['sendBackLevel'].updateValueAndValidity();
    }

    else {
      this.brandForm.patchValue({
        sendBackLevel: ''
      })
      this.brandForm.get('sendBackLevel').setErrors(null);
      // this.brandForm.controls['sendBackLevel'].updateValueAndValidity();

    }

    if (form.valid === false) {
      this.formSubmitted = true;
    }
    else {
      this.toggleLoading(status)
      this.exampleModalCenter.show()
    }

  }
  close() {
    this.exampleModalCenter.hide()
    this.isApprovedLoading = false
    this.isRejectLoading = false;
    this.isClarificationLoading = false
  }
  get brandform() {
    return this.brandForm.controls;
  }
  toggleLoading(saveType) {
    switch (saveType) {
      case 'APPROVED':
        this.isApprovedLoading = !this.isApprovedLoading;
        break;
      case 'REJECTED':
        console.log(!this.isRejectLoading)
        this.isRejectLoading = !this.isRejectLoading;
        break;
      case 'SENDBACK':
        console.log(!this.isClarificationLoading)
        this.isClarificationLoading = !this.isClarificationLoading;
        break;
    }
  }
 feedback(data) {
    const approveObj = {
      applicationNumber: this.editObject.applicationNumber,
      id: parseInt(this.viewId),
      isDigitalSignature: this.workflowdata ? this.workflowdata.isDigitalSignature : true,
      event: data,
      stage: this.editObject.stage,
      stageDesc: this.editObject.stageDesc,
      comments: this.brandForm.value.remark,
      status: data,
      sentBackLevels: this.brandForm.value.sendBackLevel ? this.brandForm.value.sendBackLevel : '',
      level: this.workflowdata ? this.workflowdata.level : 'Level 2',
      entityType:this.entityType
    };

    console.log(approveObj);
    this.distilleryService.saveStockinOmd(approveObj).subscribe((responceData: any) => {
      if (responceData.errorCode == 201) {
        this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
        this.router.navigate(['/' + this.url + '/omd/omd-stock-in/list']);

      } else {
        this.alert.showError(responceData.userDisplayMesg, 'error');
      }
    })
  }
}
